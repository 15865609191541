import {Dropdown, Radio, Space} from 'antd';
import EmbedLayout from '../../EmbedLayout';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {CURRENCY_MAPPING, VENDOR_PAYMENT_MODE_MAPPING} from '../../config';
import {convertMappingToArray, sortByProperty} from '../../helpers/utils';
import {useCategoryApi} from '../../repositories/useCategoryApi';
import React, {useEffect} from 'react';
import {usePlanApi} from '../../repositories/usePlanApi';
import MAIcon from '../../components/ui/icon/MAIcon';
import styles from './TablePlanOnPricing.module.scss';

dayjs.extend(customParseFormat);

export default function TablePlanOnPricing() {
  const {actions: actionsCategory, data: dataCategory, state: stateCategory} = useCategoryApi();
  const {actions: actionsPlan, data: dataPlan, state: statePlan} = usePlanApi();
  const [paymentMode, setPaymentMode] = React.useState<any>(VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value);
  const [currency, setCurrency] = React.useState<any>(CURRENCY_MAPPING.HKD);

  useEffect(() => {
    actionsCategory.getItems({
      limit: 0,
    });
    actionsPlan.getItems({
      limit: 0,
    });
  }, []);

  const options = [
    {label: <span className={'text-706E66 bodyLarge'}>Monthly</span>, value: VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value},
    {
      label: (
        <span className={'text-706E66 bodyLarge'}>
          Annually <span className={'text-[#8A6FE8]'}>Save {VENDOR_PAYMENT_MODE_MAPPING.YEARLY.discount}%</span>
        </span>
      ),
      value: VENDOR_PAYMENT_MODE_MAPPING.YEARLY.value,
    },
  ];

  const items = convertMappingToArray(CURRENCY_MAPPING).map((item, index) => {
    return {
      label: (
        <div
          onClick={() => {
            setCurrency(item);
          }}
          className={`cursor-pointer ${currency.key === item.key ? 'text-7E38B7' : ''}`}
        >
          {item.label}
        </div>
      ),
      key: item.label,
    };
  });

  // const items: MenuProps['items'] = [
  //   {
  //     label: (
  //       <div
  //         onClick={() => {
  //           // onResendEmployees(selectedRowKeys);
  //         }}
  //         className='cursor-pointer'
  //       >
  //         Resend activation email
  //       </div>
  //     ),
  //     key: '0',
  //   },
  //   {
  //     label: (
  //       <div
  //         onClick={() => {
  //           // setIsOpenModalConfirmDeactive(true);
  //         }}
  //         className='cursor-pointer'
  //       >
  //         Deactivate
  //       </div>
  //     ),
  //     key: '1',
  //   },
  //   {
  //     label: (
  //       <div
  //         onClick={() => {
  //           // setIsOpenModalConfirmDeleteMany(true);
  //         }}
  //         className='cursor-pointer'
  //       >
  //         Delete
  //       </div>
  //     ),
  //     key: '2',
  //   },
  // ];

  return (
    <EmbedLayout
      colors={{
        primary: '7E38B7',
        secondary: 'F6EAFF',
      }}
    >
      <div className={styles.moduleWrapper}>
        <div className={'mx-auto max-w-[1024px] bg-F5F5F5 text-center flex items-start justify-start flex-col mb-[20px]'}>
          <div className={'pricingHeading flex items-center justify-between w-full max-w-[100vw]'}>
            <Radio.Group
              options={options}
              onChange={e => {
                setPaymentMode(e.target.value);
              }}
              value={paymentMode}
            />

            <div>
              <Dropdown menu={{items}} trigger={['click']}>
                <a className='cursor-pointer text-706E66' onClick={e => e.preventDefault()}>
                  <Space>
                    {currency.label}
                    <MAIcon name='arrowDown' />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className={'w-full overflow-y-hidden overflow-x-auto bg-F5F5F5'}>
          <div className={'flex flex-row gap-[20px] min-w-full w-[1024px] p-[2px] justify-center'}>
            {sortByProperty(dataPlan?.listItems, 'price_monthly').map((plan: any) => {
              return (
                <div className={`w-[328px] bg-white rounded-[8px] text-left py-[25px] px-[30px]`}>
                  <div className={'text-7E38B7 titleExtraLarge mb-[8px]'}>{plan.title}</div>
                  <div className={'flex items-center justify-start gap-[8px] mb-[10px]'}>
                    <div className='left displayMedium'>
                      $
                      {Number(
                        (paymentMode === VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value ? plan.price_monthly : plan.price_yearly) * currency.value
                      ).toFixed(0)}
                    </div>
                    <div className='right labelMedium'>
                      {currency.key}
                      <br />/{paymentMode === VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value ? 'MO' : 'YR'}
                    </div>
                  </div>

                  <div className={'labelMedium mb-[24px]'}>{plan.short_description}</div>

                  <a
                    href={'/vendors#vendor-form'}
                    className={
                      'rounded-[100px] text-white visited:text-white bg-7E38B7 py-[10px] flex items-center justify-center w-full text-center mb-[24px]'
                    }
                  >
                    Sign up now
                  </a>

                  {plan.features.map((feature: any, index: number) => {
                    return (
                      <div key={index} className={'flex items-start justify-start gap-[8px] bodyMedium'}>
                        <div className={'mt-[4px]'}>
                          <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M13 1.19995L5.8 10.8L1 5.99995'
                              stroke='#7E38B7'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <div>{feature}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </EmbedLayout>
  );
}
