import {useEffect} from 'react';
import {EnterpriseLayout} from '../../layouts/enterprise/EnterpriseLayout';

export default function EnterpriseReports() {
  useEffect(() => {}, []);
  return (
    <EnterpriseLayout>
      <center>
        <h2 className={'mt-[100px]'}>Enterprise programs</h2>
      </center>
    </EnterpriseLayout>
  );
}
