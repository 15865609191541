import {FormInstance} from 'antd/lib';
import {Form} from 'antd';
import {FormFileUploader} from '../../components/shared/file-uploader/FormImageUploader';

export const ProfileBuilderStep5 = ({step5Form}: {step5Form: FormInstance}) => {
  return (
    <div className='w-full flex flex-col gap-y-[40px]'>
      <div className='flex flex-col w-full'>
        <span className='titleLarge'>Add any certifications you’d like to showcase</span>
        <span className='bodyExtraLarge text-5A5A5A'>Share qualifications, awards or recognitions your customers should know about.</span>
      </div>
      <div className='gap-[20px]'>
        <Form form={step5Form} name='showcases' layout='vertical' size='large' className='w-full'>
          <Form.Item rules={[{required: true, message: 'Showcase file is required'}]} name='showcasePhotos'>
            <FormFileUploader name='showcasePhotos' accept={'*'} multiple maxCount={5} className={'w-full'} draggerClassName='flex flex-col' />
          </Form.Item>
          <div className='flex flex-col gap-y-[8px]'>
            <span className='bodySmall !font-semibold'>Add your logo</span>
            <span className='bodySmall text-5A5A5A'>Upload a JPEG or PNG with 500x500px proportions.</span>
            <Form.Item rules={[{required: true, message: 'Logo is required'}]} name='logo'>
              <FormFileUploader accept={'image/*'} className={'w-full'} />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
