import useRestApi from '../hooks/useRestApi';

interface VendorRegisterApi {
  actions: {
    createItem: (values: Record<string, any>) => Promise<any>;
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useVendorRegisterApi(): VendorRegisterApi {
  return useRestApi('register-vendor');
}
