import axios, {AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {CONFIG} from '../config';
import {LOCAL_STORAGE_KEY} from '../helpers/utils';

declare global {
  interface Window {
    counter: number;
    custom_axios?: AxiosInstance;
  }
}

window.counter = 0;

function handleLoading(): void {
  document.querySelector('body')?.setAttribute('loading', window.counter > 0 ? 'true' : 'false');
}

export function getAxios(): AxiosInstance {
  if (!window.custom_axios) {
    window.custom_axios = axios.create({
      baseURL: `${CONFIG.apiEndpoint}`,
    });

    window.custom_axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      window.counter++;
      handleLoading();

      const token = localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    });

    window.custom_axios.interceptors.response.use(
      (response: AxiosResponse) => {
        window.counter--;
        handleLoading();

        return response;
      },
      (error: any) => {
        window.counter--;
        handleLoading();

        try {
          if (error.response) {
            console.log('DEBUG error', error.response);

            // Handle status code
            const statusCode = error.response.status;
            console.log('Status code:', statusCode);

            if (statusCode === 401) {
              // Clean up
              localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
              localStorage.removeItem(LOCAL_STORAGE_KEY.STEP_DATA);
              window.location.href = '/auth/login';
              return;
            }

            // Handle error
            // const serverError = error.response.data;
            // const errors = parseServerError(serverError);
            // errors.forEach(errorMessage => message.error(errorMessage, 5));
          } else {
            // The request was made but no response was received
            console.log('Error', error.message);
          }
        } catch (e) {
          // No handle
        }

        return Promise.reject(error);
      }
    );
  }

  return window.custom_axios;
}
