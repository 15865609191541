import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useEmployeeApi} from '../../../../repositories/useEmployeeApi';
import React, {useEffect, useState} from 'react';
import {EnterpriseLayout} from '../../../../layouts/enterprise/EnterpriseLayout';
import {EmployeeStatusLabel} from '../../../../components/shared/EmployeeStatusLabel';
import MAIcon from '../../../../components/ui/icon/MAIcon';
import MAButton from '../../../../components/ui/button/MAButton';
import {Breadcrumb, Dropdown, Form, message, Select, Table, Tooltip} from 'antd';
import EmployeeEditModal from '../../../../components/pages/enterprise/employees/detail/EmployeeEditModal';
import {convertMappingToArray, formatDate, handleApiError} from '../../../../helpers/utils';
import {DeletionModal} from '../../../../components/ui/modals/DeletionModal';
import {SuccessModal} from '../../../../components/ui/modals/SuccessModal';
import {DELETABLE_STATUS, EMPLOYEE_STATUSES, MESSAGES} from '../../../../config';

function EnterpriseEmployeeDetailPage() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const programId = searchParams.get('programId');
  const [form] = Form.useForm();

  const {actions: actionsEmployee, data: dataEmployee, state: stateEmployee} = useEmployeeApi();
  const [isOpenModalConfirmDeleteOne, setIsOpenModalConfirmDeleteOne] = useState(false);
  const [isOpenModalConfirmDeleteReadyToLanch, setIsOpenModalConfirmDeleteReadyToLanch] = useState(false);
  const [isOpenModalConfirmDeleteLive, setIsOpenModalConfirmDeleteLive] = useState(false);
  const [isOpenModalConfirmDeactive, setIsOpenModalConfirmDeactive] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState({value: false, title: '', content: ''});
  const [programDataPoint, setProgramDataPoint] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('DEBUG params', params.id);
    (async () => {
      try {
        await actionsEmployee.getItem(params.id);
      } catch (error) {
        navigate('/404');
      }
    })();
  }, [params]);

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
  ];

  const dropDownMenuItems = [
    ...(dataEmployee?.detail?.status === EMPLOYEE_STATUSES.INACTIVE.value
      ? [
          {
            key: '1',
            label: (
              <div
                onClick={() => {
                  onResendEmployees([params.id]);
                }}
              >
                Resend activation email
              </div>
            ),
          },
        ]
      : []),
    {
      key: '2',
      label: (
        <div
          onClick={() => {
            setIsOpenModalConfirmDeactive(true);
          }}
        >
          Deactivate
        </div>
      ),
    },
  ];

  // Mock data source
  const dataSource = [
    {
      date: 'Sample date',
      activity: 'Sample activity',
      vendor: 'Sample vendor',
      status: 'Sample status',
      amount: 'Sample amount',
      balance: 'Sample balance',
    },
    {
      date: 'Sample date',
      activity: 'Sample activity',
      vendor: 'Sample vendor',
      status: 'Sample status',
      amount: 'Sample amount',
      balance: 'Sample balance',
    },
    {
      date: 'Sample date',
      activity: 'Sample activity',
      vendor: 'Sample vendor',
      status: 'Sample status',
      amount: 'Sample amount',
      balance: 'Sample balance',
    },
    {
      date: 'Sample date',
      activity: 'Sample activity',
      vendor: 'Sample vendor',
      status: 'Sample status',
      amount: 'Sample amount',
      balance: 'Sample balance',
    },
    {
      date: 'Sample date',
      activity: 'Sample activity',
      vendor: 'Sample vendor',
      status: 'Sample status',
      amount: 'Sample amount',
      balance: 'Sample balance',
    },
    {
      date: 'Sample date',
      activity: 'Sample activity',
      vendor: 'Sample vendor',
      status: 'Sample status',
      amount: 'Sample amount',
      balance: 'Sample balance',
    },
    {
      date: 'Sample date',
      activity: 'Sample activity',
      vendor: 'Sample vendor',
      status: 'Sample status',
      amount: 'Sample amount',
      balance: 'Sample balance',
    },
  ];

  const handleDeleteWithStatus = () => {
    if (dataEmployee.detail.deletable_status === DELETABLE_STATUS.DELETE_ONE) {
      setIsOpenModalConfirmDeleteOne(true);
    } else if (dataEmployee.detail.deletable_status === DELETABLE_STATUS.DELETE_READY_TO_LANCH) {
      setIsOpenModalConfirmDeleteReadyToLanch(true);
    } else if (dataEmployee.detail.deletable_status === DELETABLE_STATUS.DELETE_LIVE) {
      setIsOpenModalConfirmDeleteLive(true);
    } else {
      setIsOpenModalConfirmDeleteOne(true);
    }
  };
  async function handleOnDeleteEmployees(ids: any[]) {
    try {
      await actionsEmployee.customDelete('', {
        ids,
      });
      message.success('Deleted employees successfully');
      navigate('/enterprise/employees');
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error deleting employees');
    }
  }
  async function onDeactivateEmployees(ids: any[]) {
    try {
      await actionsEmployee.customPost('deactive', {
        ids,
      });
      setIsOpenSuccess({value: true, title: 'Employee successfully deactivated!', content: '  '});
      await actionsEmployee.getItem(params.id);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error deleting employees');
    }
  }
  async function onResendEmployees(ids: any[]) {
    try {
      await actionsEmployee.customPost('resend-activation-email', {
        ids,
      });
      message.success('Resent activation email successfully');
      await actionsEmployee.getItem(params.id);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error resend employees');
    }
  }
  const updateEmployee = async (id: any, values: any) => {
    try {
      await actionsEmployee.updateItem(id, {
        user_detail: {
          first_name: values.firstName,
          last_name: values.lastName,
          contact_number: values.phoneNumber,
        },
        department_id: values.department,
      });
      setIsShowEditModal(false);
      message.success(MESSAGES.MSG16);
      await actionsEmployee.getItem(params.id);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error updating employee');
    }
  };
  const checkIsDeActive = dataEmployee?.detail?.status === EMPLOYEE_STATUSES.DEACTIVATE.value;

  return (
    <EnterpriseLayout>
      <div className='flex gap-[24px] flex-col'>
        {programId ? (
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={`/enterprise/programs/${programId}/overview`}>
                    <div className={'flex items-center justify-center gap-[5px]'}>
                      <MAIcon size={20} name={'previous'} />
                      <div>Programme</div>
                    </div>
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={`/enterprise/programs/${programId}/employees`}>
                    <div className={'flex items-center justify-center gap-[5px]'}>
                      <div>Employees</div>
                    </div>
                  </Link>
                ),
              },
              {
                title: (
                  <strong className={'text-2A2A2A font-semibold'}>
                    {dataEmployee?.detail?.user_detail?.first_name} {dataEmployee?.detail?.user_detail?.last_name}
                  </strong>
                ),
              },
            ]}
          />
        ) : (
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={`/enterprise/employees`}>
                    <div className={'flex items-center justify-center gap-[5px]'}>
                      <MAIcon size={20} name={'previous'} />
                      <div>Employees</div>
                    </div>
                  </Link>
                ),
              },
              {
                title: (
                  <strong className={'text-2A2A2A font-semibold'}>
                    {dataEmployee?.detail?.user_detail?.first_name} {dataEmployee?.detail?.user_detail?.last_name}
                  </strong>
                ),
              },
            ]}
          />
        )}

        <div className='titleAndCtas w-full flex justify-between items-center'>
          <div className='left flex gap-[12px]'>
            <div className='title titleLarge'>
              {dataEmployee?.detail?.user_detail?.first_name} {dataEmployee?.detail?.user_detail?.last_name}
            </div>
            <EmployeeStatusLabel statusId={dataEmployee?.detail?.status} />
          </div>
          <div className='right flex items-center gap-[20px]'>
            {!programId ? (
              <div>
                {!checkIsDeActive && (
                  <Dropdown placement='bottomRight' trigger={['click']} menu={{items: dropDownMenuItems}}>
                    <div className={`flex items-center gap-[4px] cursor-pointer bodyMedium`}>
                      <div>More options</div>
                      <MAIcon name={'arrowDown'} />
                    </div>
                  </Dropdown>
                )}
              </div>
            ) : (
              <div onClick={handleDeleteWithStatus} className={'flex items-center gap-[4px] cursor-pointer bodyMedium'}>
                <MAIcon name={'delete'} />
                <div>Delete</div>
              </div>
            )}
            {dataEmployee?.detail?.status !== EMPLOYEE_STATUSES.DEACTIVATE.value && (
              <MAButton>
                <div
                  className={'flex items-center gap-[8px]'}
                  onClick={() => {
                    setIsShowEditModal(true);
                  }}
                >
                  <MAIcon name={'edit'} />
                  <div>Edit</div>
                </div>
              </MAButton>
            )}

            {isShowEditModal && (
              <EmployeeEditModal
                isEdit={true}
                title='Edit Employee'
                onSave={values => {
                  console.log('bb values', values);

                  updateEmployee(params.id, values);
                }}
                currentInfo={{
                  firstName: dataEmployee?.detail?.user_detail?.first_name,
                  lastName: dataEmployee?.detail?.user_detail?.last_name,
                  department: dataEmployee?.detail?.department || null,
                  email: dataEmployee?.detail?.user?.email,
                  phoneNumber: dataEmployee?.detail?.user_detail?.contact_number,
                  status: 'Active',
                }}
                onCancel={() => {
                  setIsShowEditModal(false);
                }}
              />
            )}
            <DeletionModal
              title={'Remove Employee'}
              content={'Are you sure you want to delete this employee and related activities?'}
              isOpen={isOpenModalConfirmDeleteOne}
              onCancel={() => {
                setIsOpenModalConfirmDeleteOne(false);
              }}
              onOk={() => {
                setIsOpenModalConfirmDeleteOne(false);
                handleOnDeleteEmployees([params.id]);
              }}
            />
            {/* DeleteReadyToLanch */}
            <DeletionModal
              title={'Delete Employee'}
              content={
                <div>
                  The employees are taking part in existing programs. E-contracts of the programs will be re-generated if deleting the employees.{' '}
                  <br /> <br />
                  Are you sure you want to delete this employee and related activities?
                </div>
              }
              isOpen={isOpenModalConfirmDeleteReadyToLanch}
              onCancel={() => {
                setIsOpenModalConfirmDeleteReadyToLanch(false);
              }}
              onOk={async () => {
                setIsOpenModalConfirmDeleteReadyToLanch(false);
                handleOnDeleteEmployees([params.id]);
              }}
            />
            {/* DeleteLive */}
            <DeletionModal
              title={'Delete Employee'}
              content={'This action cannot be performed because it is unable to delete the enterprise program’s participations.'}
              isOpen={isOpenModalConfirmDeleteLive}
              onCancel={() => {
                setIsOpenModalConfirmDeleteLive(false);
              }}
            />
            {/* Deactive */}
            <DeletionModal
              title={'Deactivate Employee'}
              content={<div>Are you sure you want to deactivate this employee? Their dashboard access will be denied immediately.</div>}
              isOpen={isOpenModalConfirmDeactive}
              onCancel={() => {
                setIsOpenModalConfirmDeactive(false);
              }}
              onOk={async () => {
                setIsOpenModalConfirmDeactive(false);
                onDeactivateEmployees([params.id]);
              }}
              textOk='Deactivate'
            />
            <SuccessModal
              onCancel={() => setIsOpenSuccess({value: false, title: '', content: ''})}
              title={isOpenSuccess.title}
              content={isOpenSuccess.content}
              isOpen={isOpenSuccess.value}
            />
          </div>
        </div>
        <div className={'flex flex-col gap-0'}>
          <div className='blockInfoAndPoints flex flex-col'>
            <div className='info bg-white px-[24px] py-[20px] rounded-[4px] grid grid-cols-4 gap-[24px]'>
              <div className={'flex flex-col gap-[4px]'}>
                <div className='label labelSmall text-primary'>Email</div>
                <div className='body bodyMedium'>{dataEmployee?.detail?.user?.email}</div>
              </div>
              <div className={'flex flex-col gap-[4px]'}>
                <div className='label labelSmall text-primary'>Contact Number</div>
                <div className='body bodyMedium'>{dataEmployee?.detail?.user_detail?.contact_number}</div>
              </div>
              <div className={'flex flex-col gap-[4px]'}>
                <div className='label labelSmall text-primary'>Active since</div>
                <div className='body bodyMedium'>
                  {dataEmployee?.detail?.user_detail?.active_since ? formatDate(dataEmployee?.detail?.user_detail?.active_since) : '-'}
                </div>
              </div>

              <div></div>

              <div className={'flex flex-col gap-[4px]'}>
                <div className='label labelSmall text-primary'>Department</div>
                <div className='body bodyMedium'>{dataEmployee?.detail?.department?.name || '-'}</div>
              </div>
              <div className={'flex flex-col gap-[4px]'}>
                <div className='label labelSmall text-primary'>Location</div>
                <div className='body bodyMedium'>-</div>
              </div>
              <div className={'flex flex-col gap-[4px]'}>
                <div className='label labelSmall text-primary'>Last login</div>
                <div className='body bodyMedium'>
                  {dataEmployee?.detail?.user_detail?.last_Login ? formatDate(dataEmployee?.detail?.user_detail?.last_Login) : '-'}
                </div>
              </div>

              <div></div>
            </div>
          </div>

          {!programId &&
            (dataEmployee?.detail?.programs?.length > 0 ? (
              <div className='programFilter my-[20px]'>
                <Form form={form} layout={'vertical'}>
                  <Form.Item label={'Program'} className={'mb-0 w-[325px]'}>
                    <Select
                      defaultValue={dataEmployee?.detail?.programs?.[0]?.id}
                      onChange={(value: any) => {
                        const findProgram = dataEmployee?.detail?.programs?.find((item: any) => item.id === value);
                        console.log('bb findProgram', findProgram);

                        setProgramDataPoint(findProgram);
                      }}
                      placeholder='Select option'
                      options={dataEmployee?.detail?.programs?.map((item: any) => ({label: item?.name, value: item?.id}))}
                    ></Select>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <p className='mt-[20px] bodyMedium text-5A5A5A'>Program</p>
            ))}
          {dataEmployee?.detail?.programs?.length > 0 ? (
            <div className='blockInfoAndPoints flex flex-col'>
              <div className='points bg-secondary px-[24px] py-[20px] rounded-[4px] grid grid-cols-4 gap-[24px]'>
                <div className={'flex flex-col gap-[4px]'}>
                  <div className='label labelMedium text-primary'>Activities Redeemed</div>
                  <div className='body displaySmall'>0</div>
                </div>
                <div className={'flex flex-col gap-[4px]'}>
                  <div className='label labelMedium text-primary flex items-center gap-[5px]'>
                    <div>Total Used Points</div>
                    <Tooltip title='TODO Show text here!' color={'var(--primary)'} key={'008080'}>
                      <MAIcon name={'info'} />
                    </Tooltip>
                  </div>
                  <div className='body displaySmall'>{dataEmployee?.detail?.total_point || 0}</div>
                </div>
                <div className={'flex flex-col gap-[4px]'}>
                  <div className='label labelMedium text-primary flex items-center gap-[5px]'>
                    <div>Remaining Balance</div>
                    <Tooltip title='TODO Show text here!' color={'var(--primary)'} key={'008080'}>
                      <MAIcon name={'info'} />
                    </Tooltip>
                  </div>
                  <div className='body displaySmall'>4,000</div>
                </div>
                <div className={'flex flex-col gap-[4px]'}>
                  <div className='label labelMedium text-primary flex items-center gap-[5px]'>
                    <div>Allotted Points</div>
                    <Tooltip title='TODO Show text here!' color={'var(--primary)'} key={'008080'}>
                      <MAIcon name={'info'} />
                    </Tooltip>
                  </div>
                  <div className='body displaySmall'>4,000</div>
                </div>
              </div>
            </div>
          ) : (
            <div className='text-center text-7D7D7A'>There are no programs</div>
          )}
        </div>

        {dataEmployee?.detail?.programs?.length > 0 ? (
          <>
            <hr className={'text-E2E2E2'} />
            <div className='title titleLarge'>Transactions</div>
            <Table
              locale={{
                emptyText: <div className='flex justify-center flex-col items-center gap-[12px] py-[50px]'>{'No data found'}</div>,
              }}
              loading={false}
              pagination={false}
              dataSource={dataSource}
              columns={columns}
            />
          </>
        ) : null}
      </div>
    </EnterpriseLayout>
  );
}

export default EnterpriseEmployeeDetailPage;
