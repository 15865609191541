/* eslint-disable @typescript-eslint/no-var-requires */

import React from 'react';
import {MdOutlineEdit, MdOutlineVerified} from 'react-icons/md';
import {BsEye, BsUpload} from 'react-icons/bs';
import {IoIosCloseCircle, IoIosMail, IoIosSearch, IoMdInformationCircleOutline} from 'react-icons/io';
import {HiOutlineChartBarSquare} from 'react-icons/hi2';
import {AiOutlineLoading3Quarters, AiOutlineMessage} from 'react-icons/ai';
import {CiBookmarkCheck, CiCircleCheck, CiFilter, CiWarning} from 'react-icons/ci';
import {IoCalendarOutline, IoChevronDownSharp} from 'react-icons/io5';
import {FaFileAlt} from 'react-icons/fa';
import {LuFileText} from 'react-icons/lu';
import {TiBusinessCard} from 'react-icons/ti';
import {GoXCircleFill} from 'react-icons/go';
import PickImage from '../../../assets/icons/pick-mix.svg';
import IndividualImage from '../../../assets/icons/individual.svg';
import BulkImage from '../../../assets/icons/bulk.svg';
import CustomImage from '../../../assets/icons/custom.svg';
import CartList from '../../../assets/icons/cartlist.svg';
import Refresh from '../../../assets/icons/refresh.svg';
import ShopIcon from '../../../assets/icons/shop.svg';
import PersonInfo from '../../../assets/icons/person-info.svg';
import Security from '../../../assets/icons/security.svg';
import Notification from '../../../assets/icons/notification.svg';
import ContractIcon from '../../../assets/icons/contract.svg';
import DeleteIcon from '../../../assets/icons/delete.svg';
import {FaSortDown} from 'react-icons/fa6';
import {GrFormNextLink, GrFormPreviousLink} from 'react-icons/gr';

const svgMapping: any = {};
// eslint-disable-next-line @typescript-eslint/no-var-requires
(svgMapping['fitnessTraining'] = require('!raw-loader!../../../assets/icons/fitnessTraining.svg').default),
  (svgMapping['holisticMedicineAndTherapy'] = require('!raw-loader!../../../assets/icons/holisticMedicineAndTherapy.svg').default),
  (svgMapping['financialEducation'] = require('!raw-loader!../../../assets/icons/financialEducation.svg').default),
  (svgMapping['homeCareAndDecoration'] = require('!raw-loader!../../../assets/icons/homeCareAndDecoration.svg').default),
  (svgMapping['nutritionAndWeightManagement'] = require('!raw-loader!../../../assets/icons/nutritionAndWeightManagement.svg').default),
  (svgMapping['beautySkincareAndGrooming'] = require('!raw-loader!../../../assets/icons/beautySkincareAndGrooming.svg').default),
  (svgMapping['meditationAndMindfulness'] = require('!raw-loader!../../../assets/icons/meditationAndMindfulness.svg').default),
  (svgMapping['fitnessApparelAndTools'] = require('!raw-loader!../../../assets/icons/fitnessApparelAndTools.svg').default),
  (svgMapping['healthyFoodAndSupplements'] = require('!raw-loader!../../../assets/icons/healthyFoodAndSupplements.svg').default),
  (svgMapping['insuranceService'] = require('!raw-loader!../../../assets/icons/insuranceService.svg').default),
  (svgMapping['wearableDevices'] = require('!raw-loader!../../../assets/icons/wearableDevices.svg').default),
  (svgMapping['counsellingAndTherapy'] = require('!raw-loader!../../../assets/icons/counsellingAndTherapy.svg').default),
  (svgMapping['fashionAndAccessories'] = require('!raw-loader!../../../assets/icons/fashionAndAccessories.svg').default),
  (svgMapping['booksAndMedia'] = require('!raw-loader!../../../assets/icons/booksAndMedia.svg').default),
  (svgMapping['onlineLearning'] = require('!raw-loader!../../../assets/icons/onlineLearning.svg').default),
  (svgMapping['mealKitsAndRecipeBox'] = require('!raw-loader!../../../assets/icons/mealKitsAndRecipeBox.svg').default),
  (svgMapping['sleepTherapy'] = require('!raw-loader!../../../assets/icons/sleepTherapy.svg').default),
  (svgMapping['restaurantsBarsAndCafes'] = require('!raw-loader!../../../assets/icons/restaurantsBarsAndCafes.svg').default),
  (svgMapping['employeeBenefitsAndRetirement'] = require('!raw-loader!../../../assets/icons/employeeBenefitsAndRetirement.svg').default),
  (svgMapping['fragrancesAndAromatherapy'] = require('!raw-loader!../../../assets/icons/fragrancesAndAromatherapy.svg').default),
  (svgMapping['stressManagement'] = require('!raw-loader!../../../assets/icons/stressManagement.svg').default),
  (svgMapping['socialConnections'] = require('!raw-loader!../../../assets/icons/socialConnections.svg').default),
  (svgMapping['stayPhysicallyFit'] = require('!raw-loader!../../../assets/icons/stayPhysicallyFit.svg').default),
  (svgMapping['healthyEating'] = require('!raw-loader!../../../assets/icons/healthyEating.svg').default),
  (svgMapping['stayMentallyFit'] = require('!raw-loader!../../../assets/icons/stayMentallyFit.svg').default),
  (svgMapping['financialAwareness'] = require('!raw-loader!../../../assets/icons/financialAwareness.svg').default),
  (svgMapping['sleepingWell'] = require('!raw-loader!../../../assets/icons/sleepingWell.svg').default),
  (svgMapping['learnNewThings'] = require('!raw-loader!../../../assets/icons/learnNewThings.svg').default),
  (svgMapping['parenting'] = require('!raw-loader!../../../assets/icons/parenting.svg').default),
  (svgMapping['preventingAccidents'] = require('!raw-loader!../../../assets/icons/preventingAccidents.svg').default),
  (svgMapping['healthAssessment'] = require('!raw-loader!../../../assets/icons/healthAssessment.svg').default),
  (svgMapping['stayDigitallySavvy'] = require('!raw-loader!../../../assets/icons/stayDigitallySavvy.svg').default),
  console.log('DEBUG svgMapping', svgMapping);

interface MAIconProps {
  color?: string;
  className?: string;
  name:
    | 'edit'
    | 'delete'
    | 'verified'
    | 'eye'
    | 'search'
    | 'chart'
    | 'spinner'
    | 'previewDocument'
    | 'message'
    | 'bookmark'
    | 'calendar'
    | 'mail'
    | 'file'
    | 'businessCard'
    | 'pickImage'
    | 'individual'
    | 'bulk'
    | 'upload'
    | 'checkList'
    | 'refresh'
    | 'downArrow'
    | 'closeCircle'
    | 'customImage'
    | 'arrowDown'
    | 'filter'
    | 'remove'
    | 'checkCircle'
    | 'next'
    | 'previous'
    | 'loading'
    | 'warning'
    | 'shop'
    | 'contract'
    | 'personInfo'
    | 'security'
    | 'notification'
    | 'info'
    | string;
  size?: number | string;
  spin?: boolean;
}

const MAIcon: React.FC<MAIconProps> = ({name, ...rest}) => {
  if (rest.color === 'primary') {
    rest.color = 'var(--primary)';
  }

  if (rest.color === 'secondary') {
    rest.color = 'var(--secondary)';
  }

  switch (name) {
    case 'previewDocument':
      return <FaFileAlt {...rest} />;
    case 'remove':
      return <GoXCircleFill {...rest} />;
    case 'checkCircle':
      return <CiCircleCheck {...rest} />;
    case 'loading':
      return <AiOutlineLoading3Quarters {...rest} />;
    case 'spinner':
      return <AiOutlineLoading3Quarters className='animate-spin' {...rest} />;
    case 'warning':
      return <CiWarning {...rest} />;
    case 'businessCard':
      return <TiBusinessCard {...rest} />;
    case 'file':
      return <LuFileText {...rest} />;
    case 'mail':
      return <IoIosMail {...rest} />;
    case 'calendar':
      return <IoCalendarOutline {...rest} />;
    case 'edit':
      return <MdOutlineEdit {...rest} />;
    case 'delete':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={DeleteIcon} />;
    case 'verified':
      return <MdOutlineVerified {...rest} />;
    case 'eye':
      return <BsEye {...rest} />;
    case 'search':
      return <IoIosSearch {...rest} />;
    case 'chart':
      return <HiOutlineChartBarSquare {...rest} />;
    case 'upload':
      return <BsUpload {...rest} />;
    case 'message':
      return <AiOutlineMessage {...rest} />;
    case 'bookmark':
      return <CiBookmarkCheck {...rest} />;
    case 'downArrow':
      return <FaSortDown {...rest} />;
    case 'closeCircle':
      return <IoIosCloseCircle {...rest} />;
    case 'previous':
      return <GrFormPreviousLink {...rest} />;
    case 'next':
      return <GrFormNextLink {...rest} />;
    case 'checkList':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={CartList} />;
    case 'pickImage':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={PickImage} />;
    case 'individual':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={IndividualImage} />;
    case 'bulk':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={BulkImage} />;
    case 'customImage':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={CustomImage} />;
    case 'refresh':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={Refresh} />;
    case 'personInfo':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={PersonInfo} />;
    case 'security':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={Security} />;
    case 'notification':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={Notification} />;
    case 'arrowDown':
      return <IoChevronDownSharp {...rest} />;
    case 'info':
      return <IoMdInformationCircleOutline {...rest} />;
    case 'filter':
      return <CiFilter {...rest} />;
    case 'shop':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={ShopIcon} />;
    case 'contract':
      return <img className={`w-[${rest?.size || 26}px] h-[${rest?.size || 26}px]`} src={ContractIcon} />;
  }

  console.log('DEBUG svgMapping', svgMapping);
  if (svgMapping[name]) {
    return <span dangerouslySetInnerHTML={{__html: svgMapping[name]}} />;
  }

  return null;
};

export default MAIcon;
