import {useEffect, useState} from 'react';
import {Card, Col, Form, Input, Row} from 'antd';
import styles from '../../../../../pages/enterprise/programs/start/EnterpriseProgramStart.module.scss';
import {Step1Props} from '../../../../../types';
import {handleStepData, PROGRAM_TYPES_ENUM, STEP_NUMBERS_ENUM} from '../../../../../helpers/utils';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import MAIcon from '../../../../ui/icon/MAIcon';
import {MESSAGES} from '../../../../../config';

export default function Step1({formRef, initialValues}: Step1Props) {
  const navigate = useNavigate();
  const {id} = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const programTypeFromRouter = searchParams.get('program_type');
  const [selectedProgramType, setSelectedProgramType] = useState<string | null>(programTypeFromRouter ?? PROGRAM_TYPES_ENUM.TAILOR_MADE);

  const queryParams = new URLSearchParams(location.search);

  const updateQueryParams = (key: string, value: string) => {
    if (!queryParams.has(key)) {
      queryParams.append(key, value);
    } else {
      queryParams.set(key, value);
    }
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };
  useEffect(() => {
    if (initialValues && formRef.current) {
      formRef.current.setFieldsValue({
        programName: initialValues.programName,
        programType: initialValues.programType,
      });

      if (id) {
        updateQueryParams('program_type', initialValues.programType);
      } else {
        setSelectedProgramType(initialValues.programType);

        const params = new URLSearchParams();
        params.append('program_type', initialValues.programType);
        navigate({
          pathname: location.pathname,
          search: params.toString(),
        });
      }
    }
  }, [initialValues, formRef]);
  const handleCardClick = (value: string) => {
    setSelectedProgramType(value);
    handleStepData(STEP_NUMBERS_ENUM.STEP1, {programType: value, programName: formRef.current.getFieldValue('programName')});
    if (id) {
      updateQueryParams('program_type', value);
    } else {
      const params = new URLSearchParams();
      params.append('program_type', value);
      navigate(`/enterprise/programs/start?${params.toString()}`);
    }
  };
  return (
    <div className={`${styles.moduleWrapper} w-full flex  h-full flex-col`}>
      <Form ref={formRef}>
        <Col span={18} offset={3}>
          <p className='titleSmall'>Give your program a memorable name</p>
          <h3 className='my-3 bodySmall text-5A5A5A'>Keep your programs organised in your dashboard by giving it a name.</h3>
          <Form.Item
            name='programName'
            rules={[
              {required: true, message: MESSAGES.MSG18},
              {whitespace: true, message: MESSAGES.MSG18},
            ]}
          >
            <Input className='grayInput' size='large' maxLength={50} placeholder='My first wellness program' />
          </Form.Item>

          <p className='titleSmall mt-[40px] mb-[10px]'>Select a plan</p>

          <Row gutter={16}>
            <Col span={12}>
              <Card
                className={selectedProgramType === PROGRAM_TYPES_ENUM.CHERRY_PICK ? 'border-primary cursor-pointer border-[2px]' : ' cursor-pointer'}
                headStyle={{borderBottom: 'none'}}
                onClick={() => handleCardClick(PROGRAM_TYPES_ENUM.CHERRY_PICK)}
              >
                <MAIcon size={25} color={'primary'} name={'pickImage'} />
                <p className='titleMedium my-[10px]'>Pick & Mix</p>
                <p className='text-706E66'>Let your employees redeem products and services from the MaestroWellness network.</p>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                className={selectedProgramType === PROGRAM_TYPES_ENUM.TAILOR_MADE ? 'border-primary cursor-pointer border-[2px]' : ' cursor-pointer'}
                headStyle={{borderBottom: 'none'}}
                onClick={() => handleCardClick(PROGRAM_TYPES_ENUM.TAILOR_MADE)}
              >
                <MAIcon size={25} color={'primary'} name={'customImage'} />
                <p className='titleMedium my-[10px]'>Custom</p>
                <p className='text-706E66'>Design a custom solution best suited to your specific requirements.</p>
              </Card>
            </Col>
          </Row>
        </Col>
      </Form>
    </div>
  );
}
