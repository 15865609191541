import {useEffect} from 'react';
import {EnterpriseLayout} from '../../layouts/enterprise/EnterpriseLayout';
import {useNavigate} from 'react-router-dom';
import MAButton from '../../components/ui/button/MAButton';
import {LOCAL_STORAGE_KEY} from '../../helpers/utils';

export default function EnterpriseHome() {
  const navigate = useNavigate();

  useEffect(() => {}, []);
  return (
    <EnterpriseLayout>
      <div className='flex flex-col gap-[10rem]'>
        <div className='flex items-center justify-between'>
          <div className='left displaySmall'>Welcome Harry!</div>
          <div className='right'>
            <MAButton
              onClick={() => {
                localStorage.removeItem(LOCAL_STORAGE_KEY.STEP_DATA);
                navigate('/enterprise/programs/start?step=0&program_type=cherry_pick');
              }}
            >
              Create Program
            </MAButton>
          </div>
        </div>
        {/*<div>TODO Display charts below</div>*/}
      </div>
    </EnterpriseLayout>
  );
}
