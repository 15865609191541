import {Form, FormInstance} from 'antd';
import React, {useCallback, useState} from 'react';
import MAButton from '../../components/ui/button/MAButton';
import MAIcon from '../../components/ui/icon/MAIcon';
import {VENDOR_PROGRAM_BUILDER_STEPS} from '../../config';
import {VendorLayout} from '../../layouts/vendor/VendorLayout';
import {ProfileBuilderStep2} from './ProfileBuilderStep2';
import {ProfileBuilderStep3} from './ProfileBuilderStep3';
import {ProfileBuilderStep4} from './ProfileBuilderStep4';
import {ProfileBuilderStep5} from './ProfileBuilderStep5';
import ProfileBuilderImage1 from '../../assets/images/vendor-profile-builder-1.png';
import ProfileBuilderImage2 from '../../assets/images/vendor-profile-builder-2.png';
import ProfileBuilderImage3 from '../../assets/images/vendor-profile-builder-3.png';

const AmenitiesDefaultData = [
  {
    id: '123',
    isSelected: false,
    label: 'Wifi',
    value: 'wifi',
    icon: <MAIcon name='chart' size={32} />,
  },
  {
    id: '124',
    isSelected: false,
    label: 'Cafe',
    value: 'cafe',
    icon: <MAIcon name='chart' size={32} />,
  },
  {
    id: '125',
    isSelected: false,
    label: 'Workspace',
    value: 'workspace',
    icon: <MAIcon name='chart' size={32} />,
  },
  {
    id: '126',
    isSelected: false,
    label: 'Cafe',
    value: 'cafe',
    icon: <MAIcon name='chart' size={32} />,
  },
  {
    id: '127',
    isSelected: true,
    label: 'Workspace',
    value: 'workspace',
    icon: <MAIcon name='chart' size={32} />,
  },
  {
    id: '128',
    isSelected: false,
    label: 'Cafe',
    value: 'cafe',
    icon: <MAIcon name='chart' size={32} />,
  },
  {
    id: '129',
    isSelected: false,
    label: 'Workspace',
    value: 'workspace',
    icon: <MAIcon name='chart' size={32} />,
  },
];
const FitnessBusinesses = [
  {id: '123', label: 'HIIT', name: 'hiit', isSelected: false},
  {id: '124', label: 'Bootcamp', name: 'bootcamp', isSelected: false},
  {id: '125', label: 'All levels', name: 'allLevels', isSelected: false},
  {id: '126', label: 'Beginners', name: 'beginners', isSelected: false},
  {id: '127', label: 'Advance', name: 'advance', isSelected: false},
  {id: '128', label: 'Indoor', name: 'indoor', isSelected: false},
  {id: '129', label: 'Outdoors', name: 'outdoors', isSelected: false},
  {id: '130', label: '30 mins', name: 'thirtyMins', isSelected: false},
  {id: '131', label: 'Bodyweight', name: 'bodyweight', isSelected: false},
  {id: '132', label: '45 mins', name: 'fortyFiveMins', isSelected: false},
  {id: '133', label: 'Small group class', name: 'smallGroupClass', isSelected: false},
];

const stepTitleData: any = {
  0: {
    title: 'Tell us about your business',
    description: 'You can continue to edit your profile after you publish your profile.',
    banner: ProfileBuilderImage1,
  },
  1: {
    title: 'Make your business stand out',
    description: 'In this step, you’ll add the amenities your business offers, certifications or photos.',
    banner: ProfileBuilderImage2,
  },
  2: {
    title: 'Review and publish',
    description: 'One last thing, take a quick look before you publish your profile. ',
    banner: ProfileBuilderImage3,
  },
};

const BannerBuilderStep = ({currentStep}: {currentStep: any}) => {
  return (
    <div className='grid grid-cols-5 w-full'>
      <div className='col-span-3 flex items-center'>
        <div className='flex flex-col gap-y-[8px]'>
          <div className='flex flex-col'>
            <span>{`Step ${currentStep + 1}`}</span>
            <span className='displaySmall'>{stepTitleData[currentStep]?.title}</span>
          </div>
          <span className='bodyExtraLarge'>{stepTitleData[currentStep]?.description}</span>
        </div>
      </div>
      <div className='col-span-2'>
        <div style={{backgroundImage: `url(${stepTitleData[currentStep].banner})`}} className='bg-no-repeat bg-cover w-auto h-full max-w-full' />
      </div>
    </div>
  );
};
const MainProfileBuilderLayout = ({handleChangeStep, currentStep, navigateToStep, handleFinish, progress, children}: any) => {
  console.log('Debug MainProfileBuilderLayout'.toUpperCase(), {handleChangeStep, currentStep, navigateToStep, handleFinish, progress});
  const isFinalStep = currentStep == VENDOR_PROGRAM_BUILDER_STEPS - 1;
  return (
    <div className='w-full h-full flex flex-col justify-between bg-white'>
      <div className='flex flex-grow overflow-y-auto overflow-x-hidden'>{children}</div>
      <div>
        <div className=' w-full relative mt-auto'>
          <div className={`innerProgress bg-primary absolute h-[4px]`} style={{width: `${progress}%`}}></div>
          <div className='progress bg-D9D9D9 h-[4px] w-full'></div>
          <div className='flex justify-between items-center pt-[16px] bg-white'>
            <MAButton
              type='text'
              className='!px-0 '
              onClick={() => {
                handleChangeStep('back');
              }}
            >
              Back
            </MAButton>
            <div className='flex flex-row'>
              <MAButton className='' type='outline'>
                Save & exit
              </MAButton>
              <MAButton className={`bg-primary px-6 ml-3`} onClick={() => (isFinalStep ? handleFinish() : handleChangeStep('next'))}>
                {isFinalStep ? 'Proceed & Close' : 'Next'}
              </MAButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function VendorProfileBuilder() {
  const [currentStep, setCurrentStep] = useState(0);
  const [fitnessBusinesses, setFitnessBusiness] = useState(FitnessBusinesses);
  const [amenities, setAmenities] = useState<any[]>(AmenitiesDefaultData);
  const [step4Form] = Form.useForm();
  const [step5Form] = Form.useForm();
  const progress = (currentStep / (VENDOR_PROGRAM_BUILDER_STEPS - 1)) * 100;
  const validateForm = async (form: FormInstance) => {
    try {
      console.log('FORMDATA', form.getFieldsValue());
      await form.validateFields();
      return true;
    } catch (error) {
      console.log('FORM_VALIDATION_ERROR: ', error);
      return false;
    }
  };

  const handleChangeStep = async (type: 'next' | 'back') => {
    if (type == 'back') {
      currentStep > 0 && setCurrentStep(currentStep - 1);
      return;
    }
    // next step
    if (currentStep >= VENDOR_PROGRAM_BUILDER_STEPS) return;
    const isValid4 = await validateForm(step4Form);
    const isValid5 = await validateForm(step5Form);
    if (currentStep == 4 && !isValid4) return;
    if (currentStep == 5 && !isValid5) return;
    setCurrentStep(currentStep + 1);
  };
  const handleSelectedAmenity = (index: any) => {
    setAmenities(prev => {
      if (!prev[index]) return prev;
      const updatedAmenities = [...prev];
      updatedAmenities[index] = {
        ...updatedAmenities[index],
        isSelected: !updatedAmenities[index]?.isSelected,
      };
      return updatedAmenities;
    });
  };

  const handleSelectedBusiness = (index: number) => {
    setFitnessBusiness(prev => {
      if (!prev[index]) return prev;

      const updatedFitnessBusiness = [...prev];
      updatedFitnessBusiness[index] = {
        ...updatedFitnessBusiness[index],
        isSelected: !updatedFitnessBusiness[index]?.isSelected,
      };

      return updatedFitnessBusiness;
    });
  };
  const handleFinish = async () => {
    const data: {fitnessBusinesses?: any[]; amenities?: any[]; certifications: any[]; showcases: any} = {
      certifications: [],
      showcases: null,
    };
    try {
      data['fitnessBusinesses'] = fitnessBusinesses && fitnessBusinesses.filter(business => business.isSelected).map(business => business.id);
      data['amenities'] = amenities && amenities.filter(amenity => amenity.isSelected).map(amenities => amenities.id);
      data['certifications'] = step4Form.getFieldValue('certifications');
      data['showcases'] = {
        showcasePhotos: step5Form.getFieldValue('showcasePhotos'),
        logo: step5Form.getFieldValue('logo'),
      };
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const formDataBuilder = useCallback(() => {
    switch (currentStep) {
      case 0:
        return <BannerBuilderStep currentStep={0} />;
      case 1:
        return <BannerBuilderStep currentStep={1} />;
      case 2:
        return <ProfileBuilderStep2 amenities={amenities} handleSelectedAmenity={handleSelectedAmenity} />;
      case 3:
        return <ProfileBuilderStep3 fitnessBusinesses={fitnessBusinesses} handleSelectedBusiness={handleSelectedBusiness} />;
      case 4:
        return <ProfileBuilderStep4 step4Form={step4Form} />;
      case 5:
        return <ProfileBuilderStep5 step5Form={step5Form} />;
      case 6:
        return <BannerBuilderStep currentStep={2} />;
    }
  }, [currentStep, amenities, fitnessBusinesses]);
  return (
    <VendorLayout sidebarProps={{bgColor: 'white'}}>
      <MainProfileBuilderLayout
        {...{
          currentStep,
          handleChangeStep,
          progress,
          handleFinish,
        }}
      >
        {formDataBuilder()}
      </MainProfileBuilderLayout>
    </VendorLayout>
  );
}
