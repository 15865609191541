import React from 'react';
import {AccountLayout} from '../../layouts/account/AccountLayout';
import MAIcon from '../../components/ui/icon/MAIcon';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

export default function AccountOverView() {
  const navigate = useNavigate();
  const {role} = useParams();

  const goToPersonalInfo = () => {
    console.log('Personal Info');
    navigate(`/${role}/account/personal-info`);
  };
  const goToLoginAndSecurity = () => {
    console.log('Login and Security');
    navigate(`/${role}/account/setting-security`);
  };

  return (
    <AccountLayout className={'mt-[70px]'}>
      <div className=' flex flex-row justify-center w-full '>
        <div className='displaySmall w-[1222px]'>Account</div>
      </div>
      <div className='mt-[30px] flex flex-row justify-center w-full '>
        <div onClick={goToPersonalInfo} className='col-span-1 p-[20px] border border-E2E2E2 rounded-[4px] cursor-pointer w-[394px]'>
          <div className='card '>
            <MAIcon name='personinfo' />
            <p className='labelExtraLarge text-2A2A2A pt-[12px] pb-[4px] '>Personal Info</p>
            <div className='h-18'>
              <span className='bodySmall text-5A5A5A'>Provide personal details and how we can contact you.</span>
            </div>
          </div>
        </div>
        <div onClick={goToLoginAndSecurity} className='col-span-1 p-[20px] border border-E2E2E2 rounded-[4px] cursor-pointer w-[394px] mx-[20px]'>
          <div className='card '>
            <MAIcon name='secirity' />
            <p className='labelExtraLarge text-2A2A2A pt-[12px] pb-[4px] '>Login & Security</p>
            <div className='h-18'>
              <span className='bodySmall text-5A5A5A'>Update your password and set up multi-factor authentication.</span>
            </div>
          </div>
        </div>
        <div className='col-span-1 p-[20px] border border-E2E2E2 rounded-[4px] cursor-pointer w-[394px]'>
          <div className='card '>
            <MAIcon name='notification' />
            <p className='labelExtraLarge text-2A2A2A pt-[12px] pb-[4px] '>Notifications</p>
            <div className='h-18'>
              <span className='bodySmall text-5A5A5A'>Adjust settings to how you prefer to receive notifications.</span>
            </div>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
}
