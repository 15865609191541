import React from 'react';
import {AdminLayout} from '../../layouts/admin/AdminLayout';

const AdminEnterprises: React.FC = () => {
  return (
    <AdminLayout>
      <div className='container'>TODO</div>
    </AdminLayout>
  );
};

export default AdminEnterprises;
