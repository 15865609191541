import MAButton from '../button/MAButton';
import {Modal} from 'antd';
import React, {Fragment} from 'react';
import {formatSignContractTime} from '../../../helpers/utils';

interface ContractModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onAgree: () => void;
  onDeny: () => void;
  content: string;
  signedAt?: string;
  isSignable?: boolean;
}

export function ContractModal({isSignable, signedAt, content, isOpen, onCancel, onAgree, onDeny}: ContractModalProps) {
  return (
    <Modal
      width={980}
      title={'Contract'}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      okText={'Delete'}
      cancelButtonProps={{className: 'border-none hover:text-black'}}
      okButtonProps={{className: '!bg-FFDDDA text-DE1135 !hover:text-DE1135'}}
      cancelText={'Cancel'}
    >
      {!!signedAt && (
        <div className={'mb-[10px]'}>
          Signed on <span className={'font-medium'}>{formatSignContractTime(signedAt)}</span>
        </div>
      )}
      <div className={'p-[10px] rounded-[5px] border border-[#D2D2D2]'}>
        <p className={'text-5A5A5A max-h-[500px] overflow-auto'}>{content || 'Are you sure want to delete selected items?'}</p>
      </div>
      <div className='mt-[20px] flex flex-row items-end justify-end gap-[12px]'>
        {!signedAt && isSignable && (
          <Fragment>
            <MAButton onClick={onDeny} customBgColor={'FFFFFF'} customTextColor={'676B73'} size={'small'}>
              {'Reject'}
            </MAButton>
            <MAButton onClick={onAgree} className={'!px-[24px]'} size={'small'}>
              {'Approve & Sign'}
            </MAButton>
          </Fragment>
        )}

        {(!!signedAt || !isSignable) && (
          <Fragment>
            <MAButton onClick={onCancel} className={'!px-[24px]'} size={'small'}>
              {'Close'}
            </MAButton>
          </Fragment>
        )}
      </div>
    </Modal>
  );
}
