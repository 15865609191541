import React, {useContext, useEffect} from 'react';
import styles from './AdminLayout.module.scss';
import {CiUser} from 'react-icons/ci';
import {SidebarContentLayout} from '../shared/SidebarContentLayout';
import {ColorsContext} from '../../contexts/ColorsContext';

const links = [
  {
    to: '/admin/home',
    title: 'Home',
    icon: <CiUser size={25} />,
  },
  {
    to: '/admin/enterprises',
    title: 'Enterprises',
    icon: <CiUser size={25} />,
  },
  {
    to: '/admin/users',
    title: 'Users',
    icon: <CiUser size={25} />,
  },
  {
    to: '/admin/staffs',
    title: 'Staffs',
    icon: <CiUser size={25} />,
  },
  {
    to: '/admin/reports',
    title: 'Reports',
    icon: <CiUser size={25} />,
  },
];

export function AdminLayout(props: any) {
  const {setColors} = useContext(ColorsContext);
  useEffect(() => {
    setColors({
      primary: '008080',
      secondary: 'E6F7F6',
    });

    console.log('DEBUG SET COLOR FROM ADMIN LAYOUT');
  }, [setColors]);

  return (
    <div className={styles.moduleWrapper}>
      <SidebarContentLayout title={'Maestro Admin'} target={'admin'} links={links}>
        {props.children}
      </SidebarContentLayout>
    </div>
  );
}
