import moment from 'moment';
import {DateValidatorProps, ServerError, ValidatorProps} from '../types';
import {CONFIG, MESSAGES} from '../config';
import {message} from 'antd';

export function formatDate(dateString: moment.MomentInput) {
  return moment(dateString).format(CONFIG.frontendDateFormat);
}

export function formatTimelineDate(dateString: moment.MomentInput) {
  return moment(dateString).format(CONFIG.timelineDateFormat);
}

export function formatSignContractTime(dateString: moment.MomentInput) {
  return moment(dateString).format(CONFIG.signDateFormat);
}

export function getDiffYear(date: moment.MomentInput): string {
  try {
    const custDate = moment(date);
    const formattedDate = moment().diff(custDate, 'years');
    const result = `${custDate.format('YYYY')}, ${formattedDate} yrs old`;
    return result;
  } catch (e) {
    return '';
  }
}

export function getHostname(href: string) {
  try {
    const url = new URL(href);
    const hostnameParts = url.hostname.split('.');
    if (hostnameParts.length < 3) return url.hostname;

    // Remove the first part (subdomain) and join the rest
    const domain = hostnameParts.slice(1).join('.');
    return domain;
  } catch (e) {
    return href;
  }
}

export function mappingRoleToLabel(status: string) {
  const mapping: any = {
    'role.company-admin': 'Company admin',
    'role.super-admin': 'Super admin',
  };

  return mapping[status] || 'Không xác định';
}

export function getCurrentUserFromStorage() {
  if (localStorage.getItem(LOCAL_STORAGE_KEY.USER)) {
    const local: any = localStorage.getItem(LOCAL_STORAGE_KEY.USER);
    return JSON.parse(local);
  }

  return null;
}

export function getCurrentUserDetailFromStorage() {
  if (localStorage.getItem(LOCAL_STORAGE_KEY.USER_DETAIL)) {
    const local: any = localStorage.getItem(LOCAL_STORAGE_KEY.USER_DETAIL);
    return JSON.parse(local);
  }

  return null;
}

export function copyToClipboard(text: string) {
  // Create a new text area element
  const textArea = document.createElement('textarea');

  // Set the text content of the text area to the text you want to copy
  textArea.value = text;

  // Append the text area to the document
  document.body.appendChild(textArea);

  // Select the text in the text area
  textArea.select();

  // Execute the copy command to copy the selected text to the clipboard
  document.execCommand('copy');

  // Remove the temporary text area element
  document.body.removeChild(textArea);
}

export const Regex = {
  email: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
  phoneNumberWithSpace: /^\s*[0-9+() ]+\s*$/,
};

export function parseServerError(error: ServerError, defaultErrorMessage = 'An error occurred'): string[] {
  if (error?.error?.errors) {
    const errorMessages = Object.values(error.error.errors).flat();
    return errorMessages.length > 0 ? errorMessages : [defaultErrorMessage];
  }
  return [defaultErrorMessage];
}

export function isNumber(num: any) {
  return typeof num == 'number' && Number.isFinite(num);
}

export function formatCurrencyUSD(number: number | string) {
  try {
    const num = Number(number);

    if (isNaN(num)) {
      throw new Error('Invalid number');
    }

    return num.toLocaleString('en-US', {style: 'currency', currency: 'HKD'});
  } catch (error) {
    console.log('Failed to format currency:', error);
  }
}

export const handleStepData = (step: number, data: any) => {
  const stepData = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
  const prevData = stepData ? JSON.parse(stepData) : {};
  const newData = {...prevData, [`step${step}Data`]: data};
  localStorage.setItem(LOCAL_STORAGE_KEY.STEP_DATA, JSON.stringify(newData));
};
export const getDataStepFromStorage = (key: string) => {
  const stepData = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
  const prevData = stepData ? JSON.parse(stepData) : {};
  return prevData[key];
};
export const removeDataStepFromStorage = (key: string) => {
  const stepData = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
  const prevData = stepData ? JSON.parse(stepData) : {};
  delete prevData[key];
  localStorage.setItem(LOCAL_STORAGE_KEY.STEP_DATA, JSON.stringify(prevData));
};

export enum PROGRAM_TYPES_ENUM {
  TAILOR_MADE = 'tailor_made',
  CHERRY_PICK = 'cherry_pick',
}

export function makeEmptyArray(size: number) {
  return Array.from({length: size}, () => null);
}

export enum PROGRAM_STATUSES_ENUM {
  START = -1,
  DRAFT = 0,
  PENDING_CONTRACT = 1,
  WAITING_REVIEW = 2,
  READY_TO_LAUNCH = 3,
  LIVE = 4,
  COMPLETED = 5,
  PAUSE = 6,
}

export enum STEP_NUMBERS_ENUM {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
  STEP4 = 4,
  STEP5 = 5,
  STEP6 = 6,
}

// export local storage key
export const LOCAL_STORAGE_KEY = {
  STEP_DATA: 'stepData',
  TOKEN: 'token',
  USER: 'user',
  USER_DETAIL: 'user_detail',
  DEVICE_ID: 'device_id',
  COME_FROM_LOGIN: 'come_from_login',
};

export const cleanObjectDeep = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
    // Return the value as is if it's not an object or if it's an array
    return obj;
  }

  return Object.entries(obj)
    .filter(([key, value]) => {
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        // If the value is an object and not an array, recurse into it
        const cleaned = cleanObjectDeep(value);
        // Exclude the key if the cleaned object is now empty
        return Object.keys(cleaned).length > 0;
      }

      // Exclude keys with empty values
      return value !== null && value !== undefined && value !== '';
    })
    .reduce((acc, [key, value]) => {
      // Recurse into the value if it's an object and not an array
      acc[key] = typeof value === 'object' && value !== null && !Array.isArray(value) ? cleanObjectDeep(value) : value;
      return acc;
    }, {} as any);
};

// convert date and format
export const convertDateAndFormat = (date: string) => {
  if (!date) {
    return '';
  }
  return moment(new Date(date)).format('YYYY-MM-DD');
};

export function getLabelByValue(items: any[], value: string | number) {
  for (let i = 0; i < items.length; i++) {
    if (items[i].value === value) {
      return items[i].label;
    }
  }

  return null;
}

export function convertMappingToArray(object: {[x: string]: any}) {
  return Object.keys(object).map(key => {
    return object[key];
  });
}

export function getObjectByValue(items: any[], value: string | number) {
  for (let i = 0; i < items.length; i++) {
    if (items[i].value === value) {
      return items[i];
    }
  }

  return null;
}

export function getKeyByValue(items: any[], value: string | number) {
  for (let i = 0; i < items.length; i++) {
    if (items[i].value === value) {
      return items[i].key;
    }
  }

  return null;
}

export function formValueToApiDateFormat(date: string | object) {
  if (!date) {
    return null;
  }

  return moment(date.toString()).format(CONFIG.apiDateFormat).valueOf();
}

export function apiValueToFrontendDateFormat(date: string | object) {
  if (!date) {
    return null;
  }

  return moment(date, CONFIG.apiDateFormat).format(CONFIG.frontendDateFormat).valueOf();
}

export const createBudgetValidator =
  ({firstFieldName, secondFieldName, operator, message}: ValidatorProps) =>
  ({getFieldValue}: {getFieldValue: (field: string) => any}) => ({
    validator(_: any, value: any) {
      if (!value) {
        return Promise.resolve();
      }
      const secondFieldValue = getFieldValue(secondFieldName);
      if (value && !secondFieldValue) {
        return Promise.resolve();
      }
      if (!getFieldValue(firstFieldName) && getFieldValue(secondFieldName)) {
        return Promise.resolve();
      }

      const convertFirstValue = parseFloat(getFieldValue(firstFieldName));
      const convertSecondValue = parseFloat(secondFieldValue);
      const convertedValue = parseFloat(value);
      const comparisonResult = operator === 'greater' ? convertFirstValue < convertedValue : convertSecondValue > convertedValue;
      if (comparisonResult) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(message));
    },
  });
export const createDateValidator =
  ({startDateFieldName, endDateFieldName, operator, message}: DateValidatorProps) =>
  ({getFieldValue}: {getFieldValue: (field: string) => any}) => ({
    validator(_: any, value: any) {
      if (!value) {
        return Promise.resolve();
      }
      const startDate = getFieldValue(startDateFieldName);
      const endDate = getFieldValue(endDateFieldName);
      if (value && (!startDate || !endDate)) {
        return Promise.resolve();
      }
      const comparisonResult = operator === 'before' ? value.isBefore(endDate, 'day') : value.isAfter(startDate, 'day');
      if (comparisonResult) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(message));
    },
  });

export const reverseMappingDataToLocal = (serverData: any) => {
  if (serverData.type === PROGRAM_TYPES_ENUM.CHERRY_PICK) {
    const localData = {
      step1Data: {
        programName: serverData.name,
        programType: serverData.type,
      },
      step2Data: serverData.employees_file_id
        ? {
            fileId: serverData?.employees_file_id,
            url: serverData?.fileData?.download_url,
            file_name: serverData?.fileData?.file_name,
            fileList: [
              {
                uid: serverData?.fileData?.id,
                name: serverData?.fileData?.name,
                status: 'done',
                url: serverData?.fileData?.download_url,
                isLocal: true,
              },
            ],
          }
        : null,
      step3Data: {
        allotPoint: serverData.points_per_employee,
        programDateStart: serverData.start_date,
        programDateEnd: serverData.end_date,
        reminder: serverData.remind_before_end === 1,
      },
      step: serverData.program_matching?.step ?? 0,
      status: serverData.status,
    };
    return localData;
  } else {
    const localData = {
      step1Data: {
        programName: serverData.name,
        programType: serverData.type,
      },
      step2Data: serverData.program_matching
        ? {
            subCategory: serverData.program_matching.category_id,
            serviceType: serverData.program_matching.service_type,
            physicalLocation: serverData.program_matching.physical_location,
            regionLocation: serverData.program_matching.location,
            startDate: serverData.program_matching.start_date,
            endDate: serverData.program_matching.end_date,
            numberOfEmployee: serverData.program_matching.number_of_employees,
            totalBudgetMin: serverData.program_matching.total_budget_min,
            totalBudgetMax: serverData.program_matching.total_budget_max,
            budgetPerEmployeeMin: serverData.program_matching.budget_per_employee_min,
            budgetPerEmployeeMax: serverData.program_matching.budget_per_employee_max,
          }
        : {},
      step3Data: [],
      step4Data: serverData.employees_file_id
        ? {
            fileId: serverData.employees_file_id,
            url: serverData?.fileData?.download_url,
            file_name: serverData?.fileData?.file_name,
            fileList: [
              {
                uid: serverData?.fileData?.id,
                name: serverData?.fileData?.file_name,
                status: 'done',
                url: serverData?.fileData?.download_url,
                isLocal: true,
              },
            ],
          }
        : null,
      step5Data: {
        allotPoint: serverData.points_per_employee,
        programDateStart: serverData.start_date,
        programDateEnd: serverData.end_date,
        reminder: serverData.remind_before_end === 1,
      },
      step: serverData.program_matching?.step ?? 0,
      status: serverData.status,
    };
    if (serverData.status === PROGRAM_STATUSES_ENUM.DRAFT) {
      localData.step3Data = serverData.cart
        ? serverData?.cart?.products?.map((item: any) => ({
            ...item,
            id: item.product_id ?? '',
            vendorName: item?.vendor?.name ?? '',
            name: item.name ?? '',
          }))
        : [];
    }
    if (serverData.status === PROGRAM_STATUSES_ENUM.PENDING_CONTRACT || serverData.status === PROGRAM_STATUSES_ENUM.WAITING_REVIEW) {
      localData.step3Data = serverData.order
        ? serverData?.order?.products?.map((item: any) => ({
            ...item,
            id: item.product_id,
            vendorName: item?.vendor?.name,
            name: item.name,
            isServer: true,
          }))
        : [];
    }

    return localData;
  }
};

export async function handleApiError(error: any, defaultMsg = 'An error occurred') {
  let finalMessage;
  try {
    const errors = error.response.data.error.errors;
    finalMessage = errors[Object.keys(errors)[0]][0];
  } catch (e) {
    if (error?.response?.data?.error?.message) {
      finalMessage = error?.response?.data?.error?.message;
    } else {
      finalMessage = defaultMsg;
    }
  }

  await message.error(finalMessage);
}

export function upperFirstChar(words: string) {
  return words.charAt(0).toUpperCase() + words.slice(1);
}

export function validatePasswordRules(_: any, value: string) {
  if (!value) {
    return Promise.resolve();
  }

  if (value.length < 8 || value.length > 20) {
    return Promise.resolve();
  }

  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /\d/.test(value);
  const hasSymbol = /[^A-Za-z0-9]/.test(value);

  if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSymbol) {
    return Promise.reject(new Error(MESSAGES.MSG6));
  }

  return Promise.resolve();
}

export function trimSpaceForObject(obj: any) {
  try {
    const clonedObj = JSON.parse(JSON.stringify(obj));

    for (const key in clonedObj) {
      if (typeof clonedObj[key] === 'string') {
        clonedObj[key] = clonedObj[key].trim();
      } else if (typeof clonedObj[key] === 'object') {
        clonedObj[key] = trimSpaceForObject(clonedObj[key]);
      }
    }

    return obj;
  } catch (e) {
    return obj;
  }
}

export function sortByProperty(arr: {[x: string]: number}[], prop: string | number) {
  if (!arr) {
    return [];
  }

  return arr.sort((a: {[x: string]: number}, b: {[x: string]: number}) => a[prop] - b[prop]);
}
export const getCurrentDeviceId = () => {
  const deviceID = localStorage.getItem(LOCAL_STORAGE_KEY.DEVICE_ID);
  if (deviceID) {
    return deviceID;
  } else {
    const newDevices = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);
    localStorage.setItem(LOCAL_STORAGE_KEY.DEVICE_ID, newDevices);
    return newDevices;
  }
};

export const securityEmail = (email: string | null) => {
  if (!email) return '';

  const emailParts = email.split('@');
  const firstPart = emailParts[0].substring(0, 1);
  const secondPart = emailParts[0].substring(4, emailParts[0].length);
  const emailSecurity = firstPart + '***' + secondPart + '@' + emailParts[1];
  return emailSecurity;
};
export const securityPhone = (phone: string | null) => {
  if (!phone) return '';

  const phoneSecurity = phone.substring(0, 4) + '****' + phone.substring(8, phone.length);
  return phoneSecurity;
};

export const formatDateFromServer = (timestamp: string) => {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

export function isMySubDomain() {
  return location.href.includes('//my.');
}
