import {PROGRAM_STATUSES_MAPPING} from '../../config';
import {convertMappingToArray} from '../../helpers/utils';

export function ProgramStatusLabel({statusId, className}: {statusId: number; className?: string}) {
  const target = convertMappingToArray(PROGRAM_STATUSES_MAPPING).find(item => item.value === statusId);

  if (target) {
    return <div className={`py-[2px] px-[10px] rounded-[10px] w-[fit-content] bg-${target.color} ${className}`}>{target.label}</div>;
  }

  return null;
}
