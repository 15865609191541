import React, {useState} from 'react';
import {Form, Input, Modal} from 'antd';
import {MESSAGES} from '../../../../../config';

interface ProgramNameEditorModalProps {
  onCancel: () => void;
  onSave: (newName: string) => void;
  currentName: string;
}

const ProgramNameEditorModal: React.FC<ProgramNameEditorModalProps> = ({onCancel, onSave, currentName}) => {
  const [form] = Form.useForm();
  const [newName, setNewName] = useState(currentName);

  const handleSave = async () => {
    try {
      await form.validateFields();
      onSave(newName);
      onCancel();
    } catch (error) {
      // Handle validation error (form fields are invalid)
    }
  };

  return (
    <Modal width={418} open={true} title='Edit Program Name' onCancel={onCancel} onOk={handleSave} okText='Save' cancelText='Cancel'>
      <Form form={form} layout='vertical'>
        <div className={'bodyMedium mb-[12px]'}>Keep your programs organised in your dashboard by giving it a name.</div>
        <Form.Item name='newName' initialValue={newName} rules={[{required: true, message: MESSAGES.MSG18}]}>
          <Input maxLength={100} value={newName} onChange={e => setNewName(e.target.value)} placeholder='Enter the new program name' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProgramNameEditorModal;
