import MAButton from '../button/MAButton';
import {Modal} from 'antd';
import React from 'react';
import MAIcon from '../icon/MAIcon';

interface SuccessModalProps {
  isOpen: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  title?: string;
  content?: any;
  textCancel?: string;
  textOk?: string;
}

export function SuccessModal({isOpen, onCancel, onOk, title, content, textOk}: SuccessModalProps) {
  return (
    <Modal
      width={418}
      // title={<div className={'titleLarge'}>{title || ''}</div>}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      okText={'Close'}
      okButtonProps={{className: 'bg-primary  !hover:text-DE1135'}}
      cancelText={'Cancel'}
    >
      <div className='flex justify-center items-center flex-col'>
        <MAIcon name='checkCircle' size={70} color='primary' />
        <p className='titleLarge align-middle text-center'>{title}</p>
        {content && <p className={'text-5A5A5A text-center'}>{content}</p>}
        <div className='mt-[20px] flex flex-row items-end justify-end gap-[12px]'>
          <MAButton onClick={onCancel} className={'!px-[24px]'} customBgColor={'bg-primary'} customTextColor={'#FFFF'} size={'small'}>
            {'Close'}
          </MAButton>
        </div>
      </div>
    </Modal>
  );
}
