import {FormInstance} from 'antd/lib';
import React, {useMemo} from 'react';
import {MIN_YEAR_ISSUE} from '../../config';
import {Form} from 'antd';
import MAButton from '../../components/ui/button/MAButton';
import {CertificationFormItem} from './CertificationFormItem';

export const ProfileBuilderStep4 = ({step4Form}: {step4Form: FormInstance}) => {
  // Create an array for years (e.g., from 2000 to the current year)
  const yearSelectOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({length: currentYear - MIN_YEAR_ISSUE - 1}, (_, index) => ({value: 2000 + index, label: <span>{2000 + index}</span>}));
    return years.reverse();
  }, []);

  // Create an array for months (from January to December)
  const monthSelectOptions = useMemo(() => {
    const months = Array.from({length: 12}, (_, index) => ({value: index + 1, label: <span>{index + 1}</span>}));
    return months;
  }, []);

  return (
    <div className='w-full flex flex-col gap-y-[40px]'>
      <div className='flex flex-col w-full'>
        <span className='titleLarge'>What amenities does your business have to offer?</span>
        <span className='bodyExtraLarge text-5A5A5A'>You can add more amenities after you publish your business.</span>
      </div>
      <div className='gap-[20px]'>
        <Form
          form={step4Form}
          name='validateOnly'
          layout='vertical'
          size='large'
          className='w-full'
          autoComplete='off'
          initialValues={{certifications: [{}]}}
        >
          <Form.List name='certifications'>
            {(fields, {add, remove}) => {
              return (
                <div className='grid gap-y-[24px]'>
                  {fields.map(({name}: any, index: number) => {
                    return <CertificationFormItem key={index} {...{name, index, remove, monthSelectOptions, yearSelectOptions, form: step4Form}} />;
                  })}
                  <MAButton
                    type='text'
                    onClick={() => {
                      add();
                    }}
                  >
                    <div className='flex items-center'>
                      <span className='labelMedium'>+ Add another</span>
                    </div>
                  </MAButton>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </div>
    </div>
  );
};
