import {Avatar} from 'antd';
import MAButton from '../../ui/button/MAButton';
import {apiValueToFrontendDateFormat} from '../../../helpers/utils';
import {Fragment, useState} from 'react';

import styles from './CustomerItemMatches.module.scss';
import eaLogo from '../../../assets/images/default-ea-logo.webp';

interface CustomerItemMatchesProps {
  contract: any;
  setClickedContract: (contract: any) => void;
  onDeclineContract: (contract: any) => void;
}

export const CustomerItemMatches = ({contract, setClickedContract, onDeclineContract}: CustomerItemMatchesProps) => {
  const [isShowFull, setIsShowFull] = useState(false);

  return (
    <div className={`bg-white border rounded border-E2E2E2 flex justify-between py-[30px] px-[22px] ${styles.moduleWrapper}`}>
      <div className='flex gap-[20px]'>
        <Avatar src={eaLogo} size={60} />
        <div className='flex flex-col gap-y-[12px] max-w-[600px]'>
          <div className='flex flex-col h-full'>
            <span className='labelExtraLarge text-2A2A2A !font-normal'>
              {contract?.enterprise?.users[0]?.detail?.first_name} {contract?.enterprise?.users[0]?.detail?.last_name}
            </span>
            <p className={`text-5A5A5A bodySmall`}>{contract?.enterprise?.name}</p>
            <div className={'mt-[12px] bodyMedium'}>
              Interested in offering the following activities in my corporate programme:
              <ul className={'listProducts'}>
                {contract?.order?.products?.map((item: any, index: any) => {
                  return (
                    <li className={index > 2 && !isShowFull ? 'hidden' : ''}>
                      {item?.product?.name} <br />
                    </li>
                  );
                })}
              </ul>
              {isShowFull && (
                <Fragment>
                  <div>
                    From {apiValueToFrontendDateFormat(contract?.order?.program?.start_date)} to{' '}
                    {apiValueToFrontendDateFormat(contract?.order?.program?.end_date)}, accessed in all locations.
                    <br />
                    <br />
                    Separately, keen to understand if you are able to customise specific classes at our on-site venues.
                    <br />
                    <br />
                    Best,
                    <br />
                    {contract?.enterprise?.users[0]?.detail?.first_name}.
                  </div>
                  <span
                    className={'cursor-pointer font-semibold underline'}
                    onClick={() => {
                      setIsShowFull(false);
                    }}
                  >
                    Show less
                  </span>
                </Fragment>
              )}
              {!isShowFull && (
                <Fragment>
                  <div>
                    From {apiValueToFrontendDateFormat(contract?.order?.program?.start_date)} to{' '}
                    {apiValueToFrontendDateFormat(contract?.order?.program?.end_date)}.
                    <span
                      className={'pl-[5px] cursor-pointer font-semibold underline'}
                      onClick={() => {
                        setIsShowFull(true);
                      }}
                    >
                      Show more
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='flex h-min items-center gap-x-[24px]'>
        <div
          className={'cursor-pointer'}
          onClick={() => {
            alert('TODO go to module message!');
          }}
        >
          Message
        </div>
        {!contract?.vendor_signed_at && (
          <div
            className={'cursor-pointer'}
            onClick={async () => {
              await onDeclineContract(contract);
            }}
          >
            Decline
          </div>
        )}
        {/*<EContractStatus statusId={contract.status} />*/}
        {/*<span className='labelMedium cursor-pointer' onClick={() => onDecline(contract)}>Decline</span>*/}
        <MAButton onClick={() => setClickedContract(contract)}>Preview e-contract</MAButton>
      </div>
    </div>
  );
};
