import ProductLeftInfo from './ProductLeftInfo';
import React from 'react';
import ProductCarousel from './ProductCarousel';
import ProductReview from './ProductReview';
import {Link, useSearchParams} from 'react-router-dom';
import MAIcon from '../../../../ui/icon/MAIcon';

export default function EnterpriseProductsDetail({product, reviews}: any) {
  const [searchParams] = useSearchParams();
  const programId = searchParams.get('programId');

  return (
    <div className='flex flex-col gap-y-[24px]'>
      <div className='heroBanner h-[500px] grid grid-cols-2 gap-[34px] border-b border-D4DDDC'>
        <div className='flex-1 flex gap-[20px] flex-col'>
          {programId && (
            <Link to={`/enterprise/programs/${programId}/overview`}>
              <div className={'flex items-center justify-start gap-[12px]'}>
                <MAIcon size={20} name={'previous'} />
                <div>Back</div>
              </div>
            </Link>
          )}

          <ProductLeftInfo product={product} />
        </div>
        <ProductCarousel images={product.gallery} />
      </div>
      {/* Client reviews */}
      <div>
        <p className='labelExtraLarge !font-semibold'>Reviews</p>
        <div className='mt-[24px]'>
          <div className='grid gap-x-[34px] gap-y-[40px] grid-flow-row grid-cols-12 justify-items-stretch'>
            {reviews?.map((review: any, index: number) => {
              return (
                <div className='col-span-6' key={index}>
                  <ProductReview review={review} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
