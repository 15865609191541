import {getDiffYear, getHostname, upperFirstChar} from '../../helpers/utils';

export const VendorOverview = ({vendor}: any) => {
  return (
    <div className='px-[24px] py-[20px] grid grid-cols-12 grid-rows-2 gap-y-[24px] gap-x-[22px] bg-white rounded-md'>
      <div className='col-span-3 flex flex-col gap-y-[4px]'>
        <span className='labelSmall text-008080'>Location</span>
        <span className='bodyMedium'>{vendor?.location?.name || ''}</span>
      </div>
      <div className='col-span-3 flex flex-col gap-y-[4px]'>
        <span className='labelSmall text-008080'>Active since</span>
        <span className='bodyMedium'>{getDiffYear(vendor?.active_date || '')}</span>
      </div>
      <div className='col-span-6'>
        <div className='flex flex-col gap-y-[4px] w-max'>
          <span className='labelSmall text-008080'>Category</span>
          <span className={`text-center px-[10px] py-[2px] rounded-[10px] bodySmall bg-EFE1B2`}>{vendor?.category?.name}</span>
        </div>
      </div>
      <div className='col-span-3 flex flex-col gap-y-[4px]'>
        <span className='labelSmall text-008080'>Website</span>
        <div className='bodyMedium'>
          <a className='underline bodyMedium' href={vendor?.website} target='_blank'>
            {getHostname(vendor?.website)}
          </a>
        </div>
      </div>
      <div className='col-span-3 flex flex-col gap-y-[4px]'>
        <span className='labelSmall text-008080'>Social channels</span>
        <div className='bodyMedium flex gap-x-[4px]'>
          {vendor?.channels?.map((channel: any, index: number) => (
            <div key={index}>
              <a className='underline bodyMedium' href={channel?.value} target='_blank'>
                {upperFirstChar(channel?.type)}
              </a>
              {index !== vendor?.channels?.length - 1 && <span className='bodyMedium'>, </span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
