import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Input, message, Select, Upload} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {AdminLayout} from '../../layouts/admin/AdminLayout';
import {UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import {MESSAGES} from '../../config';

const {Option} = Select;

const AdminEnterprisesRegisterAndUpdate: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {id} = useParams(); // Get the id parameter from the URL

  const isEditing = !!id; // Check if it's an edit page

  const [initialValues, setInitialValues] = useState({}); // Store initial form values

  const fetchEnterpriseData = useCallback(async () => {
    try {
      // Fetch enterprise data based on the id parameter
      const response = await axios.get(`https://dev-api.maestro.moha.team/api/enterprises/${id}`);
      const enterpriseData = response.data.data; // Replace with your API response structure

      // Set the initial form values with the fetched data
      setInitialValues(enterpriseData);

      // Set the form fields with the initial values
      form.setFieldsValue(enterpriseData);
    } catch (error) {
      console.error('Error fetching enterprise data:', error);
      message.error('Failed to fetch enterprise data');
    }
  }, [form, id]);

  useEffect(() => {
    if (isEditing) {
      // If it's an edit page, fetch the enterprise data
      (async () => {
        await fetchEnterpriseData();
      })();
    }
  }, [fetchEnterpriseData, isEditing]);

  const onFinish = async (values: any) => {
    try {
      if (isEditing) {
        // If it's an edit page, send a PUT request to update the enterprise
        await axios.put(`https://dev-api.maestro.moha.team/api/enterprises/${id}`, {
          data: values,
        });
        message.success('Enterprise updated successfully');
      } else {
        // If it's a create page, send a POST request to create a new enterprise
        await axios.post('https://dev-api.maestro.moha.team/api/enterprises', {
          data: values,
        });
        message.success('Enterprise created successfully');
      }

      // Redirect back to the enterprises list page
      navigate('/admin/enterprises');
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to save enterprise');
    }
  };

  return (
    <AdminLayout>
      <div className='container'>
        <h2 className='mt-[100px] text-center'>{isEditing ? 'Edit Enterprise' : 'Register New Enterprise'}</h2>
        <Form
          form={form}
          name='register-enterprise'
          onFinish={onFinish}
          labelCol={{span: 6}}
          wrapperCol={{span: 12}}
          initialValues={{status: 'NEW', ...initialValues}} // Set initial form values
        >
          <Form.Item label='Name' name='name' rules={[{required: true, message: 'Please enter the name of the enterprise'}]}>
            <Input />
          </Form.Item>

          <Form.Item label='Email' name='email' normalize={value => value.trim()} rules={[{required: true, type: 'email', message: MESSAGES.MSG11}]}>
            <Input />
          </Form.Item>

          <Form.Item label='Description' name='description' rules={[{required: true, message: 'Please enter a description of the enterprise'}]}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label='SubDomain'
            name='subDomain'
            rules={[
              {required: true, message: 'Please enter the subdomain of the enterprise'},
              {
                pattern: /^[a-z0-9-]+$/,
                message: 'Subdomain can only contain lowercase letters, numbers, and hyphens',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label='Status' name='status' rules={[{required: true, message: 'Please select the status of the enterprise'}]}>
            <Select>
              <Option value='NEW'>NEW</Option>
              <Option value='REVIEWING'>REVIEWING</Option>
              <Option value='APPROVED'>APPROVED</Option>
              <Option value='REJECTED'>REJECTED</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label='Logo'
            name='logo'
            valuePropName='fileList'
            getValueFromEvent={(e: any) =>
              e.fileList.map((file: any) => ({
                ...file,
                url: URL.createObjectURL(file.originFileObj),
              }))
            }
          >
            <Upload name='logo' action='https://www.mocky.io/v2/5cc8019d300000980a055e76' listType='picture'>
              <Button icon={<UploadOutlined />}>Upload Logo</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{offset: 6, span: 12}}>
            <Button type='primary' htmlType='submit' className='!bg-[#1677ff]'>
              {isEditing ? 'Update Enterprise' : 'Create Enterprise'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AdminLayout>
  );
};

export default AdminEnterprisesRegisterAndUpdate;
