import React, {useEffect, useRef} from 'react';
import {Attachment, Message, mockMessages, Thread} from './mockData';
import {FaFilePdf} from 'react-icons/fa';
import MessageInput from './MessageInput';

interface MessagesProps {
  selectedThread: Thread | null;
}

const Messages: React.FC<MessagesProps> = ({selectedThread}) => {
  const messages: Message[] = selectedThread ? mockMessages[selectedThread.id] || [] : [];
  const messageListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    // Scroll to the bottom of the message list when new messages arrive
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const openAttachmentInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const renderAttachments = (attachments: Attachment[]) => {
    return (
      <div className='mt-[8px] flex space-x-2'>
        {attachments.map(attachment => (
          <div key={attachment.id} className='flex items-center space-x-2 cursor-pointer' onClick={() => openAttachmentInNewTab(attachment.url)}>
            {attachment.type === 'image' ? (
              <img src={attachment.url} alt='Attachment' className='w-16 h-16 rounded' />
            ) : (
              <div className='flex items-center'>
                <FaFilePdf className='text-red-500 mr-[8px]' />
                <span className='text-blue-500'>PDF Attachment</span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='bg-white w-3/4 p-4 flex flex-col'>
      <h2 className='text-2xl mb-[16px]'>{selectedThread ? selectedThread.title : 'Select a Thread'}</h2>
      {selectedThread ? (
        <div className='flex-grow flex flex-col overflow-hidden'>
          <ul className='flex-grow flex flex-col overflow-y-auto gap-y-[10px] pr-[5px]' ref={messageListRef}>
            {messages.map(message => (
              <li key={message.id} className={`flex ${message.isMyMessage ? 'justify-end' : 'justify-start'} items-center space-x-2`}>
                {!message.isMyMessage && <img src={message.senderAvatar} alt={message.senderName} className='w-8 h-8 rounded-full' />}
                <div className={`bg-blue-200 p-2 rounded-lg ${message.isMyMessage ? 'bg-blue-600 text-white' : ''}`}>
                  {message.text}
                  {message.attachments && renderAttachments(message.attachments)}
                  {!message.isMyMessage && <div className='text-xs text-gray-500'>{message.senderName}</div>}
                </div>
              </li>
            ))}
          </ul>
          <div className='typeMessageArea mt-[20px]'>
            <MessageInput
              onFileUpload={e => {
                console.log('onFileUpload', e);
              }}
              onSendMessage={e => {
                console.log('onSendMessage', e);
              }}
            />
          </div>
        </div>
      ) : (
        <div className='text-lg text-center text-gray-500'>Select a thread to start.</div>
      )}
    </div>
  );
};

export default Messages;
