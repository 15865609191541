import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Select, message} from 'antd';
import {EMPLOYEE_DEPARTMENTS, EMPLOYEE_STATUSES, MESSAGES} from '../../../../../config';
import {Regex, convertMappingToArray} from '../../../../../helpers/utils';
import {useDepartmentApi} from '../../../../../repositories/useDepartment';
import {useEmployeeApi} from '../../../../../repositories/useEmployeeApi';

interface EmployeeEditModalProps {
  onCancel: () => void;
  onSave: (newName: string) => void;
  title?: string;
  isEdit?: boolean;
  currentInfo: {
    firstName: string;
    lastName: string;
    department: string;
    email: string;
    phoneNumber: string;
    status: string;
  };
}

const EmployeeEditModal: React.FC<EmployeeEditModalProps> = ({onCancel, onSave, currentInfo, title, isEdit}) => {
  const [form] = Form.useForm();
  const {actions: actionsDepartment, data: dataDepartment} = useDepartmentApi();
  useEffect(() => {
    (async () => {
      try {
        await actionsDepartment.getItems();
      } catch (e) {
        console.error(e);
        message.error('Error while loading department list, please try again later');
      }
    })();
  }, []);

  useEffect(() => {
    if (!currentInfo || !isEdit) {
      return;
    }

    form.setFieldsValue({
      firstName: currentInfo.firstName,
      lastName: currentInfo.lastName,
      department: currentInfo.department,
      email: currentInfo.email,
      contractNumber: currentInfo.phoneNumber,
      status: currentInfo.status,
    });
  }, [currentInfo]);

  const handleSave = async () => {
    try {
      await form.validateFields();
      onSave(form.getFieldsValue());
      onCancel();
    } catch (error) {
      // Handle validation error (form fields are invalid)
    }
  };

  return (
    <Modal open={true} title={title} onCancel={onCancel} onOk={handleSave} okText='Save' cancelText='Cancel'>
      <Form form={form} layout='vertical' initialValues={isEdit ? {...currentInfo} : {}}>
        <Form.Item label={'Department'} name='department' className={'mb-[20px]'}>
          <Select placeholder='Select option' options={dataDepartment?.listItems?.map(item => ({label: item?.name, value: item?.id}))} />
        </Form.Item>
        <div className={'mb-[20px]'}>Personal Details</div>
        <div className={'grid grid-cols-2 gap-[12px]'}>
          <Form.Item rules={[{required: true, message: MESSAGES.MSG18}]} label={'First name'} name='firstName' className={'mb-[20px]'}>
            <Input placeholder='First name' maxLength={50} />
          </Form.Item>
          <Form.Item rules={[{required: true, message: MESSAGES.MSG18}]} label={'Last name'} name='lastName' className={'mb-[20px]'}>
            <Input placeholder='Last name' maxLength={50} />
          </Form.Item>
        </div>
        <div className={'grid grid-cols-2 gap-[12px]'}>
          <Form.Item
            rules={[
              {required: true, message: MESSAGES.MSG18},
              {pattern: Regex.email, message: MESSAGES.MSG11},
            ]}
            label={'Email'}
            name='email'
            className={'mb-0'}
            normalize={value => value.trim()}
          >
            <Input placeholder='Email' disabled={isEdit} maxLength={50} />
          </Form.Item>
          <Form.Item
            rules={[
              {required: true, message: MESSAGES.MSG18},
              {pattern: Regex.phoneNumberWithSpace, message: MESSAGES.MSG20},
            ]}
            // normalize={value => value.replace(/[^0-9+() ]/g, '')}
            label={'Phone Number'}
            name='phoneNumber'
            className={'mb-0'}
          >
            <Input placeholder='(+country code) 0000 0000' maxLength={20} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default EmployeeEditModal;
