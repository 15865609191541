import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './SidebarContentLayout.module.scss';
import {Helmet} from 'react-helmet-async';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';

export function SidebarContentLayout(props: any) {
  console.log('Render SidebarContentLayout');
  // Initialize state with a default value of true
  const [isSidebarOpen, setSidebarOpen] = useState(localStorage.getItem('sidebarState') === 'true' || false);

  useEffect(() => {
    document.body.setAttribute('sidebar', isSidebarOpen ? 'true' : 'false');
  }, [isSidebarOpen]);

  // Function to toggle the sidebar state and update localStorage
  const toggleSidebar = useCallback(() => {
    const newState = !isSidebarOpen;
    setSidebarOpen(newState);
    localStorage.setItem('sidebarState', String(newState));
  }, [isSidebarOpen]);

  const appSidebarMemo = useMemo(() => {
    return <AppSidebar links={props.links} isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
  }, [props.links, isSidebarOpen, toggleSidebar]);

  const appHeaderMemo = useMemo(() => {
    return <AppHeader target={props.target} />;
  }, [props.target]);

  return (
    <div className={styles.moduleWrapper}>
      <Helmet>
        <title>{props.title}</title>
        <meta name='description' content={props.title} />
      </Helmet>

      <div className={'wrapper'}>
        <div className='header'>{appHeaderMemo}</div>
        <div className='main'>
          <div className='sidebar'>{appSidebarMemo}</div>
          <div className={`pageContent bg-${props.bgColor}`}>
            <div className={`centeredContent ${props.bgColor && `bg-${props.bgColor}`} ${props.customContentClasses || ''}`}>{props.children}</div>
          </div>
        </div>
        <div className='footer'>
          <AppFooter />
        </div>
      </div>
    </div>
  );
}
