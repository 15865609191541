import React, {useEffect, useRef, useState} from 'react';

import styles from './AppHeader.module.scss';

import logoSrc from '../../assets/images/maestro-logo.svg';
import {Link, useNavigate} from 'react-router-dom';
import {LOCAL_STORAGE_KEY} from '../../helpers/utils';

const AppHeader: React.FC<{target: string}> = ({target}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER) || '{}');

  useEffect(() => {
    // Close the dropdown when clicking outside it
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.USER);
    localStorage.removeItem(LOCAL_STORAGE_KEY.USER_DETAIL);
    localStorage.removeItem(LOCAL_STORAGE_KEY.STEP_DATA);
    navigate('/auth/login');
  };
  const handleAccount = () => {
    navigate(`/${target}/account/overview`);
  };
  const handleNotifications = () => {
    navigate(`/${target}/notifications`);
  };

  return (
    <div className={styles.moduleWrapper}>
      <header className={`header bg-white p-4 flex justify-between items-center`}>
        {/* Logo on the left */}
        <div className='flex items-center space-x-2'>
          <Link to={`/${target}/home`}>
            <img src={logoSrc} alt='Maestro' />
          </Link>
        </div>

        {/* Account Dropdown on the right */}
        <div className='relative' ref={dropdownRef}>
          <button onClick={toggleDropdown} className='text-black flex items-center space-x-[10px]'>
            <span className='text-sm'>{currentUser?.email}</span>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path
                d='M15.1719 5.01074L8.33787 11.8441C8.29353 11.8885 8.24086 11.9237 8.18288 11.9478C8.12491 11.9718 8.06276 11.9842 8 11.9842C7.93724 11.9842 7.87509 11.9718 7.81712 11.9478C7.75914 11.9237 7.70647 11.8885 7.66213 11.8441L0.828125 5.01074'
                stroke='#2A2A2A'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
          {isDropdownOpen && (
            <div className='absolute bg-white z-[1] right-0 mt-[8px] w-48 border border-gray-300 rounded shadow-lg'>
              <ul className='py-2'>
                {/*<li onClick={() => {*/}
                {/*  alert('Not implemented yet');*/}
                {/*}} className='px-4 py-2 hover:bg-gray-100 cursor-pointer'>My Profile</li>*/}
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={handleNotifications}>
                  Notifications
                </li>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={handleAccount}>
                  Account
                </li>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={handleLogout}>
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default AppHeader;
