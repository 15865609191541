import React from 'react';
import MAButton from '../../components/ui/button/MAButton';

export const ProfileBuilderStep2 = ({amenities, handleSelectedAmenity}: any) => {
  return (
    <div className='w-full flex flex-col gap-y-[40px]'>
      <div className='flex flex-col w-full'>
        <span className='titleLarge'>What amenities does your business have to offer?</span>
        <span className='bodyExtraLarge text-5A5A5A'>You can add more amenities after you publish your business.</span>
      </div>
      <div className='gap-[20px] grid grid-cols-3 md:grid-cols-5'>
        {amenities.map(
          (
            amenity: {
              isSelected: boolean;
              icon: any;
              label: any;
            },
            index: React.Key
          ) => (
            <MAButton
              key={index}
              type={amenity.isSelected ? 'fill' : 'text'}
              className={`h-[108px] w-full hover:border-primary !text-2A2A2A border-E2E2E2 border rounded ${amenity.isSelected ? '!text-white' : ''}`}
              onClick={() => handleSelectedAmenity(index)}
            >
              <div className='w-full flex flex-col gap-y-[8px]'>
                {amenity.icon}
                <span className='text-start'>{amenity.label}</span>
              </div>
            </MAButton>
          )
        )}
      </div>
    </div>
  );
};
