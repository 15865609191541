import {ConfigProvider} from 'antd';
import enUs from 'antd/locale/en_US';
import 'dayjs/locale/en';

import styles from './EmbedLayout.module.scss';

function EmbedLayout(props: any) {
  const style: any = {
    '--primary': `#${props.colors?.primary}`,
    '--secondary': `#${props.colors?.secondary}`,
  };

  // @ts-ignore
  return (
    <div className={styles.moduleWrapper} style={style}>
      <ConfigProvider
        locale={enUs}
        theme={{
          token: {
            // Seed Token
            colorPrimary: `#${props.colors?.primary}`,
            colorLink: `#${props.colors?.primary}`,
            fontFamily: 'inherit',
            colorBgContainer: '#F5F5F5',
          },
          components: {
            Table: {
              headerBg: `#${props.colors?.secondary}`,
              headerColor: `#5A5A5A`,
              selectionColumnWidth: 10,
              // bodySortBg: 'inherit',
              headerSortHoverBg: `#${props.colors?.primary}`,
              headerSortActiveBg: `#${props.colors?.primary}`,
              rowSelectedBg: '#e3e3e3',
              rowSelectedHoverBg: '#c0c0c0',
            },
            Button: {
              colorPrimary: `#${props.colors?.primary}`,
              algorithm: true, // Enable algorithm
              primaryShadow: 'none',
              defaultShadow: 'none',
            },
            Select: {
              optionActiveBg: '#e3e3e3',
              optionSelectedBg: '#e3e3e3',
            },
            Input: {
              colorPrimary: `#${props.colors?.primary}`,
              algorithm: true, // Enable algorithm
              colorBgContainer: '#F5F5F5',
            },
          },
        }}
      >
        {props.children}
      </ConfigProvider>
    </div>
  );
}

export default EmbedLayout;
