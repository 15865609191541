import React, {useEffect} from 'react';
import {EnterpriseLayout} from '../../../layouts/enterprise/EnterpriseLayout';
import {useParams} from 'react-router-dom';
import VendorDetail from '../../../components/pages/enterprise/vendors/detail';
import {useVendorApi} from '../../../repositories/useVendorApi';
import {useVendorReviewApi} from '../../../repositories/useVendorReviewApi';
import {useAmenityApi} from '../../../repositories/useAmenityApi';

export default function EnterpriseVendorsDetail() {
  const params = useParams();
  const {actions: actionsVendor, data: dataVendor, state: stateVendor} = useVendorApi();
  const {actions: actionsVendorReview, data: dataVendorReview, state: stateVendorReview} = useVendorReviewApi();
  const {actions: actionsAmenity, data: dataAmenity, state: stateAmenity} = useAmenityApi();

  useEffect(() => {
    (async () => {
      await Promise.all([
        actionsVendor.getItem(params.id),
        actionsVendorReview.getItems({vendor_id: params.id}),
        actionsAmenity.getItems({vendor_id: params.id}),
      ]);
    })();
  }, [params]);
  return (
    <EnterpriseLayout bgColor={'white'}>
      <VendorDetail vendor={dataVendor.detail} reviews={dataVendorReview.listItems} amenities={dataAmenity.listItems} />
    </EnterpriseLayout>
  );
}
