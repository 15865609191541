import React from 'react';
import MAButton from '../../components/ui/button/MAButton';

export const ProfileBuilderStep3 = ({fitnessBusinesses, handleSelectedBusiness}: any) => {
  return (
    <div className='w-full flex flex-col gap-y-[40px]'>
      <div className='flex flex-col w-full'>
        <span className='titleLarge'>Next, let’s describe your business</span>
        <span className='bodyExtraLarge text-5A5A5A'>Choose up to 5 descriptions. We’ll use these to get your business matched.</span>
      </div>
      <div className='gap-[20px] grid grid-cols-4 md:grid-cols-4'>
        {fitnessBusinesses.map((business: any, index: React.Key | null | undefined) => (
          <MAButton
            key={index}
            type={business.isSelected ? 'fill' : 'text'}
            className={`h-[52px] w-full hover:border-primary ${!business?.isSelected && '!text-2A2A2A'} border-E2E2E2 border rounded`}
            onClick={() => handleSelectedBusiness(index)}
          >
            <div className='w-full flex flex-col gap-y-[8px]'>
              <span className='text-start'>{business.label}</span>
            </div>
          </MAButton>
        ))}
      </div>
    </div>
  );
};
