import useRestApi from '../hooks/useRestApi';

interface ProfileApi {
  actions: {
    updateItem: (id: string | number | undefined, values: Record<string, any>) => Promise<any>;
    customUpdate: (path: string, values: Record<string, any>) => Promise<any>;
    customPost: (path: string, values: Record<string, any>) => Promise<any>;
    customGet: (path: string, params: Record<string, any>) => Promise<any>;
    deleteItem: (id: string | number | undefined) => Promise<any>;
    customDelete: (path: string, values: Record<string, any>) => Promise<any>;
    getItems: (params?: Record<string, any>) => Promise<any>;
  };
  data: {
    detail: any;
    listItems: any;
    listPagination: any;
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useProfileApi(): ProfileApi {
  return useRestApi('profile');
}
