import React from 'react';
import styles from './AppFooter.module.scss';

const AppFooter: React.FC = () => {
  return (
    <div className={styles.moduleWrapper}>
      <footer className={`footer bg-white p-4 flex justify-between items-center`}>© 2024 Maestro Wellness. All rights reserved.</footer>
    </div>
  );
};

export default AppFooter;
