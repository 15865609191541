import {useEffect, useState} from 'react';
import {useContractApi} from '../../../repositories/useContractApi';
import {CONTRACT_STATUSES} from '../../../config';
import {ContractModal} from '../../ui/modals/ContractModal';
import {message, Modal} from 'antd';
import MAButton from '../../ui/button/MAButton';
import MAIcon from '../../ui/icon/MAIcon';
import {CustomerItemMatches} from './CustomerItemMatches';

export const VendorContracts = () => {
  const [clickedContract, setClickedContract] = useState<any>(null);
  const [isOpenSucceeded, setIsOpenSucceeded] = useState(false);

  const {actions: actionsContract, data: dataContract, state: stateContract} = useContractApi();

  const toggleSucceeded = () => setIsOpenSucceeded(prev => !prev);

  useEffect(() => {
    (async () => {
      try {
        await actionsContract.getItems({
          order_by: '-created_at',
        });
      } catch (e) {
        console.log('Debug ', e);
      }
    })();
  }, []);

  async function handleOnDeclineContract(contract: {id: any}) {
    try {
      await actionsContract.customPost(`${contract.id}/sign`, {
        status: CONTRACT_STATUSES.REJECTED_BY_VENDOR.value,
      });
      await actionsContract.getItems();
      message.success('Contract declined');
    } catch (e) {
      console.log('Debug ', e);
      message.error('Error while declining contract. Please try again.');
    }
  }

  useEffect(() => {
    console.log('DEBUG dataContract?.listItems', dataContract?.listItems);
  }, [dataContract?.listItems]);

  if (!Array.isArray(dataContract?.listItems) || dataContract?.listItems?.length == 0)
    return (
      <div className='bg-white min-h-[200px] h-[200px] border rounded border-E2E2E2'>
        <div className='w-full h-full'>
          <div className='flex flex-col gap-[12px] w-full h-full items-center justify-center'>
            <span className='titleMedium '>You have no matches</span>
            <span className='text-706E66'>When you’ve been matched with an enterprise, they’ll be displayed here</span>
          </div>
        </div>
      </div>
    );
  return (
    <div className='flex flex-col gap-y-6'>
      {dataContract?.listItems
        ?.filter(contract => {
          const allowStatus = [
            CONTRACT_STATUSES.PENDING_CONTRACT.value,
            CONTRACT_STATUSES.APPROVED_BY_VENDOR.value,
            CONTRACT_STATUSES.REJECTED_BY_ENTERPRISE.value,
            CONTRACT_STATUSES.CONTRACT_SIGNED.value,
          ];

          return allowStatus.includes(contract.status);
        })
        ?.map(contract => {
          return (
            <CustomerItemMatches
              onDeclineContract={handleOnDeclineContract}
              key={contract.id}
              contract={contract}
              setClickedContract={setClickedContract}
            />
          );
        })}

      <ContractModal
        signedAt={clickedContract?.vendor_signed_at}
        isSignable={true}
        content={clickedContract?.content}
        isOpen={!!clickedContract}
        onDeny={async () => {
          try {
            await actionsContract.customPost(`${clickedContract.id}/sign`, {
              status: CONTRACT_STATUSES.REJECTED_BY_VENDOR.value,
            });
            await actionsContract.getItems();
            message.success('Contract declined');
          } catch (e) {
            console.log('Debug ', e);
            message.error('Error while declining contract. Please try again.');
          }
          setClickedContract(null);
        }}
        onAgree={async () => {
          toggleSucceeded();
          try {
            await actionsContract.customPost(`${clickedContract.id}/sign`, {
              status: CONTRACT_STATUSES.APPROVED_BY_VENDOR.value,
            });

            await actionsContract.getItems();
            message.success('Contract approved');
          } catch (e) {
            console.log('Debug ', e);
            message.error('Error while sign contract. Please try again.');
          }
          setClickedContract(null);
        }}
        onCancel={() => {
          setClickedContract(null);
        }}
      />
      <Modal
        width={420}
        open={isOpenSucceeded}
        onCancel={toggleSucceeded}
        centered
        footer={() => (
          <>
            <div className='flex justify-center w-full'>
              <MAButton className='px-[24px] py-[6px]' onClick={toggleSucceeded}>
                Close
              </MAButton>
            </div>
          </>
        )}
        okText={'Delete'}
        cancelButtonProps={{className: 'border-none hover:text-black'}}
        okButtonProps={{className: '!bg-FFDDDA text-DE1135 !hover:text-DE1135'}}
        cancelText={'Cancel'}
      >
        <div className='flex flex-col gap-y-3 justify-between items-center mt-[24px] px-[8px]'>
          <div className='w-[60px] h-[60px]'>
            <MAIcon name='checkCircle' size={60} color='primary' />
          </div>
          <span className='titleLarge text-2A2A2A'>E-contract successfully signed!</span>
          <span className='bodyLarge text-5A5A5A'>Manage your new customer by selecting the Enterprises tab, found under Customers page. </span>
        </div>
      </Modal>
    </div>
  );
};
