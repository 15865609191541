import {useEffect} from 'react';
import {VendorLayout} from '../../layouts/vendor/VendorLayout';

export default function VendorStaffs() {
  useEffect(() => {}, []);
  return (
    <VendorLayout>
      <center>
        <h2 className={'mt-[100px]'}>Vendor staffs</h2>
      </center>
    </VendorLayout>
  );
}
