import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Form, Input} from 'antd';
import {AdminLayout} from '../../layouts/admin/AdminLayout';
import axios from 'axios';

interface EnterpriseData {
  name: string;
  email: string;
  description: string;
  subDomain: string;
  status: string;
  logo?: string;
}

const AdminEnterprisesViewDetails: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const [enterpriseData, setEnterpriseData] = useState<EnterpriseData | null>(null);

  useEffect(() => {
    // Fetch enterprise data based on the id parameter
    const fetchEnterpriseData = async () => {
      try {
        const response = await axios.get(`https://dev-api.maestro.moha.team/api/enterprises/${id}`);
        const data: EnterpriseData = response.data.data; // Replace with your API response structure
        setEnterpriseData(data);
      } catch (error) {
        console.error('Error fetching enterprise data:', error);
      }
    };

    fetchEnterpriseData();
  }, [id]);

  return (
    <AdminLayout>
      <div className='container mx-auto py-10'>
        <h2 className='text-3xl font-semibold mb-[16px]'>Enterprise Details</h2>
        {enterpriseData ? (
          <Form labelCol={{span: 6}} wrapperCol={{span: 12}} initialValues={enterpriseData}>
            <Form.Item label='Name' name='name'>
              <Input disabled />
            </Form.Item>

            <Form.Item label='Email' name='email'>
              <Input disabled />
            </Form.Item>

            <Form.Item label='Description' name='description'>
              <Input.TextArea disabled />
            </Form.Item>

            <Form.Item label='SubDomain' name='subDomain'>
              <Input disabled />
            </Form.Item>

            <Form.Item label='Status' name='status'>
              <Input disabled />
            </Form.Item>

            {enterpriseData.logo && (
              <Form.Item label='Logo'>
                <img src={enterpriseData.logo} alt='Enterprise Logo' className='max-w-xs' />
              </Form.Item>
            )}

            <div className='mt-[15px]'>
              <Link to={`/admin/enterprises/update/${id}`} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                Edit
              </Link>
            </div>
          </Form>
        ) : (
          <p>Loading enterprise data...</p>
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminEnterprisesViewDetails;
