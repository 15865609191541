import React, {useEffect} from 'react';
import {Form, InputNumber, Modal} from 'antd';
import dayjs from 'dayjs';
import MADatepicker from '../../../../ui/datepicker/MADatepicker';
import {createDateValidator} from '../../../../../helpers/utils';
import {MESSAGES} from '../../../../../config';

interface ProgramPlanEditorModalProps {
  onCancel: () => void;
  onSave: (updateValues: any) => void;
  currentStartDate: string;
  currentEndDate: string;
  currentPointsPerEmployee: string | number;
  isOpen: boolean;
  isViewOnly?: boolean;
  isShowPointPerEmployee?: boolean;
}

const ProgramPlanEditorModal: React.FC<ProgramPlanEditorModalProps> = ({
  isOpen,
  onCancel,
  onSave,
  currentStartDate,
  currentEndDate,
  isViewOnly,
  currentPointsPerEmployee,
  isShowPointPerEmployee = false,
}) => {
  const [form] = Form.useForm();

  const handleSave = async () => {
    try {
      await form.validateFields();
      const currentValues = form.getFieldsValue();
      currentValues.start_date = currentValues.start_date?.format('YYYY-MM-DD') ?? null;
      currentValues.end_date = currentValues.end_date?.format('YYYY-MM-DD') ?? null;

      onSave(currentValues);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      start_date: currentStartDate ? dayjs(currentStartDate) : null,
      end_date: currentEndDate ? dayjs(currentEndDate) : null,
      points_per_employee: currentPointsPerEmployee || 0,
    });
  }, []);

  const resetErrorField = () => {
    ['start_date', 'end_date'].forEach(field => {
      form.setFields([{name: field, errors: []}]);
    });
  };

  return (
    <Modal
      open={isOpen}
      title='Implementation'
      onCancel={onCancel}
      okButtonProps={{
        className: isViewOnly ? 'hidden' : '',
      }}
      onOk={handleSave}
      okText={isViewOnly ? null : 'Save'}
      cancelText={isViewOnly ? 'Close' : 'Cancel'}
    >
      <Form requiredMark={false} form={form} layout='vertical' className='flex flex-col gap-[12px] mt-[1rem]'>
        <div className={'bodyMedium'}>Program Duration</div>
        <div className={'grid grid-cols-2 gap-[12px] items-start justify-center'}>
          <div>
            <div className={'bodyMedium text-7D7D7A mb-[4px]'}>Start date</div>
            <Form.Item
              label={null}
              name='start_date'
              rules={[
                {required: true, message: MESSAGES.MSG18},
                createDateValidator({
                  startDateFieldName: 'start_date',
                  endDateFieldName: 'end_date',
                  message: MESSAGES.TODO_START_DATE,
                  operator: 'before',
                }),
              ]}
              className='col-span-1 mb-0 !text-red'
            >
              <MADatepicker
                onChange={() => {
                  resetErrorField();
                }}
                disabledDate={current => current && current.isBefore(dayjs().startOf('day'))}
                disabled={isViewOnly}
                className='w-full'
              />
            </Form.Item>
          </div>
          <div>
            <div className={'bodyMedium text-7D7D7A mb-[4px]'}>End date</div>
            <Form.Item
              label={null}
              name='end_date'
              rules={[
                {required: true, message: MESSAGES.MSG18},
                createDateValidator({
                  startDateFieldName: 'start_date',
                  endDateFieldName: 'end_date',
                  message: MESSAGES.TODO_END_DATE,
                  operator: 'after',
                }),
              ]}
              className='col-span-1 mb-0'
            >
              <MADatepicker
                onChange={() => {
                  resetErrorField();
                }}
                disabledDate={current => current && current.isBefore(dayjs().startOf('day'))}
                disabled={isViewOnly}
                className='w-full'
              />
            </Form.Item>
          </div>
        </div>
        {!!isShowPointPerEmployee && (
          <div>
            <div className={'bodyMedium mb-[4px]'}>Points Per Employee</div>
            <div className={'bodySmall mb-[4px]'}>Assign points for your employees to redeem goods or services from your program.</div>
            <Form.Item label={null} name='points_per_employee' rules={[{required: true, message: MESSAGES.MSG18}]} className='col-span-1 mb-0'>
              <InputNumber disabled={isViewOnly} className='w-full' />
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default ProgramPlanEditorModal;
