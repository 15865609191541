import React, {useContext, useEffect} from 'react';
import styles from './VendorLayout.module.scss';
import {CiUser} from 'react-icons/ci';
import {SidebarContentLayout} from '../shared/SidebarContentLayout';
import {ColorsContext} from '../../contexts/ColorsContext';

const links = [
  {
    to: '/vendor/home',
    title: 'Home',
    icon: <CiUser size={25} />,
  },
  {
    to: '/vendor/customers',
    title: 'Customers',
    icon: <CiUser size={25} />,
  },
  {
    to: '/vendor/users',
    title: 'Users',
    icon: <CiUser size={25} />,
  },
  {
    to: '/vendor/staffs',
    title: 'Staffs',
    icon: <CiUser size={25} />,
  },
  {
    to: '/vendor/reports',
    title: 'Reports',
    icon: <CiUser size={25} />,
  },
];

export function VendorLayout(props: any) {
  const {setColors} = useContext(ColorsContext);

  useEffect(() => {
    setColors({
      primary: '7E38B7',
      secondary: 'F6EAFF',
    });

    console.log('DEBUG SET COLOR FROM VendorLayout');
  }, [setColors]);

  return (
    <div className={styles.moduleWrapper}>
      <SidebarContentLayout
        {...{
          bgColor: '#F5F5F5',
          title: 'Maestro Vendor',
          target: 'vendor',
          links,
          ...props?.sidebarProps,
        }}
      >
        {props.children}
      </SidebarContentLayout>
    </div>
  );
}
