import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, message, Progress, Row, Spin} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import Step1 from '../../../../components/pages/enterprise/programs/start/Step1';
import Step2 from '../../../../components/pages/enterprise/programs/start/Step2';
import Step3 from '../../../../components/pages/enterprise/programs/start/Step3';
import styles from './EnterpriseProgramEdit.module.scss';
import {ProgramData, StepData} from '../../../../types';
import Step4 from '../../../../components/pages/enterprise/programs/start/Step4';
import Step5 from '../../../../components/pages/enterprise/programs/start/Step5';
import Step6 from '../../../../components/pages/enterprise/programs/start/Step6';
import {
  convertDateAndFormat,
  getDataStepFromStorage,
  LOCAL_STORAGE_KEY,
  PROGRAM_STATUSES_ENUM,
  PROGRAM_TYPES_ENUM,
  reverseMappingDataToLocal,
} from '../../../../helpers/utils';
import {EnterpriseLayout} from '../../../../layouts/enterprise/EnterpriseLayout';
import {addActivities, editProgram, getFileDetail, getPrograms} from '../../../../services/apiService';
import OutLineButton from '../../../../components/shared/OutLineButton';
import {MESSAGES} from '../../../../config';

const EnterpriseProgramEdit: React.FC = () => {
  const step1FormRef = useRef<FormInstance>(null);
  const step2FormRef = useRef<FormInstance>(null);
  const step5FormRef = useRef<FormInstance>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {id, tab} = useParams();
  const stepFromRouter = searchParams.get('step');
  const programTypeFromRouter = searchParams.get('program_type');
  const statusFromRouter = searchParams.get('status');

  const [currentStep, setCurrentStep] = useState(stepFromRouter ? parseInt(stepFromRouter) : 0);
  const [stepData, setStepData] = useState<StepData>({});
  const [stepType, setStepType] = useState(programTypeFromRouter ?? PROGRAM_TYPES_ENUM.TAILOR_MADE);
  const formRefs = [step1FormRef, step2FormRef, step5FormRef];
  const [loading, setLoading] = useState<null | boolean>(null);
  const [initOrder, setInitOrder] = useState<any>([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const steps = [
    {title: "Let's get started"},
    ...(stepType === PROGRAM_TYPES_ENUM.TAILOR_MADE
      ? [
          {
            title: 'Design your program',
            subTitle: 'Tell us your preferences so we can match you with the right vendors.',
          },
          {
            title: 'Design your program',
            subTitle: 'Your wellness program’s built! Review your matched services to proceed. Vendor not quite right? Hover over and swap!',
          },
        ]
      : []),
    {
      title: 'Import Employees',
      subTitle: 'Add your employees so they can get access when you’re ready to launch',
    },
    {
      title: 'Rules of Engagement',
      subTitle: 'Tailor your program for your employees',
    },
    ...(stepType === PROGRAM_TYPES_ENUM.TAILOR_MADE
      ? [
          {
            title: 'Vendors Engagement',
            subTitle: 'We’ll take the contracts and negotiations off your hands',
          },
        ]
      : []),
  ];
  const progress = (currentStep / (steps.length - 1)) * 100;
  const isFinalStep =
    (currentStep === 5 && stepType === PROGRAM_TYPES_ENUM.TAILOR_MADE) || (currentStep === 2 && stepType === PROGRAM_TYPES_ENUM.CHERRY_PICK);

  useEffect(() => {
    if (id) {
      const getProgram = async () => {
        try {
          setLoading(true);
          const program = await getPrograms(id);
          if (program) {
            if (program.employees_file_id) {
              const resDetailFile = await getFileDetail(program.employees_file_id);
              program.fileData = resDetailFile;
            }

            const dataParse = reverseMappingDataToLocal(program);
            console.log('bb program', dataParse);
            if (dataParse.step3Data && dataParse.step1Data?.programType === PROGRAM_TYPES_ENUM.TAILOR_MADE) {
              setInitOrder(dataParse.step3Data);
            }
            const status = statusFromRouter ? parseInt(statusFromRouter) : null;
            if (
              status !== undefined &&
              status !== null &&
              [PROGRAM_STATUSES_ENUM.DRAFT, PROGRAM_STATUSES_ENUM.PENDING_CONTRACT, PROGRAM_STATUSES_ENUM.WAITING_REVIEW].includes(status)
            ) {
              localStorage.setItem(LOCAL_STORAGE_KEY.STEP_DATA, JSON.stringify(dataParse));
            }

            setStepData(dataParse);

            if (dataParse.status === PROGRAM_STATUSES_ENUM.DRAFT) {
              setCurrentStep(dataParse.step - 1);
            }

            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          message.error('Get program failed!');
          console.log('bb error', error);
        }
      };
      getProgram();
    }
  }, [id]);

  const getCommonData = (dataLocal: any, step: number, status: PROGRAM_STATUSES_ENUM) => ({
    name: dataLocal?.step1Data?.programName ?? '',
    type: dataLocal?.step1Data?.programType ?? '',
    status: status ?? '',
    employees_file_id: stepType === PROGRAM_TYPES_ENUM.TAILOR_MADE ? dataLocal.step4Data?.fileId : dataLocal.step2Data?.fileId,
    program_matching: {
      step: step ?? '',
    },
  });
  const getTailorMadeData = (dataLocal: any, commonData: any) => ({
    ...commonData,
    points_per_employee: dataLocal?.step5Data?.allotPoint ?? '',
    start_date: convertDateAndFormat(dataLocal?.step5Data?.programDateStart),
    end_date: convertDateAndFormat(dataLocal?.step5Data?.programDateEnd),
    remind_before_end: dataLocal?.step5Data?.reminder ? 1 : 0,
    product_ids: dataLocal?.step3Data?.length > 0 ? dataLocal?.step3Data?.map((item: any) => item?.id) : [],
    program_matching: {
      ...commonData.program_matching,
      category_id: dataLocal?.step2Data?.subCategory ?? '',
      service_type: dataLocal?.step2Data?.serviceType ?? '',
      physical_location: dataLocal?.step2Data?.physicalLocation ?? '',
      location: dataLocal?.step2Data?.regionLocation ?? '',
      start_date: convertDateAndFormat(dataLocal?.step2Data?.startDate),
      end_date: convertDateAndFormat(dataLocal?.step2Data?.endDate),
      number_of_employees: dataLocal?.step2Data?.numberOfEmployee ?? '',
      total_budget_min: dataLocal?.step2Data?.totalBudgetMin ?? '',
      total_budget_max: dataLocal?.step2Data?.totalBudgetMax ?? '',
      budget_per_employee_min: dataLocal?.step2Data?.budgetPerEmployeeMin ?? '',
      budget_per_employee_max: dataLocal?.step2Data?.budgetPerEmployeeMax ?? '',
    },
  });
  const getOtherProgramData = (dataLocal: any, commonData: any) => {
    return {
      ...commonData,
      points_per_employee: dataLocal?.step3Data?.allotPoint ?? '',
      start_date: convertDateAndFormat(dataLocal?.step3Data?.programDateStart),
      end_date: convertDateAndFormat(dataLocal?.step3Data?.programDateEnd),
      remind_before_end: dataLocal?.step3Data?.reminder ? 1 : 0,
    };
  };
  const handleStepData = (step: number, data: any, status: PROGRAM_STATUSES_ENUM) => {
    const stepDataLocal = {...data};
    const commonData = getCommonData(stepDataLocal, step, status);

    let mappingBodyData: ProgramData = {};
    if (stepType === PROGRAM_TYPES_ENUM.TAILOR_MADE) {
      mappingBodyData = getTailorMadeData(stepDataLocal, commonData);
    } else {
      mappingBodyData = getOtherProgramData(stepDataLocal, commonData);
    }

    createProgramAndClearData(mappingBodyData, status);
  };

  const createProgramAndClearData = async (mappingBodyData: ProgramData, status: PROGRAM_STATUSES_ENUM) => {
    try {
      setLoading(true);
      await editProgram(mappingBodyData, id);
      setLoading(false);
      localStorage.removeItem(LOCAL_STORAGE_KEY.STEP_DATA);
      if (status === PROGRAM_STATUSES_ENUM.PENDING_CONTRACT || status === PROGRAM_STATUSES_ENUM.READY_TO_LAUNCH) {
        message.success('Created program successfully!');
        navigate(`/enterprise/programs/`);
      }
      if (status === PROGRAM_STATUSES_ENUM.DRAFT) {
        message.success('Saved draft successfully!');
        navigate(`/enterprise/programs/`);
      }
    } catch (error) {
      setLoading(false);
      message.error('Save failed!');
    }
  };
  const updateStepData = (step: number, data: any) => {
    const stepData = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
    const prevData = stepData ? JSON.parse(stepData) : {};
    const newData = {...prevData, [`step${step}Data`]: data};
    setStepData(newData);
    localStorage.setItem(LOCAL_STORAGE_KEY.STEP_DATA, JSON.stringify(newData));
    return newData;
  };

  const handleStepDataDraft = async (step: number, data?: any, isForm = true) => {
    try {
      let stepDataLocal;
      if (data && isForm) {
        const newData = updateStepData(step, data);
        stepDataLocal = {...newData, [`step${step}Data`]: data};
      } else {
        stepDataLocal = data;
      }
      handleStepData(step, stepDataLocal, PROGRAM_STATUSES_ENUM.DRAFT);
    } catch (error) {
      console.log('bb error', error);
    }
  };

  const handleStepDataFinal = async (step: number, data: any) => {
    try {
      handleStepData(
        step,
        data,
        stepType === PROGRAM_TYPES_ENUM.TAILOR_MADE ? PROGRAM_STATUSES_ENUM.PENDING_CONTRACT : PROGRAM_STATUSES_ENUM.READY_TO_LAUNCH
      );
    } catch (error) {
      console.log('bb error', error);
    }
  };

  const changeStep = (type: 'next' | 'back') => {
    if (type === 'next') {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep > 0 ? currentStep - 1 : 0);
    }
  };
  const navigateToStep = (step: number) => {
    const params = new URLSearchParams(location.search);
    params.set('step', String(step));
    navigate(`/enterprise/programs/${id}/${tab}/edit?${params.toString()}`);
  };
  const handleProgramTypeChange = (programType: string) => {
    const getDataLocal = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
    setStepType(programType);
    if (getDataLocal && programType !== JSON.parse(getDataLocal).step1Data.programType) {
      setStepData({});
      localStorage.removeItem(LOCAL_STORAGE_KEY.STEP_DATA);
    }
  };

  const nextStep3 = () => {
    if (PROGRAM_TYPES_ENUM.TAILOR_MADE && currentStep === 2) {
      const step3DataLocal = getDataStepFromStorage('step3Data');
      if (step3DataLocal && step3DataLocal.length > 0) {
        changeStep('next');
        navigateToStep(currentStep + 1);
      } else {
        message.error(MESSAGES.MSG21);
      }
    }
  };

  const nextClick = () => {
    const currentFormRef = formRefs[currentStep] ?? step5FormRef;
    if (currentFormRef && currentFormRef.current) {
      currentFormRef.current
        .validateFields()
        .then(values => {
          let updatedValues = values;

          if (currentStep === 0) {
            const programType = programTypeFromRouter ?? PROGRAM_TYPES_ENUM.TAILOR_MADE;
            handleProgramTypeChange(programType);
            updatedValues = {...values, programType};
          }

          updateStepData(currentStep + 1, updatedValues);
          changeStep('next');
          navigateToStep(currentStep + 1);
        })
        .catch(errorInfo => {
          console.log('bb errorInfo', errorInfo);
        });
    } else {
      switch (stepType) {
        case PROGRAM_TYPES_ENUM.TAILOR_MADE:
          if (currentStep === 2) {
            const step3DataLocal = getDataStepFromStorage('step3Data');

            if (step3DataLocal && step3DataLocal.length > 0) {
              setIsCartOpen(true);
            } else {
              message.error(MESSAGES.MSG21);
            }
          }

          if (currentStep === 3) {
            const step4DataLocal = getDataStepFromStorage('step4Data');

            if (step4DataLocal) {
              changeStep('next');
              navigateToStep(currentStep + 1);
            } else {
              message.error(MESSAGES.MSG22);
            }
          }
          if (currentStep > 3 && currentStep <= 5) {
            changeStep('next');
            navigateToStep(currentStep + 1);
          }
          break;
        case PROGRAM_TYPES_ENUM.CHERRY_PICK:
          if (currentStep === 1) {
            const step2DataLocal = getDataStepFromStorage('step2Data');

            if (step2DataLocal) {
              changeStep('next');
              navigateToStep(currentStep + 1);
            } else {
              message.error(MESSAGES.MSG22);
            }
          }
          if (currentStep > 1 && currentStep <= 3) {
            changeStep('next');
            navigateToStep(currentStep + 1);
          }
          break;
      }
    }
  };
  const proceedAndCloseClick = () => {
    if (stepType === PROGRAM_TYPES_ENUM.CHERRY_PICK) {
      const currentFormRef = formRefs[currentStep];
      if (currentFormRef && currentFormRef.current) {
        currentFormRef.current
          .validateFields()
          .then(values => {
            const saveData = {...stepData, [`step${currentStep + 1}Data`]: values};
            handleStepDataFinal(currentStep + 1, saveData);
          })
          .catch(errorInfo => {
            console.log('bb errorInfo', errorInfo);
          });
      }
    } else {
      const currentDataLocal = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
      if (!currentDataLocal) return;
      handleStepDataFinal(currentStep + 1, JSON.parse(currentDataLocal));
    }
  };

  const saveDraftClick = () => {
    const currentFormRef = formRefs[currentStep] ?? step5FormRef;
    if (currentFormRef && currentFormRef.current) {
      currentFormRef.current
        .validateFields()
        .then(values => {
          if (currentStep === 0) {
            const programType = programTypeFromRouter ?? PROGRAM_TYPES_ENUM.TAILOR_MADE;
            handleProgramTypeChange(programType);
            values = {...values, programType};
          }
          handleStepDataDraft(currentStep + 1, values, true);
        })
        .catch(errorInfo => {
          console.log('bb errorInfo', errorInfo);
        });
    } else {
      console.log('bb not have form stepData', stepData);
      const currentDataLocal = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
      if (!currentDataLocal) return;
      handleStepDataDraft(currentStep + 1, JSON.parse(currentDataLocal), false);
    }
  };

  const renderStep = () => {
    if (currentStep === 0) {
      return <Step1 formRef={step1FormRef} initialValues={stepData.step1Data} />;
    }

    if (stepType === PROGRAM_TYPES_ENUM.TAILOR_MADE) {
      switch (currentStep) {
        case 1:
          return <Step2 formRef={step2FormRef} initialValues={stepData.step2Data} />;
        case 2:
          return (
            <Step3
              syncCart={(cart: boolean | ((prevState: boolean) => boolean)) => setIsCartOpen(cart)}
              isCartOpen={isCartOpen}
              addActivities={handleAddActivities}
              addCartAndClose={saveDraftClick}
              nextStep={nextStep3}
              initialValues={{...stepData.step2Data, programName: stepData.step1Data?.programName}}
            />
          );
        case 3:
          return <Step4 initialValues={stepData.step4Data} />;
        case 4:
          return <Step5 formRef={step5FormRef} initialValues={stepData.step5Data} />;
        case 5:
          return <Step6 />;
      }
    }

    if (stepType === PROGRAM_TYPES_ENUM.CHERRY_PICK) {
      switch (currentStep) {
        case 1:
          return <Step4 initialValues={stepData.step2Data} />;
        case 2:
          return <Step5 formRef={step5FormRef} initialValues={stepData.step3Data} />;
      }
    }

    return null;
  };

  const handleAddActivities = async (listProduct: any) => {
    try {
      await addActivities(listProduct, id);
      message.success('Update activities successfully!');
      navigate(`/enterprise/programs/${id}/products`);
    } catch (error) {
      message.error('Add activities failed!');
    }
  };

  return (
    <EnterpriseLayout bgColor={'white'} customContentClasses={'h-full'}>
      <div className={`${styles.moduleWrapper} flex h-full`}>
        <div className={'flex flex-col w-full'}>
          {loading === true && (
            <div className='flex-grow flex items-center justify-center'>
              <Spin size='large' />
            </div>
          )}
          {loading === false && (
            <>
              {currentStep >= 0 && currentStep < steps.length && (
                <div className='h-[14vh]'>
                  {statusFromRouter && parseInt(statusFromRouter) === PROGRAM_STATUSES_ENUM.DRAFT && (
                    <Row className='w-full'>
                      <Col span={18} offset={3}>
                        <h2 className='titleSmall'>Step: {currentStep + 1}</h2>
                      </Col>
                    </Row>
                  )}

                  <Row className='w-full'>
                    <Col span={18} offset={3}>
                      <div>
                        <p className='titleLarge'>{steps[currentStep].title}</p>
                      </div>
                    </Col>
                  </Row>
                  {steps[currentStep].subTitle && (
                    <Row className='w-full'>
                      <Col span={18} offset={3}>
                        <h2 className='bodyExtraLarge'>{steps[currentStep].subTitle}</h2>
                      </Col>
                    </Row>
                  )}
                </div>
              )}

              <div className='flex-grow w-full'>{renderStep()}</div>
              {statusFromRouter && parseInt(statusFromRouter) === PROGRAM_STATUSES_ENUM.DRAFT ? (
                <Row justify={'center'} className='fixed left-0 bottom-[10px] w-full bg-white'>
                  <Col span={24}>
                    <Progress showInfo={false} strokeColor={'var(--primary)'} percent={progress} />
                    <Row className='mx-2' justify={'space-between'}>
                      <Button
                        type='text'
                        onClick={() => {
                          changeStep('back');
                          console.log('bb currentStep', currentStep);

                          if (currentStep > 0) {
                            navigateToStep(currentStep > 0 ? currentStep - 1 : 0);
                          } else {
                            navigate(`/enterprise/programs`);
                          }
                        }}
                      >
                        Back
                      </Button>
                      <div className='flex flex-row'>
                        <OutLineButton onClick={saveDraftClick}>Save & exit</OutLineButton>

                        <Button
                          disabled={!!loading}
                          className={`bg-primary px-[24px] ml-[12px]`}
                          type='primary'
                          onClick={isFinalStep ? proceedAndCloseClick : nextClick}
                        >
                          {isFinalStep ? 'Proceed & Close' : 'Next'}
                        </Button>
                      </div>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row className='fixed left-0 bottom-[10px] w-full bg-white' justify={'center'}>
                  <Col span={24}>
                    <Progress showInfo={false} strokeColor={'var(--primary)'} percent={100} />
                    <Row className='mx-[50px]' justify={'space-between'}>
                      <Button
                        type='text'
                        onClick={() => {
                          navigate(`/enterprise/programs/${id}/products`);
                        }}
                      >
                        Back
                      </Button>
                      <div className='flex flex-row'>
                        <Button
                          className={`bg-primary px-[24px] ml-[12px]`}
                          type='primary'
                          disabled={!!loading}
                          onClick={() => {
                            nextClick();
                            // const dataStep = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
                            // if (!dataStep) return;
                            // const data = JSON.parse(dataStep);
                            // const diff = data.step3Data
                            //   .filter((item: any) => !initOrder.map((i: any) => i.id).includes(item.id))
                            //   .map((_e: any) => _e.id);
                            // handleAddActivities(diff);
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </div>
    </EnterpriseLayout>
  );
};

export default EnterpriseProgramEdit;
