import React from 'react';
import StarsReview from '../../../../shared/StarsReview';

export default function ProductLeftInfo({product}: any) {
  const createMarkup = () => {
    return {__html: product.description};
  };

  const handleClickReview = () => {
    document.querySelector('.reviewArea')?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  };
  return (
    <div className='flex flex-col gap-y-[24px]'>
      <span className='displaySmall'>{product.name}</span>
      {/* reviews */}
      <div className='flex items-center gap-x-[20px]'>
        <div className='text-center px-[10px] py-[2px] bg-E2EDDE rounded-[10px] bodySmall'>{product?.category?.name}</div>
        {/* vendor stars */}
        <div className='flex gap-[4px]'>
          <p className='!font-medium bodyMedium'>{Number(product.rating).toFixed(1)}</p>
          <StarsReview scoreNum={Number(product.rating)} />
        </div>
        <span onClick={handleClickReview} className='lableMedium cursor-pointer underline'>{`${product.total_reviews} reviews`}</span>
      </div>

      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
}
