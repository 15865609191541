import React, {useContext, useEffect} from 'react';
import styles from './EmployeeLayout.module.scss';
import {CiUser} from 'react-icons/ci';
import {SidebarContentLayout} from '../shared/SidebarContentLayout';
import {ColorsContext} from '../../contexts/ColorsContext';

const links = [
  {
    to: '/employee/home',
    title: 'Home',
    icon: <CiUser size={25} />,
  },
];

export function EmployeeLayout(props: any) {
  const {setColors} = useContext(ColorsContext);

  useEffect(() => {
    setColors({
      primary: 'E4A45C',
      secondary: 'FFDFBA',
    });
  }, [setColors]);

  return (
    <div className={styles.moduleWrapper}>
      <SidebarContentLayout
        {...{
          bgColor: 'white',
          title: 'Maestro Employee',
          target: 'vendor',
          links,
          ...props?.sidebarProps,
        }}
      >
        {props.children}
      </SidebarContentLayout>
    </div>
  );
}
