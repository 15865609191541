import React, {useContext, useEffect} from 'react';
import styles from './EmployeeFullWidthLayout.module.scss';
import {ColorsContext} from '../../contexts/ColorsContext';
import logoSrc from '../../assets/images/maestro-logo.svg';

export function EmployeeFullWidthLayout(props: any) {
  const {setColors} = useContext(ColorsContext);

  useEffect(() => {
    setColors({
      primary: 'E4A45C',
      secondary: 'FFDFBA',
    });
  }, [setColors]);

  return (
    <div className={styles.moduleWrapper}>
      <div className='logo fixed left-[20px] top-[20px]'>
        <img src={logoSrc} alt='Logo' />
      </div>
      <div className='layoutContent'>{props.children}</div>
    </div>
  );
}
