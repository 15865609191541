import React from 'react';
import {AccountLayout} from '../../layouts/account/AccountLayout';
import OutLineButton from '../../components/shared/OutLineButton';
import {Avatar, List} from 'antd';
import ReadMore from '../../components/shared/Readmore';

function Notifications() {
  const data = [
    {
      title:
        'Ant Design Title 1 Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatem eaque dolor vitae asperiores amet iure eos totam dolorem est nostrum.',
      desciption: 'Ant Design, a design language for background applications, is refined by Ant UED Team lorem20',
    },
    {
      title: 'Ant Design Title 2',
      desciption: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
    },
    {
      title: 'Ant Design Title 3',
      desciption: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
    },
    {
      title: 'Ant Design Title 4',
      desciption: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
    },
  ];

  return (
    <AccountLayout className={'mt-[72px]'}>
      <div className='w-[818px] ml-[231px]'>
        <div className='flex flex-row justify-between'>
          <p className='displaySmall text-2A2A2A font-medium'>Notifications</p>
          <OutLineButton className='pr-[10px] pl-[10px] titleSmall'>Mark all as read</OutLineButton>
        </div>
        <div>
          <List
            itemLayout='horizontal'
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                  title={
                    <div className='bodyMedium text-2A2A2A'>
                      {item.title}
                      {/* <ReadMore text={item.title} length={100}></ReadMore> */}
                    </div>
                  }
                  description={<div className='pb-[24px] bodySmall text-5A5A5A mt-[4px] '>{item.desciption}</div>}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </AccountLayout>
  );
}
export default Notifications;
