import {getHostname} from '../../../../../helpers/utils';

export default function VendorBrandInfoDetail({name, text, href}: any) {
  return (
    <div className='grid items-center grid-flow-row grid-cols-12 w-full'>
      <div className='col-span-2'>
        <p className='text-B4B4B4 labelSmall !font-semibold'>{name}</p>
      </div>
      <div className='col-span-9 leading-[18px]'>
        {href ? (
          <a className='underline bodyMedium' href={href ?? ''} target='_blank'>
            {getHostname(href)}
          </a>
        ) : (
          <span className={`bodyMedium !leading-[18px]`}>{text || ''}</span>
        )}
      </div>
    </div>
  );
}
