import React, {useState} from 'react';

interface ChatboxInputProps {
  onSendMessage: (text: string) => void;
  onFileUpload: (files: File[]) => void;
}

const MessageInput: React.FC<ChatboxInputProps> = ({onSendMessage}) => {
  const [messageText, setMessageText] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleSendMessage = () => {
    if (messageText.trim() !== '') {
      onSendMessage(messageText);
      setMessageText('');
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      const filesArray = Array.from(fileList);
      setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...filesArray]);
      e.target.value = ''; // Clear the file input
    }
  };

  const handleRemoveFile = (fileToRemove: File) => {
    setSelectedFiles(prevSelectedFiles => prevSelectedFiles.filter(file => file !== fileToRemove));
  };

  // Handle pressing Enter to create new lines
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setMessageText(prevText => prevText + '\n');
    }
  };

  return (
    <div className='bg-gray-200 p-4'>
      <div className='flex items-center space-x-2'>
        {/* Attach File/Image Button */}
        <input type='file' className='hidden' id='fileInput' multiple onChange={handleFileUpload} />
        <label htmlFor='fileInput' className='cursor-pointer'>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 text-gray-600' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
          </svg>
        </label>

        {/* Text Input */}
        <textarea
          placeholder='Type your message...'
          className='w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:border-blue-500'
          value={messageText}
          onChange={e => setMessageText(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        {/* Send Button */}
        <button
          className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-500'
          onClick={handleSendMessage}
        >
          Send
        </button>
      </div>
      {selectedFiles.length > 0 && (
        <div className='mt-[8px]'>
          <p>Selected Files:</p>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index} className='flex items-center space-x-2'>
                <span>{file.name}</span>
                <button className='text-red-600 hover:text-red-800' onClick={() => handleRemoveFile(file)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MessageInput;
