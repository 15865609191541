import useRestApi from '../hooks/useRestApi';

interface ResetPasswordApi {
  actions: {
    createItem: (values: Record<string, any>) => Promise<any>;
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useResetPasswordApi(): ResetPasswordApi {
  return useRestApi('forgot-password/reset');
}
