import {useEffect, useState} from 'react';
import {Step4Props} from '../../../../../types';
import styles from '../../../../../pages/enterprise/programs/start/EnterpriseProgramStart.module.scss';
import {LoadingOutlined} from '@ant-design/icons';
import type {UploadProps} from 'antd';
import {Col, message, Row, Spin, Upload} from 'antd';
import MAButton from '../../../../ui/button/MAButton';
import {uploadFile, validateFile} from '../../../../../services/apiService';
import {handleStepData, PROGRAM_TYPES_ENUM, removeDataStepFromStorage, STEP_NUMBERS_ENUM} from '../../../../../helpers/utils';
import {useSearchParams} from 'react-router-dom';
import OutLineButton from '../../../../shared/OutLineButton';
import {CONFIG, MESSAGES} from '../../../../../config';
import importUrl from '../../../../../assets/csv/employees_template.csv';
import MAIcon from '../../../../ui/icon/MAIcon';
import {SuccessModal} from '../../../../ui/modals/SuccessModal';

const {Dragger} = Upload;

export default function Step4({initialValues}: Step4Props) {
  const [fileList, setFileList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [dataUpload, setDataUpload] = useState<any>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [totalEmployee, setTotalEmployee] = useState(null);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);

  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const programTypeFromRouter = searchParams.get('program_type');

  const antIcon = <LoadingOutlined style={{fontSize: 24, color: `var(--primary)`}} spin />;

  const renderItem = (file: any, loading: boolean | null, dataUpload: any, error: boolean) => {
    return (
      <div
        onClick={() => {
          if (file?.url) {
            window.open(file.url);
          } else if (dataUpload?.download_url) {
            window.open(dataUpload.download_url);
          }
        }}
        className={`border-2 border-primary rounded-lg p-[12px] mt-[8px] flex flex-row justify-between cursor-pointer items-center`}
      >
        <div>{file.name}</div>
        {loading === false && dataUpload && <MAIcon color='primary' name='checkCircle' size={20} />}
        {file.status === 'done' && file.isLocal && <MAIcon color='primary' name='checkCircle' size={20} />}
        {loading && <Spin indicator={antIcon} />}
        {loading === false && error && <MAIcon color='red' name='warning' size={20} />}
      </div>
    );
  };

  useEffect(() => {
    if (initialValues && initialValues.fileList) {
      setFileList(initialValues.fileList);
    }
  }, [initialValues]);

  const props: UploadProps = {
    name: 'media',
    multiple: false,
    accept: '.csv',
    fileList: fileList,
    itemRender: (_originNode, file) => renderItem(file, loading, dataUpload, error),
    beforeUpload: file => {
      const isLt5M = file.size / 1024 / 1024 < CONFIG.defaultMaxFileSizeMb;
      setError(false);
      if (!isLt5M) {
        message.error(MESSAGES.MSG10);
        return Upload.LIST_IGNORE;
      }

      setFileList([file]);
      return false;
    },
    onChange() {
      setDataUpload(null);
    },
  };

  const handleRemove = () => {
    setUploadedFiles([]);
    setFileList([]);
    const stepKey = programTypeFromRouter === PROGRAM_TYPES_ENUM.TAILOR_MADE ? 'step4Data' : 'step2Data';
    removeDataStepFromStorage(stepKey);
  };

  const uploadToServer = async () => {
    try {
      // Check if a file with the same name, size, and last modified date already exists in the uploadedFiles
      const isDuplicate = uploadedFiles.some(
        existingFile =>
          existingFile.name === fileList[0].name && existingFile.size === fileList[0].size && existingFile.lastModified === fileList[0].lastModified
      );

      if (isDuplicate) {
        message.error('This file has already been uploaded.');
        setLoading(false);
        return;
      }

      setLoading(true);

      console.log('bb upload');

      const resUpload = await uploadFile(fileList[0]);
      const checkFileServer = await validateFile(resUpload.id);

      if (checkFileServer?.error) {
        setError(true);
        setLoading(false);
        message.error(checkFileServer.message);
        return;
      }
      setDataUpload(resUpload);
      console.log('bb checkFileServer', checkFileServer, checkFileServer?.result?.total_records);
      setTotalEmployee(checkFileServer?.result?.total_records || 0);
      setIsOpenSuccess(true);
      handleStepData(programTypeFromRouter === PROGRAM_TYPES_ENUM.TAILOR_MADE ? STEP_NUMBERS_ENUM.STEP4 : STEP_NUMBERS_ENUM.STEP2, {
        fileId: resUpload.id,
        url: resUpload.download_url,
        file_name: resUpload.file_name,
        fileList: [{uid: resUpload.id, name: fileList[0].name, status: 'done', url: resUpload.download_url, isLocal: true}],
      });
      setUploadedFiles([...uploadedFiles, fileList[0]]);
    } catch (error: any) {
      setLoading(false);
      message.error(error.response?.data?.message || MESSAGES.MSG17);
      setError(true);
      setUploadedFiles([]);
    } finally {
      setLoading(false);
    }
  };
  const isFileTypeValid = fileList[0] && fileList[0].type === 'text/csv';
  const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = importUrl;
    link.download = 'employees_template.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className={`${styles.moduleWrapper} w-full flex flex-col mt-[5%] relative bg-red`}>
      <div className='h-[60%]'>
        <Col span={18} offset={3}>
          <div className='mt-[-50px] mb-[30px]  flex justify-end'>
            <OutLineButton onClick={downloadTemplate}>Download Template</OutLineButton>
          </div>

          <Dragger {...props}>
            <div className='flex flex-row justify-center  mb-[15px]'>
              <MAIcon name='upload' size={40} />
            </div>
            <p className='ant-upload-text'>
              Drag and drop or <span className='text-primary underline'>browser</span> your files
            </p>
          </Dragger>
          {fileList.length > 0 && (
            <Row className='mt-[15px]' justify={'end'}>
              <MAButton color='black' type='outline' onClick={handleRemove}>
                Cancel
              </MAButton>
              <MAButton disabled={!isFileTypeValid || !!loading} className='ml-[12px]' onClick={uploadToServer}>
                Import
              </MAButton>
            </Row>
          )}
        </Col>
      </div>
      <SuccessModal
        content={'When your program launches, invitations will be emailed to your employees. '}
        onCancel={() => setIsOpenSuccess(false)}
        isOpen={isOpenSuccess}
        title={`${totalEmployee} employees successfully added!`}
      />
    </div>
  );
}
