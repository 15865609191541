import React, {useContext} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {ColorsContext} from '../../contexts/ColorsContext';

const AppSidebar: React.FC<any> = ({links, isSidebarOpen, toggleSidebar}) => {
  console.log('Render AppSidebar');
  const location = useLocation();
  const {colors} = useContext(ColorsContext);

  return (
    <aside aria-label='Sidebar' className={`${isSidebarOpen ? 'w-[81px]' : 'w-[208px]'}`}>
      <div className='relative overflow-visible p-[20px] rounded'>
        <button className='p-2 absolute right-0 mr-[-20px]' onClick={toggleSidebar}>
          {isSidebarOpen ? (
            <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect x='25' y='25' width='24' height='24' rx='12' transform='rotate(-180 25 25)' fill={`#${colors && colors.secondary}`} />
              <rect x='25' y='25' width='24' height='24' rx='12' transform='rotate(-180 25 25)' stroke='white' />
              <path
                d='M12.0022 18L16.9919 13L12.0022 8'
                stroke={`#${colors && colors?.primary}`}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          ) : (
            <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect x='1' y='1' width='24' height='24' rx='12' fill={`#${colors && colors.secondary}`} />
              <rect x='1' y='1' width='24' height='24' rx='12' stroke='white' />
              <path
                d='M13.9978 8L9.00812 13L13.9978 18'
                stroke={`#${colors && colors?.primary}`}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          )}{' '}
          {/* Change to your desired icons */}
        </button>
        <ul className='space-y-2'>
          {/*@ts-ignore*/}
          {links.map(link => (
            <li key={link.to}>
              <Link
                to={link.to}
                className={`flex items-center p-2 text-base font-normal rounded-lg hover:bg-${colors && colors.secondary} hover:text-${
                  colors && colors?.primary
                } ${location.pathname.indexOf(link.to) >= 0 ? `bg-${colors && colors.secondary} text-${colors && colors?.primary}` : `text-5A5A5A`}`}
              >
                {isSidebarOpen ? (
                  link.icon // Show only the icon in collapsed mode
                ) : (
                  <>
                    {link.icon}
                    <span className='ml-[12px]'>{link.title}</span>
                  </>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default AppSidebar;
