import React from 'react';
import {Button, Divider, Form, Input, message, Select} from 'antd';
import {FcGoogle} from 'react-icons/fc';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {TwoColsLayout} from '../../layouts/shared/TwoColsLayout';
import {parseServerError} from '../../helpers/utils';
import {MESSAGES} from '../../config';

const {Option} = Select;

const Register: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    try {
      await axios.post('https://dev-api.maestro.moha.team/api/auth/local/register', values);
      message.success('Registration successful! Redirecting to login...');
      setTimeout(() => navigate('/auth/login'), 1500);
    } catch (error: any) {
      const errors = parseServerError(error.response.data, 'Register failed');
      errors.forEach(error => message.error(error));
    }
  };

  const handleGoogleSignUp = () => {
    console.log('Sign Up with Google');
    // Implement Google Sign Up logic here
  };

  return (
    <TwoColsLayout>
      <Form form={form} name='register' onFinish={onFinish}>
        <Form.Item name='username' rules={[{required: true, message: MESSAGES.MSG18}]}>
          <Input placeholder='Username' />
        </Form.Item>

        <Form.Item
          name='email'
          normalize={value => value.trim()}
          rules={[
            {required: true, message: MESSAGES.MSG18},
            {type: 'email', message: MESSAGES.MSG11},
          ]}
        >
          <Input placeholder='Email' />
        </Form.Item>

        <Form.Item
          name='password'
          rules={[
            {required: true, message: 'Please input your password!'},
            {min: 6, message: 'Password must be at least 6 characters long'},
          ]}
        >
          <Input.Password placeholder='Password' />
        </Form.Item>

        <Form.Item
          name='confirm'
          dependencies={['password']}
          hasFeedback
          rules={[
            {required: true, message: 'Please confirm your password!'},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder='Confirm Password' />
        </Form.Item>

        <Form.Item name='userRole' rules={[{required: true, message: 'Please select your role!'}]}>
          <Select placeholder='Select a role'>
            <Option value='ADMIN'>ADMIN</Option>
            <Option value='VENDOR'>VENDOR</Option>
            <Option value='EMPLOYEE'>ENTERPRISE EMPLOYEE</Option>
            <Option value='ENTERPRISE'>ENTERPRISE ADMIN</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' block className={'!bg-[#1677ff]'}>
            Sign Up
          </Button>
        </Form.Item>

        <Form.Item>
          <Link to='/auth/login'>Already have an account? Sign In</Link>
        </Form.Item>

        <Divider>Or</Divider>

        <Form.Item>
          <Button icon={<FcGoogle />} onClick={handleGoogleSignUp} block>
            Sign Up with Google
          </Button>
        </Form.Item>
      </Form>
    </TwoColsLayout>
  );
};

export default Register;
