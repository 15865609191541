import React, {useEffect, useState} from 'react';
import Dragger from 'antd/es/upload/Dragger';
import {getAxios} from '../../services/api';
import {message, notification, Tooltip, UploadProps} from 'antd';
import {UploadRequestOption} from 'rc-upload/lib/interface';
import {RcFile} from 'antd/es/upload';
import MAIcon from '../ui/icon/MAIcon';
import MAButton from '../ui/button/MAButton';
import {CONFIG, MESSAGES} from '../../config';

export type FileUploaderProps = {
  value?: any;
  type?: 'image' | 'document';
  className?: string;
  accept?: string;
  uploadedData?: string;
  maxCount?: number;
  multiple?: boolean;
  name?: string;
  /** maxFileSize using mb unit */
  maxFileSize?: number;
  draggerClassName?: string;
  isNotUploadImmediately?: boolean;
  ButtonUpload?: React.FC;
  onFileUploaded?: (data: any) => void;
  onChange?: (data: any) => void;
};
// ==================== Default Image Preview ====================
const extname = (url: string) => {
  const temp = url.split('/');
  const filename = temp[temp.length - 1];
  const filenameWithoutSuffix = filename.split(/#|\?/)[0];
  return (/\.[^./\\]*$/.exec(filenameWithoutSuffix) || [''])[0];
};

function isValidUrl(url: string) {
  try {
    const testUrl = new URL(url);
    return testUrl.protocol === 'http:' || testUrl.protocol === 'https:';
  } catch (_) {
    return false;
  }
}

const isImageFileType = (type: string): boolean => type.indexOf('image/') === 0;

export const isImageToPreview = (file: File | string): boolean => {
  function isBase64Image(base64String: string) {
    // Regular expression to match Base64 image format
    const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^\s]*)$/;

    // Check if the string matches the Base64 image format
    return base64Regex.test(base64String);
  }

  if (file instanceof File) {
    return isImageFileType(file.type);
  }

  return isValidUrl(file) ? isImageFromUrl(file) : isBase64Image(file);
};

export const isImageFromUrl = (url: string): boolean => {
  const extension = extname(url);

  if (/^data:image\//.test(url) || /(webp|svg|png|gif|jpg|jpeg|jfif|bmp|dpg|ico)$/i.test(extension)) {
    return true;
  }

  if (/^data:/.test(url)) {
    // other file types of base64
    return false;
  }

  return !extension;
};

// =================================================================
const FileUploader: React.FC<FileUploaderProps> = props => {
  const {
    maxFileSize = CONFIG.defaultMaxFileSizeMb,
    maxCount = 3,
    onChange,
    multiple = false,
    value,
    ButtonUpload = null,
    isNotUploadImmediately = false,
  } = props;
  const [filesSrc, setFilesSrc] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [uploading, setUploading] = useState<any>({});

  const handleFileUpload = async (file: RcFile, options?: UploadRequestOption<any>) => {
    try {
      console.log('DEBUG file', file);
      const formData = new FormData();
      formData.append('media', file);

      setUploading((prev: any) => ({...prev, [file.uid]: true}));
      const uploadRes = await getAxios().post('/api/upload-media', formData);
      setUploading((prev: any) => ({...prev, [file.uid]: false}));
      // console.log('DEBUG uploadRes.data.result', uploadRes.data.result);
      props?.onFileUploaded && props.onFileUploaded(uploadRes.data.result);
      options?.onSuccess && options?.onSuccess(uploadRes.data);
    } catch (error: any) {
      setUploading((prev: any) => ({...prev, [file.uid]: false}));
      console.error('Upload error:', error);
      options?.onError && options?.onError(error);
    }
  };
  const handleRemove = (fileId: any) => {
    // console.log('HANDLE REMOVE::',{fileId, fileList, filesSrc})
    const newData = fileList.filter(item => item.uid !== fileId);
    setFileList(newData);
    setFilesSrc(prev => prev.filter(item => item.uid !== fileId));
    onChange && onChange(newData);
  };
  const uploadProps: UploadProps = {
    name: props?.name ?? 'file',
    accept: props.accept || `*/*`,
    multiple: !!props?.multiple,
    fileList: fileList,
    maxCount: maxCount,
    customRequest: (options: UploadRequestOption) => {
      if (props.multiple && filesSrc.length == maxCount) return;
      handleFileUpload(options.file as RcFile, options);
    },
    beforeUpload(file: any) {
      const maxSize = maxFileSize * 1024 * 1024; // 10 MB (Change as per your requirement)

      if (file.size > maxSize) {
        message.error(MESSAGES.MSG10);
        return false;
      }

      const reader = new FileReader();
      if (multiple && fileList.length == maxCount) {
        notification.error({
          message: `Cannot upload over ${maxCount} record`,
          placement: 'topRight',
        });
        return false;
      }

      reader.onload = () => {
        setFilesSrc(prev =>
          multiple
            ? [...prev, {uid: file.uid, url: reader.result as string, name: file.name}]
            : [{uid: file.uid, url: reader.result as string, name: file.name}]
        );
      };
      reader.readAsDataURL(file as File);
      return !isNotUploadImmediately;
    },
    onChange(data) {
      let {fileList: newFileList} = data;
      let files: object[] = [];
      if (!multiple) newFileList = newFileList.slice(-1);

      newFileList.forEach(file => {
        const {status, response} = file;
        if (status === 'done' && !!response) {
          files.push(response.result);
        }
      });

      if (!multiple) files = files.slice(-1);

      setFileList(newFileList);

      if (
        newFileList.some(({status}: any) => status === 'uploading') ||
        JSON.stringify(fileList) === JSON.stringify(files) ||
        isNotUploadImmediately
      ) {
        return;
      }
      // done
      if (multiple) {
        onChange && onChange(files);
        return;
      }
      if (files.length > 0) {
        onChange && onChange(files);
        return;
      }
      onChange && onChange(undefined);
    },
    onRemove(file) {
      setFilesSrc(prev => prev.filter(f => f.uid != file.uid));
      setFileList(prev => prev.filter(f => f.uid != file.uid));
    },
  };
  const RenderButtonSubmit = () => {
    if (!isNotUploadImmediately) return <> </>;
    if (ButtonUpload)
      return (
        <div onClick={handleUploads}>
          {' '}
          <ButtonUpload />{' '}
        </div>
      );
    return (
      <MAButton type='text' onClick={handleUploads}>
        {' '}
        {'+ Uploads'}{' '}
      </MAButton>
    );
  };

  function RenderPreviewUploadedFile({fileSrc, fileId, name}: {fileSrc: string; fileId: any; name: string}) {
    if (!fileSrc) {
      return <></>;
    }
    const RenderPreviewer = () => {
      const isLoading = !!uploading?.[fileId];

      if (isImageToPreview(fileSrc)) {
        return (
          <div className='flex relative flex-col w-[160px]'>
            <img
              style={{maxHeight: '200px', width: '100%', borderRadius: '8px', objectFit: 'cover', border: '1px solid #d9d9d9'}}
              src={fileSrc}
            ></img>
            <div className='absolute right-2 top-2 cursor-pointer bg-D9D9D9 rounded-full' onClick={() => handleRemove(fileId)}>
              <MAIcon name='remove' color='white' size={18} />
            </div>
            <span className='truncate text-center mt-[5px]'>{name}</span>
          </div>
        );
      }

      return (
        <div className='flex relative flex-col w-[100px]'>
          <MAIcon name='previewDocument' color='#3b82f6' size={80} />
          <div className={`absolute right-2 ${isLoading ? 'cursor-progress' : 'cursor-pointer'} `} onClick={() => !isLoading && handleRemove(fileId)}>
            <MAIcon name={isLoading ? 'spinner' : 'remove'} color='#ccc' size={18} />
          </div>
          <span className='truncate '>{fileId}</span>
        </div>
      );
    };
    return (
      <Tooltip placement='bottom' title={name}>
        <span>
          <RenderPreviewer />
        </span>
      </Tooltip>
    );
  }

  const handleUploads = async () => {
    let fileNeededUpload = fileList.map(f => f.originFileObj);
    if (!multiple) fileNeededUpload = fileNeededUpload.slice(-1);
    const uploadPromises = fileNeededUpload.map(
      f => new Promise((resolve, rejected) => handleFileUpload(f, {onSuccess: resolve, onError: rejected} as UploadRequestOption))
    );
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
    try {
      const ans: any[] = [];
      // Wait for all uploads to complete
      const results = await Promise.allSettled(uploadPromises);
      for (const result of results) {
        if (result.status == 'rejected') {
          messageApi.success('Upload fail.');
          continue;
        }
        message.success('Upload successful.');

        ans.push((result?.value as any)?.result || {});
      }
      onChange && onChange(ans);
    } catch (e) {
      console.error('Error during file uploads:', e);
    }
  };
  useEffect(() => {
    (() => {
      let valuesDefault = value;
      if (fileList.length || !valuesDefault) return;
      if (!!value && !Array.isArray(value)) {
        valuesDefault = [value];
      }
      const newFileList = valuesDefault.map((file: any) => {
        const {id, download_url: url} = file;

        return {
          ...file,
          url,
          name: file?.file_name,
          uid: id,
        };
      });
      setFileList(newFileList);
      setFilesSrc(newFileList);
    })();
  }, [value]);

  return (
    <div className={props.className || ''}>
      {filesSrc.length > 0 && (
        <div className={'previewArea flex gap-[16px] mb-[8px]'}>
          {filesSrc.map((file: any, index: number) => (
            <RenderPreviewUploadedFile name={file?.name} fileSrc={file?.url} fileId={file?.uid as any} key={index} />
          ))}
          <br />
        </div>
      )}
      <Dragger {...uploadProps} className={props?.draggerClassName || ''} itemRender={_ => null}>
        {!(filesSrc.length > 0) && <div className='ant-upload-text'>Click or drag file here to upload</div>}
        <div className='ant-upload-hint'>{multiple ? 'Support multiple uploads' : 'Support for a single upload'}.</div>
      </Dragger>
      <RenderButtonSubmit />
      {contextHolder}
      {/* test
      <code>{JSON.stringify(uploading)}</code> */}
    </div>
  );
};

export default FileUploader;
