import FileUploader, {FileUploaderProps} from '../FileUploader';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface AntImageUploaderProps extends Omit<FileUploaderProps, 'onChange' | 'value'> {
  onChange?: (file: any) => void;
  value?: any;
}

export function FormFileUploader({onChange, value, ...rest}: AntImageUploaderProps) {
  return (
    <FileUploader
      {...{
        onChange: files => {
          console.log('ON CHANGE rest', rest);
          if (onChange) {
            if (rest.multiple) {
              onChange(files);
            } else {
              onChange(files[0]);
            }
          }
        },
        value: value || '',
        ...rest,
      }}
    />
  );
}
