import React, {Fragment, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {EnterpriseLayout} from '../../../../layouts/enterprise/EnterpriseLayout';
import ProgramVendorDetail from '../../../../components/pages/enterprise/programs/vendors/ProgramVendors';
import {useProgramVendorApi} from '../../../../repositories/useProgramVendorApi';
import {useVendorApi} from '../../../../repositories/useVendorApi';
import {useActivityHistoryApi} from '../../../../repositories/useActivityHistoryApi';
import {Breadcrumb, message} from 'antd';
import {useProgramApi} from '../../../../repositories/useProgramApi';
import {useContractApi} from '../../../../repositories/useContractApi';
import {CONTRACT_STATUSES} from '../../../../config';
import MAIcon from '../../../../components/ui/icon/MAIcon';
import {useProgramProductApi} from '../../../../repositories/useProgramProductApi';

export default function ProgramVendorsPage() {
  const params = useParams();
  const {vendorId, id} = params;
  console.log('DEBUG vendorId, id: ', {vendorId, id});
  const {actions: actionsProgram, data: dataProgram, state: stateProgram} = useProgramApi();
  const {actions: actionsProgramVendor, data: dataProgramVendor, state: stateProgramVendor} = useProgramVendorApi(params?.id);
  const {actions: actionsVendor, data: dataVendor, state: stateVendor} = useVendorApi();
  const {actions: actionsContract, data: dataContract, state: stateContract} = useContractApi();
  const {actions: actionsActivityHistory, data: dataActivityHistory, state: stateActivityHistory} = useActivityHistoryApi();
  const {actions: actionsProgramProduct, data: dataProgramProduct, state: stateProgramProduct} = useProgramProductApi(params?.id);

  useEffect(() => {
    (async () => {
      await Promise.all([
        actionsProgram.getItem(params?.id),
        actionsProgramVendor.getItems(),
        actionsVendor.getItem(params?.vendorId),
        actionsActivityHistory.getItems({
          program_id: params?.id,
          vendor_ids: [params?.vendorId, null],
          order_by: '-auto_increment',
        }),
        actionsProgramProduct.getItems(),
      ]);
    })();
  }, [params.id, params.vendporId]);

  return (
    <EnterpriseLayout>
      {dataProgramVendor?.listItems && dataVendor?.detail && dataActivityHistory?.listItems && (
        <Fragment>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={`/enterprise/programs/${params.id}/overview`}>
                    <div className={'flex items-center justify-center gap-[5px]'}>
                      <MAIcon size={20} name={'previous'} />
                      <div>Programme</div>
                    </div>
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={`/enterprise/programs/${params.id}/vendors`}>
                    <div className={'flex items-center justify-center gap-[5px]'}>
                      <div>Vendors</div>
                    </div>
                  </Link>
                ),
              },
              {
                title: <strong className={'text-2A2A2A font-semibold'}>{dataVendor?.detail?.name}</strong>,
              },
            ]}
          />

          <ProgramVendorDetail
            products={dataProgramProduct?.listItems?.filter(product => product.vendor_id === params?.vendorId)}
            programVendors={dataProgramVendor?.listItems}
            vendor={dataVendor?.detail}
            activities={dataActivityHistory?.listItems}
            onSignContract={async contractId => {
              try {
                await actionsContract.customPost(`${contractId}/sign`, {
                  status: CONTRACT_STATUSES.CONTRACT_SIGNED.value,
                });
                await actionsActivityHistory.getItems({
                  program_id: params?.id,
                  vendor_ids: [params?.vendorId, null],
                });
              } catch (e) {
                console.log('Debug e: ', e);
                message.error('Failed to sign contractId');
              }
            }}
            onDenyContract={async contractId => {
              try {
                await actionsContract.customPost(`${contractId}/sign`, {
                  status: CONTRACT_STATUSES.REJECTED_BY_ENTERPRISE.value,
                });
                message.success('Contract denied');
                await actionsActivityHistory.getItems({
                  program_id: params?.id,
                  vendor_ids: [params?.vendorId, null],
                });
              } catch (e) {
                message.error('Failed to deny contract');
              }
            }}
          />
        </Fragment>
      )}
    </EnterpriseLayout>
  );
}
