import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import EnterpriseProductsDetail from '../../../components/pages/enterprise/products/detail/EnterpriseProductsDetail';
import {EnterpriseLayout} from '../../../layouts/enterprise/EnterpriseLayout';
import {useProductApi} from '../../../repositories/useProductApi';
import {useProductReviewApi} from '../../../repositories/useProductReviewApi';

function EnterpriseProductDetailPage() {
  const params = useParams();
  const {actions: actionsProduct, data: dataProduct, state: stateProduct} = useProductApi();
  const {actions: actionsProductReview, data: dataProductReview, state: stateProductReview} = useProductReviewApi();

  useEffect(() => {
    (async () => {
      await Promise.all([actionsProduct.getItem(params.id), actionsProductReview.getItems({vendor_id: params.id})]);
    })();
  }, [params]);

  return (
    <EnterpriseLayout bgColor={'white'}>
      {dataProduct?.detail && dataProductReview?.listItems && (
        <EnterpriseProductsDetail product={dataProduct?.detail} reviews={dataProductReview?.listItems} />
      )}
    </EnterpriseLayout>
  );
}

export default EnterpriseProductDetailPage;
