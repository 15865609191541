import React, {Fragment, useEffect, useState} from 'react';
import {AccountLayout} from '../../layouts/account/AccountLayout';
import {Breadcrumb, Button, Form, Input, Select, Spin, message} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom';
import MAIcon from '../../components/ui/icon/MAIcon';
import {MESSAGES} from '../../config';
import MAButton from '../../components/ui/button/MAButton';
import {fetchLocations} from '../../services/apiService';
import {useProfileApi} from '../../repositories/useProfileApi';
import {Profile} from '../../types';
import {Regex, securityEmail, securityPhone} from '../../helpers/utils';
import styles from '../../layouts/account/AccountLayout.module.scss';

export default function PersonalInfo() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {actions: actionProfile, state: stateProfile} = useProfileApi();

  const {role} = useParams();
  const [editItem, setEditItem] = useState<any>({
    isEditName: false,
    isEditPhone: false,
    isEditAddress: false,
  });
  const [regionLocation, setRegionLocation] = useState([]);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const locations = await fetchLocations();
        const profileData = await actionProfile.customGet('', {});
        setRegionLocation(locations);
        setProfile(profileData?.data?.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAPI();
  }, []);

  const renderProfileItem = (title: string | null, key: string, subTitle: string, isEdit: boolean, handleEdit?: any) => {
    return (
      <div className='mt-[25px] flex flex-row justify-between border-b border-b-E2E2E2'>
        <div>
          <p className='labelMedium mb-[10px]'>{title || null}</p>
          <p className='bodyMedium mb-[25px] text-5A5A5A'>{subTitle || null}</p>
        </div>
        {isEdit && (
          <span onClick={handleEdit} className='underline font-medium cursor-pointer'>
            {editItem[`isEdit${key}`] ? 'Cancel' : 'Edit'}
          </span>
        )}
      </div>
    );
  };

  const renderEditName = () => {
    return (
      <div>
        <div className='mt-[25px] flex flex-row justify-between'>
          <div className='w-[100%]'>
            <p className='labelMedium mb-[10px]'>Legal Name</p>
          </div>
          <span onClick={() => handleCancel('isEditName')} className='underline font-medium cursor-pointer'>
            Cancel
          </span>
        </div>
        <div className='mt-[25px] flex flex-row justify-between border-b border-b-E2E2E2'>
          <div className='w-[100%] px-[2px]'>
            <div className='flex flex-row justify-between '>
              <Form.Item
                className='w-[49%]'
                name='firstName'
                rules={[
                  {required: true, message: MESSAGES.MSG18},
                  {whitespace: true, message: MESSAGES.MSG18},
                ]}
              >
                <Input placeholder='First Name' size='large' maxLength={50} className=' grayInput text-[12px]' />
              </Form.Item>
              <Form.Item
                className='w-[49%]'
                name='lastName'
                rules={[
                  {required: true, message: MESSAGES.MSG18},
                  {whitespace: true, message: MESSAGES.MSG18},
                ]}
              >
                <Input placeholder='Last Name' size='large' maxLength={50} className=' grayInput text-[12px]' />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderEditPhone = () => {
    return (
      <div>
        <div className='mt-[25px] flex flex-row justify-between '>
          <div className='w-[100%]'>
            <p className='labelMedium mb-[10px]'>Phone</p>
          </div>
          <span onClick={() => handleCancel('isEditPhone')} className='underline font-medium cursor-pointer'>
            Cancel
          </span>
        </div>
        <div className='mt-[25px] flex flex-row justify-between border-b border-b-E2E2E2'>
          <div className='w-[100%] px-[2px]'>
            <Form.Item
              name='phone'
              rules={[
                {required: true, message: MESSAGES.MSG18},
                {pattern: Regex.phoneNumberWithSpace, message: MESSAGES.MSG20},
                {whitespace: true, message: MESSAGES.MSG18},
              ]}
            >
              <Input maxLength={20} placeholder='Phone Number' size='large' className='w-[100%] grayInput text-[12px]' />
            </Form.Item>
          </div>
        </div>
      </div>
    );
  };

  const formatAddress = (detail: any) => {
    let address = '';
    if (detail?.building) address += detail?.building + ', ';
    if (detail?.address) address += detail?.address + ', ';
    if (detail?.country) {
      const country: any = regionLocation.find((item: any) => item.value === detail.country);
      address += country?.label;
    }
    if (address) {
      return address;
    }
    return 'N/A';
  };

  const handleEdit = (key: string) => {
    setEditItem({...editItem, [key]: !editItem[key]});
    setIsEditing(true); // Set isEditing to true when an item is being edited
  };

  const handleCancel = (key: string) => {
    // reset data of form
    if (key == 'isEditName') {
      form.resetFields(['firstName', 'lastName']);
    }
    if (key === 'isEditPhone') {
      form.resetFields(['phone']);
    }
    if (key === 'isEditAddress') {
      form.resetFields(['country', 'address', 'building', 'city', 'state', 'postalCode']);
    }

    setEditItem((prevState: any) => {
      const editData = {...prevState, [key]: false};
      if (!editData.isEditName && !editData.isEditPhone && !editData.isEditAddress) {
        setIsEditing(false);
      }
      return editData;
    });
  };

  const renderEditAddress = () => {
    return (
      <div>
        <div className='mt-[25px] flex flex-row justify-between'>
          <div className='w-[100%]'>
            <p className='labelMedium mb-[10px]'>Business address</p>
          </div>
          <span onClick={() => handleCancel('isEditAddress')} className='underline font-medium cursor-pointer'>
            Cancel
          </span>
        </div>
        <div className='mt-[25px] flex flex-row justify-between border-b border-b-E2E2E2'>
          <div className='w-[100%] px-[2px]'>
            <Form.Item className='w-[49%]' name='country' rules={[{required: true, message: MESSAGES.MSG18}]}>
              <Select options={regionLocation} size='large' placeholder='Country'></Select>
            </Form.Item>
            <Form.Item
              name='address'
              rules={[
                {required: true, message: MESSAGES.MSG18},
                {whitespace: true, message: MESSAGES.MSG18},
              ]}
            >
              <Input maxLength={50} placeholder='Address' size='large' className='w-[100%] grayInput text-[12px]' />
            </Form.Item>
            <Form.Item name='building'>
              <Input maxLength={50} placeholder='Building' size='large' className='w-[100%] grayInput text-[12px]' />
            </Form.Item>
            <div className='flex flex-row justify-between'>
              <Form.Item
                className='w-[49%]'
                name='city'
                rules={[
                  {required: true, message: MESSAGES.MSG18},
                  {whitespace: true, message: MESSAGES.MSG18},
                ]}
              >
                <Input maxLength={20} placeholder='City' size='large' className='w-[100%] grayInput text-[12px]' />
              </Form.Item>
              <Form.Item
                className='w-[49%]'
                name='state'
                rules={[
                  {required: true, message: MESSAGES.MSG18},
                  {whitespace: true, message: MESSAGES.MSG18},
                ]}
              >
                <Input maxLength={20} placeholder='State / Province / County / Region' size='large' className='w-[100%] grayInput text-[12px] ' />
              </Form.Item>
            </div>
            <Form.Item
              name='postalCode'
              normalize={value => value.replace(/\D/g, '')}
              rules={[
                {required: true, message: MESSAGES.MSG18},
                {whitespace: true, message: MESSAGES.MSG18},
              ]}
            >
              <Input maxLength={6} placeholder='Postal Code' size='large' className='w-[49%] grayInput text-[12px]' />
            </Form.Item>
          </div>
        </div>
      </div>
    );
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      console.log('bb values', values);
      const valudeUpdate: any = {};
      if (editItem.isEditName) {
        valudeUpdate['first_name'] = values.firstName;
        valudeUpdate['last_name'] = values.lastName;
      }
      if (editItem.isEditPhone) {
        valudeUpdate['contact_number'] = values.phone;
      }
      if (editItem.isEditAddress) {
        valudeUpdate['country'] = values.country;
        valudeUpdate['address'] = values.address;
        valudeUpdate['building'] = values.building || '';
        valudeUpdate['city'] = values.city;
        valudeUpdate['state'] = values.state;
        valudeUpdate['postal_code'] = values.postalCode;
      }
      await actionProfile.customPost('', valudeUpdate);
      const profileData = await actionProfile.customGet('', {});

      setProfile(profileData?.data?.result);
      setEditItem({
        isEditName: false,
        isEditPhone: false,
        isEditAddress: false,
      });
      setIsEditing(false);
      form.resetFields(['firstName', 'lastName', 'phone', 'country', 'address', 'building', 'city', 'state', 'postalCode']);
      message.success('Profile updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const renderName = () => {
    if (!profile?.detail?.first_name && !profile?.detail?.last_name) {
      return '';
    }
    return profile?.detail?.first_name + ' ' + profile?.detail?.last_name;
  };

  return (
    <AccountLayout>
      <Fragment>
        <div className={`${styles.moduleWrapper} w-[504px] ml-[231px]`}>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={`/${role}/account/overview`}>
                    <div className={'flex items-center justify-center gap-[5px]'}>
                      <MAIcon size={20} name={'previous'} />
                      <div>Account</div>
                    </div>
                  </Link>
                ),
              },
              {
                title: <strong className={'text-2A2A2A font-medium'}>Personal Info</strong>,
              },
            ]}
          />
          <p className='displaySmall mb-[80px] '>Personal Info</p>
          <Spin spinning={stateProfile.isLoading}>
            <Form form={form} initialValues={{}}>
              {editItem.isEditName ? renderEditName() : renderProfileItem('Legal Name', 'Name', renderName(), true, () => handleEdit('isEditName'))}
              {renderProfileItem('Business Name', 'Business', profile?.company_name || '', false)}
              {renderProfileItem('Email address', 'email', securityEmail(profile?.email || ''), false)}
              {editItem.isEditPhone
                ? renderEditPhone()
                : renderProfileItem('Phone', 'Phone', securityPhone(profile?.detail?.contact_number || ''), true, () => handleEdit('isEditPhone'))}
              {editItem.isEditAddress
                ? renderEditAddress()
                : renderProfileItem('Business address', 'Address', formatAddress(profile?.detail), true, () => handleEdit('isEditAddress'))}
              {isEditing && (
                <MAButton className='mt-[25px] px-[40px]' onClick={handleSave}>
                  Save
                </MAButton>
              )}
            </Form>
          </Spin>
        </div>
      </Fragment>
    </AccountLayout>
  );
}
