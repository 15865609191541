import useRestApi from '../hooks/useRestApi';

interface CategoryApi {
  actions: {
    getItems: (params?: object) => Promise<any>;
  };
  data: {
    detail: any;
    listItems: any[];
    listPagination: {
      total: number;
    };
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useCategoryApi(): CategoryApi {
  return useRestApi('categories');
}
