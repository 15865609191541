import {Button} from 'antd';
import React from 'react';

interface OutLineButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const OutLineButton: React.FC<OutLineButtonProps> = ({onClick, children, icon, className}) => (
  <Button icon={icon} className={`px-[20px] text-primary rounded-[4px] border-primary ${className}`} type='default' onClick={onClick}>
    {children}
  </Button>
);

export default OutLineButton;
