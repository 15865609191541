import React, {useContext, useEffect, useMemo} from 'react';

import {useParams} from 'react-router-dom';
import AppHeader from '../shared/AppHeader';
import {ColorsContext} from '../../contexts/ColorsContext';
import styles from './AccountLayout.module.scss';

export function AccountLayout(props: any) {
  const {role} = useParams();
  const {setColors} = useContext(ColorsContext);

  useEffect(() => {
    const target = role || '';
    switch (target) {
      case 'admin':
        setColors({
          primary: '008080',
          secondary: 'E6F7F6',
        });
        break;
      case 'enterprise':
        setColors({
          primary: '008080',
          secondary: 'E6F7F6',
        });
        break;
      case 'employee':
        setColors({
          primary: 'E4A45C',
          secondary: 'FFDFBA',
        });
        break;
      case 'vendor':
        setColors({
          primary: '7E38B7',
          secondary: 'F6EAFF',
        });
        break;
      default:
        setColors({
          primary: '008080',
          secondary: 'E6F7F6',
        });
    }
  }, [setColors]);

  const appHeaderMemo = useMemo(() => {
    return <AppHeader target={role || ''} />;
  }, [role]);

  return (
    <div className={styles.moduleWrapper}>
      <div className='header'>{appHeaderMemo}</div>
      <div className={`justify-center mt-[44px] bg-white w-full overflow-auto mb-[180px] ${props.className}`}>{props.children}</div>
    </div>
  );
}
