import React, {useState} from 'react';
import VendorCarousel from './VendorCarousel';
import VendorRightMainInfo from './VendorRightMainInfo';
import VendorHighlight from './VendorHighlights';
import VendorReview from './VendorReview';

export default function VendorDetail({vendor, reviews, amenities}: any) {
  const [products, setProducts] = useState([
    {
      id: 1,
      name: 'Bala Bangles',
      price: 500,
      photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
    },
    {
      id: 2,
      name: 'The Power Ring',
      price: 500,
      photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
    },
    {
      id: 3,
      name: 'Bala Beam',
      price: 500,
      photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
    },
    {
      id: 4,
      name: 'Bala Beam',
      price: 500,
      photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
    },
  ]);
  const [isSeeAllProducts, setIsSeeAllProducts] = useState(false);

  const handleSeeMoreProduct = async () => {
    setIsSeeAllProducts(!isSeeAllProducts);
    const newProductsData = [
      {
        id: 1,
        name: 'Bala Bangles',
        price: 500,
        photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
      },
      {
        id: 2,
        name: 'The Power Ring',
        price: 500,
        photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
      },
      {
        id: 3,
        name: 'Bala Beam',
        price: 500,
        photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
      },
      {
        id: 4,
        name: 'Bala Beam',
        price: 500,
        photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
      },
      {
        id: 6,
        name: 'Bala Beam',
        price: 500,
        photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
      },
      {
        id: 5,
        name: 'Bala Beam',
        price: 500,
        photo: {id: 3, image: 'https://picsum.photos/id/239/200/300', name: 'img2'},
      },
    ];
    setProducts(newProductsData);
  };
  const handleNavigateProduct = (product: unknown) => {
    // go some where
  };
  const customShowPrice = (price: number) => `$${price} KHD`;

  return (
    <div className='flex flex-col gap-y-[40px]'>
      <div className='heroBanner h-[500px] grid grid-cols-2 gap-[34px] border-b border-D4DDDC'>
        <VendorCarousel images={vendor?.gallery} />
        <div className='flex-1 flex gap-[20px] flex-col'>
          <VendorRightMainInfo vendor={vendor} />
        </div>
      </div>
      {/* Hightlight */}
      <div className='border-b border-D4DDDC'>
        <p className='labelExtraLarge !font-semibold'>Highlights</p>
        <div className='mt-[24px] mb-[40px]'>
          <VendorHighlight amenities={amenities} />
        </div>
      </div>
      {/* Products */}
      <div className='border-b border-D4DDDC'>
        <div className='flex justify-between'>
          <p className='labelExtraLarge !font-semibold'>Most valued products (Mock data)</p>
          <span className='underline labelLarge cursor-pointer' onClick={handleSeeMoreProduct}>
            {isSeeAllProducts ? 'See less' : 'See all'}
          </span>
        </div>
        <div className='flex flex-wrap gap-[34px] mt-[24px] mb-[40px]'>
          {products?.map((product, idx) => {
            if (!isSeeAllProducts && idx >= 4) return null;
            return (
              <div className='gap-y-[24px] flex flex-col' key={product.id}>
                <div className='h-[282px] rounded w-[282px]'>
                  <img
                    src={product.photo.image}
                    alt={product.photo.name}
                    onClick={() => handleNavigateProduct(product)}
                    className='h-full rounded w-full object-cover cursor-pointer'
                  />
                </div>
                <div className='flex flex-col gap-y-[8px]'>
                  <span className='underline !leading-[20px] bodyLarge cursor-pointer' onClick={() => handleNavigateProduct(product)}>
                    {product.name}
                  </span>
                  <span className='bodyLarge !leading-[20px]'>{customShowPrice(product.price)}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Client reviews */}
      <div>
        <p className='labelExtraLarge !font-semibold'>Reviews</p>
        <div className='mt-[24px]'>
          <div className='grid gap-x-[34px] gap-y-[40px] grid-flow-row grid-cols-12 justify-items-stretch'>
            {reviews?.map((review: any, index: number) => {
              return (
                <div className='col-span-6' key={index}>
                  <VendorReview review={review} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
