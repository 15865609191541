import {convertMappingToArray, formatTimelineDate, getLabelByValue} from '../../helpers/utils';
import {ACTIVITY_HISTORY_STATUSES, CAN_VIEW_CONTRACT_ACTIVITY_TYPES} from '../../config';
import MAButton from '../ui/button/MAButton';
import MAIcon from '../ui/icon/MAIcon';

interface TimeLineItemProps {
  activity: any;
  handleClickTimeline: (timeline: any) => void;
}

export const VendorTimeLineItem = ({activity, handleClickTimeline}: TimeLineItemProps) => {
  return (
    <div className='flex flex-col gap-y-[8px] mb-[20px]'>
      <div className='flex flex-col'>
        <span className='bodyLarge'>{getLabelByValue(convertMappingToArray(ACTIVITY_HISTORY_STATUSES), activity?.type)}</span>
        <span className='text-9D9D9D bodySmall line-clamp-1'>{formatTimelineDate(activity.created_at)}</span>
      </div>
      {activity.contract_id && CAN_VIEW_CONTRACT_ACTIVITY_TYPES.includes(activity.type) && (
        <MAButton size='medium' customTextColor='5A5A5A' customBgColor='white' onClick={() => handleClickTimeline(activity)}>
          <div className='flex items-center justify-between gap-x-[24px]'>
            <span className='text-white'>View e-contract</span>
            <MAIcon name='file' color='white' size={16} />
          </div>
        </MAButton>
      )}
    </div>
  );
};
