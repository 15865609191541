import useRestApi from '../hooks/useRestApi';

interface EnterpriseRegisterApi {
  actions: {
    createItem: (values: Record<string, any>) => Promise<any>;
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useEnterpriseRegisterApi(): EnterpriseRegisterApi {
  return useRestApi('register-enterprise');
}
