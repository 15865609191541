import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Table} from 'antd';
import MAIcon from '../../../../ui/icon/MAIcon';
import MAButton from '../../../../ui/button/MAButton';
import {formatCurrencyUSD, PROGRAM_STATUSES_ENUM} from '../../../../../helpers/utils';
import {DeletionModal} from '../../../../ui/modals/DeletionModal';

interface EnterpriseProgramsActivitiesProps {
  products: any[];
  allProducts: any[];
  program: any;
  onDeleteActivities: (ids: any[]) => void;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  isLoading?: boolean;
}

export default function EnterpriseProgramsProducts({
  isLoading,
  products,
  allProducts,
  program,
  onDeleteActivities,
  handleTableChange,
}: EnterpriseProgramsActivitiesProps) {
  const navigate = useNavigate();
  const {id, tab} = useParams();
  const [isOpenModalConfirmDeleteOne, setIsOpenModalConfirmDeleteOne] = useState(false);
  const [isOpenModalConfirmDeleteMany, setIsOpenModalConfirmDeleteMany] = useState(false);
  const [clickedRowId, setClickedRowId] = useState<any>(null);
  const [totalVendor, setTotalVendor] = useState<any>(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const isCanEdit = useMemo(() => {
    return ![PROGRAM_STATUSES_ENUM.LIVE, PROGRAM_STATUSES_ENUM.COMPLETED, PROGRAM_STATUSES_ENUM.PAUSE].includes(program.status);
  }, [program]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelectedRows = selectedRowKeys.length > 0;

  useEffect(() => {
    if (!allProducts) {
      return;
    }

    const vendorMapping: any = {};
    allProducts.forEach(product => {
      if (product.vendor) {
        vendorMapping[product.vendor_id] = product.vendor_id;
      }
    });

    setTotalVendor(Object.keys(vendorMapping).length);
  }, [allProducts]);

  // Define the columns for your table
  const columns: any = [
    {
      title: 'ACTIVITY NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => <Link to={`/enterprise/products/${record.id}`}>{text}</Link>,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor',
      key: 'vendor',
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text: any, record: any) => <Link to={`/enterprise/programs/${program?.id}/vendors/${record.id}`}>{record?.vendor?.name}</Link>,
    },
    {
      title: 'COST PER PERSON',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text: any, record: any) => formatCurrencyUSD(record.price),
    },
    {
      title: '',
      key: 'actions',
      render: (text: any, record: any) => (
        <div className='flex justify-end gap-[10px]'>
          {isCanEdit && (
            <div
              className={'cursor-pointer'}
              onClick={() => {
                setClickedRowId(record.id);
                setIsOpenModalConfirmDeleteOne(true);
              }}
            >
              <MAIcon size={24} name={'delete'} />
            </div>
          )}

          <Link to={`/enterprise/products/${record.id}`}>
            <MAIcon size={24} name={'eye'} />
          </Link>
        </div>
      ),
    },
  ];
  return (
    <div className={'flex flex-col gap-[1rem]'}>
      {/*Delete one program*/}
      <DeletionModal
        title={'Delete Activity'}
        content={'Are you sure want to delete this activity?'}
        isOpen={isOpenModalConfirmDeleteOne}
        onCancel={() => {
          setIsOpenModalConfirmDeleteOne(false);
        }}
        onOk={() => {
          setIsOpenModalConfirmDeleteOne(false);
          onDeleteActivities([clickedRowId]);
        }}
      />

      {/*Delete many*/}
      <DeletionModal
        title={'Delete Activities'}
        content={'Are you sure want to delete selected activities?'}
        isOpen={isOpenModalConfirmDeleteMany}
        onCancel={() => {
          setIsOpenModalConfirmDeleteMany(false);
        }}
        onOk={async () => {
          setIsOpenModalConfirmDeleteMany(false);
          onDeleteActivities(selectedRowKeys);
        }}
      />

      <div className='searchBoxAndCtas flex items-center justify-between'>
        <div className='left titleLarge'>Activities</div>
        <div className='right flex gap-[1rem]'>
          {isCanEdit && (
            <Fragment>
              <MAButton
                disabled={!hasSelectedRows}
                size={'small'}
                type={'text'}
                color={'2A2A2A'}
                onClick={async () => {
                  setIsOpenModalConfirmDeleteMany(true);
                }}
              >
                <div className='flex gap-[7px] items-center justify-between'>
                  <div className={'mt-[-2px]'}>
                    <MAIcon color={'2A2A2A'} size={14} name={'delete'} />
                  </div>
                  <div className={'text-2A2A2A'}>Delete</div>
                </div>
              </MAButton>
              <MAButton
                size={'small'}
                onClick={() => {
                  navigate(`/enterprise/programs/${id}/products/edit?program_type=tailor_made&step=2&status=${program.status}`);
                }}
              >
                Add activities
              </MAButton>
            </Fragment>
          )}
        </div>
      </div>

      <div className={'bg-E6F7F6 py-[20px] px-[24px] rounded-[4px] flex items-center justify-start items-start'}>
        <div className='first w-[250px]'>
          <div className={'text-primary labelSmall'}>Activities</div>
          <div className={'displaySmall'}>{allProducts?.length}</div>
        </div>
        <div className='second w-[250px]'>
          <div className={'text-primary labelSmall'}>Vendors</div>
          <div className={'displaySmall'}>{totalVendor}</div>
        </div>
        <div className='third flex flex-col w-[300px] flex-1'>
          <div className={'flex items-center flex-row justify-end'}>
            <div className='left w-[250px] labelSmall text-primary text-right'>Budget</div>
            <div className='right w-[150px] text-right bodyMedium'>0,000.00 HKD</div>
          </div>
          <div className={'flex items-center flex-row justify-end'}>
            <div className='left w-[250px] labelSmall text-primary text-right'>Total Cost</div>
            <div className='right w-[150px] text-right bodyMedium'>0,000.00 HKD</div>
          </div>
          <div className={'flex items-center flex-row justify-end'}>
            <div className='left w-[250px] labelSmall text-primary text-right'>Avg Cost Per Person</div>
            <div className='right w-[150px] text-right bodyMedium'>0,000.00 HKD</div>
          </div>
        </div>
      </div>

      <div className='tablePrograms'>
        <Table
          locale={{
            emptyText: <div className='flex justify-center flex-col items-center gap-[12px] py-[50px]'>{'No data found'}</div>,
          }}
          loading={isLoading}
          onChange={handleTableChange}
          rowSelection={isCanEdit ? rowSelection : undefined}
          pagination={{
            defaultPageSize: 10, // Set the initial page size
            showSizeChanger: true, // Show page size changer
            pageSizeOptions: ['10', '50', '100', '500', '1000'], // Define available page sizes
          }}
          dataSource={products.map(product => ({...product, key: product.id}))}
          columns={columns}
        />
      </div>
    </div>
  );
}
