import {CONTRACT_STATUSES} from '../../config';
import {convertMappingToArray} from '../../helpers/utils';

interface IVendorContractStatus {
  statusId: number;
}

const EContractStatus = ({statusId}: IVendorContractStatus) => {
  const target = convertMappingToArray(CONTRACT_STATUSES).find(item => item.value === statusId);
  if (!target) return null;

  return <div className={`text-center px-[10px] py-[2px] rounded-[10px] labelSmall bg-${target?.color}`}>{target?.label}</div>;
};

export default EContractStatus;
