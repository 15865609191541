import MAIcon from '../../../../ui/icon/MAIcon';

export default function VendorHigtlight({amenities}: any) {
  const RenderIconHighlight = ({hightlightType}: any) => {
    return <MAIcon name='calendar' size={28} />;
  };

  return (
    <div className='flex gap-x-[36px] gap-y-[24px] flex-wrap'>
      {amenities?.map((amenity: any, index: number) => {
        return (
          <div key={`${index}`} className='w-[280px]'>
            <div className='flex items-start gap-x-[12px] mb-[4px]'>
              <div className={'w-[28px]'}>
                {amenity?.photo ? <img src={amenity?.photo?.download_url} alt={amenity?.name} /> : <RenderIconHighlight />}
              </div>
              <div className='flex flex-col justify-between h-full'>
                <span className='bodyLarge text-2A2A2A line-clamp-1'>{amenity?.name}</span>
                <span className='text-9D9D9D bodyMedium line-clamp-1'>{amenity?.description}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
