import React, {useEffect, useRef, useState} from 'react';
import {mockThreads, Thread} from './mockData';

const ChatSidebar: React.FC<{setSelectedThread: (thread: Thread | null) => void}> = ({setSelectedThread}) => {
  const [filterOption, setFilterOption] = useState('all');
  const [activeThreadId, setActiveThreadId] = useState<number | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleThreadSelect = (selectedThread: Thread) => {
    setSelectedThread(selectedThread);
    setActiveThreadId(selectedThread.id);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFilterChange = (option: string) => {
    setFilterOption(option);
    setIsDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const filterThreads = (option: string) => {
    switch (option) {
      case 'all':
        return mockThreads;
      case 'read':
        return mockThreads.filter(thread => thread.read);
      case 'unread':
        return mockThreads.filter(thread => !thread.read);
      default:
        return mockThreads;
    }
  };

  const filteredThreads = filterThreads(filterOption);

  return (
    <div className='bg-gray-200 w-1/4 p-4'>
      <div className='flex justify-between items-center mb-[16px]'>
        <h2 className='text-2xl'>Messages</h2>
        <div className='relative inline-block text-left' ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className='bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 flex items-center'
          >
            <span className='text-sm mr-[4px]'>
              {filterOption === 'all' ? 'All Threads' : filterOption === 'read' ? 'Read Messages' : 'Unread Messages'}
            </span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              className={`ml-[4px] ${isDropdownOpen ? 'transform rotate-180' : ''}`}
            >
              <path
                d='M15.1719 5.01074L8.33787 11.8441C8.29353 11.8885 8.24086 11.9237 8.18288 11.9478C8.12491 11.9718 8.06276 11.9842 8 11.9842C7.93724 11.9842 7.87509 11.9718 7.81712 11.9478C7.75914 11.9237 7.70647 11.8885 7.66213 11.8441L0.828125 5.01074'
                stroke='#2A2A2A'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
          {isDropdownOpen && (
            <div className='absolute bg-white right-0 mt-[8px] w-48 border border-gray-300 rounded shadow-lg'>
              <ul className='py-2'>
                <li
                  onClick={() => handleFilterChange('all')}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${filterOption === 'all' ? 'bg-blue-100' : ''}`}
                >
                  All Threads
                </li>
                <li
                  onClick={() => handleFilterChange('read')}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${filterOption === 'read' ? 'bg-blue-100' : ''}`}
                >
                  Read Messages
                </li>
                <li
                  onClick={() => handleFilterChange('unread')}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${filterOption === 'unread' ? 'bg-blue-100' : ''}`}
                >
                  Unread Messages
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <ul
        style={{
          overflow: 'auto',
          height: 'calc(100% - 48px)',
        }}
      >
        {filteredThreads.map(thread => (
          <li
            key={thread.id}
            onClick={() => handleThreadSelect(thread)}
            className={`cursor-pointer p-2 rounded ${activeThreadId === thread.id ? 'bg-blue-100' : 'hover:bg-gray-300'}`}
          >
            <div className='flex items-center'>
              <div className='w-10 h-10 bg-gray-400 rounded-full mr-[8px]'>{/* Replace with the actual avatar image */}</div>
              <div>
                <p className='text-sm font-medium'>{thread.senderName}</p>
                <p className='text-xs text-gray-500'>
                  {thread.latestMessageTime} {/* Replace with actual time */}
                </p>
                <p className='text-xs'>
                  {truncate(thread.latestMessage, 30)} {/* Replace with actual message */}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

function truncate(text: string, maxLength: number) {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

export default ChatSidebar;
