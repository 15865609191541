import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Form, Input, MenuProps, Space, Table, Tag} from 'antd';
import MAIcon from '../../../ui/icon/MAIcon';
import MAButton from '../../../ui/button/MAButton';
import {DeletionModal} from '../../../ui/modals/DeletionModal';
import {CONFIG, DELETABLE_STATUS, EMPLOYEE_STATUSES} from '../../../../config';
import {convertMappingToArray} from '../../../../helpers/utils';
import {ImportModal} from './detail/ImportModal';
import EmployeeEditModal from './detail/EmployeeEditModal';
import {BulkImportModal} from './detail/BulkImportModal';

interface EnterpriseProgramsVendorsProps {
  employees: any[];
  program?: any;
  totalEmployees?: number;
  onDeleteEmployees: (ids: any[]) => void;
  onCreateIndividualEmployee: (values: any) => void;
  onCreateBulkEmployee: (values: any) => void;
  onDeactivateEmployees: (ids: any[]) => void;
  onResendEmployees: (ids: any[]) => void;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  isLoading?: boolean;
  handleSearchSubmit?: (values: any) => void;
}

export default function EnterpriseEmployeesTable({
  isLoading,
  employees,
  onDeleteEmployees,
  onDeactivateEmployees,
  onResendEmployees,
  handleTableChange,
  handleSearchSubmit,
  onCreateBulkEmployee,
  onCreateIndividualEmployee,
  totalEmployees,
}: EnterpriseProgramsVendorsProps) {
  const [isOpenModalConfirmDeleteOne, setIsOpenModalConfirmDeleteOne] = useState(false);
  const [isOpenModalConfirmDeleteMany, setIsOpenModalConfirmDeleteMany] = useState(false);
  const [isOpenModalConfirmDeleteReadyToLanch, setIsOpenModalConfirmDeleteReadyToLanch] = useState(false);
  const [isOpenModalConfirmDeleteLive, setIsOpenModalConfirmDeleteLive] = useState(false);
  const [isOpenModalConfirmDeactive, setIsOpenModalConfirmDeactive] = useState(false);
  const [isOpenSelectImport, setIsOpenSelectImport] = useState(false);
  const [isOpenIndividual, setIsOpenIndividual] = useState(false);
  const [isBulkEmployee, setIsBulkEmployee] = useState(false);
  const [selectedFullRow, setSelectedFullRow] = useState<any>([]);

  const [formFilter] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record: any, selected: any, selectedRows: any, nativeEvent: any) => {
      let newSelectedFullRow = [...selectedFullRow];
      if (selected) {
        newSelectedFullRow.push(record);
      } else {
        newSelectedFullRow = newSelectedFullRow.filter((item: any) => item.key !== record.key);
      }
      const newSelectedRowKeys = newSelectedFullRow.map((i: any) => i.key);
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedFullRow(newSelectedFullRow);
    },
  };
  const items: MenuProps['items'] = [
    {
      label: (
        <div
          onClick={() => {
            onResendEmployees(selectedRowKeys);
          }}
          className='cursor-pointer'
        >
          Resend activation email
        </div>
      ),
      key: '0',
    },
    {
      label: (
        <div
          onClick={() => {
            setIsOpenModalConfirmDeactive(true);
          }}
          className='cursor-pointer'
        >
          Deactivate
        </div>
      ),
      key: '1',
    },
    {
      label: (
        <div
          onClick={() => {
            const findIsDeletableLive = selectedFullRow?.find((i: any) => i.deletable_status === DELETABLE_STATUS.DELETE_LIVE);
            const findIsDeletableReadyLanch = selectedFullRow?.find((i: any) => i.deletable_status === DELETABLE_STATUS.DELETE_READY_TO_LANCH);
            if (findIsDeletableLive) {
              setIsOpenModalConfirmDeleteLive(true);
            } else if (findIsDeletableReadyLanch) {
              setIsOpenModalConfirmDeleteReadyToLanch(true);
            } else {
              setIsOpenModalConfirmDeleteMany(true);
            }
          }}
          className='cursor-pointer'
        >
          Delete
        </div>
      ),
      key: '2',
    },
  ];

  // Define the columns for your table
  const columns: any = [
    {
      title: 'Employee name',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (text: any, record: any) => (
        <Link to={`/enterprise/employees/${record.id}`}>
          {record?.user_detail?.first_name} {record?.user_detail?.last_name}
        </Link>
      ),
      sorter: true,
      width: '25%',
      showSorterTooltip: false,
      // sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: false,
      showSorterTooltip: false,
      width: '50%',
      render: (text: any, record: any) => {
        return record?.user?.email;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: false,
      showSorterTooltip: false,
      render: (text: any, record: any) => {
        return (
          <div className=''>
            <Tag
              className={`bg-${
                convertMappingToArray(EMPLOYEE_STATUSES).find(i => i.value === record.status).color
              }  rounded-full border-none text-2A2A2A px-[10px] py-[5px]`}
            >
              {convertMappingToArray(EMPLOYEE_STATUSES).find(i => i.value === record.status).label}
            </Tag>
          </div>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      render: (text: any, record: any) => (
        <div className='flex justify-end gap-[10px]'>
          <Link to={`/enterprise/employees/${record.id}`}>
            <MAIcon size={24} name={'eye'} />
          </Link>
          <div
            className={'cursor-pointer w-[24px]'}
            onClick={() => {
              setSelectedRowKeys([record.id]);
              if (record.deletable_status === DELETABLE_STATUS.DELETE_ONE) {
                setIsOpenModalConfirmDeleteOne(true);
              } else if (record.deletable_status === DELETABLE_STATUS.DELETE_READY_TO_LANCH) {
                setIsOpenModalConfirmDeleteReadyToLanch(true);
              } else if (record.deletable_status === DELETABLE_STATUS.DELETE_LIVE) {
                setIsOpenModalConfirmDeleteLive(true);
              } else {
                setIsOpenModalConfirmDeleteOne(true);
              }
            }}
          >
            <MAIcon size={24} name={'delete'} />
          </div>
        </div>
      ),
    },
  ];
  const handleSelectTypeImport = (type: string) => {
    if (type === 'individual') {
      setIsOpenIndividual(true);
    } else {
      setIsBulkEmployee(true);
    }
    setIsOpenSelectImport(false);
  };
  return (
    <div className={'flex flex-col gap-[1rem]'}>
      {/*Delete one program*/}
      <DeletionModal
        title={'Delete Employee'}
        content={'Are you sure you want to delete this employee and related activities?'}
        isOpen={isOpenModalConfirmDeleteOne}
        onCancel={() => {
          setIsOpenModalConfirmDeleteOne(false);
        }}
        onOk={() => {
          setIsOpenModalConfirmDeleteOne(false);
          onDeleteEmployees(selectedRowKeys);
        }}
      />

      {/*Delete many*/}
      <DeletionModal
        title={'Delete Employee'}
        content={'Are you sure you want to delete this employee and related activities?'}
        isOpen={isOpenModalConfirmDeleteMany}
        onCancel={() => {
          setIsOpenModalConfirmDeleteMany(false);
        }}
        onOk={async () => {
          setIsOpenModalConfirmDeleteMany(false);
          onDeleteEmployees(selectedRowKeys);
          setSelectedRowKeys([]);
        }}
      />
      {/* DeleteReadyToLanch */}
      <DeletionModal
        title={'Delete Employee'}
        content={
          <div>
            The employees are taking part in existing programs. E-contracts of the programs will be re-generated if deleting the employees. <br />{' '}
            <br />
            Are you sure you want to delete this employee and related activities?
          </div>
        }
        isOpen={isOpenModalConfirmDeleteReadyToLanch}
        onCancel={() => {
          setIsOpenModalConfirmDeleteReadyToLanch(false);
        }}
        onOk={async () => {
          setIsOpenModalConfirmDeleteReadyToLanch(false);
          onDeleteEmployees(selectedRowKeys);
          setSelectedRowKeys([]);
        }}
      />
      {/* DeleteLive */}
      <DeletionModal
        title={'Delete Employee'}
        content={'This action cannot be performed because it is unable to delete the enterprise program’s participations.'}
        isOpen={isOpenModalConfirmDeleteLive}
        onCancel={() => {
          setIsOpenModalConfirmDeleteLive(false);
        }}
      />
      {/* Deactive */}
      <DeletionModal
        title={'Deactivate Employee'}
        content={<div>Are you sure you want to deactivate this employee? Their dashboard access will be denied immediately.</div>}
        isOpen={isOpenModalConfirmDeactive}
        onCancel={() => {
          setIsOpenModalConfirmDeactive(false);
        }}
        onOk={async () => {
          setIsOpenModalConfirmDeactive(false);
          onDeactivateEmployees(selectedRowKeys);
          setSelectedRowKeys([]);
        }}
        textOk='Deactivate'
      />
      {/* Select import */}
      <ImportModal selectImportType={handleSelectTypeImport} isOpen={isOpenSelectImport} onCancel={() => setIsOpenSelectImport(false)} />
      {/* Add individual */}
      {isOpenIndividual && (
        <EmployeeEditModal
          title='Add an individual'
          onSave={values => {
            console.log('bb onSave', values);
            onCreateIndividualEmployee(values);
            setIsOpenIndividual(false);
          }}
          currentInfo={{
            firstName: '',
            lastName: '',
            department: '',
            email: '',
            phoneNumber: '',
            status: '',
          }}
          onCancel={() => {
            setIsOpenIndividual(false);
          }}
        />
      )}
      {/* Add bulk */}
      <BulkImportModal
        onSave={value => {
          setIsBulkEmployee(false);
          onCreateBulkEmployee(value);
        }}
        onCancel={() => {
          setIsBulkEmployee(false);
        }}
        isOpen={isBulkEmployee}
      />

      <div className='searchBoxAndCtas flex items-center justify-between'>
        <div className='left titleMedium'>Employees</div>
        <div className='right'>
          <div className='flex flex-row'>
            {selectedRowKeys?.length > 0 && (
              <Dropdown menu={{items}} trigger={['click']}>
                <a className={`cursor-pointer`} onClick={e => e.preventDefault()}>
                  <Space>
                    More options
                    <MAIcon name='arrowDown' />
                  </Space>
                </a>
              </Dropdown>
            )}

            <div className='ml-[20px]'>
              <MAButton
                onClick={() => {
                  setIsOpenSelectImport(true);
                }}
                size={'small'}
                type={'fill'}
              >
                Add employees
              </MAButton>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Form
          form={formFilter}
          onFinish={handleSearchSubmit}
          layout={'vertical'}
          initialValues={{
            status: null,
          }}
        >
          <div className='col w-[250px]'>
            <Form.Item className={'mb-0'} name='name' label={null}>
              <Input minLength={0} maxLength={50} placeholder='Search' prefix={<MAIcon name={'search'} />} />
            </Form.Item>
          </div>
        </Form>
      </div>

      <div className='tablePrograms'>
        <Table
          locale={{
            emptyText: <div className='flex justify-center flex-col items-center gap-[12px] py-[50px]'>{'No data found'}</div>,
          }}
          loading={isLoading}
          onChange={handleTableChange}
          rowSelection={rowSelection}
          pagination={{
            total: totalEmployees,
            defaultPageSize: 10, // Set the initial page size
            showSizeChanger: true, // Show page size changer
            pageSizeOptions: CONFIG.pageSizeOptions, // Define available page sizes
          }}
          dataSource={employees?.map(item => ({...item, key: item.id}))}
          columns={columns}
        />
      </div>
    </div>
  );
}
