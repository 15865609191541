import {useState} from 'react';
import {VendorLayout} from '../../layouts/vendor/VendorLayout';
import MATabsText from '../../components/ui/tabs/MATabsText';
import {VendorContracts} from '../../components/pages/vendor/VendorContracts';

export default function VendorCustomers() {
  const [currentTab, setTab] = useState<string>('matches');

  const tabItems = [
    {
      label: 'Matches',
      value: 'matches',
    },
    {
      label: 'Entrepreneurs',
      value: 'entrepreneurs',
    },
    {
      label: 'Individuals',
      value: 'individuals',
    },
  ];
  return (
    <VendorLayout>
      <div className='flex flex-col gap-y-[24px]'>
        <p className='titleLarge'>Customers</p>
        <div className='flex flex-col gap-y-[12px]'>
          <MATabsText currentTab={currentTab || 'matches'} tabs={tabItems} onChange={item => setTab(item as string)} />
          {currentTab === 'matches' && <VendorContracts />}
          {currentTab === 'entrepreneurs' && <div>entrepreneurs </div>}
          {currentTab === 'individuals' && <div>individuals </div>}
        </div>
      </div>
    </VendorLayout>
  );
}
