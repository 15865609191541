import useRestApi from '../hooks/useRestApi';

interface ProgramApi {
  actions: {
    updateItem: (id: string | number | undefined, values: Record<string, any>) => Promise<any>;
    customUpdate: (path: string, values: Record<string, any>) => Promise<any>;
    customDelete: (path: string, values: Record<string, any>) => Promise<any>;
    customPost: (path: string, values: Record<string, any>) => Promise<any>;
    getItems: (params?: object) => Promise<any>;
    getItem: (id: string | number | undefined) => Promise<any>;
    deleteItem: (id: string | number | undefined) => Promise<any>;
  };
  data: {
    detail: any;
    listItems: any[];
    listPagination: {
      total: number;
    };
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useProgramApi(): ProgramApi {
  return useRestApi('programs');
}
