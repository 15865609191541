import React, {useContext} from 'react';
import {Form, message} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {CurrentUserContext} from '../../contexts/CurrentUserContext';
import {AuthLayout} from '../../layouts/shared/AuthLayout';
import {useForgotPasswordApi} from '../../repositories/useForgotPasswordApi';

interface LoginValues {
  email: string;
  password: string;
  remember_me: boolean;
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {setCurrentUser} = useContext(CurrentUserContext);
  const {actions, data, state} = useForgotPasswordApi();

  const onFinish = async (values: LoginValues) => {
    try {
      await actions.createItem({
        email: values.email,
      });

      message.success('Reset password successfully, please check your email');
      navigate('/auth/forgot-password-success');
    } catch (error: any) {
      message.error('Email is not exist');
      console.log('error', error);
    }
  };
  return (
    <AuthLayout>
      <Form requiredMark={false} layout={'vertical'} name='login' onFinish={onFinish} form={form}>
        <h1 className={'mb-[40px] displaySmall'}>Forgot password</h1>

        <h2 className={'mb-[40px] bodyMedium'}>We have sent a reset password link to your email. Follow the link to select a new password.</h2>
        <div className='ctas flex flex-row items-center justify-start gap-[20px] titleSmall font-[600]'>
          <Link className={'hover:underline text-primary'} to='/auth/login'>
            Back to login
          </Link>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default ForgotPassword;
