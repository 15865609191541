import React from 'react';
import {DatePicker} from 'antd';
import {Dayjs} from 'dayjs';
import {CONFIG} from '../../../config';

type MADatepickerProps = {
  onChange?: (date: Dayjs | null, dateString: string) => void;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: Dayjs | null;
  size?: 'large' | 'middle' | 'small';
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabledDate?: (current: Dayjs) => boolean;
};

const MADatepicker: React.FC<MADatepickerProps> = ({onChange, ...props}) => {
  const handleChange = (date: Dayjs | null, dateString: string) => {
    if (onChange) {
      onChange(date, dateString);
    }
  };

  return (
    <DatePicker
      {...props}
      placeholder={props.placeholder || 'DD/MM/YYYY'}
      inputReadOnly={true}
      format={CONFIG.frontendDateFormat}
      onChange={handleChange}
    />
  );
};

export default MADatepicker;
