import {FaRegStar, FaStar} from 'react-icons/fa';
import {isNumber, makeEmptyArray} from '../../helpers/utils';

interface IStarsReview {
  scoreNum: number;
}

export default function StarsReview({scoreNum}: IStarsReview) {
  return (
    <div className='flex gap-[4px] items-center'>
      {makeEmptyArray(5)?.map((_, index) => {
        if (!isNumber(scoreNum)) return <FaRegStar size={12} />;
        const starValue = Number(scoreNum) || 0;
        const activeStars = Math.floor(starValue);
        if (index < activeStars) return <FaStar size={12} />;
        return <FaRegStar size={12} />;
      })}
    </div>
  );
}
