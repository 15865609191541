import React, {useContext, useEffect} from 'react';
import logoSrc from '../../assets/images/maestro-logo-black.svg';
import {TwoColsLayoutProps} from '../../types';
import {ColorsContext} from '../../contexts/ColorsContext';

export const TwoColsLayout: React.FC<TwoColsLayoutProps> = ({leftColWidth = '50%', logoPosition = 'center', paddingLeft = 100, children}) => {
  const {setColors} = useContext(ColorsContext);
  useEffect(() => {
    setColors({
      primary: '7E38B7',
      secondary: 'F6EAFF',
    });

    console.log('DEBUG SET COLOR FROM VendorLayout');
  }, [setColors]);

  const justifyContentMap = {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  };
  return (
    <div className='flex flex-row w-full h-screen'>
      {/* Left column for logo */}
      <div style={{flexBasis: leftColWidth}} className='flex bg-gray-200 justify-center items-center w-[50%]'>
        <div className='w-full h-full  justify-center flex flex-col items-end' style={{justifyContent: justifyContentMap[logoPosition]}}>
          <img
            src={logoSrc}
            alt='logo'
            className={logoPosition === 'center' ? 'h-[100px] object-contain px-[100px]' : 'h-[100px] mt-10 object-contain pr-[20px] pl-[20px]'}
          />
        </div>
      </div>

      {/* Right column for text */}
      <div className='flex-1 bg-white flex justify-center items-center'>
        <div className={`w-full h-full justify-center flex flex-col items-start`} style={{paddingLeft: paddingLeft ? `${paddingLeft}px` : '100px'}}>
          {children}
        </div>
      </div>
    </div>
  );
};
