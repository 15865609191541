import {useEffect, useState} from 'react';
import {EnterpriseLayout} from '../../../../layouts/enterprise/EnterpriseLayout';
import {useParams} from 'react-router-dom';
import {useEmployeeApi} from '../../../../repositories/useEmployeeApi';
import {message} from 'antd';
import {handleApiError} from '../../../../helpers/utils';
import EnterpriseEmployeesTable from '../../../../components/pages/enterprise/employees/EnterpriseEmployeesTable';
import {CONFIG} from '../../../../config';
import {SuccessModal} from '../../../../components/ui/modals/SuccessModal';

export default function EnterpriseEmployees() {
  const params = useParams();

  const {actions: actionsEmployee, data: dataEmployee, state: stateEmployee} = useEmployeeApi();
  // const [listRowSelected, setListRowSelected] = useState([]);
  const [savedSearchEmployees, setSavedSearchEmployees] = useState<any>({
    order_by: '-created_at',
    limit: CONFIG.defaultPageSize,
  });
  const [isOpenSuccess, setIsOpenSuccess] = useState({value: false, title: '', content: ''});
  async function handleTableEmployeesChange(pagination: any, filters: any, sorter: any) {
    let fieldSort;
    console.log('bb sorter', sorter);

    if (sorter.order === 'ascend') {
      fieldSort = `${sorter.field}`;
    } else if (sorter.order === 'descend') {
      fieldSort = `-${sorter.field}`;
    } else {
      fieldSort = '-created_at';
    }

    setSavedSearchEmployees({
      ...savedSearchEmployees,
      order_by: fieldSort,
      limit: pagination.pageSize,
      page: pagination.current,
    });

    await actionsEmployee.getItems({
      ...savedSearchEmployees,
      order_by: fieldSort,
      limit: pagination.pageSize,
      page: pagination.current,
    });
  }

  async function handleOnDeleteEmployees(ids: any[]) {
    try {
      await actionsEmployee.customDelete('', {
        ids,
      });
      message.success('Deleted employees successfully');
      await Promise.all([
        actionsEmployee.getItems({
          ...savedSearchEmployees,
          order_by: '-created_at',
        }),
      ]);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error deleting employees');
    }
  }

  async function onDeactivateEmployees(ids: any[]) {
    try {
      await actionsEmployee.customPost('deactive', {
        ids,
      });
      setIsOpenSuccess({value: true, title: 'Employee successfully deactivated!', content: '  '});
      await Promise.all([
        actionsEmployee.getItems({
          ...savedSearchEmployees,
          order_by: '-created_at',
        }),
      ]);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error deleting employees');
    }
  }

  async function onResendEmployees(ids: any[]) {
    try {
      await actionsEmployee.customPost('resend-activation-email', {
        ids,
      });
      message.success('Resent activation email successfully');
      await Promise.all([
        actionsEmployee.getItems({
          ...savedSearchEmployees,
          order_by: '-created_at',
        }),
      ]);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error resend employees');
    }
  }
  async function onCreateIndividualEmployee(value: any) {
    try {
      await actionsEmployee.customPost('', {
        user_detail: {
          first_name: value.firstName,
          last_name: value.lastName,
          contact_number: value.phoneNumber,
        },
        user: {
          email: value.email,
        },
        department_id: value.department,
      });
      setIsOpenSuccess({
        value: true,
        title: '1 employee successfully added!',
        content: 'When your program launches, invitations will be emailed to your employees.',
      });
      await Promise.all([
        actionsEmployee.getItems({
          ...savedSearchEmployees,
        }),
      ]);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error create employees');
    }
  }
  async function onCreateBulkEmployee(value: any) {
    try {
      const resImport = await actionsEmployee.customPost('import', {
        employee_file_id: value.employee_file_id,
      });
      setIsOpenSuccess({
        value: true,
        title: resImport?.new_employees_created + ` ${resImport?.new_employees_created > 1 ? 'employees' : 'employee'} successfully added!`,
        content: 'When your program launches, invitations will be emailed to your employees.',
      });
      await Promise.all([
        actionsEmployee.getItems({
          ...savedSearchEmployees,
        }),
      ]);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error create employees');
    }
  }

  useEffect(() => {
    setSavedSearchEmployees({
      ...savedSearchEmployees,
    });

    (async () => {
      try {
        await actionsEmployee.getItems({
          ...savedSearchEmployees,
        });
      } catch (e) {
        console.error(e);
        message.error('Error while loading employee list, please try again later');
      }
    })();
  }, []);

  async function handleSearchSubmit(values: any) {
    setSavedSearchEmployees({
      ...savedSearchEmployees,
      search: values.name,
    });
    await actionsEmployee.getItems({
      ...savedSearchEmployees,
      search: values.name,
    });
  }

  return (
    <EnterpriseLayout>
      <EnterpriseEmployeesTable
        employees={dataEmployee?.listItems}
        totalEmployees={dataEmployee?.listPagination?.total}
        handleTableChange={handleTableEmployeesChange}
        onDeleteEmployees={handleOnDeleteEmployees}
        onDeactivateEmployees={onDeactivateEmployees}
        onCreateIndividualEmployee={onCreateIndividualEmployee}
        onCreateBulkEmployee={onCreateBulkEmployee}
        onResendEmployees={onResendEmployees}
        isLoading={stateEmployee.isLoading}
        handleSearchSubmit={handleSearchSubmit}
      />
      <SuccessModal
        onCancel={() => setIsOpenSuccess({value: false, title: '', content: ''})}
        title={isOpenSuccess.title}
        content={isOpenSuccess.content}
        isOpen={isOpenSuccess.value}
      />
    </EnterpriseLayout>
  );
}
