import React, {useEffect, useMemo, useState} from 'react';
import {EnterpriseLayout} from '../../../../layouts/enterprise/EnterpriseLayout';
import MAButton from '../../../../components/ui/button/MAButton';
import {Form, Input, message, Popover, Select, Table} from 'antd';
import {CAN_DELETE_PROGRAM_STATUSES, CONFIG, PROGRAM_STATUSES_MAPPING} from '../../../../config';
import {Link, useNavigate} from 'react-router-dom';
import MADatepicker from '../../../../components/ui/datepicker/MADatepicker';
import {useProgramApi} from '../../../../repositories/useProgramApi';
import {
  apiValueToFrontendDateFormat,
  convertMappingToArray,
  formValueToApiDateFormat,
  handleApiError,
  LOCAL_STORAGE_KEY,
  PROGRAM_STATUSES_ENUM as STATUS,
} from '../../../../helpers/utils';
import {ProgramStatusLabel} from '../../../../components/shared/ProgramStatusLabel';
import MAIcon from '../../../../components/ui/icon/MAIcon';
import {DeletionModal} from '../../../../components/ui/modals/DeletionModal';

export default function EnterpriseProgramsList() {
  const {actions, data, state} = useProgramApi();
  const [isOpenModalConfirmDelete, setIsOpenModalConfirmDelete] = useState(false);
  const [clickedRowId, setClickedRowId] = useState<any>(null);
  const [savedSearch, setSavedSearch] = useState<any>({
    order_by: '-created_at',
    limit: CONFIG.defaultPageSize,
  });

  const [programOnFirstTimeLoaded, setProgramOnFirstTimeLoaded] = useState<any>(null);

  const navigate = useNavigate();

  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [formFilter] = Form.useForm();

  useEffect(() => {
    (async () => {
      await actions.getItems({
        ...savedSearch,
      });
    })();
  }, []);

  useEffect(() => {
    setDataSource(data.listItems);
  }, [data]);

  function getProgramLink(record: {status: STATUS; id: any; type: any}) {
    return record.status === STATUS.DRAFT
      ? `/enterprise/programs/${record.id}/draft/edit?status=${STATUS.DRAFT}&&program_type=${record.type}&&step=0`
      : `/enterprise/programs/${record.id}/overview`;
  }

  const columns: any = [
    {
      title: 'PROGRAM NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (index: number, record: any) => {
        return <Link to={getProgramLink(record)}>{record.name}</Link>;
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (index: number, record: any) => {
        return <ProgramStatusLabel statusId={record.status} />;
      },
      sorter: false,
    },
    {
      title: 'CREATED DATE',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (index: number, record: any) => {
        return apiValueToFrontendDateFormat(record.created_at);
      },
      defaultSortOrder: 'descend',
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: '',
      key: 'actions',
      width: '200px',
      render: (index: number, record: any) => {
        return (
          <div className={'flex flex-row justify-end items-center gap-[1rem]'}>
            <div className='w-[20px]'>
              <Link to={getProgramLink(record)}>
                <MAIcon name={'eye'} size={20} />
              </Link>
            </div>
            <div className={'w-[20px]'}>
              {CAN_DELETE_PROGRAM_STATUSES.includes(record.status) && (
                <div
                  className={'cursor-pointer'}
                  onClick={() => {
                    setClickedRowId(record.id);
                    setIsOpenModalConfirmDelete(true);
                  }}
                >
                  <MAIcon name={'delete'} size={20} />
                </div>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  async function handleSearchSubmit(values: any) {
    const clone = {
      name: values.name ?? null,
      status: values.status ?? null,
      created_from: formValueToApiDateFormat(values.startDate),
      created_to: formValueToApiDateFormat(values.endDate),
    };

    setSavedSearch({
      ...savedSearch,
      ...clone,
    });
    await actions.getItems({
      ...savedSearch,
      ...clone,
    });
  }

  const modalOnHover = useMemo(() => {
    return (
      <div className={'filter w-[155px]'}>
        <Form
          form={formFilter}
          onFinish={handleSearchSubmit}
          layout={'vertical'}
          initialValues={{
            status: null,
            startDate: null,
            endDate: null,
          }}
        >
          <div>
            <Form.Item name='status' label={'Status'}>
              <Select className={'w-full'} options={[{value: null, label: 'All'}, ...convertMappingToArray(PROGRAM_STATUSES_MAPPING)]}></Select>
            </Form.Item>
            <Form.Item className={'mb-[12px]'} name='startDate' label={'Created date'}>
              <MADatepicker placeholder={'From'} className={'w-full'} />
            </Form.Item>
            <Form.Item name='endDate' label={null}>
              <MADatepicker placeholder={'to'} className={'w-full'} />
            </Form.Item>
            <div className='flex items-center justify-center gap-[12px]'>
              <MAButton
                onClick={() => {
                  formFilter.resetFields();
                }}
                htmlType={'button'}
                type={'outline'}
                size={'small'}
                containerClassName={'!w-full'}
                className={'!w-full'}
              >
                Reset
              </MAButton>
              <MAButton htmlType={'submit'} type={'fill'} size={'small'} containerClassName={'!w-full'} className={'!w-full'}>
                Apply
              </MAButton>
            </div>
          </div>
        </Form>
      </div>
    );
  }, []);

  async function handleTableChange(pagination: any, filters: any, sorter: any) {
    console.log('DEBUG pagination, filters, sorter', pagination, filters, sorter);
    let fieldSort;
    if (sorter.order === 'ascend') {
      fieldSort = `${sorter.field}`;
    } else {
      fieldSort = `-${sorter.field}`;
    }
    await actions.getItems({
      ...savedSearch,
      limit: pagination.pageSize,
      page: pagination.current,
      order_by: fieldSort,
    });

    setSavedSearch({
      ...savedSearch,
      limit: pagination.pageSize,
      page: pagination.current,
      order_by: fieldSort,
    });
  }

  return (
    <EnterpriseLayout>
      <div className='inner flex flex-col gap-[1rem]'>
        {/*<Breadcrumb*/}
        {/*  items={[*/}
        {/*    {*/}
        {/*      title: 'Home',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      title: 'Programs',*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}

        <div className='titleAndCta flex items-center justify-between'>
          <div className='left titleMedium'>Programs</div>
          <div className='right'>
            <div>
              <MAButton
                onClick={() => {
                  localStorage.removeItem(LOCAL_STORAGE_KEY.STEP_DATA);
                  navigate('/enterprise/programs/start?step=0&program_type=cherry_pick');
                }}
                size={'small'}
                type={'fill'}
              >
                Create Program
              </MAButton>
            </div>
          </div>
        </div>

        <DeletionModal
          title={'Delete Program'}
          content={'Are you sure want to delete this program?'}
          isOpen={isOpenModalConfirmDelete}
          onCancel={() => {
            setIsOpenModalConfirmDelete(false);
          }}
          onOk={async () => {
            try {
              await actions.deleteItem(clickedRowId);
              message.success('Deleted program successfully');
            } catch (e) {
              await handleApiError(e, 'Error deleting program');
            } finally {
              await actions.getItems({
                ...savedSearch,
              });
              setIsOpenModalConfirmDelete(false);
            }
          }}
        />

        <div className='flex items-center justify-between'>
          <div className='left'>
            <div className='nameFilter'>
              <Form
                form={formFilter}
                onFinish={handleSearchSubmit}
                layout={'vertical'}
                initialValues={{
                  status: null,
                }}
              >
                <div className='rowItems flex flex-row justify-start items-end gap-[1rem]'>
                  <div className='col w-[250px]'>
                    <Form.Item className={'mb-0'} name='name' label={null}>
                      <Input minLength={0} maxLength={50} placeholder='Search' prefix={<MAIcon name={'search'} />} />
                    </Form.Item>
                  </div>
                  {/*<div className='col w-[250px]'>*/}
                  {/*  <Form.Item className={'mb-0'} name='status' label={'Status'}>*/}
                  {/*    <Select className={'w-full'} options={[{value: null, label: 'All'}, ...PROGRAM_STATUSES]}></Select>*/}
                  {/*  </Form.Item>*/}
                  {/*</div>*/}
                  {/*<div className='col w-[250px]'>*/}
                  {/*  <Form.Item className={'mb-0'} name='startDate' label={'Start Date'}>*/}
                  {/*    <MADatepicker className={'w-full'} />*/}
                  {/*  </Form.Item>*/}
                  {/*</div>*/}
                  {/*<div className='col w-[250px]'>*/}
                  {/*  <Form.Item className={'mb-0'} name='endDate' label={'End Date'}>*/}
                  {/*    <MADatepicker className={'w-full'} />*/}
                  {/*  </Form.Item>*/}
                  {/*</div>*/}
                  {/*<div className='col flex-1'>*/}
                  {/*  <MAButton type={'outline'} size={'small'} containerClassName={'!w-full'} className={'!w-full'}>*/}
                  {/*    Search*/}
                  {/*  </MAButton>*/}
                  {/*</div>*/}
                </div>
              </Form>
            </div>
          </div>
          <div className='right'>
            <Popover placement='leftTop' content={modalOnHover} title={null} trigger='click'>
              <div className={'flex bg-white p-[8px] rounded-[4px] cursor-pointer'}>
                <MAIcon name={'filter'} />
              </div>
            </Popover>
          </div>
        </div>

        <div className='tablePrograms'>
          <Table
            locale={{
              emptyText: (
                <div className='flex justify-center flex-col items-center gap-[12px] py-[50px]'>
                  {'No data found'}
                  {/*<div className={'titleMedium'}>There are no programs</div>*/}
                  {/*<span className={'labelSmall'}>Start designing a program and they will show up here.</span>*/}
                </div>
              ),
            }}
            onChange={handleTableChange}
            loading={state?.isLoading}
            dataSource={dataSource}
            pagination={{
              total: data?.listPagination?.total,
              showSizeChanger: true,
              pageSizeOptions: CONFIG.pageSizeOptions,
            }}
            columns={columns}
          />
        </div>
      </div>
    </EnterpriseLayout>
  );
}
