import React, {useContext, useEffect} from 'react';
import {TwoColsLayoutProps} from '../../types';
import {ColorsContext} from '../../contexts/ColorsContext';
import logoSrc from '../../assets/images/maestro-logo.svg';
import {LOCAL_STORAGE_KEY} from '../../helpers/utils';
import PublicFooter from './PublicFooter';

export const AuthLayout: React.FC<TwoColsLayoutProps> = ({leftColWidth = '50%', logoPosition = 'center', paddingLeft = 100, children}) => {
  const {setColors} = useContext(ColorsContext);
  useEffect(() => {
    setColors({
      primary: '7E38B7',
      secondary: 'F6EAFF',
    });
  }, [setColors]);

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.STEP_DATA);
    localStorage.removeItem(LOCAL_STORAGE_KEY.USER);
    localStorage.removeItem(LOCAL_STORAGE_KEY.USER_DETAIL);
  }, []);

  return (
    <div className='flex flex-col items-center w-full h-screen bg-[#F5F5F5]'>
      <img className={'mt-[60px] mb-[40px] w-[235px]'} src={logoSrc} alt='Maestro logo' />
      <div className='boxed bg-white py-[60px] px-[40px] w-[475px] rounded-[4px]'>{children}</div>

      <PublicFooter />
    </div>
  );
};
