import React from 'react';
import MASliders from '../../../../ui/sliders/MASlider';

export default function VendorCarousel({images}: any) {
  return (
    <div className='aspect-5/4 left flex-1'>
      <MASliders
        items={images?.map((item: {download_url: any}, index: any) => {
          return {
            id: index,
            image: item?.download_url,
          };
        })}
      />
    </div>
  );
}
