import MAButton from '../../../../ui/button/MAButton';
import MAIcon from '../../../../ui/icon/MAIcon';
import EContractStatus from '../../../../shared/EContractStatus';
import {VendorOverview} from '../../../../vendor/VendorOverview';
import {VendorTimeline} from '../../../../vendor/VendorTimeline';
import {useEffect, useState} from 'react';

interface ProgramVendorDetailProps {
  vendor: any;
  activities: any[];
  programVendors: any[];
  products: any[];
  onSignContract: (contract: any) => void;
  onDenyContract: (contract: any) => void;
}

export default function ProgramVendorDetail({
  products,
  vendor,
  onSignContract,
  onDenyContract,
  activities,
  programVendors,
}: ProgramVendorDetailProps) {
  const [firstContract, setFirstContract]: any = useState(null);

  useEffect(() => {
    for (let i = 0; i < activities.length; i++) {
      if (activities[i]?.contract) {
        setFirstContract(activities[i]);
        return;
      }
    }
  }, [activities]);

  return (
    <div className='wrapper flex gap-y-[24px] flex-col'>
      {/* title */}
      <div className='flex justify-between items-center'>
        <div className='flex justify-center items-center gap-x-[12px]'>
          <span className='titleMedium'>{vendor?.name}</span>
          {programVendors?.map(programVendor => {
            if (programVendor.id === vendor.id) {
              return <EContractStatus statusId={firstContract?.contract?.status} />;
            }
          })}
        </div>
        <MAButton customTextColor={'white'}>
          <div
            className='flex gap-[10px] justify-center items-center'
            onClick={() => {
              alert('TODO Handle');
            }}
          >
            <MAIcon size={24} name={'mail'} />
            <span>Message</span>
          </div>
        </MAButton>
      </div>
      {/* Overview */}
      <VendorOverview vendor={vendor} />
      {/* TimeLines  */}
      <VendorTimeline
        products={products}
        firstContract={firstContract}
        activities={activities}
        onSignContract={onSignContract}
        onDenyContract={onDenyContract}
      />
    </div>
  );
}
