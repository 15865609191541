import React from 'react';
import styles from './PublicFooter.module.scss';

const PublicFooter: React.FC = () => {
  return (
    <div className={styles.moduleWrapper}>
      <footer className={`footer bg-white`}>
        <div className='box flex justify-between items-center mx-auto'>
          <div className='left bodySmall p-4 border-r border-r-[#d9d9d9]'>© 2024 Maestro Wellness. All rights reserved.</div>
          <div className='right bodySmall p-4 border-l border-l-[#d9d9d9] flex items-center gap-[20px]'>
            <a target={'_blank'} href={`${process.env.REACT_APP_PUBLIC_SITE}/privacy-policy`}>
              Privacy & Cookies Policy
            </a>
            <a target={'_blank'} href={`${process.env.REACT_APP_PUBLIC_SITE}/terms-of-use`}>
              Terms & Conditions
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PublicFooter;
