import {useEffect, useState} from 'react';
import {ProductOfVendor, Step2Props, VendorItem} from '../../../../../types';
import styles from '../../../../../pages/enterprise/programs/start/EnterpriseProgramStart.module.scss';
import {CaretDownOutlined, CaretUpOutlined, MinusOutlined, PlusOutlined, StarFilled} from '@ant-design/icons';
import {Badge, Button, Col, Collapse, Drawer, Image, message, Rate, Row, Spin, Tag} from 'antd';
import MAButton from '../../../../ui/button/MAButton';
import moment from 'moment';
import {
  deleteActivities,
  fetchCategories,
  fetchConfigData,
  fetchLocations,
  fetchProductsOfVendor,
  fetchVendors,
} from '../../../../../services/apiService';
import defaultImage from '../../../../../assets/images/default-placeholder.png';
import {
  formatCurrencyUSD,
  handleStepData,
  LOCAL_STORAGE_KEY,
  PROGRAM_STATUSES_ENUM,
  PROGRAM_TYPES_ENUM,
  STEP_NUMBERS_ENUM,
} from '../../../../../helpers/utils';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import MAIcon from '../../../../ui/icon/MAIcon';
import OutLineButton from '../../../../shared/OutLineButton';
import dayjs from 'dayjs';
import {CONFIG} from '../../../../../config';

const {Panel} = Collapse;
const nameToLabelMap: any = {
  startDate: 'Start date',
  endDate: 'End date',
  numberOfEmployee: 'Number of employees',
  budgetPerEmployeeMin: 'Budget per employee min',
  budgetPerEmployeeMax: 'Budget per employee max',
  physicalLocation: 'Physical location',
  regionLocation: 'Region location',
  service: 'Service',
  serviceType: 'Service type',
  subCategory: 'Sub category',
  totalBudgetMax: 'Total budget max',
  totalBudgetMin: 'Total budget min',
  programName: 'Program name',
};

export default function Step3({initialValues, nextStep, addCartAndClose, addActivities, isCartOpen, syncCart}: Step2Props) {
  const [vendors, setVendors] = useState<VendorItem[] | null>(null);
  const [visible, setVisible] = useState(false);
  const [cartItems, setCartItems] = useState<ProductOfVendor[]>([]);
  const [convertInitValue, setConvertInitValue] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const [initOrder, setInitOrder] = useState<any>([]);
  const [regionLocation, setRegionLocation] = useState('');
  const [serviceType, setServiceType] = useState('');
  const navigate = useNavigate();

  const {id} = useParams();
  useEffect(() => {
    if (isCartOpen) {
      handleOpenCart();
    }
  }, [isCartOpen]);

  useEffect(() => {
    syncCart(visible);
  }, [visible]);

  const statusFromRouter = searchParams.get('status');
  const isDraftOrStart =
    (statusFromRouter &&
      (parseInt(statusFromRouter) === PROGRAM_STATUSES_ENUM.DRAFT || parseInt(statusFromRouter) === PROGRAM_STATUSES_ENUM.START)) ||
    !statusFromRouter;
  const isDifferenceDraft = statusFromRouter && parseInt(statusFromRouter) !== PROGRAM_STATUSES_ENUM.DRAFT;

  useEffect(() => {
    const dataCart = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
    if (dataCart) {
      const data = JSON.parse(dataCart);
      if (data.step3Data && data?.step1Data?.programType === PROGRAM_TYPES_ENUM.TAILOR_MADE) {
        setCartItems(data.step3Data);
      }
    }
  }, []);

  useEffect(() => {
    if (isDifferenceDraft) {
      const dataCart = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
      if (dataCart) {
        const data = JSON.parse(dataCart);
        if (data.step3Data && data?.step1Data?.programType === PROGRAM_TYPES_ENUM.TAILOR_MADE) {
          setInitOrder(data.step3Data);
        }
      }
    }
  }, [isDifferenceDraft]);

  const onClose = () => {
    setVisible(false);
  };

  const onNext = () => {
    setVisible(false);
    nextStep();
  };
  const onAdd = () => {
    if (cartItems?.length > 0) {
      setVisible(false);
      const diff = cartItems.filter(item => !initOrder.map((i: {id: any}) => i.id).includes(item.id)).map(_e => _e.id);
      addActivities(diff);
    } else {
      message.error('Please add product to cart');
    }
  };
  const saveDraftAndClose = () => {
    setVisible(false);
    addCartAndClose();
  };

  const handleAdd = (product: any) => {
    try {
      const cartData = Array.isArray(cartItems) ? cartItems : [];
      if (!checkIsExist(product)) {
        setCartItems([...cartData, product]);
        handleStepData(STEP_NUMBERS_ENUM.STEP3, [...cartData, product]);
      }
    } catch (error) {
      console.log('bb error', error);
    }
  };

  const handleRemove = async (productItem: any) => {
    try {
      if (productItem.isServer && isDifferenceDraft) {
        await deleteActivities(productItem.id, id);
        const order = initOrder.filter((item: any) => item.id !== productItem.id);
        setInitOrder(order);
      }
      const newCartItems = cartItems.filter(item => item.id !== productItem.id);
      setCartItems(newCartItems);
      handleStepData(STEP_NUMBERS_ENUM.STEP3, newCartItems);
    } catch (error) {
      message.error('Failed to remove product');
    }
  };

  const checkIsExist = (product: any) => {
    try {
      const cartData = Array.isArray(cartItems) ? cartItems : [];
      const productExistsInCart = cartData.some(cartItem => cartItem.id === product.id);
      return productExistsInCart;
    } catch (error) {
      console.log('bb error', error);
    }
  };

  const convertInitialValues = (initialValues: any, categories: any[]) => {
    return {
      ...initialValues,
      startDate: initialValues.startDate ? moment(initialValues.startDate).format('DD/MM/YYYY') : null,
      endDate: initialValues.endDate ? moment(initialValues.endDate).format('DD/MM/YYYY') : null,
      service: categories.find((item: any) => item.id === initialValues.service)?.name,
      subCategory: categories.find((item: any) => item.id === initialValues.subCategory)?.name,
    };
  };

  useEffect(() => {
    const fetchApis = async () => {
      try {
        const [locations, configData, categories] = await Promise.all([fetchLocations(), fetchConfigData(), fetchCategories()]);

        const findServiceType = configData?.find((item: any) => item.value === initialValues?.serviceType);
        const findRegion = locations?.find((item: any) => item.value === initialValues?.regionLocation);
        setServiceType(findServiceType.label);
        setRegionLocation(findRegion.label);
        console.log('locations,configData ', locations);

        const values = convertInitialValues(initialValues, categories);
        setConvertInitValue(values);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };
    const fetchAndSetVendors = async () => {
      try {
        const params: {[key: string]: any} = {};
        params['category_id'] = initialValues?.subCategory;
        params['parent_category_id'] = initialValues?.service;
        params['service_type'] = initialValues?.serviceType;
        params['location_id'] = initialValues?.regionLocation;
        params['max_budget'] = initialValues?.totalBudgetMax;
        params['start_date'] = dayjs(initialValues?.startDate).format(CONFIG.apiDateFormat);
        params['end_date'] = dayjs(initialValues?.endDate).format(CONFIG.apiDateFormat);
        params['limit'] = 0;
        params['page'] = 1;

        const vendors = await fetchVendors(params);
        setVendors(vendors);
      } catch (error) {
        console.error('Failed to fetch vendors:', error);
      }
    };
    if (initialValues && initialValues?.programName) {
      fetchApis();
      fetchAndSetVendors();
    }
  }, [initialValues]);

  const updateVendors = (vendorId: string, changes: object) => {
    setVendors(prevVendors =>
      prevVendors
        ? prevVendors.map(vendor => {
            if (vendor.id === vendorId) {
              return {...vendor, ...changes};
            } else {
              return vendor;
            }
          })
        : null
    );
  };

  const totalPriceOfVendorId = (vendorId: string) => {
    const products = cartItems.filter(item => item.vendor_id === vendorId);
    if (products?.length <= 0) {
      return false;
    } else {
      const total = products.reduce((acc, item) => {
        return acc + parseFloat(item.price);
      }, 0);

      return formatCurrencyUSD(total);
    }
  };

  const handleClickToCollapse = async (key: any, vendorId: string) => {
    if (key[0] === vendorId) {
      updateVendors(vendorId, {loading: true, active: true});
      const products = await fetchProductsOfVendor(vendorId);
      if (initOrder?.length >= 0) {
        const converProduct = products.map((item: any) => {
          const isExist = initOrder?.find((i: any) => i?.id === item?.id);
          if (isExist) {
            return {...item, isServer: true};
          }
          return item;
        });
        updateVendors(vendorId, {products: converProduct, loading: false, active: true});
      } else {
        updateVendors(vendorId, {products: products, loading: false, active: true});
      }
    } else {
      updateVendors(vendorId, {active: false});
    }
  };

  const countDateActive = (activeDate: any) => {
    if (activeDate) {
      const date = new Date();
      const year = date.getFullYear();
      const yearActive = activeDate.split('-')[0];
      return year - yearActive;
    }
    return null;
  };

  const sumPriceProductInCart = () => {
    let sum = 0;
    cartItems.forEach(item => {
      sum += parseFloat(item.price);
    });
    return sum;
  };
  const totalCostPerEnployee = () => {
    if (initialValues?.numberOfEmployee) {
      const sumEachEmployee = sumPriceProductInCart() * (parseFloat(initialValues?.numberOfEmployee) || 0);
      return sumEachEmployee;
    }
    return 0;
  };
  const handleOpenCart = () => {
    if (cartItems?.length > 0) {
      setVisible(true);
    }
  };

  return (
    <div className={`${styles.moduleWrapper} w-full flex  h-full flex-col `}>
      <div onClick={handleOpenCart}>
        <Badge offset={[0, 5]} className='fixed top-[100px] right-[40px] bodyMedium cursor-pointer' count={cartItems?.length}>
          <MAIcon name='checkList' size={30} />
        </Badge>
      </div>

      <Col span={18} offset={3}>
        <div className='bg-white my-4'>
          <Collapse
            bordered={false}
            expandIcon={({isActive}) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
            defaultActiveKey='1'
            expandIconPosition='start'
          >
            <Panel
              className='bg-white '
              header={<div className={`flex justify-centerfont-medium labelLarge `}>Your Program Requirements</div>}
              key='1'
            >
              <div className={`flex flex-wrap gap-2 mb-[16px] bg-secondary px-[50px] py-[20px] rounded-[4px] `}>
                {convertInitValue &&
                  Object.entries(convertInitValue).map(([key, value], index) => {
                    const label = nameToLabelMap[key] || key;
                    let displayValue = value;

                    if (key === 'physicalLocation') {
                      displayValue = value == 1 ? 'YES' : 'NO';
                    } else if (key === 'serviceType') {
                      displayValue = serviceType;
                    } else if (key === 'regionLocation') {
                      displayValue = regionLocation;
                    }

                    return (
                      <Tag key={index} className='bg-white  rounded-full border-none text-5A5A5A px-[10px] py-[5px]'>
                        {`${label}: ${displayValue}`}
                      </Tag>
                    );
                  })}
              </div>
            </Panel>
          </Collapse>
        </div>
        <div className='mt-[20px]'>
          {vendors && vendors.length === 0 ? (
            <div className='text-center mt-10'>
              <div className='titleMedium font-medium'>Sorry, we're unable to find any vendors that matched to your request</div>
            </div>
          ) : (
            vendors &&
            vendors?.map((item: VendorItem, index: any) => (
              <Collapse activeKey={item.active ? [item.id] : []} key={item.id} expandIcon={() => null} expandIconPosition='end' className='mb-[16px]'>
                <Panel
                  key={item.id}
                  header={
                    <Row key={index} align={'middle'} className='' gutter={16}>
                      <Col span={5}>
                        <Image width={150} src={item?.photo ? item.photo?.download_url : defaultImage} />{' '}
                      </Col>
                      <Col span={19}>
                        <div>
                          <div className='titleLarge mb-[8px] truncate  flex flex-row justify-between'>
                            <span
                              className='cursor-pointer'
                              onClick={() => {
                                navigate(`/enterprise/vendors/${item.id}`);
                              }}
                            >
                              {item.name}
                            </span>
                            {totalPriceOfVendorId(item.id) ? (
                              <div className='flex flex-row justify-center items-center'>
                                <span className='text-898989 labelSmall mr-[4px]'>Total price</span>
                                <span className='text-1E1E1E titleLarge'>{totalPriceOfVendorId(item.id)}</span>
                              </div>
                            ) : null}
                          </div>
                          <div className='bodySmall mb-[8px] truncate flex flex-row justify-start w-full items-center border-b-[1px] pb-[12px] border-E2E2E2'>
                            <span>{countDateActive(item?.active_date)} yrs</span>
                            <span className='ml-[10px] mr-[5px]'>5.0</span>
                            <Rate disabled character={<StarFilled style={{fontSize: '15px'}} />} className='mr-[10px] text-2A2A2A' value={4} />
                            <span> 13 reviews</span>
                          </div>
                        </div>
                        <div className='flex flex-row '>
                          <div className='w-[68%] '>
                            <div className='bodySmall text-5A5A5A text-truncate '>{item.description}</div>
                            {serviceType && (
                              <Tag className='bg-A1C4BC  rounded-full border-none labelSmall mt-[9px] px-[10px] py-[5px]'>{serviceType}</Tag>
                            )}
                          </div>
                          <div className='flex flex-row w-[32%] justify-around items-center'>
                            <OutLineButton className='pr-[10px] pl-[10px] labelMedium' icon={<MAIcon name='refresh' size={14} />} onClick={() => {}}>
                              Swap
                            </OutLineButton>
                            <OutLineButton
                              className='pr-[10px] pl-[10px] labelMedium'
                              icon={item.active ? <CaretDownOutlined /> : <CaretUpOutlined />}
                              onClick={() => {
                                if (item.active) {
                                  handleClickToCollapse([], item.id);
                                } else {
                                  handleClickToCollapse([item.id], item.id);
                                }
                              }}
                            >
                              Activities
                            </OutLineButton>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  }
                >
                  {item.loading ? (
                    <div className='flex items-center justify-center h-full'>
                      <Spin />
                    </div>
                  ) : (
                    item.products &&
                    item.products?.map((productItem: ProductOfVendor) => (
                      <Row className='py-[5px]' key={productItem.id} align='middle' gutter={16}>
                        <Col span={12}>
                          <div className='bodyMedium '>
                            <span
                              className='cursor-pointer'
                              onClick={() => {
                                navigate(`/enterprise/products/${productItem.id}`);
                              }}
                            >
                              {productItem.name}
                            </span>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className='bodyMedium'>{formatCurrencyUSD(productItem.price)}/person</div>
                        </Col>
                        <Col className=' flex flex-row justify-end' span={4}>
                          {checkIsExist({...productItem, vendorName: item.name}) ? (
                            <MAButton className='w-[100px]' onClick={() => handleRemove(productItem)}>
                              Remove
                            </MAButton>
                          ) : (
                            <MAButton className='w-[100px]' onClick={() => handleAdd({...productItem, vendorName: item.name})}>
                              Add
                            </MAButton>
                          )}
                        </Col>
                      </Row>
                    ))
                  )}
                </Panel>
              </Collapse>
            ))
          )}
        </div>
      </Col>
      <Drawer
        className={styles.moduleWrapper}
        title={<div className='labelExtraLarge text-2A2A2A'>{initialValues?.programName}</div>}
        placement='right'
        closable={false}
        onClose={onClose}
        open={visible}
        width={400}
      >
        <h1 className='labelLarge text-2A2A2A mb-[16px]'>Product</h1>
        {cartItems?.length > 0 && (
          <>
            <div className='scrollable-list'>
              {cartItems?.map((item, index) => (
                <div className='mb-[5px] mt-[10px] rounded-[5px] w-[95%]  border-E2E2E2 border-[1px] p-[13px] relative' key={index}>
                  <Row justify='space-between'>
                    <Col>
                      <p className='titleSmall text-truncate'>{item.name}</p>
                    </Col>
                    <div onClick={() => handleRemove(item)} className='absolute top-[-10px] right-[-5px] w-[20px] h-[20px] cursor-pointer'>
                      <MAIcon name='closeCircle' color='#DBDBDB' size={25} />
                    </div>
                  </Row>
                  <Row justify={'space-between'}>
                    <Col span={12}>
                      <p className='bodyMedium'>{item.vendorName}</p>
                    </Col>
                    <Col span={12}>
                      <p className='bodyMedium'>{formatCurrencyUSD(item.price)}</p>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
            <div className='h-[22%] pl-[20px]'>
              <Row className='mt-[8px]'>
                <Col span={14}>Budget</Col>
                <Col className='truncate' span={10}>
                  {initialValues?.budgetPerEmployeeMin}-{initialValues?.budgetPerEmployeeMax}{' '}
                </Col>
              </Row>
              <Row className='mt-[8px]'>
                <Col span={14}>Cost per employee</Col>
                <Col className='truncate' span={10}>
                  {formatCurrencyUSD(sumPriceProductInCart())}
                </Col>
              </Row>
              <Row className='mt-[8px]'>
                <Col span={14}>Total program cost</Col>
                <Col className='truncate' span={10}>
                  {formatCurrencyUSD(totalCostPerEnployee())}
                </Col>
              </Row>
            </div>
          </>
        )}

        {isDraftOrStart ? (
          <Row className='absolute bottom-4 w-full  left-0' justify={'space-around'}>
            <Col>
              <OutLineButton className='w-[140px] text-center' onClick={saveDraftAndClose}>
                Save and close
              </OutLineButton>
            </Col>
            <Col>
              <Button type='primary' className={`w-[140px] bg-primary px-[24px] ml-[12px] text-white`} onClick={onNext}>
                Next
              </Button>
            </Col>
          </Row>
        ) : (
          <Row className='absolute bottom-4 w-full left-0' justify={'space-around'}>
            <Col>
              <OutLineButton
                className='w-[140px] text-center'
                onClick={() => {
                  if (cartItems?.length === 0) {
                    setVisible(false);
                  } else {
                    navigate(`/enterprise/programs/${id}/products`);
                  }
                }}
              >
                Back
              </OutLineButton>
            </Col>
            <Col>
              <MAButton className='w-full' onClick={onAdd}>
                Add
              </MAButton>
            </Col>
          </Row>
        )}
      </Drawer>
    </div>
  );
}
