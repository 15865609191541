import useRestApi from '../hooks/useRestApi';

interface ValidateResetPasswordTokenApi {
  actions: {
    createItem: (values: Record<string, any>) => Promise<any>;
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useValidateResetPasswordTokenApi(): ValidateResetPasswordTokenApi {
  return useRestApi('forgot-password/validate-token');
}
