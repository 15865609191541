import React from 'react';
import {Form, FormInstance, Input, Select} from 'antd';
import MAButton from '../../ui/button/MAButton';
import {MESSAGES} from '../../../config';
import {Regex} from '../../../helpers/utils';

type EnterpriseSignupForm = {
  enterpriseSignupForm: FormInstance<any>;
  bestOptions: any[];
  countries: any[];
  handleSubmit: (values: any) => void;
  formTitle: FormTitle;
};
type FormTitle = {
  submitText: string;
};

function EnterpriseSignupForm(props: EnterpriseSignupForm) {
  const {enterpriseSignupForm, countries, bestOptions, formTitle, handleSubmit} = props;
  return (
    <Form
      onFinish={handleSubmit}
      form={enterpriseSignupForm}
      name='validateOnly'
      layout='vertical'
      autoComplete='off'
      initialValues={{}}
      size={'large'}
    >
      <div className='flex flex-col gap-y-[40px]'>
        <div className='flex flex-col gap-y-[24px]'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-y-[24px] gap-x-[20px]'>
            <Form.Item
              name={'firstName'}
              label={<span className='bodySmall !font-semibold'>First name</span>}
              className='bodySmall mb-0'
              rules={[{required: true, message: MESSAGES.MSG18}]}
            >
              <Input placeholder='First Name' maxLength={50} />
            </Form.Item>
            <Form.Item
              name={'lastName'}
              label={<span className='bodySmall !font-semibold'>Last name</span>}
              className='bodySmall mb-0'
              rules={[{required: true, message: MESSAGES.MSG18}]}
            >
              <Input placeholder='Last Name' maxLength={50} />
            </Form.Item>
          </div>
          <Form.Item
            hasFeedback={false}
            name={'companyName'}
            label={<span className='bodySmall !font-semibold'>Company</span>}
            className='bodySmall mb-0'
            rules={[{required: true, message: MESSAGES.MSG18}]}
          >
            <Input placeholder='Company Name' maxLength={200} />
          </Form.Item>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-y-[24px] gap-x-[20px]'>
            <Form.Item
              name={'country'}
              label={<span className='bodySmall !font-semibold'>Country</span>}
              className='bodySmall mb-0'
              rules={[{required: true, message: MESSAGES.MSG18}]}
            >
              <Select placeholder='Select an option' optionFilterProp='children' options={countries} />
            </Form.Item>
            <Form.Item
              name={'best'}
              label={<span className='bodySmall !font-semibold'>What describes you best?</span>}
              className='bodySmall mb-0'
              rules={[{required: true, message: MESSAGES.MSG18}]}
            >
              <Select placeholder='Select an option' optionFilterProp='children' options={bestOptions} />
            </Form.Item>
          </div>

          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.best !== currentValues.best}>
            {({getFieldValue}) =>
              getFieldValue('best') === 'other' ? (
                <Form.Item
                  name={'best_other'}
                  label={<span className='bodySmall !font-semibold'>Others</span>}
                  className='bodySmall mb-0'
                  rules={[{required: true, message: MESSAGES.MSG18}]}
                >
                  <Input maxLength={50} />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            name={'email'}
            label={<span className='bodySmall !font-semibold'>Email</span>}
            className='bodySmall mb-0'
            normalize={value => value.trim()}
            rules={[
              {required: true, message: MESSAGES.MSG18},
              {type: 'email', message: MESSAGES.MSG11},
            ]}
          >
            <Input placeholder='name@sample.com' maxLength={50} />
          </Form.Item>
          <Form.Item
            name={'phoneNumber'}
            label={<span className='bodySmall !font-semibold'>Phone Number</span>}
            className='bodySmall mb-0'
            rules={[
              {required: true, message: MESSAGES.MSG18},
              {pattern: Regex.phoneNumberWithSpace, message: MESSAGES.MSG20},
            ]}
          >
            <Input className='w-full' placeholder='(+country code) 0000 0000' maxLength={20} />
          </Form.Item>
        </div>
        <div className='flex justify-center'>
          <MAButton htmlType='submit' className='!px-[100px] !rounded-full !py-[10px]'>
            <span className='labelMedium'>{formTitle.submitText}</span>
          </MAButton>
        </div>
      </div>
    </Form>
  );
}

export default EnterpriseSignupForm;
