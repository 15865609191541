import React, {useContext} from 'react';
import {Form, Input, message} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {CurrentUserContext} from '../../contexts/CurrentUserContext';
import {handleApiError, Regex} from '../../helpers/utils';
import {AuthLayout} from '../../layouts/shared/AuthLayout';
import MAButton from '../../components/ui/button/MAButton';
import {useForgotPasswordApi} from '../../repositories/useForgotPasswordApi';
import {MESSAGES} from '../../config';

interface LoginValues {
  email: string;
  password: string;
  remember_me: boolean;
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {setCurrentUser} = useContext(CurrentUserContext);
  const {actions, data, state} = useForgotPasswordApi();

  const onFinish = async (values: LoginValues) => {
    try {
      await actions.createItem({
        email: values.email,
      });

      message.success('Sent link to reset password, please check your email');
      navigate('/auth/forgot-password-success');
    } catch (error: any) {
      console.log('error', error);
      await handleApiError(error, 'Email is not exist');
    }
  };
  return (
    <AuthLayout>
      <Form requiredMark={false} layout={'vertical'} name='login' onFinish={onFinish} form={form}>
        <h1 className={'mb-[40px] displaySmall'}>Forgot password</h1>

        <h2 className={'mb-[40px] bodyMedium'}>Enter your email and we’ll send a link to reset your password</h2>

        <Form.Item
          className={'labelSmall mb-[40px]'}
          label={'Email'}
          name='email'
          normalize={value => value.trim()}
          rules={[
            {required: true, message: MESSAGES.MSG18},
            {pattern: Regex.email, message: MESSAGES.MSG11},
          ]}
        >
          <Input maxLength={50} className={'grayInput'} />
        </Form.Item>

        <Form.Item className={'mb-[40px]'}>
          <MAButton disabled={state?.isLoading} className={'!rounded-[20px] !bg-293734 !py-[10px] !px-[45px] bodyExtraLarge'}>
            Reset password
          </MAButton>
        </Form.Item>
        <div className='ctas flex flex-row items-center justify-start gap-[20px] titleSmall font-[600]'>
          <div>Remember your password?</div>
          <Link className={'hover:underline text-primary'} to='/auth/login'>
            Back to login
          </Link>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default ForgotPassword;
