import React from 'react';
import styles from './MAButton.module.scss';

// Define the type for your custom props if any
interface MAButtonProps {
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  size?: 'small' | 'medium' | 'large';
  containerClassName?: string;
  type?: 'fill' | 'outline' | 'text';
  color?: 'danger' | 'primary' | 'secondary' | string;
  customBgColor?: string;
  customTextColor?: string;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
}

const MAButton: React.FC<MAButtonProps> = ({
  disabled,
  customBgColor,
  customTextColor,
  children,
  color,
  type,
  size,
  className,
  containerClassName,
  ...rest
}) => {
  const sizeClasses = size === 'small' ? 'py-[5px] px-[10px]' : 'py-[10px] px-[20px]';

  const colorClasses = () => {
    if (color === 'danger') {
      return 'text-red-500';
    }

    return `!text-${color}`;
  };

  const disabledClasses = () => {
    if (disabled) {
      return 'cursor-not-allowed opacity-[50%]';
    }

    return 'cursor-pointer';
  };

  const typeClasses = () => {
    // Default
    if (!type || type === 'fill') {
      return 'text-white bg-primary';
    }

    if (type === 'outline') {
      return 'text-primary border-[1px] bg-secondary border-primary';
    }

    if (type === 'text') {
      return 'text-primary bg-transparent px-0 py-0';
    }
  };

  return (
    <div className={`${styles.moduleWrapper} ${containerClassName}`}>
      {/*@ts-ignore*/}
      <button
        {...rest}
        type={rest.htmlType}
        disabled={disabled}
        className={`
          mainButton 
          ${className || ''} 
          ${sizeClasses} 
          ${typeClasses()} 
          ${colorClasses()} 
          ${disabledClasses()} 
          ${customBgColor ? `!bg-${customBgColor}` : ''} 
          ${customTextColor ? `!text-${customTextColor}` : ''}
        `}
      >
        {children}
      </button>
    </div>
  );
};

export default MAButton;
