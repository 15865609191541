import React, {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {Table} from 'antd';
import MAIcon from '../../../../ui/icon/MAIcon';
import MAButton from '../../../../ui/button/MAButton';
import {DeletionModal} from '../../../../ui/modals/DeletionModal';
import EContractStatus from '../../../../shared/EContractStatus';
import {PROGRAM_STATUSES_ENUM} from '../../../../../helpers/utils';
import {CONFIG, CONTRACT_STATUSES} from '../../../../../config';
import {ContractModal} from '../../../../ui/modals/ContractModal';

interface EnterpriseProgramsVendorsProps {
  vendors: any[];
  products: any[];
  program: any;
  onDeleteVendors: (ids: any[]) => void;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  isLoading?: boolean;
  onSignContract: () => void;
  onDenyContract: () => void;
  clickedContractRow: any;
  setClickedContractRow: any;
}

export default function EnterpriseProgramsVendors({
  products,
  isLoading,
  vendors,
  program,
  onDeleteVendors,
  handleTableChange,
  onSignContract,
  onDenyContract,
  setClickedContractRow,
  clickedContractRow,
}: EnterpriseProgramsVendorsProps) {
  const [isOpenModalConfirmDeleteOne, setIsOpenModalConfirmDeleteOne] = useState(false);
  const [isOpenModalConfirmDeleteMany, setIsOpenModalConfirmDeleteMany] = useState(false);
  const [clickedRowId, setClickedRowId] = useState<any>(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const isCanEdit = useMemo(() => {
    return ![PROGRAM_STATUSES_ENUM.LIVE, PROGRAM_STATUSES_ENUM.COMPLETED, PROGRAM_STATUSES_ENUM.PAUSE].includes(program.status);
  }, [program]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelectedRows = selectedRowKeys.length > 0;

  // Define the columns for your table
  const columns: any = [
    {
      title: 'VENDOR NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => <Link to={`/enterprise/programs/${program?.id}/vendors/${record.id}`}>{text}</Link>,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'ACTIVITIES',
      dataIndex: 'products',
      key: 'products',
      render: (text: any, record: any) => {
        return (
          <div className={'flex flex-col gap-[8px] titleSmall text-5A5A5A'}>
            {products
              ?.filter(product => product.vendor_id === record.id)
              .map(product => {
                return <Link to={`/enterprise/products/${product.id}`}>• {product.name}</Link>;
              })}
          </div>
        );
      },
    },
    {
      title: 'CONTRACT STATUS',
      dataIndex: 'contract_status',
      key: 'contract_status',
      render: (text: any, record: any) => {
        return (
          <div className={'flex'}>
            <EContractStatus statusId={record?.contract?.status} />
          </div>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      render: (text: any, record: any) => (
        <div className='flex justify-end gap-[10px]'>
          <Link to={`/enterprise/programs/${program?.id}/vendors/${record.id}`}>
            <MAIcon size={24} name={'shop'} />
          </Link>

          <div
            className={`cursor-pointer`}
            aria-disabled={!record?.contract || !record?.contract?.vendor_signed_at}
            onClick={() => {
              if (!record?.contract || !record?.contract?.vendor_signed_at) {
                return;
              }

              console.log('DEBUG record', record);
              setClickedContractRow(record);
            }}
          >
            <MAIcon size={24} name={'contract'} />
          </div>

          {isCanEdit && (
            <div
              className={'cursor-pointer'}
              onClick={() => {
                setClickedRowId(record.id);
                setIsOpenModalConfirmDeleteOne(true);
              }}
            >
              <MAIcon size={24} name={'delete'} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={'flex flex-col gap-[1rem]'}>
      {/*Delete one program*/}
      <DeletionModal
        title={'Delete Vendor'}
        content={'Are you sure want to delete this vendor and related activities?'}
        isOpen={isOpenModalConfirmDeleteOne}
        onCancel={() => {
          setIsOpenModalConfirmDeleteOne(false);
        }}
        onOk={() => {
          setIsOpenModalConfirmDeleteOne(false);
          onDeleteVendors([clickedRowId]);
        }}
      />

      {/*Delete many*/}
      <DeletionModal
        title={'Delete Vendors'}
        content={'Are you sure want to delete selected vendors?'}
        isOpen={isOpenModalConfirmDeleteMany}
        onCancel={() => {
          setIsOpenModalConfirmDeleteMany(false);
        }}
        onOk={async () => {
          setIsOpenModalConfirmDeleteMany(false);
          onDeleteVendors(selectedRowKeys);
        }}
      />

      {!!clickedContractRow && (
        <ContractModal
          isSignable={clickedContractRow?.contract?.status === CONTRACT_STATUSES.APPROVED_BY_VENDOR.value}
          signedAt={clickedContractRow?.contract?.enterprise_signed_at}
          content={clickedContractRow?.contract?.content}
          isOpen={!!clickedContractRow}
          onDeny={() => {
            onDenyContract();
          }}
          onAgree={() => {
            onSignContract();
          }}
          onCancel={() => {
            setClickedContractRow(null);
          }}
        />
      )}

      <div className='searchBoxAndCtas flex items-center justify-between'>
        <div className='left titleLarge'>Vendors</div>
        <div className='right flex gap-[1rem]'>
          {isCanEdit && (
            <MAButton
              disabled={!hasSelectedRows}
              size={'small'}
              type={'text'}
              color={'2A2A2A'}
              onClick={async () => {
                setIsOpenModalConfirmDeleteMany(true);
              }}
            >
              <div className='flex gap-[7px] items-center justify-between'>
                <div className={'mt-[-2px]'}>
                  <MAIcon color={'2A2A2A'} size={14} name={'delete'} />
                </div>
                <div className={'text-2A2A2A'}>Delete</div>
              </div>
            </MAButton>
          )}
        </div>
      </div>
      <div className='tablePrograms'>
        <Table
          locale={{
            emptyText: <div className='flex justify-center flex-col items-center gap-[12px] py-[50px]'>{'No data found'}</div>,
          }}
          loading={isLoading}
          onChange={handleTableChange}
          rowSelection={isCanEdit ? rowSelection : undefined}
          pagination={{
            defaultPageSize: 10, // Set the initial page size
            showSizeChanger: true, // Show page size changer
            pageSizeOptions: CONFIG.pageSizeOptions, // Define available page sizes
          }}
          dataSource={vendors.map(vendor => ({...vendor, key: vendor.id}))}
          columns={columns}
        />
      </div>
    </div>
  );
}
