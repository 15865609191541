import React, {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Form, Input, message, Table} from 'antd';
import MAIcon from '../../../../ui/icon/MAIcon';
import MAButton from '../../../../ui/button/MAButton';
import {DeletionModal} from '../../../../ui/modals/DeletionModal';
import {handleApiError, PROGRAM_STATUSES_ENUM} from '../../../../../helpers/utils';
import {EMPLOYEE_STATUSES, MESSAGES} from '../../../../../config';
import {useEmployeeResendActivationEmailApi} from '../../../../../repositories/useEmployeeResendActivationEmailApi';
import {useProfileApi} from '../../../../../repositories/useProfileApi';

interface EnterpriseProgramsVendorsProps {
  employees: any[];
  program: any;
  onDeleteEmployees: (ids: any[]) => void;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  isLoading?: boolean;
  handleSearchSubmit: (values: any) => void;
  totalEmployees?: number;
}

export default function EnterpriseProgramsEmployees({
  isLoading,
  employees,
  program,
  onDeleteEmployees,
  handleTableChange,
  handleSearchSubmit,
  totalEmployees,
}: EnterpriseProgramsVendorsProps) {
  const [isOpenModalConfirmDeleteOne, setIsOpenModalConfirmDeleteOne] = useState(false);
  const [isOpenModalConfirmDeleteMany, setIsOpenModalConfirmDeleteMany] = useState(false);
  const [clickedRowId, setClickedRowId] = useState<any>(null);
  const [formFilter] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const {actions} = useEmployeeResendActivationEmailApi();

  const isCanEdit = useMemo(() => {
    return ![PROGRAM_STATUSES_ENUM.LIVE, PROGRAM_STATUSES_ENUM.COMPLETED, PROGRAM_STATUSES_ENUM.PAUSE].includes(program.status);
  }, [program]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelectedRows = selectedRowKeys.length > 0;

  function isDeactivateEmployee(employee: {status: number}) {
    return EMPLOYEE_STATUSES.DEACTIVATE.value === employee.status;
  }

  // Define the columns for your table
  const columns: any = [
    {
      title: 'NAME',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (text: any, record: any) => (
        <Link to={`/enterprise/programs/${program?.id}/employees/${record.id}?programId=${program?.id}`}>
          {record?.user_detail?.first_name} {record?.user_detail?.last_name}
        </Link>
      ),
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      render: (text: any, record: any) => {
        return record?.user?.email;
      },
    },
    {
      title: '',
      key: 'actions',
      render: (text: any, record: any) => (
        <div className='flex justify-end gap-[10px]'>
          <div className={'w-[24px]'}>
            <Link to={`/enterprise/programs/${program?.id}/employees/${record.id}?programId=${program?.id}`}>
              <MAIcon size={24} name={'eye'} />
            </Link>
          </div>
          <div className={'w-[24px]'}>
            {isCanEdit && !isDeactivateEmployee(record) && (
              <div
                className={'cursor-pointer'}
                onClick={() => {
                  setClickedRowId(record.id);
                  setIsOpenModalConfirmDeleteOne(true);
                }}
              >
                <MAIcon size={24} name={'delete'} />
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  const dropDownMenuItems = useMemo(() => {
    return [
      {
        key: '1',
        disabled: !hasSelectedRows,
        label: (
          <div
            onClick={async () => {
              try {
                await actions.createItem({ids: selectedRowKeys});
                message.success(MESSAGES.MSG19);
              } catch (e) {
                await handleApiError(e, MESSAGES.MSG15);
                console.error(e);
              }
            }}
          >
            Resend activation email
          </div>
        ),
      },
    ];
  }, [hasSelectedRows]);

  return (
    <div className={'flex flex-col gap-[1rem]'}>
      {/*Delete one program*/}
      <DeletionModal
        title={'Delete Employee'}
        content={'Are you sure you want to delete this employee and related activities?'}
        isOpen={isOpenModalConfirmDeleteOne}
        onCancel={() => {
          setIsOpenModalConfirmDeleteOne(false);
        }}
        onOk={() => {
          setIsOpenModalConfirmDeleteOne(false);
          onDeleteEmployees([clickedRowId]);
        }}
      />

      {/*Delete many*/}
      <DeletionModal
        title={'Delete Employees'}
        content={'Are you sure you want to delete selected employees and related activities?'}
        isOpen={isOpenModalConfirmDeleteMany}
        onCancel={() => {
          setIsOpenModalConfirmDeleteMany(false);
        }}
        onOk={async () => {
          setIsOpenModalConfirmDeleteMany(false);
          onDeleteEmployees(selectedRowKeys);
        }}
      />

      <div className={'titleLarge'}>Employees</div>

      <div className='searchBoxAndCtas flex items-center justify-between'>
        <div className='left'>
          <Form
            form={formFilter}
            onFinish={handleSearchSubmit}
            layout={'vertical'}
            initialValues={{
              status: null,
            }}
          >
            <div className='col w-[250px]'>
              <Form.Item className={'mb-0'} name='full_name' label={null}>
                <Input minLength={0} maxLength={50} placeholder='Search' prefix={<MAIcon name={'search'} />} />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className='right flex gap-[1rem]'>
          {isCanEdit && (
            <MAButton
              disabled={!hasSelectedRows}
              size={'small'}
              type={'text'}
              color={'2A2A2A'}
              onClick={async () => {
                setIsOpenModalConfirmDeleteMany(true);
              }}
            >
              <div className='flex gap-[7px] items-center justify-between'>
                <div className={'mt-[-2px]'}>
                  <MAIcon color={'2A2A2A'} size={14} name={'delete'} />
                </div>
                <div className={'text-2A2A2A bodyMedium'}>Delete</div>
              </div>
            </MAButton>
          )}

          <Dropdown placement='bottomRight' trigger={['click']} menu={{items: dropDownMenuItems}}>
            <div className={'flex items-center gap-[4px] cursor-pointer bodyMedium'}>
              <div>More options</div>
              <MAIcon name={'arrowDown'} />
            </div>
          </Dropdown>
        </div>
      </div>

      <div className='tablePrograms'>
        <Table
          locale={{
            emptyText: <div className='flex justify-center flex-col items-center gap-[12px] py-[50px]'>{'No data found'}</div>,
          }}
          loading={isLoading}
          onChange={handleTableChange}
          rowSelection={isCanEdit ? rowSelection : undefined}
          pagination={{
            total: totalEmployees,
            defaultPageSize: 10, // Set the initial page size
            showSizeChanger: true, // Show page size changer
            pageSizeOptions: ['10', '50', '100', '500', '1000'], // Define available page sizes
          }}
          dataSource={employees.map(vendor => ({...vendor, key: vendor.id}))}
          columns={columns}
        />
      </div>
    </div>
  );
}
