import React from 'react';

interface TabText {
  label: string;
  value: string | number;
}

interface MATabsText {
  tabs: TabText[];
  currentTab: string | number;
  onChange: (tab: string | number) => void;
  tabWidth?: string | number;
}

const MATabsText: React.FC<MATabsText> = ({tabs, tabWidth, currentTab, onChange}) => {
  return (
    <div>
      <div className='flex border-b-[1px] border-E2E2E2'>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`cursor-pointer py-[10px] bodyMedium px-[20px] mb-[-2px] 
            
            ${tabWidth ? `w-[${tabWidth}]` : ''} 
            ${currentTab === tab.value ? 'border-b-[3px] border-primary !font-semibold' : ''}`}
            onClick={() => {
              onChange && onChange(tab.value);
            }}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MATabsText;
