import React, {useEffect} from 'react';
import {EmployeeLayout} from '../../layouts/employee/EmployeeLayout';
import styles from './EmployeeHome.module.scss';
import sampleImage from '../../assets/images/sample-product-avatar.png';
import {useSurveyEmployeeApi} from '../../repositories/useSurveyEmployeeApi';
import {CONFIG} from '../../config';
import {message} from 'antd';
import {useProfileApi} from '../../repositories/useProfileApi';
import {useNavigate} from 'react-router-dom';
import {LOCAL_STORAGE_KEY} from '../../helpers/utils';

export default function EmployeeHome() {
  const {actions: actionProfile, data: dataProfile} = useProfileApi();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await actionProfile.getItems({});
    })();
  }, []);

  useEffect(() => {
    if (!dataProfile?.listItems?.detail) {
      // Wait until have data;
      return;
    }

    const isComeFromLogin = localStorage.getItem(LOCAL_STORAGE_KEY.COME_FROM_LOGIN);
    if (isComeFromLogin) {
      localStorage.removeItem(LOCAL_STORAGE_KEY.COME_FROM_LOGIN);

      if (dataProfile.listItems?.detail && !dataProfile.listItems?.detail?.is_saved_survey) {
        navigate('/employee/profile-builder');
      }
    }
  }, [dataProfile]);

  if (!dataProfile?.listItems?.detail) {
    return null;
  }

  return (
    <EmployeeLayout>
      <div className={styles.moduleWrapper}>
        <div className='heading flex items-start justify-center gap-[38px]'>
          <div className={'flex items-center justify-center text-center flex-col gap-[12px] cursor-pointer'}>
            <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M2.15625 16C2.15625 19.8042 3.66746 23.4526 6.35744 26.1426C9.04741 28.8325 12.6958 30.3438 16.5 30.3438C20.3042 30.3438 23.9526 28.8325 26.6426 26.1426C29.3325 23.4526 30.8437 19.8042 30.8438 16C30.8437 12.1958 29.3325 8.54741 26.6426 5.85744C23.9526 3.16746 20.3042 1.65625 16.5 1.65625C12.6958 1.65625 9.04741 3.16746 6.35744 5.85744C3.66746 8.54741 2.15625 12.1958 2.15625 16Z'
                stroke='#2A2A2A'
                stroke-width='1.4'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M17.4152 8.89811L19.3813 13.1349H23.2063C23.3992 13.1274 23.5897 13.1796 23.7518 13.2844C23.9139 13.3892 24.0397 13.5415 24.112 13.7205C24.1843 13.8995 24.1995 14.0965 24.1556 14.2845C24.1117 14.4724 24.0108 14.6423 23.8667 14.7708L20.5517 18.0373L22.3916 22.2627C22.4719 22.4561 22.4895 22.6699 22.4421 22.8739C22.3946 23.0779 22.2844 23.2619 22.127 23.4001C21.9696 23.5383 21.7728 23.6236 21.5643 23.6442C21.3559 23.6647 21.1462 23.6195 20.9648 23.5147L16.5189 21.0144L12.0679 23.5147C11.8865 23.6195 11.6768 23.6647 11.4684 23.6442C11.2599 23.6236 11.0632 23.5383 10.9057 23.4001C10.7483 23.2619 10.6381 23.0779 10.5906 22.8739C10.5432 22.6699 10.5609 22.4561 10.6412 22.2627L12.481 18.0399L9.16598 14.7733C9.02076 14.6458 8.91858 14.4764 8.87349 14.2885C8.82839 14.1006 8.84259 13.9033 8.91413 13.7237C8.98566 13.5442 9.11103 13.3912 9.27302 13.2858C9.435 13.1803 9.62565 13.1277 9.81878 13.1349H13.6438L15.6073 8.89811C15.6936 8.73299 15.8235 8.59466 15.9828 8.49814C16.1422 8.40162 16.3249 8.35059 16.5113 8.35059C16.6976 8.35059 16.8803 8.40162 17.0397 8.49814C17.199 8.59466 17.3289 8.73299 17.4152 8.89811Z'
                fill='#E4A45C'
                stroke='#2A2A2A'
                stroke-width='1.4'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>

            <div className={'labelSmall'}>For You</div>
            <hr className={'text-primary h-[2px] mx-auto w-[80px]'} />
          </div>

          {[1, 2, 3, 4, 5].map((item, index) => {
            return (
              <div
                onClick={() => {
                  alert('TODO handle change category');
                }}
                className={'flex items-center justify-center text-center flex-col gap-[12px] cursor-pointer'}
              >
                <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M17 18.5583C15.8005 17.5951 14.8229 16.388 14.1329 15.0183C13.4429 13.6486 13.0566 12.1481 13 10.6177C13.0379 9.23788 13.3502 7.87906 13.9191 6.61944C14.4879 5.35982 15.3021 4.22425 16.3147 3.27806C16.4979 3.09983 16.7441 3 17.0007 3C17.2572 3 17.5035 3.09983 17.6867 3.27806C18.6988 4.22455 19.5125 5.3602 20.0811 6.61977C20.6497 7.87935 20.962 9.23802 21 10.6177C20.9434 12.1481 20.5571 13.6486 19.8671 15.0183C19.1771 16.388 18.1995 17.5951 17 18.5583Z'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M21.0815 8.44597C22.5929 7.05653 24.3808 6.32012 26.2086 6.33418C26.4169 6.33455 26.6167 6.44631 26.7644 6.64506C26.912 6.84381 26.9955 7.11343 26.9966 7.39507C27.0279 8.87786 26.8423 10.3544 26.4505 11.7401C26.0588 13.1258 25.4686 14.3933 24.7137 15.4701C23.8194 16.5931 22.7575 17.4441 21.5994 17.9658C20.4413 18.4875 19.214 18.6677 18 18.4944'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M13.2756 8.46121C11.931 7.0498 10.3337 6.30712 8.70222 6.33473C8.51762 6.33472 8.34052 6.44621 8.20955 6.64487C8.07858 6.84353 8.00438 7.11323 8.00315 7.39512C7.97477 8.87764 8.13832 10.3541 8.48444 11.7399C8.83055 13.1257 9.35241 14.3935 10.0201 15.4706C10.8187 16.595 11.7652 17.4462 12.7964 17.9675C13.8276 18.4888 14.9198 18.6681 16 18.4936'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M25 19.6689C25 22.1239 21.4181 24.1142 17 24.1142C12.5819 24.1142 9 22.1286 9 19.6689'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M28.9161 15.2236C29.6108 16.3487 29.9879 17.6742 30 19.0338C30 24.2944 24.1795 28.5593 17 28.5593C9.82053 28.5593 4 24.2982 4 19.0376C4.01157 17.6783 4.38781 16.3529 5.0816 15.2274'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>

                <div className={'labelSmall'}>Lifestyle & Beauty</div>
                {/*<hr className={'text-primary h-[2px] mx-auto w-full min-w-[80px]'}/>*/}
              </div>
            );
          })}
        </div>

        <div className='products grid grid-cols-5 gap-x-[20px] gap-y-[40px]'>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
            return (
              <div
                className='product flex flex-col gap-[8px] w-[212px] justify-between cursor-pointer'
                onClick={() => {
                  alert('TODO redirect to detail product');
                }}
              >
                <div className='top flex flex-col gap-[8px] items-start'>
                  <div className={'rounded-[4px] relative w-[212px] h-[212px] overflow-hidden'}>
                    <img className={'absolute inset-0 w-full h-full object-cover'} src={sampleImage} alt={'Sample product'} />
                  </div>
                  <div className='title labelExtraLarge'>{index % 2 === 0 ? 'Bala Bangles' : 'Medium product placeholder name'}</div>
                </div>
                <div className='bottom flex flex-col gap-[8px]'>
                  <div className='tags flex items-center justify-between w-full'>
                    <div className='rounded-[10px] tag bodySmall bg-E4A45C px-[8px] py-[2px]'>Physical Wellness</div>
                    <div className='right flex items-center gap-[8px] justify-center'>
                      <div className='star flex items-center gap-[3px] justify-center'>
                        <svg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M5.72916 0.832355L7.32966 4.00323L10.4101 4.3084C10.4835 4.3145 10.5535 4.34148 10.612 4.38615C10.6705 4.43082 10.7149 4.49131 10.7401 4.56046C10.7652 4.62961 10.7701 4.70453 10.754 4.77633C10.7379 4.84814 10.7016 4.91383 10.6493 4.96563L8.11413 7.47832L9.05405 10.8927C9.07328 10.9651 9.07118 11.0415 9.048 11.1128C9.02482 11.184 8.98155 11.2471 8.92339 11.2943C8.86523 11.3416 8.79466 11.371 8.72017 11.3791C8.64568 11.3872 8.57044 11.3736 8.50349 11.3399L5.38045 9.79345L2.26172 11.338C2.19478 11.3717 2.11953 11.3853 2.04504 11.3772C1.97055 11.3691 1.89998 11.3396 1.84182 11.2924C1.78366 11.2452 1.74039 11.1821 1.71721 11.1109C1.69403 11.0396 1.69193 10.9632 1.71116 10.8907L2.65108 7.4764L0.114003 4.96372C0.0617369 4.91192 0.0254079 4.84623 0.00931767 4.77442C-0.00677257 4.70261 -0.00195016 4.6277 0.0232139 4.55855C0.048378 4.4894 0.0928298 4.42891 0.151307 4.38424C0.209783 4.33956 0.279836 4.31258 0.35317 4.30649L3.43364 4.00131L5.03175 0.832355C5.06462 0.768163 5.11456 0.714289 5.17609 0.676668C5.23762 0.639047 5.30834 0.619141 5.38045 0.619141C5.45257 0.619141 5.52329 0.639047 5.58482 0.676668C5.64634 0.714289 5.69629 0.768163 5.72916 0.832355Z'
                            fill='#2A2A2A'
                          />
                        </svg>
                        4.3
                      </div>
                      <div className='point bodySmall'>
                        243<span className={'text-929292'}>pt</span>
                      </div>
                    </div>
                  </div>
                  <div className='description text-5A5A5A'>Bala Bangles add a constant but comfortable resistance to your workout.</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </EmployeeLayout>
  );
}
