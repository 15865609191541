// src/mockData.ts
interface Thread {
  read: boolean;
  id: number;
  title: string;
  latestMessage: string; // Add latestMessage attribute
  latestMessageTime: string; // Add latestMessageTime attribute
  senderName: string; // Add senderName attribute
  senderAvatar: string; // Add senderAvatar attribute
}

interface Attachment {
  id: number;
  type: 'file' | 'image';
  url: string;
}

interface Message {
  id: number;
  text: string;
  senderName: string;
  senderAvatar: string;
  isMyMessage: boolean;
  attachments?: Attachment[];
}

interface MessagesMap {
  [threadId: number]: Message[];
}

const getRandomAvatarUrl = () => {
  // Generate a random number between 1 and 1000 for the placeholder image
  const randomAvatarNumber = Math.floor(Math.random() * 1000) + 1;
  return `https://via.placeholder.com/50?text=Avatar${randomAvatarNumber}`;
};

const mockThreads: Thread[] = [
  {
    id: 1,
    title: 'Thread 1',
    read: true,
    latestMessage: 'Hello from Thread 1!',
    latestMessageTime: '1 day ago',
    senderName: 'John Doe',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 2,
    title: 'Thread 2',
    read: true,
    latestMessage: 'Hi there!',
    latestMessageTime: '2 days ago',
    senderName: 'Jane Smith',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  {
    id: 3,
    title: 'Thread 3',
    read: false,
    latestMessage: 'Thread 3 messages go here.',
    latestMessageTime: '3 days ago',
    senderName: 'James Brown',
    senderAvatar: getRandomAvatarUrl(),
  },
  // Add additional threads...
];

const mockMessages: MessagesMap = {
  1: [
    {
      id: 1,
      text: 'Hello from Thread 1!',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
      attachments: [
        {
          id: 1,
          type: 'image',
          url: 'https://via.placeholder.com/150?text=Image1',
        },
        {
          id: 2,
          type: 'image',
          url: 'https://via.placeholder.com/150?text=Image2',
        },
        {
          id: 3,
          type: 'file',
          url: 'https://example.com/files/document.pdf',
        },
      ],
    },
    {
      id: 2,
      text: 'How are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
      attachments: [
        {
          id: 1,
          type: 'image',
          url: 'https://via.placeholder.com/150?text=Image1',
        },
        {
          id: 2,
          type: 'image',
          url: 'https://via.placeholder.com/150?text=Image2',
        },
        {
          id: 3,
          type: 'file',
          url: 'https://example.com/files/document.pdf',
        },
      ],
    },
    {
      id: 3,
      text: "I'm good, thanks!",
      senderName: 'You',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: true,
      attachments: [
        {
          id: 1,
          type: 'image',
          url: 'https://via.placeholder.com/150?text=Image1',
        },
        {
          id: 2,
          type: 'image',
          url: 'https://via.placeholder.com/150?text=Image2',
        },
        {
          id: 3,
          type: 'file',
          url: 'https://example.com/files/document.pdf',
        },
      ],
    },
    {
      id: 2,
      text: 'How are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
    {
      id: 2,
      text: 'How are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
    {
      id: 2,
      text: 'How are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
    {
      id: 2,
      text: 'How are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
    {
      id: 2,
      text: 'How are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
    {
      id: 2,
      text: 'How are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
    {
      id: 2,
      text: 'How are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
    {
      id: 2,
      text: 'How are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
    {
      id: 2,
      text: 'How are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are youHow are you?',
      senderName: 'John Doe',
      senderAvatar: getRandomAvatarUrl(),
      isMyMessage: false,
    },
  ],
  // Add messages for other threads...
};

export {mockThreads, mockMessages};
export type {Thread, Message, Attachment};
