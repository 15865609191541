import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Row, Select, Spin} from 'antd';
import styles from '../../../../../pages/enterprise/programs/start/EnterpriseProgramStart.module.scss';
import {customizeRequiredMark} from '../../../../shared/RequireMark';
import {BudgetFormItemProps, Category, DatePickerFormItemProps, Step2Props} from '../../../../../types';
import {fetchCategories, fetchConfigData, fetchLocations} from '../../../../../services/apiService';
import {createBudgetValidator, createDateValidator} from '../../../../../helpers/utils';
import MADatepicker from '../../../../ui/datepicker/MADatepicker';
import dayjs from 'dayjs';
import {MESSAGES} from '../../../../../config';

const {Option} = Select;
export default function Step2({formRef, initialValues}: Step2Props) {
  const [categories, setCategories] = useState<any>([]);
  const [regionLocation, setRegionLocation] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [service, setService] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fillInitValue = (initCategories: any) => {
    if (!initialValues || !formRef.current) {
      return;
    }
    const cloneInitValue = {...initialValues};
    if (initialValues.subCategory && !initialValues.service) {
      const serviceId = findServiceFromSubcategory(initialValues.subCategory, initCategories);
      cloneInitValue.service = serviceId;
    }

    if (cloneInitValue.service) {
      const subCategory = initCategories?.find((item: any) => item.id === cloneInitValue.service)?.children;
      const convertedSubCategory = convertSubCategories(subCategory);
      setSubCategory(convertedSubCategory);
    }

    const values = {
      ...cloneInitValue,
      startDate: cloneInitValue.startDate ? dayjs(cloneInitValue.startDate) : null,
      endDate: cloneInitValue.endDate ? dayjs(cloneInitValue.endDate) : null,
    };

    formRef.current.setFieldsValue(values);
  };

  const organizeData = (data: Category[], parentId: string | null = null): Category[] => {
    return data
      .filter(item => item.parent_id === parentId)
      .map(item => ({
        ...item,
        children: organizeData(data, item.id),
      }));
  };

  const convertSubCategories = (subCategories: any[]) => {
    return (
      subCategories?.map((item: any) => ({
        label: item.name,
        value: item.id,
      })) ?? []
    );
  };

  const convertToOption = (data: any[]) => {
    const listService = data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
    return listService;
  };

  const findServiceFromSubcategory = (subCategory: any, initCategories?: any[]) => {
    const service = initCategories?.find((item: any) => item.children?.find((child: any) => child.id === subCategory))?.id;
    return service;
  };

  useEffect(() => {
    const fetchApis = async () => {
      try {
        setLoading(true);
        const [locations, categories, configData] = await Promise.all([fetchLocations(), fetchCategories(), fetchConfigData()]);
        const organizedCategories = organizeData(categories);
        setCategories(organizedCategories);
        setService(convertToOption(organizedCategories));
        setRegionLocation(locations);
        setServiceType(configData);
        if (initialValues) {
          fillInitValue(organizedCategories);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('bb error', error);
      }
    };

    if (formRef.current) {
      fetchApis();
    }
  }, [initialValues, formRef]);

  const handleChangeService = (value: any) => {
    const subCategory = categories?.find((item: any) => item.id === value)?.children;
    const convertedSubCategory = convertSubCategories(subCategory);
    setSubCategory(convertedSubCategory);
  };
  const resetErrorField = (listFields: string[]) => {
    listFields.forEach(field => {
      formRef.current.setFields([{name: field, errors: []}]);
    });
  };
  const BudgetFormItem: React.FC<BudgetFormItemProps> = ({label, name, minName, maxName, message, maxLength, resetErrorFields}) => (
    <Col span={11}>
      <Form.Item
        label={label}
        name={name}
        normalize={value => value.replace(/\D/g, '')}
        rules={[
          {required: true, message: MESSAGES.MSG18},
          createBudgetValidator({firstFieldName: minName, secondFieldName: maxName, operator: name === minName ? 'smaller' : 'greater', message}),
        ]}
      >
        <Input
          onChange={() => {
            resetErrorField(resetErrorFields);
          }}
          maxLength={maxLength}
          size='large'
          className='w-[100%]'
        />
      </Form.Item>
    </Col>
  );
  const DatePickerFormItem: React.FC<DatePickerFormItemProps> = ({
    label,
    name,
    startDateFieldName,
    endDateFieldName,
    operator,
    message,
    resetErrorFields,
  }) => {
    return (
      <Col span={11}>
        <Form.Item
          label={label}
          name={name}
          rules={[{required: true, message: MESSAGES.MSG18}, createDateValidator({startDateFieldName, endDateFieldName, operator, message})]}
        >
          <MADatepicker
            disabledDate={current => current && current.isBefore(dayjs().startOf('day'))}
            onChange={() => {
              resetErrorField(resetErrorFields);
            }}
            size={'large'}
            className='w-[100%]'
          />
        </Form.Item>
      </Col>
    );
  };
  return (
    <div className={`${styles.moduleWrapper} w-full flex  h-full flex-col overflow-auto`}>
      <Spin spinning={loading}>
        <Form
          ref={formRef}
          onValuesChange={changedValues => {
            if ('service' in changedValues) {
              formRef.current.setFieldsValue({subCategory: undefined});
            }
          }}
          layout='vertical'
          requiredMark={customizeRequiredMark}
        >
          <Col span={18} offset={3}>
            <Row justify={'space-between'}>
              <Col span={11}>
                <Form.Item label='Service' name='service' rules={[{required: true, message: MESSAGES.MSG18}]}>
                  <Select onChange={handleChangeService} options={service} size='large' placeholder='Select option'></Select>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label='Sub-category' name='subCategory' rules={[{required: true, message: MESSAGES.MSG18}]}>
                  <Select options={subCategory} size='large' placeholder='Select option'></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className='mt-[15px]' justify={'space-between'}>
              <Col span={11}>
                <Form.Item label='Service type' name='serviceType' rules={[{required: true, message: MESSAGES.MSG18}]}>
                  <Select options={serviceType} size='large' placeholder='Select option'></Select>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label='Physical location required' name='physicalLocation' rules={[{required: true, message: MESSAGES.MSG18}]}>
                  <Select size='large' placeholder='Select option'>
                    <Option value={1}>Yes</Option>
                    <Option value={0}>No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row className='mt-[15px]' justify={'space-between'}>
              <Col span={11}>
                <Form.Item label='Region/ Location' name='regionLocation' rules={[{required: true, message: MESSAGES.MSG18}]}>
                  <Select options={regionLocation} size='large' placeholder='Select option'></Select>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  normalize={value => value.replace(/\D/g, '')}
                  label='Number of employees'
                  name='numberOfEmployee'
                  rules={[{required: true, message: MESSAGES.MSG18}]}
                >
                  <Input size='large' maxLength={5} className='w-[100%]' />
                </Form.Item>
              </Col>
            </Row>
            <Row className='mt-[15px]' justify={'space-between'}>
              <BudgetFormItem
                label='Total budget (HKD) min'
                name='totalBudgetMin'
                minName='totalBudgetMin'
                maxName='totalBudgetMax'
                message='Min budget should be smaller than max budget'
                maxLength={15}
                resetErrorFields={['totalBudgetMin', 'totalBudgetMax']}
              />
              <BudgetFormItem
                label='Total budget (HKD) max'
                name='totalBudgetMax'
                minName='totalBudgetMin'
                maxName='totalBudgetMax'
                message='Max budget should be greater than Min budget'
                maxLength={15}
                resetErrorFields={['totalBudgetMin', 'totalBudgetMax']}
              />
            </Row>
            <Row className='mt-[15px]' justify={'space-between'}>
              <BudgetFormItem
                label='Budget per employee (HKD) min'
                name='budgetPerEmployeeMin'
                minName='budgetPerEmployeeMin'
                maxName='budgetPerEmployeeMax'
                message='Min budget should be smaller than max budget'
                maxLength={15}
                resetErrorFields={['budgetPerEmployeeMin', 'budgetPerEmployeeMax']}
              />
              <BudgetFormItem
                label='Budget per employee (HKD) max'
                name='budgetPerEmployeeMax'
                minName='budgetPerEmployeeMin'
                maxName='budgetPerEmployeeMax'
                message='Max budget should be greater than Min budget'
                maxLength={15}
                resetErrorFields={['budgetPerEmployeeMin', 'budgetPerEmployeeMax']}
              />
            </Row>
            <Row className='mt-[15px]' justify={'space-between'}>
              <DatePickerFormItem
                label='Start date'
                name='startDate'
                startDateFieldName='startDate'
                endDateFieldName='endDate'
                operator='before'
                message={MESSAGES.TODO_START_DATE}
                resetErrorFields={['startDate', 'endDate']}
              />
              <DatePickerFormItem
                label='End date'
                name='endDate'
                startDateFieldName='startDate'
                endDateFieldName='endDate'
                operator='after'
                message={MESSAGES.TODO_END_DATE}
                resetErrorFields={['startDate', 'endDate']}
              />
            </Row>
          </Col>
        </Form>
      </Spin>
    </div>
  );
}
