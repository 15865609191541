import React, {Fragment, useMemo, useState} from 'react';
import ProgramPlanEditorModal from './ProgramPlanEditorModal';
import {Link} from 'react-router-dom';
import {PROGRAM_TYPES_ENUM} from '../../../../../helpers/utils';
import {PROGRAM_STATUSES_MAPPING} from '../../../../../config';

interface EnterpriseProgramsOverviewProps {
  program?: any;
  products?: any[];
  vendors?: any[];
  employees?: any[];
  onChangeImplementation: (updatedValues: any) => void;
}

export default function EnterpriseProgramsOverview({employees, program, products, vendors, onChangeImplementation}: EnterpriseProgramsOverviewProps) {
  const [isOpenEditPlanModal, setIsOpenEditPlanModal] = useState(false);

  const isViewOnly = useMemo(() => {
    return [PROGRAM_STATUSES_MAPPING.LIVE.value, PROGRAM_STATUSES_MAPPING.PAUSE.value, PROGRAM_STATUSES_MAPPING.COMPLETED.value].includes(
      program?.status
    );
  }, [program]);

  const isShowPointPerEmployee = useMemo(() => {
    // Always show if is cherry-pick
    if (program?.type === PROGRAM_TYPES_ENUM.CHERRY_PICK) {
      return true;
    }

    // Hide if is tailor-made and status is pending contract or ready to launch
    if ([PROGRAM_STATUSES_MAPPING.PENDING_CONTRACT.value, PROGRAM_STATUSES_MAPPING.READY_TO_LAUNCH.value].includes(program?.status)) {
      return false;
    }
  }, [program]);

  const buttonText = useMemo(() => {
    if (isViewOnly) {
      return 'View';
    }

    return 'Manage';
  }, [isViewOnly]);

  return (
    <div className='mainCtas flex flex-col gap-[12px]'>
      {isOpenEditPlanModal && (
        <ProgramPlanEditorModal
          isShowPointPerEmployee={isShowPointPerEmployee}
          isViewOnly={isViewOnly}
          isOpen={isOpenEditPlanModal}
          currentPointsPerEmployee={program?.points_per_employee}
          currentStartDate={program?.start_date}
          currentEndDate={program?.end_date}
          onCancel={() => {
            setIsOpenEditPlanModal(false);
          }}
          onSave={updatedValues => {
            console.log('DEBUG updatedValues', updatedValues);
            // console.log('DEBUG startDate, endDate', startDate, endDate);
            onChangeImplementation(updatedValues);
            setIsOpenEditPlanModal(false);
          }}
        />
      )}

      {program?.type !== PROGRAM_TYPES_ENUM.CHERRY_PICK && (
        <Fragment>
          <div className='cta py-[20px] px-[24px] flex items-center bg-white border border-E2E2E2 rounded-[4px] gap-[14px]'>
            {/*<div className='first w-[24px]'>*/}
            {/*  <MAIcon size={24} name={'verified'} />*/}
            {/*</div>*/}
            <div className='second w-auto flex-1'>
              <div className='flex gap-[4px] flex-col'>
                <div className={'bodyExtraLarge'}>Activities</div>
                <div className={'bodySmall'}>{products?.length} activities added into your programme</div>
              </div>
            </div>
            <div className='third w-[200px] text-right bodyMedium underline cursor-pointer'>
              <Link to={`/enterprise/programs/${program?.id}/products`}>{buttonText} activities</Link>
            </div>
          </div>
          <div className='cta py-[20px] px-[24px] flex items-center bg-white border border-E2E2E2 rounded-[4px] gap-[14px]'>
            {/*<div className='first w-[24px]'>*/}
            {/*  <MAIcon size={24} name={'verified'} />*/}
            {/*</div>*/}
            <div className='second w-auto flex-1'>
              <div className='flex gap-[4px] flex-col'>
                <div className={'bodyExtraLarge'}>Vendors</div>
                <div className={'bodySmall'}>{vendors?.length} vendors added</div>
              </div>
            </div>
            <div className='third w-[200px] text-right bodyMedium underline cursor-pointer'>
              <Link to={`/enterprise/programs/${program?.id}/vendors`}>{buttonText} vendors</Link>
            </div>
          </div>
        </Fragment>
      )}

      <div className='cta py-[20px] px-[24px] flex items-center bg-white border border-E2E2E2 rounded-[4px] gap-[14px]'>
        {/*<div className='first w-[24px]'>*/}
        {/*  <MAIcon size={24} name={'verified'} />*/}
        {/*</div>*/}
        <div className='second w-auto flex-1'>
          <div className='flex gap-[4px] flex-col'>
            <div className={'bodyExtraLarge'}>Employees</div>
            <div className={'bodySmall'}>{employees?.length} employees</div>
          </div>
        </div>
        <div className='third w-[200px] text-right bodyMedium underline cursor-pointer'>
          <Link to={`/enterprise/programs/${program?.id}/employees`}>{buttonText} employees</Link>
        </div>
      </div>
      <div className='cta py-[20px] px-[24px] flex items-center bg-white border border-E2E2E2 rounded-[4px] gap-[14px]'>
        {/*<div className='first w-[24px]'>*/}
        {/*  <MAIcon size={24} name={'verified'} />*/}
        {/*</div>*/}
        <div className='second w-auto flex-1'>
          <div className='flex gap-[4px] flex-col'>
            <div className={'bodyExtraLarge'}>Implementation Plan</div>
            <div className={'bodySmall'}>
              {/*From {apiValueToFrontendDateFormat(program?.start_date)} to {apiValueToFrontendDateFormat(program?.end_date)}*/}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setIsOpenEditPlanModal(true);
          }}
          className='third w-[200px] text-right bodyMedium underline cursor-pointer'
        >
          {buttonText} Plan
        </div>
      </div>
    </div>
  );
}
