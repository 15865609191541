import React, {useContext, useEffect} from 'react';
import {Checkbox, Form, Input, message} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {CurrentUserContext} from '../../contexts/CurrentUserContext';
import {getCurrentDeviceId, handleApiError, isMySubDomain, LOCAL_STORAGE_KEY, Regex, validatePasswordRules} from '../../helpers/utils';
import {AuthLayout} from '../../layouts/shared/AuthLayout';
import MAButton from '../../components/ui/button/MAButton';
import {useLoginApi} from '../../repositories/useLoginApi';
import {MESSAGES} from '../../config';

interface LoginValues {
  email: string;
  password: string;
  remember_me: boolean;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {setCurrentUser} = useContext(CurrentUserContext);
  const {actions, state} = useLoginApi();

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.USER);
    localStorage.removeItem(LOCAL_STORAGE_KEY.USER_DETAIL);
  }, []);

  const onFinish = async (values: LoginValues) => {
    try {
      const deviceId = getCurrentDeviceId();
      const response = await actions.createItem({
        email: values.email,
        password: values.password,
        remember_me: values.remember_me,
        device_id: deviceId,
      });

      const role = response?.user?.roles[0].split('.')[1];
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, response?.access_token);
      localStorage.setItem(LOCAL_STORAGE_KEY.USER, JSON.stringify(response?.user));
      localStorage.setItem(LOCAL_STORAGE_KEY.USER_DETAIL, JSON.stringify(response?.user_detail));
      setCurrentUser(response?.user);

      localStorage.setItem(LOCAL_STORAGE_KEY.COME_FROM_LOGIN, '1');
      if (role) {
        message.success('Login successfully');
        if (role === 'super-admin') {
          setTimeout(() => navigate('/admin/home'));
        } else {
          setTimeout(() => navigate(`/${role.toLowerCase()}/home`));
        }
      }
    } catch (error: any) {
      console.log('error', error);
      await handleApiError(error, 'Email or password is valid');
    }
  };
  return (
    <AuthLayout>
      <Form requiredMark={false} layout={'vertical'} name='login' onFinish={onFinish} form={form}>
        <h1 className={'mb-[40px] displaySmall'}>Login</h1>
        <Form.Item
          className={'labelSmall mb-[20px]'}
          label={'Email'}
          normalize={value => value.trim()}
          name='email'
          rules={[
            {required: true, message: MESSAGES.MSG18},
            {pattern: Regex.email, message: MESSAGES.MSG11},
          ]}
        >
          <Input maxLength={50} className={'grayInput'} />
        </Form.Item>
        <Form.Item
          className={'labelSmall mb-[20px]'}
          label={'Password'}
          name='password'
          rules={[{required: true, message: MESSAGES.MSG18}, {min: 8, message: MESSAGES.MSG7}, {validator: validatePasswordRules}]}
        >
          <Input.Password maxLength={20} className={'grayInput'} />
        </Form.Item>

        {/*<Form.Item>*/}
        {/*  <Form.Item name='remember_me' valuePropName='checked' noStyle>*/}
        {/*    <Checkbox className={'bodyMedium'}>Keep me logged in</Checkbox>*/}
        {/*  </Form.Item>*/}
        {/*</Form.Item>*/}

        <Form.Item className={'mb-[40px]'}>
          <MAButton disabled={state?.isLoading} className={'!rounded-[20px] !bg-293734 !py-[10px] !px-[45px] bodyExtraLarge'}>
            Log in
          </MAButton>
        </Form.Item>
        <div className='ctas flex flex-row items-center justify-start gap-[20px] titleSmall font-[600]'>
          <Link className={'hover:underline text-primary'} to='/auth/forgot-password'>
            Forgot password?
          </Link>
          <Link
            className={'hover:underline text-primary'}
            to={`${process.env.REACT_APP_PUBLIC_SITE}?showPopup=signup` || `https://staging-maestro.xyz?showPopup=signup`}
            target={'_blank'}
          >
            Create an account
          </Link>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default Login;
