import useRestApi from '../hooks/useRestApi';

interface SurveyEmployeeApi {
  actions: {
    updateItem: (id: string | number | undefined, values: Record<string, any>) => Promise<any>;
    customUpdate: (path: string, values: Record<string, any>) => Promise<any>;
    getItems: (params?: object) => Promise<any>;
    getItem: (id: string | number | undefined) => Promise<any>;
    deleteItem: (id: string | number | undefined) => Promise<any>;
    createItem: (values: Record<string, any>) => Promise<any>;
  };
  data: {
    detail: any;
    listItems: any[];
    listPagination: object;
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useSurveyEmployeeApi(): SurveyEmployeeApi {
  return useRestApi('surveys/employee');
}
