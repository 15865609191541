export const CONFIG = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'https://dev-api.server.moha.team',
  apiDateFormat: 'YYYY-MM-DD',
  frontendDateFormat: 'DD/MM/YYYY',
  signDateFormat: 'hh:mm DD/MM/YYYY',
  timelineDateFormat: 'dddd, MMMM D LT',
  defaultPageSize: 10,
  defaultMaxFileSizeMb: 5,
  pageSizeOptions: [10, 20, 50, 100],
};

export const VENDOR_PROGRAM_BUILDER_STEPS = 7;
export const EMPLOYEE_PROGRAM_BUILDER_STEPS = 7;
export const MIN_YEAR_ISSUE = 2000;
// MATCH_MADE = 0;
// VENDOR_ACCEPTED_INVITATION = 1;
// VENDOR_SIGNED_E_CONTRACT = 2;
// EDITED_ACTIVITY = 3;
// NEW_CONTRACT_GENERATED_AND_SHARE_WITH_VENDOR = 4;

export const DELETABLE_STATUS = {
  DELETE_ONE: 0,
  DELETE_READY_TO_LANCH: 1,
  DELETE_LIVE: 2,
};

export const GENDERS = {
  MALE: {
    key: 'MALE',
    label: 'Male',
    value: 1,
  },
  FEMALE: {
    key: 'FEMALE',
    label: 'Female',
    value: 2,
  },
  PREFER_NOT_TO_RESPOND: {
    key: 'PREFER_NOT_TO_RESPOND',
    label: 'Prefer not to respond',
    value: 0,
  },
};

export const ACTIVITY_HISTORY_STATUSES = {
  MATCH_MADE: {
    key: 'MATCH_MADE',
    label: 'Match made',
    value: 0,
  },
  VENDOR_REJECTED_CONTRACT: {
    key: 'VENDOR_REJECTED_CONTRACT',
    label: 'Vendor rejected e-contract',
    value: 1,
  },
  VENDOR_SIGNED_CONTRACT: {
    key: 'VENDOR_SIGNED_CONTRACT',
    label: 'Vendor signed e-contract',
    value: 2,
  },
  ENTERPRISE_REJECT_CONTRACT: {
    key: 'ENTERPRISE_REJECT_CONTRACT',
    label: 'Enterprise rejected e-contract',
    value: 3,
  },
  ENTERPRISE_SIGNED_CONTRACT: {
    key: 'ENTERPRISE_SIGNED_CONTRACT',
    label: 'Enterprise signed e-contract',
    value: 4,
  },
  REMOVED_VENDOR: {
    key: 'VENDOR_REJECTED_CONTRACT',
    label: 'Enterprise removed vendor',
    value: 5,
  },
  EDITED_ACTIVITY: {
    key: 'EDITED_ACTIVITY',
    label: 'Enterprise edited activity',
    value: 6,
  },
  EDITED_EMPLOYEE: {
    key: 'EDITED_EMPLOYEE',
    label: 'Enterprise edited employee',
    value: 7,
  },
  REGENERATED_CONTRACT: {
    key: 'REGENERATED_CONTRACT',
    label: 'New contract was generated and shared with vendor',
    value: 8,
  },
  EDIT_PLAN: {
    key: 'EDIT_PLAN',
    label: 'Enterprise edited plan',
    value: 9,
  },
};

export const CAN_SIGN_CONTRACT_ACTIVITY_TYPES = [
  // ACTIVITY_HISTORY_STATUSES.MATCH_MADE.value,
  ACTIVITY_HISTORY_STATUSES.VENDOR_SIGNED_CONTRACT.value,
  // ACTIVITY_HISTORY_STATUSES.VENDOR_REJECTED_CONTRACT.value,
  // ACTIVITY_HISTORY_STATUSES.ENTERPRISE_SIGNED_CONTRACT.value,
  // ACTIVITY_HISTORY_STATUSES.ENTERPRISE_REJECT_CONTRACT.value,
  // ACTIVITY_HISTORY_STATUSES.REGENERATED_CONTRACT.value,
];

export const CAN_VIEW_CONTRACT_ACTIVITY_TYPES = [
  ACTIVITY_HISTORY_STATUSES.VENDOR_SIGNED_CONTRACT.value,
  ACTIVITY_HISTORY_STATUSES.VENDOR_REJECTED_CONTRACT.value,
  ACTIVITY_HISTORY_STATUSES.ENTERPRISE_SIGNED_CONTRACT.value,
  ACTIVITY_HISTORY_STATUSES.ENTERPRISE_REJECT_CONTRACT.value,
  ACTIVITY_HISTORY_STATUSES.REGENERATED_CONTRACT.value,
];

export const VENDOR_PAYMENT_MODE_MAPPING = {
  MONTHLY: {
    key: 'MONTHLY',
    label: 'Monthly',
    value: 'MONTHLY',
  },
  YEARLY: {
    key: 'YEARLY',
    label: 'Yearly',
    value: 'YEARLY',
    discount: 20,
  },
};

export const CURRENCY_MAPPING = {
  AUD: {
    key: 'AUD',
    label: 'AUD $',
    value: 0.2,
  },
  GBP: {
    key: 'GBP',
    label: 'GBP $',
    value: 0.1,
  },
  HKD: {
    key: 'HKD',
    label: 'HKD $',
    value: 1,
  },
  USD: {
    key: 'USD',
    label: 'USD $',
    value: 0.13,
  },
};

export const USER_ROLES_MAPPING = {
  VENDOR_ADMIN: {
    key: 'VENDOR_ADMIN',
    label: 'Vendor',
    value: 'role.vendor',
  },
  ENTERPRISE_ADMIN: {
    key: 'ENTERPRISE_ADMIN',
    label: 'Enterprise',
    value: 'role.enterprise',
  },
  ENTERPRISE_EMPLOYEE: {
    key: 'ENTERPRISE_EMPLOYEE',
    label: 'Employee',
    value: 'role.employee',
  },
};

export const PROGRAM_STATUSES_MAPPING = {
  DRAFT: {key: 'DRAFT', label: 'Draft', value: 0, color: 'EFE1B2'},
  PENDING_CONTRACT: {key: 'PENDING_CONTRACT', label: 'Pending E-contract', value: 1, color: 'F0D549'},
  // WAITING_REVIEW: {key: 'WAITING_REVIEW', label: 'Waiting Review', value: 2, color: 'FFD688'},
  READY_TO_LAUNCH: {key: 'READY_TO_LAUNCH', label: 'Ready to Launch', value: 3, color: 'FFD688'},
  LIVE: {key: 'LIVE', label: 'Live', value: 4, color: '7FD99A'},
  COMPLETED: {key: 'COMPLETED', label: 'Completed', value: 5, color: 'E2E2E2'},
  PAUSE: {key: 'PAUSE', label: 'Paused', value: 6, color: 'E2E2E2'},
};

export const CAN_DELETE_PROGRAM_STATUSES = [
  PROGRAM_STATUSES_MAPPING.DRAFT.value,
  PROGRAM_STATUSES_MAPPING.PENDING_CONTRACT.value,
  PROGRAM_STATUSES_MAPPING.READY_TO_LAUNCH.value,
];

export const PROGRAM_TYPES_MAPPING = {
  CHERRY_PICK: {key: 'CHERRY_PICK', label: 'Cherry Pick', value: 'cherry_pick'},
  TAILOR_MADE: {key: 'TAILOR_MADE', label: 'Tailor Made', value: 'tailor_made'},
};

export const CONTRACT_STATUSES = {
  PENDING_CONTRACT: {key: 'PENDING_CONTRACT', label: 'Pending E-contract', value: 1, color: 'EFE1B2'},
  APPROVED_BY_VENDOR: {key: 'APPROVED_BY_VENDOR', label: 'Approved by vendor', value: 2, color: 'F0D549'},
  REJECTED_BY_VENDOR: {key: 'REJECTED_BY_VENDOR', label: 'Rejected by vendor', value: 3, color: 'FFB2AB'},
  REJECTED_BY_ENTERPRISE: {key: 'REJECTED_BY_ENTERPRISE', label: 'Rejected by enterprise', value: 4, color: '7FD99A'},
  CONTRACT_SIGNED: {key: 'CONTRACT_SIGNED', label: 'Signed contract', value: 5, color: '7FD99A'},
  CANCELLED: {key: 'CANCELLED', label: 'Cancelled', value: 6, color: 'E2E2E2'},
};

// TODO check with backend
export const EMPLOYEE_DEPARTMENTS = [
  {key: 'HR', label: 'HR', value: 0, color: 'EFE1B2'},
  {key: 'Developer', label: 'Developer', value: 1, color: 'F0D549'},
];

export const EMPLOYEE_STATUSES = {
  INACTIVE: {label: 'Inactive', value: 0, color: 'EFE1B2'},
  ACTIVE: {label: 'Active', value: 1, color: '7FD99A'},
  DEACTIVATE: {label: 'Deactivate', value: 2, color: 'E2E2E2'},
  DELETED: {label: 'Deleted', value: 3, color: 'E2E2E2'},
};
export const MESSAGES = {
  TODO_END_DATE: 'End date must be after start date',
  TODO_START_DATE: 'Start date must be before end date',
  MSG3: 'This email does not exist.',
  MSG4: 'These credentials are incorrect.',
  MSG5: 'Confirmation password must match.',
  MSG6: 'Invalid password.',
  MSG7: 'Password must be at least 8 characters.',
  MSG8: 'This email exists.',
  MSG9: 'Invalid file format. Please upload jpg, png or pdf only.',
  MSG10: 'File size exceeds maximum file size. Please upload a smaller file.',
  MSG11: 'Invalid email. Please enter a valid email address.',
  MSG12: 'This field must be numeric.',
  MSG13: 'You was logged out. Please login again.',
  MSG14: 'Invalid file format. Please upload CSV only.',
  MSG15: 'Resend email failed. Some selected employees are already active',
  MSG16: 'Updated employee successfully.',
  MSG17: 'File upload failed. Please correct and try again.',
  MSG18: 'This field is required.',
  MSG19: 'Resend activation email successfully.	',
  MSG20: 'Invalid phone number. Please enter a valid phone number.',
  MSG21: 'No activity added. Please add activity to the cart.',
  MSG22: 'No employee imported. Please upload a CSV file to add employees.',
  MSG23: 'No record found. Please upload other CSV file to add employees.',
};
