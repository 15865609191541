import {useEffect} from 'react';
import {AdminLayout} from '../../layouts/admin/AdminLayout';
import MAButton from '../../components/ui/button/MAButton';

export default function AdminHome() {
  useEffect(() => {}, []);
  return (
    <AdminLayout>
      <center>
        <h2 className={'mt-[100px]'}>Admin maestro home</h2>

        <MAButton
          onClick={() => {
            alert('CLick at Admin');
          }}
        >
          See me?
        </MAButton>
      </center>
    </AdminLayout>
  );
}
