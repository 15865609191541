import React, {useContext, useEffect} from 'react';
import styles from './EnterpriseLayout.module.scss';
import {SidebarContentLayout} from '../shared/SidebarContentLayout';
import {ColorsContext} from '../../contexts/ColorsContext';
import {AiOutlineLineChart} from 'react-icons/ai';
import {TbUsersGroup} from 'react-icons/tb';
import {PiFolderStarThin} from 'react-icons/pi';
import {GoHome} from 'react-icons/go';

const links = [
  {
    to: '/enterprise/home',
    title: 'Home',
    icon: <GoHome size={25} />,
  },
  {
    to: '/enterprise/programs',
    title: 'Programs',
    icon: <PiFolderStarThin size={25} />,
  },
  {
    to: '/enterprise/employees',
    title: 'Employees',
    icon: <TbUsersGroup size={25} />,
  },
  {
    to: '/enterprise/reports',
    title: 'Reports',
    icon: <AiOutlineLineChart size={25} />,
  },
  // {
  //   to: '/enterprise/messages',
  //   title: 'Messages',
  //   icon: <CiUser size={25} />,
  // },
];

export function EnterpriseLayout(props: any) {
  const {setColors} = useContext(ColorsContext);
  useEffect(() => {
    setColors({
      primary: '008080',
      secondary: 'E6F7F6',
    });
  }, [setColors]);

  return (
    <div className={styles.moduleWrapper}>
      <SidebarContentLayout
        customContentClasses={props.customContentClasses}
        bgColor={props.bgColor}
        title={'Maestro Enterprise'}
        target={'enterprise'}
        links={links}
      >
        {props.children}
      </SidebarContentLayout>
    </div>
  );
}
