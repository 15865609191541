import {useState} from 'react';
import {VendorLayout} from '../../layouts/vendor/VendorLayout';
import MAButton from '../../components/ui/button/MAButton';
import MAIcon from '../../components/ui/icon/MAIcon';
import MATabsText from '../../components/ui/tabs/MATabsText';
import {useNavigate} from 'react-router-dom';
import {getCurrentUserDetailFromStorage} from '../../helpers/utils';

import HereToHelp1 from '../../assets/images/here-to-help-1.webp';
import HereToHelp2 from '../../assets/images/here-to-help-2.webp';

export default function VendorHome() {
  const [currentTab, setCurrentTab] = useState<'upcoming' | 'completed' | 'cancelled'>('upcoming');
  const [completedPercent, setCompletedPercent] = useState(0);
  const navigate = useNavigate();
  const handleCompleteProfile = () => {
    navigate('/vendor/profile-builder');
  };
  const handleChangeTab = (value: any) => {
    console.log('DEBUG value', value);
    setCurrentTab(value);
  };

  const currentUserDetail = getCurrentUserDetailFromStorage();
  return (
    <VendorLayout>
      <div className='flex flex-col gap-y-[40px]'>
        <div className='title flex justify-between'>
          <span className='displaySmall'>
            Welcome {currentUserDetail?.first_name} {currentUserDetail.last_name}!
          </span>
          <MAButton onClick={handleCompleteProfile}>
            <span className='labelMedium'>Complete profile</span>
          </MAButton>
        </div>
        {/* progress */}
        <div className='flex flex-col gap-[12px]'>
          <div className='flex flex-col gap-y-[4px]'>
            <span className='labelExtraLarge'>Setup Guide</span>
            <span className='bodyMedium text-5A5A5A'>Complete all the steps to get your business set up and running on Maestro Wellness. </span>
          </div>
          <div className='progressStarted'>
            <div className='flex gap-x-[4px] items-center'>
              <span className='bodyLarge'>{completedPercent}%</span>
              <span className='bodySmall text-5A5A5A'>Getting started</span>
            </div>
            <div className='h-[4px] w-full relative'>
              <div className={`innerProgress bg-primary h-full absolute `} style={{width: `${completedPercent}%`}}></div>
              <div className='progress bg-D9D9D9 h-[4px] w-full'></div>
            </div>
          </div>
          <div className='grid grid-flow-col grid-cols-3 gap-[24px]'>
            <div className='col-span-1 gap-y-[12px] flex flex-col py-[16px] px-[20px] bg-white rounded hover:shadow-[0px_0px_0px_2px_#7E38B7] hover:border-primary border-[1px] border-E2E2E2 cursor-pointer'>
              <div className='flex gap-y-[12px]'>
                <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect x='6' y='1' width='15' height='3' fill='#DDD7F5' />
                  <rect x='1' y='19' width='26' height='4' fill='#DDD7F5' />
                  <path d='M9.41602 26.75H18.0827' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path d='M13.75 23.2832V26.7499' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path d='M0.75 18.9502H26.75' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path
                    d='M25.0167 3.34961C25.2443 3.34961 25.4697 3.39444 25.68 3.48155C25.8903 3.56866 26.0814 3.69634 26.2423 3.85729C26.4033 4.01825 26.5309 4.20933 26.618 4.41962C26.7052 4.62992 26.75 4.85532 26.75 5.08294V21.5496C26.75 22.0093 26.5674 22.4502 26.2423 22.7753C25.9172 23.1003 25.4764 23.2829 25.0167 23.2829H2.48333C2.02362 23.2829 1.58274 23.1003 1.25768 22.7753C0.932618 22.4502 0.75 22.0093 0.75 21.5496V5.08294C0.75 4.85532 0.794834 4.62992 0.881942 4.41962C0.96905 4.20933 1.09673 4.01825 1.25768 3.85729C1.58274 3.53223 2.02362 3.34961 2.48333 3.34961'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M5.94922 15.4833V2.48333C5.94922 2.02362 6.13184 1.58274 6.4569 1.25768C6.78196 0.932618 7.22284 0.75 7.68255 0.75H19.8159C20.2756 0.75 20.7165 0.932618 21.0415 1.25768C21.3666 1.58274 21.5492 2.02362 21.5492 2.48333V15.4833'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M9.41602 7.68359H12.8827V11.1503H9.41602V7.68359Z'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path d='M5.94922 4.2168H21.5492' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path d='M16.3496 7.68359H18.0829' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path d='M16.3496 10.2832H18.0829' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path d='M9.41602 14.6162H18.0827' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                </svg>
              </div>
              <div className='flex flex-col gap-y-[4px]'>
                <span className='labelExtraLarge'>Complete you business profile</span>
                <span className='bodySmall'>Add details about your company so we can match you with the right type of customers. </span>
              </div>
            </div>

            <div
              onClick={handleCompleteProfile}
              className='col-span-1 gap-y-[12px] flex flex-col py-[16px] px-[20px] bg-white rounded hover:shadow-[0px_0px_0px_2px_#7E38B7] hover:border-primary border-[1px] border-E2E2E2 cursor-pointer'
            >
              <div className='flex gap-y-[12px]'>
                <svg width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M16.1777 10.0921C16.1774 10.1878 16.1515 10.2817 16.1026 10.3641C16.0538 10.4465 15.9838 10.5143 15.9 10.5605C15.815 10.6073 15.7193 10.6312 15.6223 10.63C15.5252 10.6287 15.4302 10.6023 15.3465 10.5533L12.334 9.15625L9.29281 10.6333C9.20894 10.676 9.1156 10.6969 9.0215 10.6938C8.92741 10.6908 8.83562 10.6639 8.75468 10.6158C8.67445 10.5687 8.60787 10.5015 8.5615 10.4208C8.51513 10.3401 8.49057 10.2487 8.49023 10.1556V1.46875H16.1777V10.0921Z'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M2.34023 1.46875H22.3277C22.3277 1.46875 23.8652 1.46875 23.8652 3.00625V22.9938C23.8652 22.9938 23.8652 24.5312 22.3277 24.5312H2.34023C2.34023 24.5312 0.802734 24.5313 0.802734 22.9938V3.00625C0.802734 3.00625 0.802734 1.46875 2.34023 1.46875Z'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M13.1035 17.6123H20.791V21.4561H13.1035V17.6123Z'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <div className='flex flex-col gap-y-[4px]'>
                <span className='labelExtraLarge'>Add your products & services</span>
                <span className='bodySmall'>Write a description, add photos and set pricing for the products or services you plan to offer.</span>
              </div>
            </div>

            <div className='col-span-1 gap-y-[12px] flex flex-col py-[16px] px-[20px] bg-white rounded hover:shadow-[0px_0px_0px_2px_#7E38B7] hover:border-primary border-[1px] border-E2E2E2 cursor-pointer'>
              <div className='flex gap-y-[12px]'>
                <svg width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M19.4618 20.9434H5.87033C5.76001 20.9431 5.65104 20.9191 5.55083 20.8729C5.45061 20.8268 5.3615 20.7596 5.28955 20.676C5.21759 20.5924 5.16447 20.4943 5.13381 20.3883C5.10315 20.2823 5.09565 20.171 5.11183 20.0619L7.28483 5.56836H18.0473L20.2203 20.0619C20.2365 20.171 20.229 20.2823 20.1983 20.3883C20.1677 20.4943 20.1146 20.5924 20.0426 20.676C19.9707 20.7596 19.8815 20.8268 19.7813 20.8729C19.6811 20.9191 19.5722 20.9431 19.4618 20.9434Z'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path d='M4.97852 24.5312H20.3535' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path
                    d='M14.2031 10.1808H12.1531C11.831 10.1766 11.5176 10.2858 11.2678 10.4892C11.018 10.6927 10.8477 10.9775 10.7866 11.2938C10.7256 11.6102 10.7776 11.9379 10.9337 12.2197C11.0899 12.5015 11.3401 12.7195 11.6406 12.8355L13.7521 13.676C14.0527 13.792 14.3029 14.01 14.4591 14.2918C14.6152 14.5737 14.6672 14.9014 14.6062 15.2177C14.5451 15.5341 14.3748 15.8189 14.125 16.0223C13.8752 16.2258 13.5618 16.335 13.2396 16.3308H11.1896'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path d='M12.666 10.1811V8.64355' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path d='M12.666 17.8686V16.3311' stroke='#2A2A2A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
                  <path
                    d='M3.18477 9.15625H1.90352C1.70046 9.1536 1.50646 9.07175 1.36286 8.92815C1.21927 8.78455 1.13742 8.59056 1.13477 8.3875V2.2375C1.13742 2.03444 1.21927 1.84044 1.36286 1.69685C1.50646 1.55325 1.70046 1.4714 1.90352 1.46875H23.4285C23.6316 1.4714 23.8256 1.55325 23.9692 1.69685C24.1128 1.84044 24.1946 2.03444 24.1973 2.2375V8.3875C24.1946 8.59056 24.1128 8.78455 23.9692 8.92815C23.8256 9.07175 23.6316 9.1536 23.4285 9.15625H22.1473'
                    stroke='#2A2A2A'
                    stroke-width='1.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <div className='flex flex-col gap-y-[4px]'>
                <span className='labelExtraLarge'>Set up your payout</span>
                <span className='bodySmall'>Receive and manage your payments by adding your bank details.</span>
              </div>
            </div>
          </div>
        </div>
        {/* orders */}
        <div>
          <p className='titleLarge'>Orders</p>
          {/* <div className='mt-[24px] mb-[40px]'>
          </div> */}
          <MATabsText
            currentTab={currentTab || 'upcoming'}
            onChange={tab => handleChangeTab(tab)}
            tabs={[
              {label: 'Upcoming', value: 'upcoming'},
              {label: 'Completed', value: 'completed'},
              {label: 'Cancelled', value: 'cancelled'},
            ]}
          />
          <div className='mt-[12px] bg-white min-h-[200px] h-[200px] border rounded border-E2E2E2'>
            {currentTab === 'upcoming' && (
              <div className='w-full h-full'>
                <div className='flex flex-col gap-[12px] w-full h-full items-center justify-center'>
                  <span className='titleMedium '>You have no upcoming orders</span>
                  <span className='text-706E66'>Complete & publish your profile to see your orders come through</span>
                  <MAButton>
                    <span>Complete profile</span>
                  </MAButton>
                </div>
              </div>
            )}
            {currentTab === 'completed' && <div className={'p-[20px]'}>Completed </div>}
            {currentTab === 'cancelled' && <div className={'p-[20px]'}>Cancelled </div>}
          </div>
        </div>
        {/* Here to Help */}
        <div className='flex-col flex gap-[12px]'>
          <p className='titleLarge'>Here to Help</p>
          <div className='grid grid-flow-col grid-cols-3 gap-[24px]'>
            <div className='col-span-1 flex flex-col bg-white rounded border-E2E2E2 cursor-pointer'>
              <div className='aspect-15/6'>
                <img className='w-full aspect-15/6 rounded-t' src={HereToHelp1} alt='Here to help 1'></img>
              </div>
              <div className='flex flex-col gap-y-[4px] py-[16px] px-[20px]'>
                <span className='labelExtraLarge'>Contact specialise support</span>
                <span className='bodySmall text-706E66 line-clamp-3'>
                  As a new vendor, you get one-tap access to a specially trained support team.
                </span>
              </div>
            </div>

            <div className='col-span-1 flex flex-col bg-white rounded border-E2E2E2 cursor-pointer'>
              <div className='aspect-15/6'>
                <img className='w-full aspect-15/6 rounded-t' src={HereToHelp2} alt='Here to help 2'></img>
              </div>
              <div className='flex flex-col gap-y-[4px] py-[16px] px-[20px]'>
                <span className='labelExtraLarge'>Make your profile stand out</span>
                <span className='bodySmall text-706E66 line-clamp-3'>
                  Check our playbook to see top tips from other vendors on making the most of your profile.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VendorLayout>
  );
}
