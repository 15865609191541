import {Avatar} from 'antd';
import moment from 'moment';

import StarsReview from '../../../../shared/StarsReview';
import {useState} from 'react';

export default function VendorReview({review}: any) {
  const [isShowMore, setIsShowMore] = useState(review.content.length >= 200);
  return (
    <section className='reviewArea'>
      <div className='flex mb-[20px] items-center gap-[24px]'>
        <Avatar src={review.enterprise.photo} size={80} />
        <div className='flex flex-col gap-y-[8px] h-full'>
          <span className='labelExtraLarge font-medium text-2A2A2A'>{review.enterprise.name}</span>
          <div className='flex items-center gap-x-[8px]'>
            <StarsReview scoreNum={Number(review.rating)} />
            <div className='w-[4px] h-[4px] bg-black rounded-sm'></div>
            <span className='labelSmall'>{moment(review.created_at).format('MMMM YYYY')}</span>
          </div>
          {/*<span className='text-9D9D9D labelMedium !font-normal line-clamp-1'>{reviewInfo.blabla}</span>*/}
        </div>
      </div>
      <p className={`text-2A2A2A bodyMedium`}>
        {isShowMore ? review.content.substring(0, 200) : review.content + '  '}
        {isShowMore && review.content.length >= 200 && ' ...  '}
        {review.content.length >= 200 && (
          <span onClick={() => setIsShowMore(!isShowMore)} className='cursor-pointer underline'>
            {isShowMore ? 'Show more' : 'Show less'}
          </span>
        )}
      </p>
    </section>
  );
}
