import {Card, Col, Form, Input, Row, Select} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {FormFileUploader} from '../../components/shared/file-uploader/FormImageUploader';
import React from 'react';

export const CertificationFormItem = ({name, index, remove, monthSelectOptions, yearSelectOptions, form}: any) => {
  const certifications = Form.useWatch('certifications', form);
  return (
    <Card
      size='small'
      title={` Certification ${certifications?.[index]?.qualificationName || index + 1}`}
      key={index}
      extra={index > 0 && <CloseOutlined disabled={index == 0} onClick={() => remove(name)} />}
    >
      {/* Your form fields or components for each item */}
      <Form.Item
        name={[name, 'qualificationName']}
        label={<span className='bodySmall !font-semibold'>Name of Qualification/Award</span>}
        className='bodySmall'
        rules={[{required: true, message: 'Please enter Qualification'}]}
      >
        <Input
          className='bg-F5F5F5 rounded py-[12px] px-[10px] !border-none focus:!border-0 !outline-none focus:!shadow-none'
          placeholder='Name of Qualification'
        />
      </Form.Item>
      <Form.Item
        name={[name, 'issuer']}
        label={<span className='bodySmall !font-semibold'>Issuer</span>}
        rules={[{required: true, message: 'Please enter Issuer'}]}
      >
        <Input className='bg-F5F5F5 rounded py-[12px] px-[10px] !border-none focus:!border-0 !outline-none focus:!shadow-none' placeholder='Issuer' />
      </Form.Item>
      <Row gutter={[20, 0]}>
        <Col span={12} className='gutter-row'>
          <Form.Item
            name={[name, 'issueMonth']}
            label={<span className='bodySmall !font-semibold'>Issue Month</span>}
            rules={[{required: true, message: 'Please enter Issue Month'}]}
          >
            <Select placeholder='Select an option' className='' options={monthSelectOptions} />
          </Form.Item>
        </Col>
        <Col span={12} className='gutter-row'>
          <Form.Item
            name={[name, 'issuerYear']}
            label={<span className='bodySmall !font-semibold'>Issue Year</span>}
            rules={[{required: true, message: 'Please enter Issue Year'}]}
          >
            <Select
              placeholder='Select an option'
              options={yearSelectOptions}
              className='bg-F5F5F5 rounded !border-none focus:!border-0 outline-none focus:!shadow-none'
            />
          </Form.Item>
        </Col>
      </Row>
      <div className='w-full bg-F5F5F5 px-[10px] py-[8px] rounded'>
        <Form.Item
          name={[name, 'fileId']}
          label={<span className='bodySmall !font-semibold'>Upload Business Registration</span>}
          rules={[{required: true, message: 'Please upload your Business Registration'}]}
        >
          <FormFileUploader
            {...{
              accept: '.pdf, .doc, .docx',
            }}
          />
        </Form.Item>
      </div>
    </Card>
  );
};
