import {Modal} from 'antd';
import {useState} from 'react';
import MAIcon from '../../../../ui/icon/MAIcon';
import OutLineButton from '../../../../shared/OutLineButton';
import importUrl from '../../../../../assets/csv/employees_template.csv';
import Dragger from 'antd/es/upload/Dragger';
import {LoadingOutlined} from '@ant-design/icons';
import {Row, Upload, Spin, message} from 'antd';
import type {UploadProps} from 'antd';
import {CONFIG, MESSAGES} from '../../../../../config';
import {uploadFile, validateFile} from '../../../../../services/apiService';
import MAButton from '../../../../ui/button/MAButton';

interface BulkImportModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onOk?: () => void;
  title?: string;
  content?: any;
  textCancel?: string;
  textOk?: string;
  onSave: (data: any) => void;
}

export function BulkImportModal({isOpen, onCancel, onSave}: BulkImportModalProps) {
  const [fileList, setFileList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [dataUpload, setDataUpload] = useState<any>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const [error, setError] = useState(false);

  const antIcon = <LoadingOutlined style={{fontSize: 24, color: `var(--primary)`}} spin />;

  const renderItem = (file: any, loading: boolean | null, dataUpload: any, error: boolean) => {
    return (
      <div
        onClick={() => {
          if (file?.url) {
            window.open(file.url);
          } else if (dataUpload?.download_url) {
            window.open(dataUpload.download_url);
          }
        }}
        className={`border-2 border-primary rounded-lg p-[12px] mt-[8px] flex flex-row justify-between cursor-pointer items-center`}
      >
        <div>{file.name}</div>
        {loading === false && dataUpload && <MAIcon color='primary' name='checkCircle' size={20} />}
        {file.status === 'done' && file.isLocal && <MAIcon color='primary' name='checkCircle' size={20} />}
        {loading && <Spin indicator={antIcon} />}
        {loading === false && error && <MAIcon color='red' name='warning' size={20} />}
      </div>
    );
  };
  const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = importUrl;
    link.download = 'employees_template.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const props: UploadProps = {
    name: 'media',
    multiple: false,
    accept: '.csv',
    fileList: fileList,
    itemRender: (_originNode, file) => renderItem(file, loading, dataUpload, error),
    beforeUpload: file => {
      const isLt5M = file.size / 1024 / 1024 < CONFIG.defaultMaxFileSizeMb;
      setError(false);
      if (!isLt5M) {
        message.error(MESSAGES.MSG10);
        return Upload.LIST_IGNORE;
      }

      setFileList([file]);
      return false;
    },
    onChange() {
      setDataUpload(null);
    },
  };
  const handleRemove = () => {
    setUploadedFiles([]);
    setFileList([]);
  };

  const uploadToServer = async () => {
    try {
      // Check if a file with the same name, size, and last modified date already exists in the uploadedFiles
      const isDuplicate = uploadedFiles.some(
        existingFile =>
          existingFile.name === fileList[0].name && existingFile.size === fileList[0].size && existingFile.lastModified === fileList[0].lastModified
      );

      if (isDuplicate) {
        message.error('This file has already been uploaded.');
        setLoading(false);
        return;
      }

      setLoading(true);

      console.log('bb upload');

      const resUpload = await uploadFile(fileList[0]);
      const checkFileServer = await validateFile(resUpload.id);

      if (checkFileServer?.error) {
        setError(true);
        setLoading(false);
        message.error(checkFileServer.message);
        return;
      }
      setUploadedFiles([]);
      setFileList([]);
      onSave({employee_file_id: resUpload.id, total: checkFileServer?.result?.total_records});
    } catch (error: any) {
      setLoading(false);
      message.error(error.response?.data?.message || MESSAGES.MSG17);
      setError(true);
      setUploadedFiles([]);
    } finally {
      setLoading(false);
    }
  };
  const isFileTypeValid = fileList[0] && fileList[0].type === 'text/csv';
  return (
    <Modal
      width={418}
      title={<div className={'titleLarge'}>{'Add by bulk'}</div>}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      okText={'Close'}
      okButtonProps={{className: 'bg-primary  !hover:text-DE1135'}}
      cancelText={'Cancel'}
    >
      <div className='flex justify-start  flex-col'>
        <p className={'text-5A5A5A bodyMedium '}>You can invite multiple employees in one go by uploading a CSV.</p>
        <br />
        <p className={'text-5A5A5A bodyMedium '}>Download the template and fill in required fields.</p>
        <p className={'text-5A5A5A bodyMedium '}>Upload the file. </p>
        <br />
        <p className={'text-5A5A5A bodyMedium '}>If your employee has already joined your program, their email will not be processed. </p>
        <br />
        <div>
          <OutLineButton onClick={downloadTemplate}>Download Template</OutLineButton>
        </div>
        <br />
        <div>
          <Dragger {...props}>
            <div className='flex flex-row justify-center  mb-[15px]'>
              <MAIcon name='upload' size={40} />
            </div>
            <p className='ant-upload-text'>
              Drag and drop or <span className='text-primary underline'>browser</span> your files
            </p>
          </Dragger>
        </div>
        <Row className='mt-[15px]' justify={'end'}>
          <MAButton
            customTextColor='black'
            color='black'
            type='text'
            onClick={() => {
              handleRemove();
              onCancel();
            }}
          >
            Cancel
          </MAButton>
          <MAButton disabled={!isFileTypeValid || !!loading} className='ml-[12px]' onClick={uploadToServer}>
            Add
          </MAButton>
        </Row>
      </div>
    </Modal>
  );
}
