// src/services/api.ts

import {MESSAGES} from '../config';
import {cleanObjectDeep} from '../helpers/utils';
import {getAxios} from './api';

export const fetchCategories = async () => {
  const response = await getAxios().get('/api/categories?limit=0');
  return response?.data?.result;
};

export const fetchVendors = async (params: any) => {
  const cleanedData = cleanObjectDeep(params);

  const response = await getAxios().get('/api/vendors', {params: {}});
  return response?.data?.result?.data;
};

export const fetchProductsOfVendor = async (vendorId: string) => {
  try {
    const response = await getAxios().get(`/api/products`, {params: {vendor_id: vendorId, limit: 0}});
    return response?.data?.result;
  } catch (error) {
    console.error('Failed to fetch products:', error);
  }
};
export const fetchLocations = async () => {
  const response = await getAxios().get('/api/locations?order_by=name');
  return response?.data?.result?.data.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
};
export const fetchConfigData = async () => {
  const response = await getAxios().get('/api/config-data?key=service_types');
  return response?.data?.result[0]?.value?.map((item: any) => ({
    label: item.value,
    value: item.key,
  }));
};
export const logoutDevice = async (ids: string[]) => {
  const response = await getAxios().post('/api/logout-devices', {
    device_ids: ids || [],
  });
  return response?.data?.result;
};
export const changePassword = async (data: any) => {
  const response = await getAxios().post('/api/change-password', {
    password: data.password,
    new_password: data.new_password,
  });
  return response?.data?.result;
};

export const uploadFile = async (file: File, onUploadProgress?: (progress: number) => void) => {
  const formData = new FormData();
  formData.append('media', file);

  try {
    const response = await getAxios().post('/api/upload-media', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 0;
        if (onUploadProgress) {
          onUploadProgress(percentCompleted); // Call the callback with the upload progress
        }
      },
    });
    return response?.data?.result;
  } catch (error) {
    console.error('Failed to upload file:', error);
  }
};

export const createProgram = async (data: any) => {
  const cleanedData = cleanObjectDeep(data);
  const response = await getAxios().post('/api/programs', cleanedData);
  return response?.data?.result;
};
export const validateFile = async (fileId: any) => {
  const response = await getAxios().post('/api/upload-media/check-csv-employees', {
    file_id: fileId,
  });
  if (response?.data?.result.total_records === 0) {
    return {
      error: true,
      message: MESSAGES.MSG23,
    };
  }
  return response?.data;
};

export const editProgram = async (data: any, id: any) => {
  const cleanedData = cleanObjectDeep(data);
  const response = await getAxios().post(`/api/programs/${id}`, cleanedData);
  return response?.data?.result;
};
export const addActivities = async (data: any, id: any) => {
  const response = await getAxios().post(`/api/programs/${id}/products`, {
    ids: data,
  });
  return response?.data?.result;
};

export const deleteActivities = async (data: any, id: any) => {
  const response = await getAxios().delete(`/api/programs/${id}/products`, {
    data: {
      ids: [data],
    },
  });
  return response?.data?.result;
};

export const getPrograms = async (programId: any) => {
  const response = await getAxios().get(`/api/programs/${programId}`);
  return response?.data?.result;
};
export const getFileDetail = async (fileId: any) => {
  const response = await getAxios().get(`/api/media/${fileId}`);
  return response?.data?.result;
};
