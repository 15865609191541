import {Form, Input, Modal, Select} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {FormFileUploader} from '../../components/shared/file-uploader/FormImageUploader';
import MAButton from '../../components/ui/button/MAButton';
import EmbedLayout from '../../EmbedLayout';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import MADatepicker from '../../components/ui/datepicker/MADatepicker';
import {CONFIG, MESSAGES, VENDOR_PAYMENT_MODE_MAPPING} from '../../config';
import {handleApiError, Regex, sortByProperty} from '../../helpers/utils';
import {useVendorRegisterApi} from '../../repositories/useVendorRegisterApi';
import {useCategoryApi} from '../../repositories/useCategoryApi';
import React, {useEffect, useMemo} from 'react';
import {usePlanApi} from '../../repositories/usePlanApi';

dayjs.extend(customParseFormat);

export default function VendorSignUp() {
  const [registerForm] = useForm<any>();
  const {actions, state} = useVendorRegisterApi();
  const {actions: actionsCategory, data: dataCategory, state: stateCategory} = useCategoryApi();
  const {actions: actionsPlan, data: dataPlan, state: statePlan} = usePlanApi();
  const [paymentMode, setPaymentMode] = React.useState<any>(VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value);
  const [selectedPlan, setSelectedPlan] = React.useState<any>(null);
  const [openPlanModal, setOpenPlanModal] = React.useState<any>(false);

  useEffect(() => {
    actionsCategory.getItems({
      limit: 0,
    });
    actionsPlan.getItems({
      limit: 0,
    });
  }, []);

  async function handleSubmit() {
    try {
      await registerForm.validateFields();
      setOpenPlanModal(true);
    } catch (e) {
      console.error(e);
    }
  }

  const categories = useMemo(() => {
    if (!dataCategory?.listItems) {
      return [];
    }

    return [
      ...dataCategory.listItems
        .filter((item: any) => item.parent_id === null)
        .map((item: any) => ({
          value: item.id,
          label: item.name,
        })),
      {
        value: 'other',
        label: 'Others',
      },
    ];
  }, [dataCategory.listItems]);

  async function handleProcess() {
    const values = registerForm.getFieldsValue();
    try {
      if (values.category_id === 'other') {
        delete values.category_id;
      }

      if (values.marketing_via === 'other') {
        values.marketing_via = values.marketing_via_other;
      }

      if (values.marketing_via_other) {
        delete values.marketing_via_other;
      }

      await actions.createItem({
        ...values,
        incorporation_date: dayjs(values.incorporation_date).format(CONFIG.apiDateFormat),
        business_registration_file_id: values.business_registration_file?.id,
      });

      registerForm.resetFields();
      setOpenPlanModal(false);
      registerForm.resetFields();

      window.elementorProFrontend.modules.popup.showPopup({id: process.env.REACT_APP_WORDPRESS_POPUP_VENDOR_THANK_YOU});
    } catch (error) {
      await handleApiError(error, 'Error while registering vendor');
      console.error('ERROR: ', error);
    }
  }

  return (
    <EmbedLayout
      colors={{
        primary: '7E38B7',
        secondary: 'F6EAFF',
      }}
    >
      <div className='flex justify-center'>
        <Modal
          width={1072}
          title={null}
          open={openPlanModal}
          onCancel={() => {
            setOpenPlanModal(false);
          }}
          footer={null}
          okText={'Delete'}
          cancelButtonProps={{className: 'border-none hover:text-black'}}
          okButtonProps={{className: '!bg-FFDDDA text-DE1135 !hover:text-DE1135'}}
          cancelText={'Cancel'}
        >
          <div className={'text-center flex items-center justify-center flex-col gap-[24px] py-[20px]'}>
            <div className={'displaySmall'}>Select a plan</div>
            <div className={'border border-[#E2E2E2] rounded-[100px] flex flex-row items-center justify-center'}>
              <div
                onClick={() => {
                  setPaymentMode(VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value);
                }}
                className={`bodyMedium cursor-pointer px-[24px] py-[10px] rounded-[100px] ${
                  paymentMode === VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value ? 'bg-7E38B7 text-white' : ''
                }`}
              >
                Pay monthly
              </div>
              <div
                onClick={() => {
                  setPaymentMode(VENDOR_PAYMENT_MODE_MAPPING.YEARLY.value);
                }}
                className={`bodyMedium cursor-pointer px-[24px] py-[10px] rounded-[100px] ${
                  paymentMode === VENDOR_PAYMENT_MODE_MAPPING.YEARLY.value ? 'bg-7E38B7 text-white' : ''
                }`}
              >
                Pay yearly (save {VENDOR_PAYMENT_MODE_MAPPING.YEARLY.discount}%)
              </div>
            </div>
            <div className={'w-full overflow-y-hidden overflow-x-auto'}>
              <div className={'flex flex-row gap-[20px] min-w-full w-[1024px] p-[2px]'}>
                {sortByProperty(dataPlan?.listItems, 'price_monthly').map((plan: any) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedPlan(plan.id);
                      }}
                      className={`
                        cursor-pointer border border-E2E2E2 w-[328px] rounded-[8px] text-left py-[25px] px-[30px] 
                        ${selectedPlan === plan.id ? '!border-[transparent] shadow-[0px_0px_0px_2px_#7E38B7]' : ''}
                      `}
                    >
                      <div className={'text-7E38B7 titleExtraLarge mb-[8px]'}>{plan.title}</div>
                      <div className={'flex items-center justify-start gap-[8px] mb-[20px]'}>
                        <div className='left displayMedium'>
                          ${Number(paymentMode === VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value ? plan.price_monthly : plan.price_yearly).toFixed(0)}
                        </div>
                        <div className='right labelMedium'>
                          HKD
                          <br />/{paymentMode === VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value ? 'MO' : 'YR'}
                        </div>
                      </div>
                      <hr className={'text-E2E2E2 mb-[24px]'} />
                      <div className={'labelMedium mb-[16px]'}>
                        <span className={'font-semibold'}>{plan.leads_unlocked}</span> Leads unlocked
                      </div>
                      <div className={'labelMedium mb-[24px]'}>
                        <span className={'font-semibold'}>{plan.transaction_fee}%</span> Transaction fee
                      </div>
                      {plan.features.map((feature: any, index: number) => {
                        return (
                          <div key={index} className={'flex items-start justify-start gap-[8px] bodyMedium'}>
                            <div className={'mt-[4px]'}>
                              <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M13 1.19995L5.8 10.8L1 5.99995'
                                  stroke='#7E38B7'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </div>
                            <div>{feature}</div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>

            <MAButton
              onClick={handleProcess}
              disabled={!selectedPlan || state.isLoading}
              className={'!bg-7E38B7 !rounded-[100px] !py-[10px] px-[45px] text-white'}
            >
              Process
            </MAButton>
          </div>
        </Modal>

        <div className='w-[680px]'>
          <Form onFinish={handleSubmit} form={registerForm} size={'large'} name='validateOnly' layout='vertical' autoComplete='off'>
            <div className='flex flex-col gap-y-[40px]'>
              <div className='flex flex-col gap-y-[30px]'>
                <span className='titleLarge text-2A2A2A'>Business Info</span>
                <Form.Item
                  hasFeedback={false}
                  name={'company_name'}
                  label={<span className='bodySmall !font-semibold'>Registered Company Name</span>}
                  className='bodySmall mb-0'
                  rules={[{required: true, message: MESSAGES.MSG18}]}
                >
                  <Input placeholder='Registered Company Name' maxLength={50} />
                </Form.Item>
                <Form.Item
                  name={'trading_name'}
                  label={<span className='bodySmall !font-semibold'>Trading Name, if different to Registered Name </span>}
                  className='bodySmall mb-0'
                >
                  <Input placeholder='Trading Name' maxLength={50} />
                </Form.Item>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-[24px] gap-x-[20px]'>
                  <Form.Item
                    name={'incorporation_number'}
                    label={<span className='bodySmall !font-semibold'>Incorporation Number</span>}
                    className='bodySmall mb-0'
                    rules={[{required: true, message: MESSAGES.MSG18}]}
                  >
                    <Input className={'w-full'} maxLength={20} placeholder='123456789' />
                  </Form.Item>
                  <Form.Item
                    name={'incorporation_date'}
                    label={<span className='bodySmall !font-semibold'>Incorporation Date</span>}
                    className='bodySmall mb-0'
                    rules={[{required: true, message: MESSAGES.MSG18}]}
                  >
                    <MADatepicker disabledDate={d => !d || d.isAfter(new Date())} className={'w-full'} placeholder={CONFIG.frontendDateFormat} />
                  </Form.Item>
                </div>
                <Form.Item
                  className='bodySmall mb-0'
                  name={'business_registration_file'}
                  label={
                    <div>
                      <span className='bodySmall !font-semibold'>Upload Business Registration</span>
                      <br />
                      <span className={'bodySmall'}>Maximum file size: 5mb. Supported formats: jpg, png, pdf.</span>
                    </div>
                  }
                  rules={[{required: true, message: MESSAGES.MSG18}]}
                >
                  <FormFileUploader
                    {...{
                      multiple: false,
                      maxFileSize: 10,
                      accept: '.pdf,.jpg,.png,.jpeg',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={'website'}
                  label={<span className='bodySmall !font-semibold'>Website</span>}
                  className='bodySmall mb-0'
                  rules={[{required: true, message: MESSAGES.MSG18}]}
                >
                  <Input placeholder='www.sample.com' maxLength={200} />
                </Form.Item>
                <Form.Item
                  name={'category_id'}
                  label={<span className='bodySmall !font-semibold'>Category</span>}
                  className='bodySmall mb-0'
                  rules={[{required: true, message: MESSAGES.MSG18}]}
                >
                  <Select placeholder='Select an option' optionFilterProp='children' options={categories} />
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.category_id !== currentValues.category_id}>
                  {({getFieldValue}) =>
                    getFieldValue('category_id') === 'other' ? (
                      <Form.Item
                        name={'category_id_other'}
                        label={<span className='bodySmall !font-semibold'>Others</span>}
                        className='bodySmall mb-0'
                        rules={[{required: true, message: MESSAGES.MSG18}]}
                      >
                        <Input maxLength={50} />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </div>
              <div className='flex flex-col gap-y-[30px]'>
                <span className='titleLarge text-2A2A2A'>Personal Info</span>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-[24px] gap-x-[20px]'>
                  <Form.Item
                    name={'first_name'}
                    label={<span className='bodySmall !font-semibold'>First name</span>}
                    className='bodySmall mb-0'
                    rules={[{required: true, message: MESSAGES.MSG18}]}
                  >
                    <Input placeholder='First Name' maxLength={50} />
                  </Form.Item>
                  <Form.Item
                    name={'last_name'}
                    label={<span className='bodySmall !font-semibold'>Last name</span>}
                    className='bodySmall mb-0'
                    rules={[{required: true, message: MESSAGES.MSG18}]}
                  >
                    <Input placeholder='Last Name' maxLength={50} />
                  </Form.Item>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-[24px] gap-x-[20px]'>
                  <Form.Item
                    name={'email'}
                    label={<span className='bodySmall !font-semibold'>Email</span>}
                    className='bodySmall mb-0'
                    normalize={value => value.trim()}
                    rules={[
                      {required: true, message: MESSAGES.MSG18},
                      {type: 'email', message: MESSAGES.MSG11},
                    ]}
                  >
                    <Input placeholder='name@sample.com' maxLength={50} />
                  </Form.Item>
                  <Form.Item
                    name={'phone_number'}
                    label={<span className='bodySmall !font-semibold'>Phone Number</span>}
                    className='bodySmall mb-0'
                    rules={[
                      {required: true, message: MESSAGES.MSG18},
                      {pattern: Regex.phoneNumberWithSpace, message: MESSAGES.MSG20},
                    ]}
                  >
                    <Input className={'w-full'} placeholder='(+country code) 0000 0000' maxLength={20} />
                  </Form.Item>
                </div>
                <Form.Item
                  name={'marketing_via'}
                  label={<span className='bodySmall !font-semibold'>How did you hear about Maestro Wellness?</span>}
                  className='bodySmall mb-0'
                  rules={[{required: true, message: MESSAGES.MSG18}]}
                >
                  <Select
                    placeholder='Select an option'
                    optionFilterProp='children'
                    options={[
                      {
                        value: 'Ad',
                        label: 'Ad',
                      },
                      {
                        value: 'Blog post',
                        label: 'Blog post',
                      },
                      {
                        value: 'Search engine',
                        label: 'Search engine',
                      },
                      {
                        value: 'Social media',
                        label: 'Social media',
                      },
                      {
                        value: 'referral',
                        label: 'Referral',
                      },
                      {
                        value: 'other',
                        label: 'Others',
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.marketing_via !== currentValues.marketing_via}>
                  {({getFieldValue}) =>
                    getFieldValue('marketing_via') === 'other' ? (
                      <Form.Item
                        name={'marketing_via_other'}
                        label={<span className='bodySmall !font-semibold'>Others</span>}
                        className='bodySmall mb-0'
                        rules={[{required: true, message: MESSAGES.MSG18}]}
                      >
                        <Input maxLength={50} />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </div>
              <div className='flex justify-center'>
                <MAButton htmlType='submit' className='!px-[100px] !rounded-full !py-[10px]'>
                  Sign up now
                </MAButton>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </EmbedLayout>
  );
}
