import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Link} from 'react-router-dom';
import SwiperCore from 'swiper';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import styles from './MASlider.module.scss';

type SliderItem = {
  id: number;
  image: string;
  link?: string;
};

type MASlidersProps = {
  items: SliderItem[];
};

SwiperCore.use([Navigation, Pagination, Autoplay]);

const MASliders: React.FC<MASlidersProps> = ({items}) => {
  return (
    <div className={styles.moduleWrapper}>
      <Swiper
        navigation={true}
        pagination={{clickable: true}}
        spaceBetween={0}
        autoplay={{delay: 5000, disableOnInteraction: false}}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper: any) => console.log(swiper)}
      >
        {items?.map(item => (
          <SwiperSlide key={item.id}>
            <div className={'h-[500px]'}>
              {item.link ? (
                <Link to={item.link}>
                  <img src={item.image} alt={`Slide ${item.id}`} className={'absolute inset-0 w-full h-full object-cover'} />
                </Link>
              ) : (
                <img src={item.image} alt={`Slide ${item.id}`} className={'absolute inset-0 w-full h-full object-cover'} />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MASliders;
