import {useMemo} from 'react';
import {CAN_SIGN_CONTRACT_ACTIVITY_TYPES} from '../../config';
import {Timeline} from 'antd';
import {VendorTimeLineItem} from './VendorTimeLineItem';

interface VendorTimelineForTabHistoryProps {
  activities: any[];
  handleClickTimeline: (timeline: any) => void;
}

export const VendorTimelineForTabHistory = ({activities, handleClickTimeline}: VendorTimelineForTabHistoryProps) => {
  const historyTimelines: any[] = useMemo(() => {
    const clonedActivities = [...activities];
    // Only first valid contract can be sign / deny
    console.log('DEBUG clonedActivities', clonedActivities);
    try {
      clonedActivities.find((timeline: any) => {
        return CAN_SIGN_CONTRACT_ACTIVITY_TYPES.includes(timeline.type);
      }).canDoActions = true;
    } catch (e) {
      console.log('No contract found');
    }

    const result = clonedActivities.map((activity: any) => {
      return {
        children: <VendorTimeLineItem handleClickTimeline={handleClickTimeline} activity={activity} />,
      };
    });
    return result;
  }, [activities]);

  return <Timeline items={historyTimelines} />;
};
