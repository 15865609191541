import React, {useEffect, useState} from 'react';
import {Form, Input, message} from 'antd';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {AuthLayout} from '../../layouts/shared/AuthLayout';
import MAButton from '../../components/ui/button/MAButton';
import {useResetPasswordApi} from '../../repositories/useResetPasswordApi';
import {handleApiError, validatePasswordRules} from '../../helpers/utils';
import {useValidateResetPasswordTokenApi} from '../../repositories/useValidateResetPasswordTokenApi';
import {MESSAGES} from '../../config';

interface LoginValues {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {actions, state} = useResetPasswordApi();
  const {actions: actionsToken, state: stateToken} = useValidateResetPasswordTokenApi();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const [validToken, setIsValidToken] = useState(false);

  useEffect(() => {
    if (!token || !email) {
      return;
    }

    (async () => {
      try {
        await actionsToken.createItem({token: token, email: email});
        setIsValidToken(true);
      } catch (e) {
        navigate('/auth/login');
        await handleApiError(e, 'Invalid token is invalid or expired, please try again');
      }
    })();
  }, [JSON.stringify([token, email])]);

  const onFinish = async (values: LoginValues) => {
    try {
      await actions.createItem({
        token: token,
        email: email,
        password: values.password,
        password_confirmation: values.password_confirmation,
      });

      message.success('Reset password successfully, you can login with new password');
      navigate('/auth/login');
    } catch (error: any) {
      await handleApiError(error, 'Something went wrong, please try again later');
      console.log('error', error);
    }
  };

  const validateMatchPassword = ({getFieldValue}: {getFieldValue: (field: string) => string}) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(MESSAGES.MSG5));
    },
  });

  return (
    <div>
      {validToken && (
        <AuthLayout>
          <Form requiredMark={false} layout={'vertical'} name='login' onFinish={onFinish} form={form}>
            <h1 className={'mb-[40px] displaySmall'}>Reset password</h1>

            <h2 className={'mb-[40px] bodyMedium'}>
              Set a strong password with the following requirements:
              <br />
              - Minimum 8 characters long
              <br />
              - Maximum 20 characters long
              <br />- Includes at least 1 uppercase and lowercase letter, a number and a symbol
            </h2>

            <Form.Item
              className={'labelSmall mb-[20px]'}
              label={'New password'}
              name='password'
              rules={[{required: true, message: MESSAGES.MSG18}, {min: 8, message: MESSAGES.MSG7}, {validator: validatePasswordRules}]}
            >
              <Input.Password maxLength={20} className={'grayInput'} />
            </Form.Item>

            <Form.Item
              className={'labelSmall mb-[40px]'}
              label={'Confirm new password'}
              name='password_confirmation'
              dependencies={['password']}
              hasFeedback
              rules={[{required: true, message: MESSAGES.MSG18}, validateMatchPassword]}
            >
              <Input.Password maxLength={20} className={'grayInput'} />
            </Form.Item>

            <Form.Item className={'mb-[40px]'}>
              <MAButton disabled={state?.isLoading} className={'!rounded-[20px] !bg-293734 !py-[10px] !px-[45px] bodyExtraLarge'}>
                Reset password
              </MAButton>
            </Form.Item>
            <div className='ctas flex flex-row items-center justify-start gap-[20px] titleSmall font-[600]'>
              <Link
                className={'hover:underline text-primary'}
                to={`${process.env.REACT_APP_PUBLIC_SITE}?showPopup=signup` || `https://staging-maestro.xyz?showPopup=signup`}
                target={'_blank'}
              >
                Create an account
              </Link>
            </div>
          </Form>
        </AuthLayout>
      )}
    </div>
  );
};

export default ResetPassword;
