import useRestApi from '../hooks/useRestApi';

interface EmployeeResendActivationEmailApi {
  actions: {
    createItem: (values: Record<string, any>) => Promise<any>;
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useEmployeeResendActivationEmailApi(): EmployeeResendActivationEmailApi {
  return useRestApi('employees/resend-activation-email');
}
