import MAButton from '../button/MAButton';
import {Modal} from 'antd';
import React from 'react';

interface DeletionModalProps {
  isOpen: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  title?: string;
  content?: any;
  textCancel?: string;
  textOk?: string;
}

export function DeletionModal({isOpen, onCancel, onOk, title, content, textCancel, textOk}: DeletionModalProps) {
  return (
    <Modal
      width={418}
      title={<div className={'text-red-500'}>{title || 'Confirm deletion'}</div>}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      okText={'Delete'}
      cancelButtonProps={{className: 'border-none hover:text-black'}}
      okButtonProps={{className: '!bg-FFDDDA text-DE1135 !hover:text-DE1135'}}
      cancelText={'Cancel'}
    >
      <p className={'text-5A5A5A'}>{content || 'Are you sure want to delete selected items?'}</p>
      {onCancel && onOk && (
        <div className='mt-[20px] flex flex-row items-end justify-end gap-[12px]'>
          <MAButton onClick={onCancel} customBgColor={'FFFFFF'} customTextColor={'676B73'} size={'small'}>
            {textCancel || 'Cancel'}
          </MAButton>
          <MAButton onClick={onOk} className={'!px-[24px]'} customBgColor={'FFDDDA'} customTextColor={'DE1135'} size={'small'}>
            {textOk || 'Delete'}
          </MAButton>
        </div>
      )}
    </Modal>
  );
}
