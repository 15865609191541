import {useEffect} from 'react';
import {VendorLayout} from '../../layouts/vendor/VendorLayout';

export default function VendorUsers() {
  useEffect(() => {}, []);
  return (
    <VendorLayout>
      <center>
        <h2 className={'mt-[100px]'}>Vendor users</h2>
      </center>
    </VendorLayout>
  );
}
