import {useEffect} from 'react';
import {AdminLayout} from '../../layouts/admin/AdminLayout';

export default function AdminStaffs() {
  useEffect(() => {}, []);
  return (
    <AdminLayout>
      <center>
        <h2 className={'mt-[100px]'}>Admin staffs</h2>
      </center>
    </AdminLayout>
  );
}
