import {createBrowserRouter, Navigate} from 'react-router-dom';
import AdminHome from './pages/admin/AdminHome';
import AdminEnterprises from './pages/admin/AdminEnterprises';
import AdminEnterprisesRegisterAndUpdate from './pages/admin/AdminEnterprisesRegisterAndUpdate';
import AdminEnterprisesViewDetails from './pages/admin/AdminEnterprisesViewDetails';
import AdminUsers from './pages/admin/AdminUsers';
import AdminStaffs from './pages/admin/AdminStaffs';
import AdminReports from './pages/admin/AdminReports';
import VendorHome from './pages/vendor/VendorHome';
import VendorUsers from './pages/vendor/VendorUsers';
import VendorStaffs from './pages/vendor/VendorStaffs';
import VendorReports from './pages/vendor/VendorReports';
import EnterpriseHome from './pages/enterprise/EnterpriseHome';
import EnterpriseProgramsList from './pages/enterprise/programs/list/EnterpriseProgramsList';
import EnterpriseEmployees from './pages/enterprise/employees/list/EnterpriseEmployees';
import EnterpriseReports from './pages/enterprise/EnterpriseReports';
import EnterpriseMessages from './pages/enterprise/EnterpriseMessages';
import EmployeeHome from './pages/employee/EmployeeHome';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Home from './pages/Home';
import EnterpriseProgramStart from './pages/enterprise/programs/start/EnterpriseProgramStart';
import ProtectedRoute from './components/shared/ProtectedRoute';
import EnterpriseProgramsDetail from './pages/enterprise/programs/detail/EnterpriseProgramsDetail';
import ProductsDetail from './pages/projected/products/detail/ProductsDetail';
import EnterpriseEmployeesDetail from './pages/enterprise/employees/detail/EnterpriseEmployeesDetail';
import VendorsDetail from './pages/projected/vendors/detail/VendorsDetail';
import EnterpriseVendorsDetail from './pages/enterprise/vendors/EnterpriseVendorsDetail';
import EnterpriseProductsDetailPage from './pages/enterprise/products/EnterpriseProductDetails';
import EnterpriseProgramEdit from './pages/enterprise/programs/edit/EnterpriseProgramEdit';
import ProgramVendor from './pages/enterprise/programs/detail/EnterpriseProgramsDetailVendor';
import VendorProfileBuilder from './pages/vendor/VendorProfileBuilder';
import ForgotPasswordSuccess from './pages/auth/ForgotPasswordSuccess';
import VendorCustomer from './pages/vendor/VendorCustomers';
import {USER_ROLES_MAPPING} from './config';
import EnterpriseSignupIFrame from './pages/embed/EnterpriseSignup';
import VendorSignupIFrame from './pages/embed/VendorSignUp';
import EnterpriseRequestDemoIFrame from './pages/embed/EnterpriseRequestDemo';
import PricingOnVendor from './pages/embed/PricingOnVendor';
import TablePlanOnPricing from './pages/embed/TablePlanOnPricing';
import AccountOverView from './pages/account/AccountOverView';
import PersonalInfo from './pages/account/PersonalInfo';
import LoginAndSecurity from './pages/account/LoginAndSecurity';
import Notifications from './pages/notification/Notifications';
import EmployeeProfileBuilder from './pages/employee/EmployeeProfileBuilder';

export const routers = createBrowserRouter([
  // For admin
  {
    path: '/products/:id',
    element: <ProductsDetail />,
  },
  {
    path: '/vendors/:id',
    element: <VendorsDetail />,
  },
  {
    path: '/admin/home',
    element: <AdminHome />,
  },
  {
    path: '/admin/enterprises',
    element: <AdminEnterprises />,
  },
  {
    path: '/admin/enterprises/register',
    element: <AdminEnterprisesRegisterAndUpdate />,
  },
  {
    path: '/admin/enterprises/update/:id',
    element: <AdminEnterprisesRegisterAndUpdate />,
  },
  {
    path: '/admin/enterprises/detail/:id',
    element: <AdminEnterprisesViewDetails />,
  },
  {
    path: '/admin/users',
    element: <AdminUsers />,
  },
  {
    path: '/admin/staffs',
    element: <AdminStaffs />,
  },
  {
    path: '/admin/reports',
    element: <AdminReports />,
  },
  {
    path: '/admin/*',
    element: <Navigate to='/admin/home' replace />,
  },
  // For vendor
  {
    path: '/vendor/home',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.VENDOR_ADMIN.value}>
        <VendorHome />
      </ProtectedRoute>
    ),
  },
  {
    path: '/vendor/profile-builder',
    element: <VendorProfileBuilder />,
  },
  {
    path: '/vendor/users',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.VENDOR_ADMIN.value}>
        <VendorUsers />
      </ProtectedRoute>
    ),
  },
  {
    path: '/vendor/staffs',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.VENDOR_ADMIN.value}>
        <VendorStaffs />
      </ProtectedRoute>
    ),
  },
  {
    path: '/vendor/reports',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.VENDOR_ADMIN.value}>
        <VendorReports />
      </ProtectedRoute>
    ),
  },
  {
    path: '/vendor/customers',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.VENDOR_ADMIN.value}>
        <VendorCustomer />
      </ProtectedRoute>
    ),
  },
  {
    path: '/vendor/*',
    element: <Navigate to='/vendor/home' replace />,
  },
  // For enterprise admin
  {
    path: '/enterprise/employees/:id',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseEmployeesDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/home',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseHome />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/programs',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseProgramsList />
      </ProtectedRoute>
    ),
  },
  // vendor detail
  {
    path: '/enterprise/vendors/:id',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseVendorsDetail />
      </ProtectedRoute>
    ),
  },
  // product detail
  {
    path: '/enterprise/products/:id',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseProductsDetailPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/programs/:id/:tab',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseProgramsDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/programs/:programId/employees/:id',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseEmployeesDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: 'enterprise/programs/:id/vendors/:vendorId',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <ProgramVendor />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/programs/start',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseProgramStart />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/programs/:id/:tab/edit',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseProgramEdit />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/employees',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseEmployees />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/reports',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseReports />
      </ProtectedRoute>
    ),
  },
  {
    path: '/enterprise/messages',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_ADMIN.value}>
        <EnterpriseMessages />
      </ProtectedRoute>
    ),
  },

  // For enterprise employee
  {
    path: '/employee/home',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_EMPLOYEE.value}>
        <EmployeeHome />
      </ProtectedRoute>
    ),
  },
  {
    path: '/employee/profile-builder',
    element: (
      <ProtectedRoute role={USER_ROLES_MAPPING.ENTERPRISE_EMPLOYEE.value}>
        <EmployeeProfileBuilder />
      </ProtectedRoute>
    ),
  },
  {
    path: '/employee/*',
    element: <Navigate to='/employee/home' replace />,
  },
  // Account login/logout/signUp
  {
    path: '/auth/login',
    element: <Login />,
  },
  {
    path: '/auth/register',
    element: <Register />,
  },
  {
    path: '/auth/logout',
    element: <Register />,
  },
  {
    path: '/embed/enterprise-request-demo',
    element: <EnterpriseRequestDemoIFrame />,
  },
  {
    path: '/embed/enterprise-signup',
    element: <EnterpriseSignupIFrame />,
  },
  {
    path: '/embed/pricing-on-vendor',
    element: <PricingOnVendor />,
  },
  {
    path: '/embed/table-plan-on-pricing',
    element: <TablePlanOnPricing />,
  },
  {
    path: '/embed/vendor-signup',
    element: <VendorSignupIFrame />,
  },
  {
    path: '/auth/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/auth/forgot-password-success',
    element: <ForgotPasswordSuccess />,
  },
  {
    path: '/auth/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/:role/account/overview',
    element: <AccountOverView />,
  },
  {
    path: '/:role/account/personal-info',
    element: <PersonalInfo />,
  },
  {
    path: '/:role/account/setting-security',
    element: <LoginAndSecurity />,
  },
  {
    path: '/:role/notifications',
    element: <Notifications />,
  },
  // Other
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/*',
    element: (
      <center className={'mt-[100px]'}>
        <h1>Page not found 404</h1>
      </center>
    ),
  },
  {
    path: '/404',
    element: (
      <center className={'mt-[100px]'}>
        <h1>Page not found 404</h1>
      </center>
    ),
  },
]);
