import {useState} from 'react';
import {EnterpriseLayout} from '../../layouts/enterprise/EnterpriseLayout';
import {Thread} from './mockData';
import ChatSidebar from './ChatSidebar';
import Messages from './Messages';

export default function EnterpriseMessages() {
  const [selectedThread, setSelectedThread] = useState<Thread | null>(null);

  return (
    <EnterpriseLayout>
      <div className='flex h-[100%]'>
        <ChatSidebar setSelectedThread={setSelectedThread} />
        <Messages selectedThread={selectedThread} />
      </div>
    </EnterpriseLayout>
  );
}
