import {useEffect} from 'react';
import {Step5Props} from '../../../../../types';
import styles from '../../../../../pages/enterprise/programs/start/EnterpriseProgramStart.module.scss';
import {Col, Form, InputNumber, Row} from 'antd';
import dayjs from 'dayjs';
import MADatepicker from '../../../../ui/datepicker/MADatepicker';
import {createDateValidator} from '../../../../../helpers/utils';
import {MESSAGES} from '../../../../../config';

export default function Step5({formRef, initialValues}: Step5Props) {
  useEffect(() => {
    if (initialValues && formRef.current) {
      formRef.current.setFieldsValue({
        optionalSettings: initialValues.optionalSettings,
        allotPoint: initialValues.allotPoint,
        programDateEnd: initialValues.programDateEnd ? dayjs(initialValues.programDateEnd) : null,
        programDateStart: initialValues.programDateStart ? dayjs(initialValues.programDateStart) : null,
      });
    }
  }, [initialValues, formRef]);
  return (
    <div className={`${styles.moduleWrapper} w-full flex flex-col mt-[5%] relative `}>
      <Form className='absolute top-[-50px] left-0 w-full' ref={formRef} layout='vertical'>
        <Col span={18} offset={3}>
          <div className='pl-[8px]'>
            <h1 className='titleSmall'>Redemption Plan</h1>
            <h2 className='bodySmall text-5A5A5A'>Allot points to your employees to spend in your program.</h2>
            <Form.Item name='allotPoint' rules={[{required: true, message: MESSAGES.MSG18}]}>
              <InputNumber placeholder='0' size='large' maxLength={5} className='w-[100%] mt-[8px]' />
            </Form.Item>
            <h1 className='titleSmall'>Program Dates</h1>
            <Row className='my-[15px]' justify={'space-between'}>
              <Col span={11}>
                <Form.Item
                  rules={[
                    {required: true, message: MESSAGES.MSG18},
                    createDateValidator({
                      startDateFieldName: 'programDateStart',
                      endDateFieldName: 'programDateEnd',
                      message: MESSAGES.TODO_START_DATE,
                      operator: 'before',
                    }),
                  ]}
                  name='programDateStart'
                >
                  <MADatepicker disabledDate={current => current && current.isBefore(dayjs().startOf('day'))} size='large' className='w-[100%]' />
                </Form.Item>
              </Col>
              <Col span={2} className='text-center'>
                <h1 className='text-2xl'>-</h1>
              </Col>
              <Col span={11}>
                <Form.Item
                  name='programDateEnd'
                  rules={[
                    {required: true, message: MESSAGES.MSG18},
                    createDateValidator({
                      startDateFieldName: 'programDateStart',
                      endDateFieldName: 'programDateEnd',
                      message: MESSAGES.TODO_END_DATE,
                      operator: 'after',
                    }),
                  ]}
                >
                  <MADatepicker disabledDate={current => current && current.isBefore(dayjs().startOf('day'))} size='large' className='w-[100%]' />
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/* <div className={`w-full h-[200px] p-2 bg-${colors?.secondary} rounded-lg`}>
            <h1 className='text-2xl'>Optional Settings</h1>
            <Form.Item name='optionalSettings' valuePropName='checked'>
              <Checkbox checked={componentDisabled} onChange={e => setComponentDisabled(e.target.checked)}>
                Remind employees 30 days before end of program
              </Checkbox>
            </Form.Item>
          </div> */}
        </Col>
      </Form>
    </div>
  );
}
