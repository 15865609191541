import {EMPLOYEE_STATUSES} from '../../config';
import {convertMappingToArray} from '../../helpers/utils';

export function EmployeeStatusLabel({statusId}: {statusId: number}) {
  const target = convertMappingToArray(EMPLOYEE_STATUSES).find(i => i.value === statusId);

  if (target) {
    return (
      <div className={`flex items-center justify-center py-[2px] px-[10px] rounded-[10px] w-[fit-content] bg-${target.color}`}>{target.label}</div>
    );
  }

  return null;
}
