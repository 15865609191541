import {Card, Modal} from 'antd';
import React from 'react';
import MAIcon from '../../../../ui/icon/MAIcon';

interface ImportModalProps {
  isOpen: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  title?: string;
  content?: any;
  textCancel?: string;
  textOk?: string;
  selectImportType: (type: string) => void;
}

export function ImportModal({isOpen, onCancel, selectImportType}: ImportModalProps) {
  return (
    <Modal
      width={418}
      title={<div className={'titleLarge'}>{'Import employees'}</div>}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      okText={'Close'}
      okButtonProps={{className: 'bg-primary  !hover:text-DE1135'}}
      cancelText={'Cancel'}
    >
      <div className='flex justify-center items-center flex-col'>
        <p className={'text-5A5A5A bodyMedium '}>Add employees to your program and we’ll invite them when you’re ready to launch. </p>
        <div className='mt-[20px] flex flex-row items-end justify-end gap-[12px]'>
          <div
            onClick={() => selectImportType('individual')}
            className='cursor-pointer flex flex-col justify-center items-center rounded-[4px] border border-D2D2D2 w-[168px] h-[85px]'
          >
            <MAIcon size={30} color={'primary'} name={'individual'} />
            <p className='titleMedium mt-[10px] text-center text-5A5A5A labelMedium'>Add an individual</p>
          </div>
          <div
            onClick={() => selectImportType('bulk')}
            className='cursor-pointer flex flex-col justify-center items-center  rounded-[4px] border border-D2D2D2 w-[168px] h-[85px]'
          >
            <MAIcon size={30} color={'primary'} name={'bulk'} />
            <p className='titleMedium mt-[10px] text-center text-5A5A5A labelMedium'>Add by bulk</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
