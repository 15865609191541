import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {EnterpriseLayout} from '../../../../layouts/enterprise/EnterpriseLayout';
import MAButton from '../../../../components/ui/button/MAButton';
import MAIcon from '../../../../components/ui/icon/MAIcon';
import EnterpriseProgramsOverview from '../../../../components/pages/enterprise/programs/detail/EnterpriseProgramsOverview';
import {Link, useNavigate, useParams} from 'react-router-dom';
import EnterpriseProgramsProducts from '../../../../components/pages/enterprise/programs/detail/EnterpriseProgramsProducts';
import EnterpriseProgramsEmployees from '../../../../components/pages/enterprise/programs/detail/EnterpriseProgramsEmployees';
import EnterpriseProgramsVendors from '../../../../components/pages/enterprise/programs/detail/EnterpriseProgramsVendors';
import ProgramNameEditorModal from '../../../../components/pages/enterprise/programs/detail/ProgramNameEditorModal';
import {Breadcrumb, message} from 'antd';
import {useProgramApi} from '../../../../repositories/useProgramApi';
import {useEmployeeApi} from '../../../../repositories/useEmployeeApi';
import {useProgramVendorApi} from '../../../../repositories/useProgramVendorApi';
import {ProgramStatusLabel} from '../../../../components/shared/ProgramStatusLabel';
import {DeletionModal} from '../../../../components/ui/modals/DeletionModal';
import {handleApiError} from '../../../../helpers/utils';
import {useProgramProductApi} from '../../../../repositories/useProgramProductApi';
import {CONTRACT_STATUSES, PROGRAM_STATUSES_MAPPING} from '../../../../config';
import {Simulate} from 'react-dom/test-utils';
import {useContractApi} from '../../../../repositories/useContractApi';

export default function EnterpriseProgramsDetail() {
  console.log('Render EnterpriseProgramsDetail');
  const params = useParams();
  const {actions: actionsProgram, data: dataProgram, state: stateProgram} = useProgramApi();
  const {actions: actionsEmployee, data: dataEmployee, state: stateEmployee} = useEmployeeApi();
  const {actions: actionsProgramVendor, data: dataProgramVendor, state: stateProgramVendor} = useProgramVendorApi(params?.id);
  const {actions: actionsProgramProduct, data: dataProgramProduct, state: stateProgramProduct} = useProgramProductApi(params?.id);
  const {actions: actionsAllProgramProduct, data: dataAllProgramProduct, state: stateAllProgramProduct} = useProgramProductApi(params?.id);
  const {actions: actionsContract, data: dataContract, state: stateContract} = useContractApi();

  async function handleSearchSubmitEmployee(values: any) {
    const clone = {
      search: values.full_name ?? null,
    };

    setSavedSearchEmployees({
      ...savedSearchEmployees,
      ...clone,
    });
    await actionsEmployee.getItems({
      ...savedSearchEmployees,
      ...clone,
    });
  }

  const [savedSearchProgramProducts, setSavedSearchProgramProducts] = useState<any>({
    order_by: 'name',
  });
  const [savedSearchProgramVendors, setSavedSearchProgramVendors] = useState<any>({
    order_by: 'name',
  });

  const [savedSearchEmployees, setSavedSearchEmployees] = useState<any>({
    order_by: 'full_name',
  });

  const [isOpenModalConfirmDelete, setIsOpenModalConfirmDelete] = useState(false);
  const navigate = useNavigate();

  const [isOpenEditNameModal, setIsOpenEditNameModal] = useState(false);
  const [currentTab, setCurrentTab]: any = useState();
  const [clickedContractRow, setClickedContractRow] = useState<any>(null);

  useEffect(() => {
    if (params.id) {
      setCurrentTab(params.tab);

      setSavedSearchEmployees({
        ...savedSearchEmployees,
        program_id: params.id,
      });

      (async () => {
        try {
          await Promise.all([
            actionsAllProgramProduct.getItems({
              page: 1,
              limit: 1000,
            }),

            actionsProgram.getItem(params.id),
            actionsProgramProduct.getItems({...savedSearchProgramProducts}),
            actionsEmployee.getItems({
              ...savedSearchEmployees,
              program_id: params.id,
            }),
            actionsProgramVendor.getItems({
              ...savedSearchProgramVendors,
            }),
          ]);
        } catch (e) {
          console.error(e);
          message.error('Error while loading program detail, please try again later');
        }
      })();
    }
  }, [params?.id]);

  useEffect(() => {
    if (params?.tab) {
      setCurrentTab(params?.tab);
    }
  }, [params?.tab]);

  async function handleOnChangeImplementation(updatedValues: any) {
    try {
      await actionsProgram.updateItem(params.id, updatedValues);
      await actionsProgram.getItem(params.id);

      message.success('Program implementation updated');
    } catch (e) {
      message.error('Error updating program implementation');
    }
  }

  async function handleOnSaveProgramName(newName: string) {
    setIsOpenEditNameModal(false);
    try {
      await actionsProgram.updateItem(params.id, {name: newName});
      await actionsProgram.getItem(params.id);
      message.success('Program name updated');
    } catch (e) {
      message.error('Error updating program name');
    }
  }

  async function handleOnDeleteActivities(ids: any[]) {
    try {
      await actionsProgram.customDelete(`${params.id}/products`, {
        ids,
      });
      message.success('Deleted activities successfully');
      await Promise.all([
        actionsProgram.getItem(params.id),
        actionsProgramProduct.getItems({
          ...savedSearchProgramProducts,
        }),
        actionsProgramVendor.getItems({
          ...savedSearchProgramVendors,
        }),
      ]);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error deleting activities');
    }
  }

  async function handleOnDeleteEmployees(ids: any[]) {
    try {
      await actionsProgram.customDelete(`${params.id}/employees`, {
        ids,
      });
      message.success('Deleted employees successfully');
      await Promise.all([
        actionsProgram.getItem(params.id),
        actionsEmployee.getItems({
          ...savedSearchEmployees,
          program_id: params.id,
        }),
      ]);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error deleting employees');
    }
  }

  async function handleOnDeleteVendors(ids: any[]) {
    try {
      await actionsProgram.customDelete(`${params.id}/vendors`, {
        ids,
      });
      message.success('Deleted vendors successfully');
      await Promise.all([
        actionsProgram.getItem(params.id),
        actionsProgramProduct.getItems({
          ...savedSearchProgramProducts,
        }),
        actionsProgramVendor.getItems({
          ...savedSearchProgramVendors,
        }),
      ]);
    } catch (e) {
      console.error(e);
      await handleApiError(e, 'Error deleting activities');
    }
  }

  async function handleTableProductsChange(pagination: any, filters: any, sorter: any) {
    let fieldSort;
    if (sorter.order === 'ascend') {
      fieldSort = `${sorter.field}`;
    } else {
      fieldSort = `-${sorter.field}`;
    }

    setSavedSearchProgramProducts({
      ...savedSearchProgramProducts,
      limit: pagination.pageSize,
      page: pagination.current,
      order_by: fieldSort,
    });

    await actionsProgramProduct.getItems({
      ...savedSearchProgramProducts,
      limit: pagination.pageSize,
      page: pagination.current,
      order_by: fieldSort,
    });
  }

  async function handleTableVendorsChange(pagination: any, filters: any, sorter: any) {
    let fieldSort;
    if (sorter.order === 'ascend') {
      fieldSort = `${sorter.field}`;
    } else {
      fieldSort = `-${sorter.field}`;
    }

    setSavedSearchProgramVendors({
      ...savedSearchProgramVendors,
      order_by: fieldSort,
    });

    await actionsProgramVendor.getItems({
      ...savedSearchProgramVendors,
      order_by: fieldSort,
    });
  }

  async function onSignContract() {
    try {
      await actionsContract.customPost(`${clickedContractRow?.contract?.id}/sign`, {
        status: CONTRACT_STATUSES.CONTRACT_SIGNED.value,
      });

      // Reload after sign/deny
      await actionsProgramVendor.getItems({
        ...savedSearchProgramVendors,
      });

      message.success('Contract signed successfully');
    } catch (e) {
      console.log('Debug e: ', e);
      message.error('Failed to sign contractId');
    } finally {
      setClickedContractRow(null);
    }
  }

  async function onDenyContract() {
    try {
      await actionsContract.customPost(`${clickedContractRow?.contract?.id}/sign`, {
        status: CONTRACT_STATUSES.REJECTED_BY_ENTERPRISE.value,
      });

      // Reload after sign/deny
      await actionsProgramVendor.getItems({
        ...savedSearchProgramVendors,
      });
      message.success('Contract denied successfully');
    } catch (e) {
      message.error('Failed to deny contract');
    } finally {
      setClickedContractRow(null);
    }
  }

  async function handleTableEmployeesChange(pagination: any, filters: any, sorter: any) {
    let fieldSort;
    if (sorter.order === 'ascend') {
      fieldSort = `${sorter.field}`;
    } else {
      fieldSort = `-${sorter.field}`;
    }

    setSavedSearchEmployees({
      ...savedSearchEmployees,
      order_by: fieldSort,
    });

    await actionsEmployee.getItems({
      ...savedSearchEmployees,
      order_by: fieldSort,
    });
  }

  const canEditName = useMemo(() => {
    // return ![PROGRAM_STATUSES_MAPPING.LIVE.value, PROGRAM_STATUSES_MAPPING.PAUSE.value, PROGRAM_STATUSES_MAPPING.COMPLETED.value].includes(
    //   dataProgram?.detail?.status
    // );

    // Can edit all time
    return true;
  }, [dataProgram?.detail]);

  return (
    <EnterpriseLayout>
      <div className='inner flex flex-col gap-[24px]'>
        <DeletionModal
          title={'Delete Program'}
          content={'Are you sure want to delete this program?'}
          isOpen={isOpenModalConfirmDelete}
          onCancel={() => {
            setIsOpenModalConfirmDelete(false);
          }}
          onOk={async () => {
            try {
              await actionsProgram.deleteItem(params.id);
              navigate('/enterprise/programs');
              message.success('Deleted program successfully');
            } catch (e) {
              await handleApiError(e, 'Error deleting program');
            }
          }}
        />

        {dataProgram?.detail &&
          dataProgramProduct?.listItems &&
          dataProgramVendor?.listItems &&
          dataEmployee?.listItems &&
          dataProgramVendor?.listItems && (
            <Fragment>
              {/*TODO timeline?*/}

              {currentTab === 'overview' && (
                <Fragment>
                  {/*<Breadcrumb*/}
                  {/*  items={[*/}
                  {/*    {*/}
                  {/*      title: <Link to={'/enterprise/programs'}>Programs</Link>,*/}
                  {/*    },*/}
                  {/*    {*/}
                  {/*      title: dataProgram?.detail?.name,*/}
                  {/*    },*/}
                  {/*  ]}*/}
                  {/*/>*/}

                  <div className='titleAndActions flex items-center justify-between'>
                    <div className='left'>
                      <div className={'flex items-center gap-[1rem]'}>
                        <h1
                          className={`titleMedium ${canEditName ? 'cursor-pointer' : 'cursor-default'}}`}
                          onClick={() => {
                            if (!canEditName) {
                              return;
                            }
                            setIsOpenEditNameModal(true);
                          }}
                        >
                          {dataProgram?.detail?.name}
                        </h1>
                        {canEditName && (
                          <div
                            className={`cursor-pointer}`}
                            onClick={() => {
                              setIsOpenEditNameModal(true);
                            }}
                          >
                            <MAIcon size={16} name={'edit'} />
                          </div>
                        )}

                        {/*<div className={'text-2A2A2A bg-EFE1B2 labelSmall py-[2px] px-[10px] rounded-[10px]'}>Draft</div>*/}
                        <ProgramStatusLabel className={'labelSmall'} statusId={dataProgram?.detail?.status} />
                      </div>
                      {isOpenEditNameModal && (
                        <ProgramNameEditorModal
                          currentName={dataProgram?.detail?.name}
                          onCancel={() => {
                            setIsOpenEditNameModal(false);
                          }}
                          onSave={handleOnSaveProgramName}
                        />
                      )}
                    </div>
                    <div className='right flex gap-[1rem]'>
                      <MAButton
                        disabled={[
                          PROGRAM_STATUSES_MAPPING.LIVE.value,
                          PROGRAM_STATUSES_MAPPING.PAUSE.value,
                          PROGRAM_STATUSES_MAPPING.COMPLETED.value,
                        ].includes(dataProgram?.detail?.status)}
                        size={'small'}
                        type={'text'}
                        color={'2A2A2A'}
                        onClick={async () => {
                          setIsOpenModalConfirmDelete(true);
                          // const agree = await globalConfirmation({
                          //   title: 'Delete Program',
                          //   content: 'Are you sure you want to delete this program?',
                          //   submitText: 'Delete',
                          // });
                        }}
                      >
                        <div className='flex gap-[7px] items-center justify-between'>
                          <div className={'mt-[-2px]'}>
                            <MAIcon color={'2A2A2A'} size={14} name={'delete'} />
                          </div>
                          <div className={'text-2A2A2A'}>Delete</div>
                        </div>
                      </MAButton>

                      {PROGRAM_STATUSES_MAPPING.READY_TO_LAUNCH.value === dataProgram?.detail?.status && (
                        <MAButton
                          size={'small'}
                          onClick={async () => {
                            try {
                              await actionsProgram.customPost(`${params?.id}/go-live`, {});
                              message.success('Program is live now');
                              await actionsProgram.getItem(params?.id);
                            } catch (e) {
                              message.error('Failed to go live program');
                            }
                          }}
                          disabled={PROGRAM_STATUSES_MAPPING.READY_TO_LAUNCH.value !== dataProgram?.detail?.status}
                        >
                          Launch program
                        </MAButton>
                      )}
                    </div>
                  </div>

                  <EnterpriseProgramsOverview
                    employees={dataEmployee?.listItems}
                    vendors={dataProgramVendor?.listItems}
                    products={dataProgramProduct?.listItems}
                    onChangeImplementation={handleOnChangeImplementation}
                    program={dataProgram?.detail}
                  />
                </Fragment>
              )}

              {currentTab === 'products' && (
                <Fragment>
                  <Breadcrumb
                    items={[
                      {
                        title: (
                          <Link to={`/enterprise/programs/${params.id}/overview`}>
                            <div className={'flex items-center justify-center gap-[5px]'}>
                              <MAIcon size={20} name={'previous'} />
                              <div>Programme</div>
                            </div>
                          </Link>
                        ),
                      },
                      {
                        title: <strong className={'text-2A2A2A font-semibold'}>Activities</strong>,
                      },
                    ]}
                  />

                  <EnterpriseProgramsProducts
                    allProducts={dataAllProgramProduct?.listItems}
                    isLoading={stateProgramProduct?.isLoading}
                    handleTableChange={handleTableProductsChange}
                    program={dataProgram?.detail}
                    products={dataProgramProduct?.listItems}
                    onDeleteActivities={handleOnDeleteActivities}
                  />
                </Fragment>
              )}

              {currentTab === 'vendors' && (
                <Fragment>
                  <Breadcrumb
                    items={[
                      {
                        title: (
                          <Link to={`/enterprise/programs/${params.id}/overview`}>
                            <div className={'flex items-center justify-center gap-[5px]'}>
                              <MAIcon size={20} name={'previous'} />
                              <div>Programme</div>
                            </div>
                          </Link>
                        ),
                      },
                      {
                        title: <strong className={'text-2A2A2A font-semibold'}>Vendors</strong>,
                      },
                    ]}
                  />

                  <EnterpriseProgramsVendors
                    isLoading={stateProgramVendor?.isLoading}
                    handleTableChange={handleTableVendorsChange}
                    program={dataProgram?.detail}
                    vendors={dataProgramVendor?.listItems}
                    products={dataProgramProduct?.listItems}
                    onDeleteVendors={handleOnDeleteVendors}
                    onSignContract={onSignContract}
                    onDenyContract={onDenyContract}
                    clickedContractRow={clickedContractRow}
                    setClickedContractRow={setClickedContractRow}
                  />
                </Fragment>
              )}

              {currentTab === 'employees' && (
                <Fragment>
                  <Breadcrumb
                    items={[
                      {
                        title: (
                          <Link to={`/enterprise/programs/${params.id}/overview`}>
                            <div className={'flex items-center justify-center gap-[5px]'}>
                              <MAIcon size={20} name={'previous'} />
                              <div>Programme</div>
                            </div>
                          </Link>
                        ),
                      },
                      {
                        title: <strong className={'text-2A2A2A font-semibold'}>Employees</strong>,
                      },
                    ]}
                  />

                  <EnterpriseProgramsEmployees
                    totalEmployees={dataEmployee?.listPagination?.total}
                    handleSearchSubmit={handleSearchSubmitEmployee}
                    isLoading={stateEmployee?.isLoading}
                    handleTableChange={handleTableEmployeesChange}
                    program={dataProgram?.detail}
                    onDeleteEmployees={handleOnDeleteEmployees}
                    employees={dataEmployee?.listItems}
                  />
                </Fragment>
              )}
            </Fragment>
          )}
      </div>
    </EnterpriseLayout>
  );
}
