import React, {Fragment, useEffect, useState} from 'react';
import {AccountLayout} from '../../layouts/account/AccountLayout';
import {Breadcrumb, Form, Input, Spin, message} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom';
import MAIcon from '../../components/ui/icon/MAIcon';
import {MESSAGES} from '../../config';
import MAButton from '../../components/ui/button/MAButton';
import {formatDateFromServer, handleApiError, validatePasswordRules} from '../../helpers/utils';
import {Profile} from '../../types';
import {useProfileApi} from '../../repositories/useProfileApi';
import {changePassword, logoutDevice} from '../../services/apiService';
import moment from 'moment';

export default function LoginAndSecurity() {
  const navigate = useNavigate();
  const {role} = useParams();
  const [isEditPassword, setisEditPassword] = useState(false);
  const [form] = Form.useForm();
  const {actions: actionProfile, state: stateProfile} = useProfileApi();

  const [profile, setProfile] = useState<Profile | null>(null);
  const fetchAPI = async () => {
    try {
      const profileData = await actionProfile.customGet('', {});
      setProfile(profileData?.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  const renderPassword = (title: string, subTitle: string) => {
    return (
      <div className='mt-[25px] flex flex-row justify-between border-b border-b-E2E2E2'>
        <div>
          <p className='labelMedium mb-[10px]'>{title}</p>
          <p className='bodyMedium mb-[25px] text-5A5A5A'>{subTitle}</p>
        </div>
        <span onClick={handleEdit} className='underline font-medium cursor-pointer'>
          {isEditPassword ? 'Cancel' : 'Update'}
        </span>
      </div>
    );
  };
  const validateMatchPassword = ({getFieldValue}: {getFieldValue: (field: string) => string}) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue('new_password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(MESSAGES.MSG5));
    },
  });

  const renderEditPassword = () => {
    return (
      <div className='mt-[25px] flex flex-row justify-between border-b border-b-E2E2E2'>
        <div className='w-[80%]'>
          <p className='labelMedium mb-[10px]'>Password</p>

          <h2 className={'mb-[40px] bodyMedium'}>
            Set a strong password with the following requirements:
            <br />
            - Minimum 8 characters long
            <br />
            - Maximum 20 characters long
            <br />- Includes at least 1 uppercase and lowercase letter, a number and a symbol
          </h2>

          <Form.Item
            className={'labelSmall mb-[20px] mt-[25px]'}
            label={'Current Password'}
            name='password'
            rules={[{required: true, message: MESSAGES.MSG18}, {min: 8, message: MESSAGES.MSG7}, {validator: validatePasswordRules}]}
          >
            <Input.Password maxLength={20} className={'grayInput'} />
          </Form.Item>
          <Form.Item
            className={'labelSmall mb-[20px]'}
            label={'New password'}
            name='new_password'
            rules={[{required: true, message: MESSAGES.MSG18}, {min: 8, message: MESSAGES.MSG7}, {validator: validatePasswordRules}]}
          >
            <Input.Password maxLength={20} className={'grayInput'} />
          </Form.Item>

          <Form.Item
            className={'labelSmall mb-[40px]'}
            label={'Confirm new password'}
            name='password_confirmation'
            dependencies={['new_password']}
            hasFeedback
            rules={[{required: true, message: MESSAGES.MSG18}, validateMatchPassword]}
          >
            <Input.Password maxLength={20} className={'grayInput'} />
          </Form.Item>

          <Form.Item className={'mb-[25px]'}>
            <MAButton onClick={updatePassword} className='mt-[25px] px-[40px]'>
              Save
            </MAButton>
          </Form.Item>
        </div>
        <span
          onClick={() => {
            form.resetFields(['password', 'new_password', 'password_confirmation']);
            setisEditPassword(!isEditPassword);
          }}
          className='underline font-medium cursor-pointer'
        >
          Cancel
        </span>
      </div>
    );
  };
  const updatePassword = async () => {
    try {
      const values = await form.validateFields();
      console.log('bb values', values);
      await changePassword(values);
      setisEditPassword(!isEditPassword);
      message.success('Password updated successfully');
      form.resetFields(['password', 'new_password', 'password_confirmation']);
      fetchAPI();
    } catch (error) {
      await handleApiError(error, 'Current password is invalid, please try again');
      console.error('error', error);
    }
  };
  const handleEdit = () => {
    setisEditPassword(!isEditPassword);
  };
  const handleSignOutAll = () => {
    logoutDevices(profile?.devices?.map(item => item?.id));
  };

  const logoutDevices = async (ids: any) => {
    try {
      const res = await logoutDevice(ids);
      message.success('Logged out successfully');
      fetchAPI();
    } catch (error) {
      await handleApiError(error, 'Something went wrong, please try again later');
    }
  };

  const converFormatTime = (time: string) => {
    if (!time) {
      return '';
    }
    return time ? `Last updated ${moment(time).fromNow()}` : '';
  };

  return (
    <AccountLayout>
      <Fragment>
        <div className='w-[504px] ml-[231px]'>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={`/${role}/account/overview`}>
                    <div className={'flex items-center justify-center gap-[5px]'}>
                      <MAIcon size={20} name={'previous'} />
                      <div>Account</div>
                    </div>
                  </Link>
                ),
              },
              {
                title: <strong className={'text-2A2A2A font-medium'}>Login & Security</strong>,
              },
            ]}
          />
          <p className='displaySmall mb-[55px]'>Login & Security</p>
          <Spin spinning={stateProfile.isLoading}>
            <Form layout='vertical' form={form}>
              {isEditPassword ? renderEditPassword() : renderPassword('Password', converFormatTime(profile?.last_updated_password || ''))}
              {profile?.devices && profile?.devices?.length > 0 && (
                <div className='border-b border-b-E2E2E2'>
                  <div className='mt-[25px] flex flex-row justify-between '>
                    <div>
                      <p className='labelMedium mb-[10px]'>Device History</p>
                    </div>
                    <span onClick={handleSignOutAll} className='underline font-medium cursor-pointer'>
                      Sign out all other sessions
                    </span>
                  </div>
                  {profile?.devices?.map((item, index) => {
                    return (
                      <div className='flex flex-row justify-between w-full'>
                        <p key={index} className='bodyMedium mb-[25px] text-5A5A5A truncate'>
                          Session on {formatDateFromServer(item?.updated_at)}
                        </p>
                        <span onClick={() => logoutDevices([item?.id])} className='cursor-pointer underline'>
                          Log out device
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </Form>
          </Spin>
        </div>
      </Fragment>
    </AccountLayout>
  );
}
