import {useState} from 'react';
import qs from 'qs';
import {getAxios} from '../services/api';

interface ApiState {
  isError: boolean;
  isLoading: boolean;
}

// Define the structure of your data here. Replace 'any' with appropriate types.
interface Data {
  listItems?: any[];
  listPagination?: object;
  detail?: any;
}

// Define the type for the parameters, if known. Replace 'any' with specific types.
const useRestApi: any = (endpoint: string) => {
  const [data, setData] = useState<Data>({});
  const [state, setState] = useState<ApiState>({
    isError: false,
    isLoading: false,
  });

  const actions = {
    getItems: async (params: Record<string, any> = {}) => {
      const clone = {...params};
      const query = qs.stringify(clone);

      setState(prevState => ({...prevState, isError: false, isLoading: true}));

      try {
        const result: any = await getAxios().get(`/api/${endpoint}?${query}`);
        setData(prevData => ({
          ...prevData,
          listItems: result.data.result.data || result.data.result,
          listPagination: {
            total: result.data.result.total,
          },
        }));
      } catch (error) {
        setState(prevState => ({...prevState, isError: true, isLoading: false}));
        throw error;
      }

      setState(prevState => ({...prevState, isError: false, isLoading: false}));
    },
    getItem: async (id: string | number, params: Record<string, any> = {}) => {
      const clone = {...params};
      const query = qs.stringify(clone);

      setState(prevState => ({...prevState, isError: false, isLoading: true}));

      try {
        const result = await getAxios().get(`/api/${endpoint}/${id}?${query}`);
        setData(prevData => ({...prevData, detail: result.data.result || result.data}));
      } catch (error) {
        console.error(error);
        setState(prevState => ({...prevState, isError: true, isLoading: false}));
        throw error;
      }
    },
    customGet: async (path: string, params: Record<string, any> = {}, options: Record<string, any> = {}) => {
      const clone = {...params};

      for (const paramsKey in clone) {
        if (clone[paramsKey] === undefined || clone[paramsKey] === null) {
          delete clone[paramsKey];
        }
      }

      const query = new URLSearchParams(clone).toString();

      setState(prevState => ({...prevState, isError: false, isLoading: true}));

      let result;
      try {
        result = await getAxios().get(`/api/${endpoint}/${path}?${query}`, options);
        setState(prevState => ({...prevState, isLoading: false}));
      } catch (error) {
        console.error(error);
        setState(prevState => ({...prevState, isError: true, isLoading: false}));
        throw error;
      }

      return result;
    },
    deleteItem: async (id: string | number) => {
      setState(prevState => ({...prevState, isLoading: true}));

      try {
        await getAxios().delete(`/api/${endpoint}/${id}`);
        setState(prevState => ({...prevState, isLoading: false}));
      } catch (error) {
        console.error(error);
        setState(prevState => ({...prevState, isLoading: false}));
        throw error;
      }
    },
    createItem: async (values: Record<string, any>) => {
      setState(prevState => ({...prevState, isLoading: true}));

      let result;
      try {
        result = await getAxios().post(`/api/${endpoint}`, {...values});
        setState(prevState => ({...prevState, isLoading: false}));
      } catch (error) {
        console.error(error);
        setState(prevState => ({...prevState, isLoading: false}));
        throw error;
      }

      return result.data.result || result.data;
    },
    updateItem: async (id: string | number, values: Record<string, any>) => {
      setState(prevState => ({...prevState, isLoading: true}));

      let result;
      try {
        result = await getAxios().put(`/api/${endpoint}/${id}`, {...values});
        setState(prevState => ({...prevState, isLoading: false}));
      } catch (error) {
        console.error(error);
        setState(prevState => ({...prevState, isLoading: false}));
        throw error;
      }
      return result.data.result || result.data;
    },
    customUpdate: async (path: string, values: Record<string, any>) => {
      setState(prevState => ({...prevState, isLoading: true}));

      let result;
      try {
        result = await getAxios().put(`/api/${endpoint}/${path}`, {...values});
      } catch (error) {
        console.error(error);
        setState(prevState => ({...prevState, isLoading: false}));
        throw error;
      }
      return result.data.result || result.data;
    },
    customPost: async (path: string, values: Record<string, any>) => {
      setState(prevState => ({...prevState, isLoading: true}));

      let result;

      try {
        result = await getAxios().post(`/api/${endpoint}/${path}`, {...values});
      } catch (error) {
        console.error(error);
        setState(prevState => ({...prevState, isLoading: false}));
        throw error;
      }

      return result.data.result || result.data;
    },
    customDelete: async (path: string, values: Record<string, any>) => {
      setState(prevState => ({...prevState, isLoading: true}));

      let result;

      try {
        result = await getAxios().delete(`/api/${endpoint}/${path}`, {
          data: values,
        });
      } catch (error) {
        console.error(error);
        setState(prevState => ({...prevState, isLoading: false}));
        throw error;
      }

      return result.data.result || result.data;
    },
  };

  return {actions, data, state};
};

export default useRestApi;
