import {Navigate} from 'react-router-dom';
import {LOCAL_STORAGE_KEY} from '../../helpers/utils';
import {message} from 'antd';

const ProtectedRoute = (props: any) => {
  const isAuthenticated = Boolean(localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN));

  if (!isAuthenticated) {
    message.destroy();
    message.warning('You are not authenticated');
    return <Navigate to={'/auth/login'} replace />;
  }

  let currentUser;
  try {
    currentUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER) || '{}');
  } catch (e) {
    currentUser = {};
  }

  if (!currentUser?.roles || currentUser?.roles[0] !== props.role) {
    message.destroy();
    message.warning('You are not authorized to access this page');
    return <Navigate to={'/auth/login'} replace />;
  }

  return props.children;
};

export default ProtectedRoute;
