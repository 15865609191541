import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {FaShoppingCart, FaUser, FaUserShield, FaUserTie} from 'react-icons/fa'; // Importing icons

export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    // Any initialization code goes here
  }, []);

  const handleRoleSelection = (role: string) => {
    console.log('Selected role:', role);
    switch (role) {
      case 'Maestro admin':
        navigate('/admin/home');
        break;
      case 'Vendor':
        navigate('/vendor/home');
        break;
      case 'Enterprise Admin':
        navigate('/enterprise/home');
        break;
      case 'Enterprise Employee':
        navigate('/employee/home');
        break;
      default:
        // Handle any other cases or default behavior
        break;
    }
  };

  return (
    <div className='flex justify-center items-center h-screen'>
      <div className='text-center'>
        <h2 className='text-2xl font-semibold mb-8'>Welcome to Maestrowellness!</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <button
            onClick={() => handleRoleSelection('Maestro admin')}
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-12 px-12 rounded-lg flex flex-col items-center'
          >
            <FaUserTie className='text-3xl mb-[8px]' />
            Maestro admin
          </button>
          <button
            onClick={() => handleRoleSelection('Vendor')}
            className='bg-green-500 hover:bg-green-700 text-white font-bold py-12 px-12 rounded-lg flex flex-col items-center'
          >
            <FaShoppingCart className='text-3xl mb-[8px]' />
            Vendor
          </button>
          <button
            onClick={() => handleRoleSelection('Enterprise Admin')}
            className='bg-red-500 hover:bg-red-700 text-white font-bold py-12 px-12 rounded-lg flex flex-col items-center'
          >
            <FaUserShield className='text-3xl mb-[8px]' />
            Enterprise Admin
          </button>
          <button
            onClick={() => handleRoleSelection('Enterprise Employee')}
            className='bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-12 px-12 rounded-lg flex flex-col items-center'
          >
            <FaUser className='text-3xl mb-[8px]' />
            Enterprise Employee
          </button>
        </div>
      </div>
    </div>
  );
}
