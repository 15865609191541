import EmbedLayout from '../../EmbedLayout';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {VENDOR_PAYMENT_MODE_MAPPING} from '../../config';
import {sortByProperty} from '../../helpers/utils';
import {useCategoryApi} from '../../repositories/useCategoryApi';
import React, {useEffect} from 'react';
import {usePlanApi} from '../../repositories/usePlanApi';

dayjs.extend(customParseFormat);

export default function PricingOnVendor() {
  const {actions: actionsCategory, data: dataCategory, state: stateCategory} = useCategoryApi();
  const {actions: actionsPlan, data: dataPlan, state: statePlan} = usePlanApi();
  const [paymentMode, setPaymentMode] = React.useState<any>(VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value);

  useEffect(() => {
    actionsCategory.getItems({
      limit: 0,
    });
    actionsPlan.getItems({
      limit: 0,
    });
  }, []);

  return (
    <EmbedLayout
      colors={{
        primary: '7E38B7',
        secondary: 'F6EAFF',
      }}
    >
      <div className={'text-center flex items-center justify-center flex-col gap-[24px]'}>
        <div className={'displayMedium'}>Pricing</div>
        <div className={'bg-white border border-[#E2E2E2] rounded-[100px] flex flex-row items-center justify-center'}>
          <div
            onClick={() => {
              setPaymentMode(VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value);
            }}
            className={`bodyMedium cursor-pointer px-[24px] py-[10px] rounded-[100px] ${
              paymentMode === VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value ? 'bg-7E38B7 text-white' : ''
            }`}
          >
            Pay monthly
          </div>
          <div
            onClick={() => {
              setPaymentMode(VENDOR_PAYMENT_MODE_MAPPING.YEARLY.value);
            }}
            className={`bodyMedium cursor-pointer px-[24px] py-[10px] rounded-[100px] ${
              paymentMode === VENDOR_PAYMENT_MODE_MAPPING.YEARLY.value ? 'bg-7E38B7 text-white' : ''
            }`}
          >
            Pay yearly (save {VENDOR_PAYMENT_MODE_MAPPING.YEARLY.discount}%)
          </div>
        </div>
        <div className={'w-full overflow-y-hidden overflow-x-auto'}>
          <div className={'flex flex-row gap-[20px] min-w-full w-[1024px] p-[2px] justify-center'}>
            {sortByProperty(dataPlan?.listItems, 'price_monthly').map((plan: any) => {
              return (
                <div className={`w-[328px] bg-white rounded-[8px] text-left py-[25px] px-[30px]`}>
                  <div className={'text-7E38B7 titleExtraLarge mb-[8px]'}>{plan.title}</div>
                  <div className={'flex items-center justify-start gap-[8px] mb-[20px]'}>
                    <div className='left displayMedium'>
                      ${Number(paymentMode === VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value ? plan.price_monthly : plan.price_yearly).toFixed(0)}
                    </div>
                    <div className='right labelMedium'>
                      HKD
                      <br />/{paymentMode === VENDOR_PAYMENT_MODE_MAPPING.MONTHLY.value ? 'MO' : 'YR'}
                    </div>
                  </div>
                  <hr className={'text-E2E2E2 mb-[24px]'} />
                  <div className={'labelMedium mb-[16px]'}>
                    Leads unlocked
                    <br />
                    <span className={'font-semibold'}>{plan.leads_unlocked}</span>
                  </div>
                  <div className={'labelMedium mb-[24px]'}>
                    Transaction fee
                    <br />
                    <span className={'font-semibold'}>{plan.transaction_fee}%</span>
                  </div>

                  <a
                    href={'/vendors#vendor-form'}
                    className={
                      'rounded-[100px] text-white visited:text-white bg-7E38B7 py-[10px] flex items-center justify-center w-full text-center mb-[24px]'
                    }
                  >
                    Sign up now
                  </a>

                  {plan.features.map((feature: any, index: number) => {
                    return (
                      <div key={index} className={'flex items-start justify-start gap-[8px] bodyMedium'}>
                        <div className={'mt-[4px]'}>
                          <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M13 1.19995L5.8 10.8L1 5.99995'
                              stroke='#7E38B7'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <div>{feature}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </EmbedLayout>
  );
}
