import {useForm} from 'antd/es/form/Form';
import EnterpriseSignupForm from '../../components/pages/embed/EnterpriseSignupForm';
import EmbedLayout from '../../EmbedLayout';

export default function EnterpriseRequestDemo() {
  const [enterpriseSignupForm] = useForm<any>();
  const formTitle = {
    submitText: 'Book your demo now',
    submitURL: '',
  };
  const bestOptions = [
    {
      value: '0',
      label: 'Consulting',
    },
    {
      value: '1',
      label: 'Education',
    },
    {
      value: '2',
      label: 'Entertainment',
    },
    {
      value: '3',
      label: 'Fashion',
    },
    {
      value: '4',
      label: 'Food & Beverage',
    },
    {
      value: '5',
      label: 'Healthcare',
    },
    {
      value: '6',
      label: 'Hospitality & Travel',
    },
    {
      value: '7',
      label: 'Technology',
    },
    {
      value: '8',
      label: 'Retail',
    },
    {
      value: 'other',
      label: 'Others',
    },
  ];
  const countries = [
    {
      value: '1',
      label: 'HongKong',
    },
  ];

  function handleSubmit(values: any): void {
    try {
      console.log('DEBUG Register form VALUES: ', values);

      try {
        window.elementorFrontend.documentsManager.documents[String(process.env.REACT_APP_WORDPRESS_POPUP_ENTERPRISE_SIGN_UP)].getModal().hide();
        window.elementorProFrontend.modules.popup.showPopup({id: process.env.REACT_APP_WORDPRESS_POPUP_ENTERPRISE_THANK_YOU_REQUEST});
      } catch (e) {
        console.log('ERROR: ', e);
      }
    } catch (error) {
      console.error('ERROR: ', error);
    }
  }

  return (
    <EmbedLayout
      colors={{
        primary: '008080',
        secondary: 'E6F7F6',
      }}
    >
      <div className='flex justify-center'>
        <div className='w-[600px]'>
          <EnterpriseSignupForm
            {...{
              bestOptions,
              countries,
              enterpriseSignupForm,
              formTitle,
              handleSubmit,
              key: 'EnterpriseRequestDemoForm',
            }}
          />
        </div>
      </div>
    </EmbedLayout>
  );
}
