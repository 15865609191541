import './App.module.scss';

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {RouterProvider} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import enUs from 'antd/locale/en_US';
import 'dayjs/locale/en';

import styles from './App.module.scss';
import {routers} from './routers';
import {CurrentUserContext} from './contexts/CurrentUserContext';
import {ColorsContext} from './contexts/ColorsContext';

import {useEffect, useState} from 'react';

document.addEventListener('keydown', e => {
  if (document.body.getAttribute('loading') === 'true') {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
  }
});

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [colors, setColors]: any = useState(null);

  useEffect(() => {
    document.body.setAttribute(
      'style',
      `
      --primary: #${colors?.primary};
      --secondary: #${colors?.secondary};
    `
    );
  }, [colors]);

  // @ts-ignore
  return (
    <div className={styles.moduleWrapper}>
      <CurrentUserContext.Provider value={{currentUser, setCurrentUser}}>
        <ColorsContext.Provider value={{colors, setColors}}>
          <ConfigProvider
            locale={enUs}
            theme={{
              token: {
                // Seed Token
                colorPrimary: `${colors?.primary}`,
                colorLink: `var(--primary)`,
                fontFamily: 'inherit',
              },
              components: {
                Table: {
                  headerBg: `var(--secondary)`,
                  headerColor: `#5A5A5A`,
                  selectionColumnWidth: 10,
                  // bodySortBg: 'inherit',
                  headerSortHoverBg: 'var(--secondary)',
                  headerSortActiveBg: 'var(--secondary)',
                  rowSelectedBg: '#e3e3e3',
                  rowSelectedHoverBg: '#c0c0c0',
                },
                Button: {
                  colorPrimary: `#${colors?.primary}`,
                  algorithm: true, // Enable algorithm
                  primaryShadow: 'none',
                  defaultShadow: 'none',
                },
                Select: {
                  optionActiveBg: '#e3e3e3',
                  optionSelectedBg: '#e3e3e3',
                },
                Input: {
                  colorPrimary: `#${colors?.primary}`,
                  algorithm: true, // Enable algorithm
                },
              },
            }}
          >
            <HelmetProvider>
              <Helmet>
                {/*Default*/}
                <title>Maestro</title>
              </Helmet>
              <RouterProvider router={routers} />
            </HelmetProvider>
          </ConfigProvider>
        </ColorsContext.Provider>
      </CurrentUserContext.Provider>
    </div>
  );
}

export default App;
