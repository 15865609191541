import React from 'react';
import {formatDate} from '../../../../../helpers/utils';
import StarsReview from '../../../../shared/StarsReview';
import MAButton from '../../../../ui/button/MAButton';
import MAIcon from '../../../../ui/icon/MAIcon';
import VendorBrandInfoDetail from './VendorBrandInfoDetail';

type Props = {
  vendor: any;
};

export default function VendorRightMainInfo({vendor}: Props) {
  const handleClickReview = () => {
    document.querySelector('.reviewArea')?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  };

  return (
    <div>
      <span className='displaySmall'>{vendor?.name}</span>
      {/* reviews */}
      <div className='flex items-center my-[12px] gap-[20px]'>
        <div className='text-center px-[10px] py-[2px] bg-E2EDDE rounded-[10px] bodySmall'>{vendor?.category?.name}</div>
        {/* vendor stars */}
        <div className='flex gap-[4px]'>
          <p className='!font-medium bodyMedium'>{Number(vendor?.rating).toFixed(1)}</p>
          <StarsReview scoreNum={Number(vendor?.rating)} />
        </div>
        <span onClick={handleClickReview} className='lableMedium cursor-pointer underline'>{`${vendor?.total_reviews} reviews`}</span>
      </div>
      <div className='mb-[24px]'>
        <span className='titleMedium !font-normal'>{vendor?.description}</span>
      </div>
      <div className='flex flex-col mb-[24px] gap-y-[8px]'>
        <VendorBrandInfoDetail name='Banch HQ' text={vendor?.location?.name} />
        {/*<VendorBrandInfoDetail name='Company Size' text={vendor?.brandInfo?.companySize} />*/}
        <VendorBrandInfoDetail name='Active Since' text={formatDate(vendor?.active_date)} />
        <VendorBrandInfoDetail name='Website' href={vendor?.website} />
      </div>
      <div className='flex flex-row gap-[20px]'>
        <MAButton customBgColor={'E2E2E2'} customTextColor={'white'}>
          <div className='flex gap-[10px] justify-center items-center'>
            <MAIcon color={'white'} size={20} name={'message'} />
            <span className={'text-white'}>Message</span>
          </div>
        </MAButton>
        <MAButton>
          <div className='flex gap-[10px] justify-center items-center'>
            <MAIcon size={20} name={'bookmark'} />
            <span className='text'>Bookmark</span>
          </div>
        </MAButton>
      </div>
    </div>
  );
}
