import React, {useState} from 'react';
import {CAN_SIGN_CONTRACT_ACTIVITY_TYPES} from '../../config';
import MATabsText from '../ui/tabs/MATabsText';
import {ContractModal} from '../ui/modals/ContractModal';
import {VendorTimelineForTabHistory} from './VendorTimelineForTabHistory';
import {Table} from 'antd';
import {Link} from 'react-router-dom';
import {formatCurrencyUSD} from '../../helpers/utils';
import MAIcon from '../ui/icon/MAIcon';

interface VendorTimelineProps {
  firstContract: any;
  activities: any[];
  products: any[];
  onSignContract: (contract: any) => void;
  onDenyContract: (contract: any) => void;
}

export const VendorTimeline = ({products, firstContract, activities, onSignContract, onDenyContract}: VendorTimelineProps) => {
  const [currentTab, setCurrentTab]: any = useState('history');
  const [clickedTimeline, setClickedTimeline]: any = useState(null);

  const handleChangeTab = (value: any) => {
    console.log('DEBUG value', value);
    setCurrentTab(value);
  };

  const columns: any = [
    {
      title: 'ACTIVITY NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => <Link to={`/enterprise/products/${record.id}`}>{text}</Link>,
    },
    {
      title: <div className={'text-right'}>COST PER PERSON</div>,
      dataIndex: 'price',
      key: 'price',
      render: (text: any, record: any) => <div className={'text-right'}>{formatCurrencyUSD(record.price)}</div>,
    },
  ];

  return (
    <div>
      <MATabsText
        currentTab={currentTab || 'history'}
        onChange={handleChangeTab}
        tabs={[
          {label: 'History', value: 'history'},
          {label: 'Activity', value: 'activity'},
        ]}
      />
      <div className='mt-[20px]'>
        {currentTab === 'history' && (
          <VendorTimelineForTabHistory
            activities={activities}
            handleClickTimeline={(timeline: any) => {
              setClickedTimeline(timeline);
            }}
          />
        )}

        {currentTab === 'activity' && (
          <div>
            <div className='tableActivities'>
              <Table
                locale={{
                  emptyText: <div className='flex justify-center flex-col items-center gap-[12px] py-[50px]'>{'No data found'}</div>,
                }}
                pagination={false}
                dataSource={products}
                columns={columns}
              />
            </div>
          </div>
        )}

        {!!clickedTimeline && (
          <ContractModal
            isSignable={firstContract?.id === clickedTimeline?.id && CAN_SIGN_CONTRACT_ACTIVITY_TYPES.includes(clickedTimeline?.type)}
            signedAt={clickedTimeline?.contract?.enterprise_signed_at}
            content={clickedTimeline?.contract?.content}
            isOpen={!!clickedTimeline}
            onDeny={async () => {
              onDenyContract(clickedTimeline?.contract?.id);
              setClickedTimeline(null);
            }}
            onAgree={async () => {
              onSignContract(clickedTimeline?.contract?.id);
              setClickedTimeline(null);
            }}
            onCancel={() => {
              setClickedTimeline(null);
            }}
          />
        )}
      </div>
    </div>
  );
};
