import React, {useEffect, useState} from 'react';
import {ProductOfVendor} from '../../../../../types';
import styles from '../../../../../pages/enterprise/programs/start/EnterpriseProgramStart.module.scss';
import {Card, Col, Row} from 'antd';
import Meta from 'antd/es/card/Meta';
import {LOCAL_STORAGE_KEY} from '../../../../../helpers/utils';

export default function Step6() {
  const [dataProductsInCart, setDataProductsInCart] = useState<ProductOfVendor[]>([]);
  useEffect(() => {
    const dataCart = localStorage.getItem(LOCAL_STORAGE_KEY.STEP_DATA);
    if (dataCart) {
      const data = JSON.parse(dataCart);
      setDataProductsInCart(data.step3Data);
    }
  }, []);

  return (
    <div className={`${styles.moduleWrapper} w-full flex  h-full flex-col overflow-auto`}>
      <Col span={18} offset={3}>
        <h1 className='titleMedium mt-[20px]'>What's next?</h1>
        <Row className='mt-[15px]' gutter={30}>
          <Col span={8}>
            <Card>
              <Meta
                className='h-[70px]'
                description={
                  <span className='bodySmall text-2A2A2A'>
                    Once you proceed with your wellness program, each vendor will be notified with your service request.{' '}
                  </span>
                }
              />{' '}
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Meta
                className='h-[70px]'
                description={
                  <span className='bodySmall text-2A2A2A'>
                    Based on their capacity, amongst other variables, they can accept or reject your service order.{' '}
                  </span>
                }
              />{' '}
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Meta
                className='h-[70px]'
                description={
                  <span className='bodySmall text-2A2A2A'>
                    If accepted, a contract will be generated for both of you to digitally sign. <span className='underline'>How it works</span>
                  </span>
                }
              />
            </Card>
          </Col>
        </Row>

        {/* Show list product in cart using grid 2 colum wrap */}
        <div className='grid grid-cols-2 gap-4 mt-[40px]'>
          {dataProductsInCart?.length > 0 &&
            dataProductsInCart?.map(item => (
              <div className='flex flex-col border border-gray-300 rounded p-4 bg-white' key={item.id}>
                <p className='titleSmall'> {item.vendorName}</p>
                <p className='bodyMedium'>{item.name}</p>
              </div>
            ))}
        </div>
      </Col>
    </div>
  );
}
