import useRestApi from '../hooks/useRestApi';

interface LoginApi {
  actions: {
    createItem: (values: Record<string, any>) => Promise<any>;
  };
  state: {
    isLoading: boolean;
    error: any;
  };
}

export function useLoginApi(): LoginApi {
  return useRestApi('login');
}
